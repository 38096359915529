import { environment } from '../../environments/environment';

export enum CookieKey {
  TOKEN = 'token',
  LOCALE = 'locale',
  NODE_ID = 'node_id',
  COOKIE_CONSENT = 'cookie_consent'
}

export enum EXTERNAL_LINKS {
  DIVERSEY_COOKIE_POLICY = 'https://diversey.com/en/cookie-policy'
}


export const COOKIE_LIST = [
  {
    name: 'Strictly Necessary Cookies',
    value: 'Strictly Necessary',
    selected: true,
    disabled: true,
    expand: false,
    description: 'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.',
    cookieList: [
      {
        name: environment.COOKIE_PREFIX + 'token',
        domain: '.diverseyiot.com',
        expiration: '1 Day',
        description: 'This cookie is set when the user signs into the IoC. The same token used all IoC applications. The cookie has a one day lifespan.'
      },
      {
        name: environment.COOKIE_PREFIX + 'locale',
        domain: '.diverseyiot.com',
        expiration: '1 Day',
        description: 'This cookie is set as soon as user visits the website. It holds locale set for the application even when the user has not signed in. The cookie has a one day lifespan.'
      },
      {
        name: environment.COOKIE_PREFIX + 'node_id',
        domain: '.diverseyiot.com',
        expiration: '1 Day',
        description: 'This cookie is set when the user selects the hierarchy s/he wants to see for the IoC application. The cookie has a one day lifespan.'
      },
      {
        name: environment.COOKIE_PREFIX + 'user_node_id',
        domain: '.diverseyiot.com',
        expiration: '1 Day',
        description: 'This cookie is set when the user signs into the IoC with the user\'s hierarchy information. The cookie has a one day lifespan.'
      },
      {
        name: environment.COOKIE_PREFIX + 'is_pass_expired',
        domain: '.diverseyiot.com',
        expiration: '1 Day',
        description: 'This cookie is set when the user signs into the application with currently expired password. The cookie has a one day lifespan.'
      },
      {
        name: environment.COOKIE_PREFIX + 'cookie_consent',
        domain: '.diverseyiot.com',
        expiration: '',
        description: 'It is a session based cookie and is set when the user accepts or rejects cookie settings.'
      },
      {
        name: 'ApplicationGatewayAffinity',
        domain: '*.diverseyiot.com',
        expiration: '',
        description: 'This cookie is to enable server affinity or sticky session.'
      },
      {
        name: 'ApplicationGatewayAffinityCORS',
        domain: '*.diverseyiot.com',
        expiration: '',
        description: 'This cookie is to enable server affinity or sticky session even for cross-origin requests.'
      },
      {
        name: '_SS',
        domain: '.bing.com',
        expiration: '',
        description: 'It is a session based cookie and enables us to display map content using Bing Maps'
      },
      {
        name: '_EDGE_S',
        domain: '.bing.com',
        expiration: '',
        description: 'It is a session based cookie and enables us to display map content using Bing Maps'
      },
      {
        name: 'SRCHHPGUSR',
        domain: '.bing.com',
        expiration: '2 Years',
        description: 'Enables us to display map content using Bing Maps'
      },
      {
        name: 'SRCHUSR',
        domain: '.bing.com',
        expiration: '2 Years',
        description: 'Enables us to display map content using Bing Maps'
      },
      {
        name: 'SRCHUID',
        domain: '.bing.com',
        expiration: '2 Years',
        description: 'Enables us to display map content using Bing Maps'
      },
      {
        name: 'SRCHD',
        domain: '.bing.com',
        expiration: '2 Years',
        description: 'Enables us to display map content using Bing Maps'
      },
      {
        name: '_EDGE_V',
        domain: '.bing.com',
        expiration: '390 Days',
        description: 'Enables us to display map content using Bing Maps'
      },
      {
        name: 'MUID',
        domain: '.bing.com',
        expiration: '390 Days',
        description: 'Used by Microsoft as a unique user ID in advertising, site analytics, and other operational purposes'
      },
      {
        name: 'MUIDB',
        domain: '.bing.com',
        expiration: '390 Days',
        description: 'Used by Microsoft as a unique user ID in advertising, site analytics, and other operational purposes'
      }]
  },
  {
    name: 'Performance Cookies',
    value: 'Performance',
    selected: false,
    disabled: false,
    expand: false,
    description: 'These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. They help us know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you have visited our site.',
    cookieList: [
      {
        name: 'ai_user',
        domain: '*.diverseyiot.com',
        expiration: '1 Year',
        description: 'This cookie is associated with Microsoft Application Insights to identify each user uniquely.'
      }, {
        name: 'ai_session',
        domain: '*.diverseyiot.com',
        expiration: '30 Mins',
        description: 'This cookie is associated with Microsoft Application Insights for unique anonymous session.'
      }]
  }];