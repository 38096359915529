import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { KeysPipe } from './pipes';
import { NgPipesModule } from 'ngx-pipes';
import { GlobalRefService,
        LocalStorageService, CommonService,
        ResponsiveService, UserService,
        GoogleService, HttpClientService, LoaderService, ToasterMessageService
      } from './services';
import { AppHeaderComponent, UserProfileComponent, ResetPasswordComponent, DeactivateAccountComponent, AppCustomCookieConsentComponent } from './components';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { RouterModule} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/pac_i18n/', '.json');
}
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    NgPipesModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    KeysPipe, AppHeaderComponent, UserProfileComponent,
    AppFooterComponent, TermsAndConditionsComponent,
    ResetPasswordComponent, DeactivateAccountComponent, AppCustomCookieConsentComponent
  ],
  exports: [KeysPipe, AppHeaderComponent, UserProfileComponent, AppFooterComponent, ResetPasswordComponent, DeactivateAccountComponent, AppCustomCookieConsentComponent]
})
export class BaseModule {
   // include base module in app module with forRoot
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: BaseModule,
      providers: [GlobalRefService,
        LocalStorageService, CommonService, ResponsiveService,
        HttpClient, UserService, GoogleService, HttpClientService,
        LoaderService, ToasterMessageService]
    };
  }
  constructor (@Optional() @SkipSelf() parentModule: BaseModule) {
    if (parentModule) {
      throw new Error('BaseModule is already loaded. Import it in the AppModule only!');
    }
  }
}
