import { Injectable, Inject } from '@angular/core';

import { Observable } from 'rxjs';
import { CookieStorage } from 'app/base/services/cookie-storage.service';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ToasterMessageService } from 'app/base/services/toaster.service';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class AuthGuard  {
  private window: Window;

  constructor(
    private cookieStorage: CookieStorage,
    private toaster: ToastrService,
    private toasterMessageService: ToasterMessageService,
    @Inject(DOCUMENT) private document: Document) { 
      this.window = this.document.defaultView;
    }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.cookieStorage.checkCookieAvailability('token')) {
      const msg = this.toasterMessageService.getErrorMessage(8001);
      this.toaster.error(msg);
      if (environment && environment.DASHBOARD_HOST_URL) {
        this.window.location.href = environment.DASHBOARD_HOST_URL;
      }
      return false;
    }
    return true;
  }
}
