import { OrgListResolveGuardService } from 'app/base/services/org-list-resolve-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortalAdminComponent } from '../portal-admin.component';
import { LandingPageComponent } from '../components';
import { TermsAndConditionsComponent } from 'app/base/components/terms-and-conditions/terms-and-conditions.component';
import { AuthGuard } from '../../auth/auth.guard';
import { UserRoleGuard } from '../services';
import { CamelCaseDirective } from '../directives/capitalize';
import { LowerCaseDirective} from '../directives/lowercase';
import { UserDataResolveGuardService } from 'app/base/services/user-data-resolve-guard.service';
import { SolutionResolveGuardService } from 'app/base/services/solution-resolve-guard.service';
import { LocalesResolveGuardService } from 'app/base/services/locales-resolve-guard.service';
import { CountriesResolveGuardService } from 'app/base/services/countries-resolve-guard.service';

/***
* NOTE: Mentioned roles in routes are as follows
*  1: Diversey Admin
*  2: Admin
*  3: User  // role-id 3 wont be able to access portal-admin
***/
const routes: Routes = [
  {
    path: '',
    component: PortalAdminComponent,
    canActivate: [AuthGuard, UserRoleGuard],
    resolve: [
      UserDataResolveGuardService,
      SolutionResolveGuardService,
      LocalesResolveGuardService,
      CountriesResolveGuardService
    ],
    data: { roles: [1, 2, 3, 4] },
    children: [{
      path: '',
      redirectTo: 'landing',
      pathMatch: 'full'
    }, {
      path: 'landing',
      component: LandingPageComponent
    }, {
      path: 'terms-and-conditions',
      component: TermsAndConditionsComponent
    }, {
      path: 'organization',
      loadChildren: () => import('app/portal-admin/organizations/organizations.module').then(m => m.OrganizationModule)
    }, {
      path: 'user',
      loadChildren: () => import('app/portal-admin/users/users.module').then(m => m.UsersModule)
    }, {
      path: 'sites',
      loadChildren: () => import('app/portal-admin/sites/sites.module').then(m => m.SitesModule)
    }, {
      path: 'hierarchy',
      loadChildren: () => import('app/portal-admin/hierarchy/hierarchy.module').then(m => m.HierarchyModule)
    }, {
      path: 'sub-site',
      loadChildren: () => import('app/portal-admin/sub-site/sub-site.module').then(m => m.SubSiteModule)
    }, {
      path: 'bulk-upload',
      loadChildren: () => import('app/portal-admin/bulk-upload/bulk-uploads.module').then(m => m.BulkModule)
    }]
  }, {
    path: '**',
    redirectTo: '/landing'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    UserRoleGuard,
    UserDataResolveGuardService
  ]
})
export class RoutingModule { }

// export all the routing components used
export const ROUTING_COMPONENTS = [
  PortalAdminComponent,
  LandingPageComponent,
  CamelCaseDirective,
  LowerCaseDirective
];
