<div class="select-dropdown" (click)="toggleCustomDropdown($event)" [ngClass]="{'active': showCustomDropdown }">
  <div class="label">
    <span>{{'hierarchy_and_sites' | translate}}</span>
  </div>
  <div class="content-container" [style.display]="!showCustomDropdown ? 'none' : 'block'" (click)="$event.stopPropagation()">
    <ul class="hierarchy-list">
      <ng-container *ngTemplateOutlet="recursiveHierarchyList; context: {$implicit: hierarchiesList, level: 'first'}"></ng-container>
    </ul>
  </div>
</div>

<!-- ng-container for recursively showing hierarchy list -->
<ng-template #recursiveHierarchyList let-hierarchiesList let-level="level" let-parent="parent">
  <li *ngFor="let node of hierarchiesList; let i = index" class="hierarchy-item" [title]="node.isDisabled ? showMessage(4515): !node.is_active ? 'Deactivated node':''">
    <div class="node-container {{level}}" [ngClass]="{'is_active-parent-node': activeTopLevelNode === node, expanded: node.expanded, 'section-disabled' : !node.is_active || node.isDisabled, 'disable-node-container': isDisabled }"
      (click)="$event.stopPropagation()">
      <div class="details-container">
        <span class="node-state">
          <i class="fa" (click)="nodeClicked(node, parent)" [ngClass]="node.type == 'Site' ? 'fa-map-marker' : (isSingleSelectWithoutSite && node.path_length == activeTopLevelNode?.hierarchySchema?.levelsCount) ? 'fa-thumb-tack' : node.expanded ? 'fa-minus-square-o' : 'fa-plus-square-o'"></i>
        </span>
        <!--checkbox for multi select-->
        <input *ngIf="config.multiselect" type="checkbox" [checked]="node.checked" (change)="updateMultipleSelection($event, node, parent);"
          (click)="$event.stopPropagation()" id="ch_{{node.id}}" [ngClass]="{'section-disabled' : !node.is_active || optionDisabled || node.isDisabled}" />
          
          <label for="ch_{{node.id}}" [ngClass]="{
          'disabled': disabled || optionDisabled,
          'partially-selected': isPartiallySelected(node),
          'section-disabled' : !node.is_active || optionDisabled || node.isDisabled
        }">
        </label>

        <span class="node-name" [ngClass]="{'disabled': disabled}">{{node.name}} - ( {{node.type}} )</span>
        <span *ngIf="node.path_length == 0 && node.fetchingSchema" class="status-indicator">
          <i class="fa fa-spinner fa-spin fa-fw"></i>{{'fetching_schema' | translate}}
        </span>
      </div>
    </div>

    <ul *ngIf="node.Client" class="hierarchy-list" [style.display]="!node.expanded ? 'none' : 'block'">
      <ng-container *ngTemplateOutlet="recursiveHierarchyList; context: {$implicit: node.Client, level: 'custom', parent: node}"></ng-container>
    </ul>
    <ul *ngIf="node.Company" class="hierarchy-list" [style.display]="!node.expanded ? 'none' : 'block'">
      <ng-container *ngTemplateOutlet="recursiveHierarchyList; context: {$implicit: node.Company, level: 'custom', parent: node}"></ng-container>
    </ul>
    <ul *ngIf="node.Customer" class="hierarchy-list" [style.display]="!node.expanded ? 'none' : 'block'">
      <ng-container *ngTemplateOutlet="recursiveHierarchyList; context: {$implicit: node.Customer, level: 'custom', parent: node}"></ng-container>
    </ul>
    <ul *ngIf="node.Site" class="hierarchy-list" [style.display]="!node.expanded ? 'none' : 'block'">
      <ng-container *ngTemplateOutlet="recursiveHierarchyList; context: {$implicit: node.Site, level: 'last', parent: node}"></ng-container>
    </ul>
  </li>
</ng-template>
