import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
// import topnav component
import { TopNavBarComponent } from 'app/shared/components';
// import topnav item model
import { TopNavItemModel } from 'app/shared/models';
// import { MapComponent } from './components/map/map.component';
import { CommonService, CookieStorage, LocalDataStoreService } from 'app/base/services';
import { Subject ,  Observable ,  Subscription } from 'rxjs';
import { PortalAdminService } from './services/portal-admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'portal-admin',
  templateUrl: './portal-admin.component.html'
})
export class PortalAdminComponent implements OnInit, OnDestroy {
  @Output() public messageEvent = new EventEmitter<string>();
  // topnav items
  items: TopNavItemModel[];
  public userSubscription: Subscription;

  constructor(private _commonService: CommonService,
              private portalAdminService: PortalAdminService,
              private cookieStorage: CookieStorage,
              private localDataStoreService: LocalDataStoreService
  ) {
    this.topNavInit();
  }

  ngOnInit() {
    this.userSubscription = this.localDataStoreService.currentUserState.subscribe(userData => {
      if (userData) {this.setData(userData); }
    });

    this._commonService.emitSolutionName('portal-admin');
  }

  setData(userData) {
    if (this.cookieStorage.checkCookieAvailability('locale')) {
      if (this.cookieStorage.getCookie('locale') !== userData['locale']) {
        this.portalAdminService.syncLanguage(userData['locale']);
      }
    } else {
      this.portalAdminService.syncLanguage(userData['locale']);
    }
  }

  // for initializing top nav items
  topNavInit() {
    this.items = [
      {
        name: 'Company',
        iconUrl: null,
        routePath: 'company',
        active: false
      }, {
        name: 'Sites',
        iconUrl: null,
        routePath: 'sites',
        active: false
      }, {
        name: 'Users',
        iconUrl: null,
        routePath: 'users',
        active: false
      }, {
        name: 'Assets',
        iconUrl: null,
        routePath: 'assets/add',
        active: false
      }
    ];
  }
  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  componentAdded(componentReference) {
    componentReference.messageEvent && componentReference.messageEvent.subscribe(() => {
      this.messageEvent.emit();
    });
  }
}
