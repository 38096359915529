import { Injectable,  Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class CookieStorage {
  private window: Window;
  constructor(@Inject(DOCUMENT) private document: Document) { 
    this.window = this.document.defaultView;
   }

  getCookie(property) {
    const cookiePrefix = environment.COOKIE_PREFIX;
    const name = `${cookiePrefix}${property}` + '=';
    const decodedCookie = decodeURIComponent(this.document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  checkCookieAvailability(value) {
    const data = this.getCookie(value);
    if (data !== '') {
      return true;
    } else {
      return false;
    }
  }

  clearCookie(value) {
    const domainName = environment.COOKIE_DOMAIN_NAME;
    const cookiePrefix = environment.COOKIE_PREFIX;
    this.document.cookie = `${cookiePrefix}${value}=;domain=${domainName}`;
  }

  setCookie(name, value) {
    const domainName = environment.COOKIE_DOMAIN_NAME;
    const cookiePrefix = environment.COOKIE_PREFIX;
    this.document.cookie = `${cookiePrefix}${name}=${value};domain=${domainName}`;
  }
}
