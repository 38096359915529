
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { CommonService } from 'app/base/services/common.service';
import { LocalDataStoreService } from 'app/base/services/local-data-store.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpClientService } from 'app/base/services/http.client-module.service';
import { catchError ,  map } from 'rxjs/operators';

import { CookieStorage } from '../../base/services/cookie-storage.service';

@Injectable()
export class PortalAdminService {
  username = '';
  private nodeAPI = environment.NODE_URL;
  private URLS = {
    countries: `${environment.NODE_URL}common/countries`,
    landingPage: `${environment.NODE_URL}landing-page`,
    siteBulkUpload: `${environment.NODE_URL}site/uploads`,
    organization: `${environment.NODE_URL}report/organization/`,
    organizations: `${environment.NODE_URL}report/organizations`,
    reportDownload: `${environment.NODE_URL}report/download`,
    imediateChild: `${environment.NODE_URL}hierarchy/immediate/child/`,
    moveSite: `${environment.NODE_URL}site/move-site/`,
    bulkUploadFileUrl: `${environment.NODE_URL}site/download/bulk-upload-template`
  };

  constructor(private http: HttpClient,
              private httpClientService: HttpClientService,
              private cookieStorage: CookieStorage,
              private localDataStoreService: LocalDataStoreService,
              private commonService: CommonService) {

    this.localDataStoreService.currentUserState.subscribe(userData => {
      this.username = userData ? userData.username : '';
    });
  }

  // integrated with pac-V2
  getLandingPageData() {
    return this.httpClientService.get(this.URLS.landingPage)
      .pipe(
        map(res => res),
        catchError(error => this.handleError(error))
      );
  }

  uploadImage(imagePayload): Observable<any> {
    return this.http.post(this.nodeAPI + 'organization/uploads', imagePayload )
     .pipe(
       map(res => this.httpClientService.handleResponse(res)),
       catchError(error => this.handleError(error))
      );
  }

  assignSolutions(orgId, solutions) {
    const url = `${this.nodeAPI}organization/solution-assign/${orgId}`;
    return this.httpClientService.put(url, { solutions: solutions })
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  addNewCompany(companyFormData): Observable<any> {
    return this.httpClientService.post(this.nodeAPI + 'hierarchy', companyFormData)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }
  updateOrg(orgId, org): Observable<any> {
    return this.httpClientService.put(this.nodeAPI + `organization/${orgId}`, org)
    .pipe(
      map(response => response),
      catchError(error => this.handleError(error))
    );
  }

  fetchOrganizationList(request) {
    const url = `${this.nodeAPI}organization/list/all`;
    return this.httpClientService.post(url, request)
    .pipe(
      map(response => response),
      catchError(error => this.handleError(error))
    );
  }

  fetchListOfOrg(request) {
    this.commonService.toggleLoader(true);
    const url = `${this.nodeAPI}organization/list`;
    return this.httpClientService.post(url, request)
      .pipe(
        map(res => {
          if (res && res.data) {
            if (res.data.organizations) {
              this.localDataStoreService.setOrganizations(res.data.organizations);
              this.commonService.toggleLoader(false);
            }
          }
          return res;
        }),
        catchError(error => this.handleError(error))
      );
  }

  getOrgDetails(id) {
    const url = `${this.nodeAPI}organization/${id}`;
    return this.httpClientService.get(url)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  getParentSolutions(id) {
    const url = `${this.nodeAPI}organization/solutions/${id}`;
    return this.httpClientService.get(url)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  activateDeactivateOrg(orgId, flag) {
    const request = { is_active: flag };
    const url = `${this.nodeAPI}organization/action/${orgId}`;
    return this.httpClientService.put(url, request)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  disableOrg(orgId) {
    const request = { is_disabled: true };
    const url = `${this.nodeAPI}organization/action/${orgId}`;
    return this.httpClientService.put(url, request)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  syncLanguage(lang) {
    if (lang) {
      this.cookieStorage.setCookie('locale', lang);
    }
  }

  // be locked in, hence Authorization token not-required
  getCountries() {
    return this.httpClientService.getDataWithNoAuth(this.URLS.countries)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  uploadFile(formData): Observable<any> {
    return this.http.post(this.URLS.siteBulkUpload, formData)
     .pipe(
       map(res => this.httpClientService.handleResponse(res)),
       catchError(error => this.handleError(error))
      );
  }

  getOrgReport(orgId): Observable<any> {
    return this.httpClientService.get(this.URLS.organization + orgId)
    .pipe(
      map(res => this.httpClientService.handleResponse(res)),
      catchError(error => this.handleError(error))
     );
  }

  getAllOrgReport(queryParams = null): Observable<any> {
    let params = '?';
    if (queryParams) {
      const tempData = Object.keys(queryParams);
      tempData.forEach(val => {
        params = params + val + '=' + queryParams[val] + '&';
      });
      params = params.slice(0, params.length - 1); // to remove '&' from end of the string
    }
    return this.httpClientService.get(this.URLS.organizations + params)
    .pipe(
      map(res => this.httpClientService.handleResponse(res)),
      catchError(error => this.handleError(error))
     );
  }

  riskLevels() {
    return [ 'low', 'medium', 'high', 'over'];
  }

  userAndSiteThresholdLimit() {
    return [25, 100, 250, 1000, 2000, 5000];
  }

  downlaodOrganizationReport(): Observable<any> {
    return this.httpClientService.download(this.URLS.reportDownload)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
     );
  }
  getImmediateOrgChild(nodeId) {
    return this.httpClientService.get(this.URLS.imediateChild + nodeId)
    .pipe(
      map(res => res.data),
      catchError(error => this.handleError(error))
    );
  }
  moveSite(siteId, body) {
    return this.httpClientService.put(this.URLS.moveSite + siteId, body)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  getBulkUploadTemplate() {
    return this.httpClientService.get(this.URLS.bulkUploadFileUrl)
      .pipe(
        map(response => response),
        catchError(error => this.handleError(error))
      );
  }

  // TODO: Handle Error and  Loader
  private handleError(error) {
    return observableThrowError(error.error || 'Server Error');
  }

}
