import { of } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import * as CryptoJS from 'crypto-js';

export function haveDiverseyAccess(org_id) {
  return (org_id === 1);
}

export function isAdminUser(user_role_id) {
  return (user_role_id === 1 || user_role_id === 2);
}

export function isSiteUser(user_role_id) {
  return (user_role_id === 3);
}

export function isPowerAdmin(user_role_id) {
  return (user_role_id === 4);
}

export function isSubAdminUser(accessible_nodes) {
  return (accessible_nodes !== null && Array.isArray(accessible_nodes) && accessible_nodes.length > 0);
}

export function isTileAccessible(org_type, have_accessible_nodes) {
  if (org_type === 'Root' && have_accessible_nodes) {
    return { Company: false, Client: true, Customer: true, Site: true, User: true };
  } else if (org_type === 'Company' && have_accessible_nodes) {
    return { Company: false, Client: false, Customer: true, Site: true, User: true };
  } else if (org_type === 'Client' && have_accessible_nodes) {
    return { Company: false, Client: false, Customer: false, Site: true, User: true };
  } else if (org_type === 'Customer' && have_accessible_nodes) {
    return { Company: false, Client: false, Customer: false, Site: false, User: false };
  } else { return { Company: true, Client: true, Customer: true, Site: true, User: true }; }
}

export function getUmlautsRegExpression() {
  return 'ʏːeːyːiːɛəôąöóœøōõîïíīįìûüùúūÿèéêëēėęàáâäæãåāßśšłžźżçćčñńÀÁÂÄÆÃÅĀÈÉÊËĒĖĘŸÛÜÙÚŪÎÏÍĪĮÌÔÖÒÓŒØŌÕŚŠŁŽŹŻÇĆČÑŃa-zA-Z';
}

// to fetch value from reactive form controllers with formGrop or with out
export function getFormControl(form, control, group?) {
  let val;
  if (group) {
    val = form.get(group).get(control).value;
  } else {
    val = form.get(control).value;
  }
  return val;
}

export function latValValidator(control: UntypedFormControl) {
  const latVal = control.value;
  if (latVal > 90 || latVal < -90) {
    return of({
      incorrectValue: true
    });
  }
  return of(null);
}

export function longValValidator(control: UntypedFormControl) {
  const longVal = control.value;
  if (longVal > 180 || longVal < -180) {
    return of({
      incorrectValue: true
    });
  }
  return of(null);
}

export function patternValidator(control: UntypedFormControl) {
  /* eslint-disable-next-line */
  const pattern = /[\[\]\+\(\)\{\}:\"\?&\^\%\$\#\@\!\~*]/;
  const value = control.value;
  if (value && pattern.test(value)) {
    return of({
      patternValidator: pattern.test(value)
    });
  }
  return of(null);
}

export function isDiverseyAdmin(user_role_id, accessible_nodes, org_id) {
  return ((user_role_id === 1 || user_role_id === 2) && accessible_nodes === null && org_id === 1);
}

export function isOrgSubAdmin(user_role_id, accessible_nodes) {
  return (user_role_id === 2 && accessible_nodes !== null);
}

export function encryptPassword(passData, email) {
  const encrypt = CryptoJS.AES.encrypt(passData, email);
  return encrypt.toString();
}

export function sortData(data) {
  if (data.type === 'Root') {
    if (data.Company) {
      sortArrayOfObjects(data.Company, 'name');
      data.Company.forEach(comp => {
        sortData(comp);
      });
    }
    if (data.Client) {
      sortArrayOfObjects(data.Client, 'name');
      data.Client.forEach(cli => {
        sortData(cli);
      });
    }
  } else if (data.type === 'Company') {
    if (data.Client) {
      sortArrayOfObjects(data.Client, 'name');
      data.Client.forEach(cli => {
        sortData(cli);
      });
    }
  } else if (data.type === 'Client') {
    if (data.Customer) {
      sortArrayOfObjects(data.Customer, 'name');
      data.Customer.forEach(cust => {
        sortData(cust);
      });
    }
    if (data.Site) {
      sortArrayOfObjects(data.Site, 'name');
    }
  } else if (data.type === 'Customer') {
    if (data.Site) {
      sortArrayOfObjects(data.Site, 'name');
    }
  }
}

export function sortArrayOfObjects(arrayToSort, key) {
  function compareObjects(a, b) {
    if (a[key].toLowerCase() < b[key].toLowerCase()) {
      return -1;
    } else if (a[key].toLowerCase() > b[key].toLowerCase()) {
      return 1;
    }
    return 0;
  }
  return arrayToSort.sort(compareObjects);
}
