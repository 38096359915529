import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { FeatureListService } from './feature-list.services';
import { Subscription } from 'rxjs';
import { ToasterMessageService } from 'app/base/services';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { constants } from 'app/base/constants';
@Component({
  selector: 'feature-list',
  templateUrl: './feature-list.component.html',
  styleUrls: ['../../../../scss/_forms_new.scss', './feature-list.component.scss'],
  host: {
    '(document:click)': 'closeDrop($event)'
  }
})

export class FeatureListComponent implements OnInit, OnChanges {
  @Input() selectedSolutions: any;
  @Input() activeOtherDropdown: any;
  @Output() onSelectionChange = new EventEmitter<number[]>();
  @Output() activeFeatureDropdown = new EventEmitter();
  @Input() isEditView: boolean;
  @Input() currentFeatures = [];
  @Input() clearFeatures = false;
  @Input() showDistributor;
  @Input() showGlobalAdmin;
  @Input() removeSelectedFeatures: string = null;
  @Input() addSelectedFeatures: string = null;
  @Input() parentId: any = null;
  @Input() initiateFeatures = false;
  public initialLoad = false;
  public solutionIds: any = [];
  public featureList = [];
  public features = [];
  public selectedSolutionsId = [];
  public featureListActiveClass = false;
  public subscription: Subscription;
  public solutionList: any[] = [];

  public assignedFeatures = [];
  public userDetails: any;
  private checkboxValueUpdate = false;

  constructor(
    private featureListService: FeatureListService,
    private toasterMessageService: ToasterMessageService,
    private toaster: ToastrService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.userDetails = this.activatedRoute.snapshot.data.userData || null;
    if (this.isEditView) {
      const userOrg = this.userDetails ? this.userDetails.organization : null;
      const userRoleId = this.userDetails.user_role_id;
      userOrg.org_parent_id = Number(userOrg.org_parent_id);
      // this.showGlobalAdmin = (!userOrg.org_parent_id && (userRoleId === 1 || userRoleId === 2) && userOrg.org_type !== 'Customer');
      // this.showDistributor = (userOrg.org_parent_id === 1 || !userOrg.org_parent_id && userOrg.org_type !== 'Customer');
    }
    this.getAllSolutions();
    this.featureListService.getUsersFeatureList().subscribe(userData => {
      if (userData && userData.metadata) {
        this.featureList = userData.metadata.features || [];
      }
    });
    // this.userDetails: will present only on edit page
    if (this.userDetails) {
      this.getUserAssignedFeatures(this.userDetails.id);
    }
  }

  ngOnChanges(change) {
    const values = Object.keys(this.activeOtherDropdown).map(key => this.activeOtherDropdown[key]);
    values.forEach(elements => {
      if (elements === 'active' && this.featureListActiveClass) {
        this.featureListActiveClass = false;
      }
    });
    const currentValue = change.selectedSolutions.currentValue || [];
    const previousValue = change.selectedSolutions.previousValue || [];
    const condition = (change && ((currentValue && currentValue.length) || (previousValue && previousValue.length)));
    // on edit-scenario current & previous will have same values,
    // So, call updateFeaturesDropdown to populate features dropdown
    if (condition || this.userDetails && !this.initialLoad) {
      this.initialLoad = true;
      this.updateFeaturesDropdown(currentValue, previousValue);
    }

    if (change.clearFeatures && change.clearFeatures.currentValue === true) {
      this.features.forEach(el => {
        el.features.forEach(features => features.checked = false);
      });
      this.onSelectionChange.emit(this.features);
    }

    const removeFeature = change.removeSelectedFeatures;
    if (removeFeature && removeFeature.currentValue) {
      this.features = this.features.filter(sol => sol.solution_name !== removeFeature.currentValue);
      this.removeSelectedFeatures = null;
    }
    const addFeature = change.addSelectedFeatures;
    this.addFeatures(addFeature);

    const diverseyAdminCondition = change.showGlobalAdmin;
    if (diverseyAdminCondition && diverseyAdminCondition.currentValue === true) {
      // add diversey global admin
      this.addFeature(constants.DISH.SOLUTION_NAME, constants.DISH.FEATURES.global_admin);
    } else if (diverseyAdminCondition && diverseyAdminCondition.currentValue === false) {
      // remove diversey global admin
      this.removeFeature(constants.DISH.SOLUTION_NAME, constants.DISH.FEATURES.global_admin);
    }

    const diverseyDistributorCondition = change.showDistributor;
    if (diverseyDistributorCondition && diverseyDistributorCondition.currentValue === true) {
      // add distributor feature
      this.addFeature(constants.DISH.SOLUTION_NAME, constants.DISH.FEATURES.distributor);
    } else if (diverseyDistributorCondition && diverseyDistributorCondition.currentValue === false) {
      // remove distributor feature
      this.removeFeature(constants.DISH.SOLUTION_NAME, constants.DISH.FEATURES.distributor);
    }

    const clearAllSeletedFeature = change.initiateFeatures;
    if (clearAllSeletedFeature && clearAllSeletedFeature.currentValue === true) {
      this.features = [];
    }
  }

  addFeatures(addFeature) {
    if (addFeature && addFeature.currentValue) {
      const foundSolution = this.solutionIds.find(sol => sol.name === addFeature.currentValue);
      if (foundSolution) {
        const foundFeature = this.featureList.find(sol => sol.solution_id === foundSolution.id);
        let tempFeatures;
        if (foundFeature && foundSolution.name === constants.DISH.SOLUTION_NAME) {
          tempFeatures = this.generateFeaturesDropdownData(foundFeature.solution_id, foundSolution.name, foundFeature.features);
        } else { tempFeatures = foundFeature; }
        const solutionFoundAlready = this.features.find(f => f.solution_name === foundSolution.name);
        if (foundFeature && !solutionFoundAlready) {
          this.features.push({
            solution_id: foundFeature.solution_id,
            solution_name: foundSolution.name,
            features: tempFeatures.features
          });
        }
      }
    }
  }

  removeFeature(solName, featureName) {
    const found = this.features.findIndex(sol => sol.solution_name === solName);
    if (found !== -1) {
      const features = this.features[found].features;
      if (features.findIndex(data => data.name === featureName) !== -1) {
        features.splice(features.findIndex(data => data.name === featureName), 1);
      }
    }
  }

  addFeature(solName, featureName) {
    const found = this.features.findIndex(sol => sol.solution_name === solName);
    if (found !== -1) {
      const features = this.features[found].features;
      if (features.findIndex(data => data.name === featureName) === -1) {
        features.push({ id: 2, name: featureName, checked: false, is_disabled: this.isEditView });
      }
    }
  }

  updateFeaturesDropdown(currentValue = null, previousValue = null) {
    if (this.solutionIds && currentValue) {
      if (previousValue.length !== currentValue.length || this.userDetails) {
        this.updateSelectedSolutions(currentValue);
      }
    }
  }

  // update selected solutions-id array on solution change
  updateSelectedSolutions(values) { // values: Array<string>
    this.selectedSolutionsId = [];
    values.forEach(cValue => {
      this.solutionIds.forEach(data => {
        if (data.name === cValue) {
          this.selectedSolutionsId.push(data);
        }
      });
    });
    if (!this.checkboxValueUpdate) {
      this.populateFeaturesList();
    }
  }

  // update dropdown, whenever solutions dropdown is updated
  populateFeaturesList() {
    this.features = [];
    if (this.selectedSolutionsId && this.selectedSolutionsId.length) {
      this.featureList.forEach(feature => {
        this.selectedSolutionsId.forEach(data => {
          if (feature.solution_id === data.id) {
            const solutionsOBJ = this.generateFeaturesDropdownData(data.id, data.name, feature.features);
            this.features.push(solutionsOBJ);
            if (this.isEditView) {
              this.generatePrePopulatedFeaturesDropdownData();
            }
          }
        });
      });
    }
  }

  generateFeaturesDropdownData(id, solutionName, features) {
    const solutionsObj = {
      solution_id: id,
      solution_name: solutionName,
      features: []
    };
    features.forEach(value => {
      const obj = { id: value.id, name: value.name, checked: false, is_disabled: this.isEditView };
      if (value.name === constants.DISH.FEATURES.global_admin && this.showGlobalAdmin) {
        solutionsObj.features.push(obj);
      } else if (value.name !== constants.DISH.FEATURES.global_admin && value.name !== constants.DISH.FEATURES.distributor) {
        solutionsObj.features.push(obj);
      } else if (value.name === constants.DISH.FEATURES.distributor && (this.parentId === 1 || !this.parentId) && this.showDistributor) {
        solutionsObj.features.push(obj);
      }
    });
    return solutionsObj;
  }

  generatePrePopulatedFeaturesDropdownData() {
    if (this.isNotEmptyArray(this.features)) {
      this.features.forEach(data => {
        const featureFound = this.assignedFeatures.find(assigned => assigned.solution_id === data.solution_id);
        if (featureFound && featureFound.features && Array.isArray(featureFound.features)) {
          featureFound.features.forEach(feature => {
            const valEl = data.features.find(val => val.name === feature.name);
            if (valEl) {
              valEl.checked = true;
            }
          });
        }
      });
      this.onSelectionChange.emit(this.features);
    }
  }

  isNotEmptyArray(arr) {
    return arr && arr.length && Array.isArray(arr);
  }

  toggleActive(event) {
    event.stopPropagation();
    this.featureListActiveClass = !this.featureListActiveClass;
    this.activeFeatureDropdown.emit(this.featureListActiveClass);
  }

  closeDrop(event) {
    this.featureListActiveClass = false;
  }

  onFeatureChange(featureId, solutionId, solutionName, currentCheckboxValue = null, solutionIdx, featureIdx, featureName) {
    this.checkboxValueUpdate = true; // flag
    this.features[solutionIdx].features[featureIdx].checked = !currentCheckboxValue;
    if (this.isEditView) {
      const obj = this.currentFeatures.find(sol => sol.solution_id === solutionId);
      if (obj && Object.keys(obj).length) {
        if (obj.features && obj.features.length) {
          const feature = obj.features.find(feat => feat.id === featureId);
          if (feature) {
            feature.checked = !currentCheckboxValue;
          } else {
            obj.features.push({ id: featureId, name: featureName, checked: true });
          }
        }
      } else {
        const featureObj = { id: featureId, name: featureName, checked: true };
        const solutionObj = {
          solution_id: solutionId,
          solution_name: solutionName,
          features: [featureObj]
        };
        this.currentFeatures.push(solutionObj);
      }
    } else {
      this.assignedFeatures.forEach(feature => {
        if (feature.solution_id === solutionId) {
          feature.features.forEach(value => {
            value.checked = (value.id === featureId) ? !currentCheckboxValue : false;
          });
        }
      });
    }
    this.onSelectionChange.emit(this.features);
  }

  getAllSolutions() {
    this.featureListService.getSolutions().subscribe(res => {
      if (res && res.data) {
        res.data.forEach(element => {
          this.solutionIds.push(element);
        });
      }
    }, err => this.showError(err.code));
  }

  showError(code) {
    const msg = this.toasterMessageService.getErrorMessage(code);
    this.toaster.error(msg);
  }

  // get features already assigned to the editable-user
  getUserAssignedFeatures(userid) {
    this.featureListService.fetchAllUserAssignedFeatures(userid).subscribe(response => {
      if (response && response.data && response.data.metadata) {
        this.assignedFeatures = this.buildUserAssignedFeatureValues(response.data.metadata.features);
        this.generatePrePopulatedFeaturesDropdownData();
      }
    });
  }

  displaySelectedFeatures(): string {
    const featuresSelected = [];
    if (this.features && this.features.length) {
      this.features.forEach(data => {
        data.features.forEach(feature => {
          if (feature.checked) {
            featuresSelected.push(feature.name);
          }
        });
      });
      return featuresSelected.join(', ');
    } else if (!this.features) {
      return 'No Features found';
    }
  }

  buildUserAssignedFeatureValues(userFeatures) {
    const temp = [];
    userFeatures.forEach(data => {
      const solutionsObj = {
        solution_id: data.solution_id,
        features: []
      };
      data.features.forEach(value => {
        const obj = {
          id: value.id,
          name: value.name,
          checked: true,
          disabled: value.disabled,
          existed: value.existed
        };
        solutionsObj.features.push(obj);
      });
      temp.push(solutionsObj);
    });
    return temp;
  }
}
