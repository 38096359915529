/* eslint-disable angular/timeout-service */

import {throwError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
// import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError ,  map } from 'rxjs/operators';

import { CommonService, CookieStorage, LocalDataStoreService, LocalStorageService } from 'app/base/services';
import { ToastrService } from 'ngx-toastr';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'environments/environment';
import { ToasterMessageService } from './toaster.service';
import { LoaderService } from './loader.service';

@Injectable()
export class HttpClientService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  window: Window;

  constructor(private http: HttpClient,
    private cookieStorage: CookieStorage,
    private toaster: ToastrService,
    private oauthService: OAuthService,
    private loaderService: LoaderService,
    private localDataStoreService: LocalDataStoreService,
    private toasterMessageService: ToasterMessageService,
    private localStorageService: LocalStorageService,
    ) { }

  // GET: method with authorization header
  get(url) {
    return this.http.get(url, this.httpOptions).pipe(
      map(res => this.handleResponse(res)),
      catchError(error => this.handleError(error))
    );
  }

  download(url) {
    return this.http.get(url, {responseType: 'arraybuffer'}).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => throwError(err))
    );
  }
  downloadpdf(url) {
    const options = {
      responseType: 'blob' as const,
      headers: new HttpHeaders({

      })
    };
    const token = this.httpOptions.headers.get('Authorization');
    options.headers.set('Authorization', token);
    return this.http.get(url, options);
  }

  // GET: method without authorization header
  getDataWithNoAuth(url) {
    return this.http.get(url).pipe(
      map(res => this.handleResponse(res)),
      catchError(error => this.handleError(error))
    );
  }

  // HttpClientModule Post method call
  post(url, postBody: any) {
    return this.http.post(url, postBody, this.httpOptions)
    .pipe(
      map(res => this.handleResponse(res)),
      catchError(error => this.handleError(error))
    );
  }

  delete(url, postBody: any) {
    return this.http.delete(url, this.httpOptions).pipe(
      map(res => this.handleResponse(res)),
      catchError(error => this.handleError(error))
    );
  }

  put(url, putData) {
    return this.http.put(url, putData, this.httpOptions).pipe(
      map(res => this.handleResponse(res)),
      catchError(error => this.handleError(error))
    );
  }

  putWithNoAuth(url, putData) {
    return this.http.put(url, putData).pipe(
      map(res => this.handleResponse(res)),
      catchError(error => this.handleError(error))
    );
  }

  upload(url: string, file: File) {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('files', file, file.name);
    }
    return this.post(url, formData);
  }

  handleResponse(res: any) {
    return res;
  }
  // use this for setting next login session token
  updateHeaderToken(token: string) {
    this.deleteToken();
    if (token) {
      this.httpOptions.headers = this.httpOptions.headers.set('Authorization', token);
    } else {
      throw new Error('Token not supplied!! Please provide proper JWT token');
    }
  }

  deleteToken() {
    this.httpOptions.headers = this.httpOptions.headers.delete('Authorization');
  }

  // private handleError(error: HttpErrorResponse) {
  //   if (error.error instanceof ErrorEvent) {
  //     // A client-side or network error occurred. Handle it accordingly.

  //     // TODO: dynamic error msg
  //     console.error('An error occurred:', error.error.message);
  //   } else {
  //     // The backend returned an unsuccessful response code.
  //     // The response body may contain clues as to what went wrong,

  //     // TODO: dynamic error msg
  //     console.error(
  //       `Backend returned code ${error.status}, ` +
  //       `body was: ${error.error}`);
  //   }
  //   // return an ErrorObservable with a user-facing error message
  //   return throwError('An error occurred');
  // }

 
  handleError(error: HttpErrorResponse) {
    // console.log('handleError: ', error);
    try {
      const errCode = this.getErrorCode(error);
      //  console.log('errCode: ', errCode, error.error.message);

      if (errCode) {
        const message = this.toasterMessageService.getErrorMessage(errCode);
        // console.log('message: ', message);

        // message ? this.toaster.error(message) : null;
        message ? this.toaster.error(message) : this.toaster.error(error.error.message);
      }
    } catch (err) {
      // console.log('err: ', error);
      if(error && error.error&& error.error.message) {
        this.toaster.error(error.error.message);
      }
    }
    
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
    } else if (error.status == 403 && (error.error.code == 2114 || error.error.code == 1200)) {
      this.updateLocalStorage(true);
      // console.log('error: 403: ', error);
      // this.toaster.error(error.error.error);
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      this.loaderService.show();
      // this.window.setTimeout(() => {
      //   // that.logoutOkta();
      // }, 2000);
      this.window.setTimeout(() => {
        that.clearStorage(true);
        this.loaderService.hide();
      }, 3000);
      
    } else if ((error.error.code == 2114 || error.error.code == 1200)) {
      this.toaster.error(error.error.error);
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      this.loaderService.show();
      // this.window.setTimeout(() => {
      //   that.logoutOkta();
      // }, 2000);
      this.window.setTimeout(() => {
        this.clearStorage(true);
        this.loaderService.hide();
      }, 3000);
    }
    //  else if (error.status == 403 && (error.error.code !== 1120)) {
    //   this.updateLocalStorage(true);

    //   this.logoutOkta();
    //   this.window.setTimeout(() => {
    //     this.updateLocalStorage(true);
    //   }, 2000);
     
    // }
     else if (error.status == 400) {
      return throwError(error.error);
    }
    //  else if(error.status == 401) {
    //   console.log('error 401:  ', error);
    //   this.toaster.error(error.error.message);
    //   return throwError(error.error);
    // }
    else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      return throwError(error.error);
    }
    // return an ErrorObservable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  // handleError(error: HttpErrorResponse) {
  //   // console.log('handleError: ', error);
  //   try {
  //     const errCode = this.getErrorCode(error);
  //     //  console.log('errCode: ', errCode, error.error.message);

  //     if (errCode) {
  //       const message = this.toasterMessageService.getErrorMessage(errCode);
  //       // console.log('message: ', message);

  //       // message ? this.toaster.error(message) : null;
  //       message ? this.toaster.error(message) : this.toaster.error(error.error.message);
  //     }
  //   } catch (err) {
  //     // console.log('err: ', error);
  //     if(error && error.error&& error.error.message) {
  //       this.toaster.error(error.error.message);
  //     }
  //   }
    
  //   if (error.error instanceof ErrorEvent) {
  //     // A client-side or network error occurred. Handle it accordingly.
  //     // console.error('An error occurred:', error.error.message);
  //   } else if (error.status == 403 && (error.error.code == 2114 || error.error.code == 1200)) {
  //     this.updateLocalStorage(true);
  //     // console.log('error: 403: ', error);
  //     // this.toaster.error(error.error.error);
  //     // eslint-disable-next-line @typescript-eslint/no-this-alias
  //     const that = this;
  //     this.loaderService.show();
  //     // this.window.setTimeout(() => {
  //     //   // that.logoutOkta();
  //     // }, 2000);
  //     this.window.setTimeout(() => {
  //       that.clearStorage(true);
  //       this.loaderService.hide();
  //     }, 3000);
      
  //   } else if ((error.error.code == 2114 || error.error.code == 1200)) {
  //     this.toaster.error(error.error.error);
  //     // eslint-disable-next-line @typescript-eslint/no-this-alias
  //     const that = this;
  //     this.loaderService.show();
  //     // this.window.setTimeout(() => {
  //     //   that.logoutOkta();
  //     // }, 2000);
  //     this.window.setTimeout(() => {
  //       this.clearStorage(true);
  //       this.loaderService.hide();
  //     }, 3000);
  //   }
  //   //  else if (error.status == 403 && (error.error.code !== 1120)) {
  //   //   this.updateLocalStorage(true);

  //   //   this.logoutOkta();
  //   //   this.window.setTimeout(() => {
  //   //     this.updateLocalStorage(true);
  //   //   }, 2000);
     
  //   // }
  //    else if (error.status == 400) {
  //     return throwError(error.error);
  //   }
  //   //  else if(error.status == 401) {
  //   //   console.log('error 401:  ', error);
  //   //   this.toaster.error(error.error.message);
  //   //   return throwError(error.error);
  //   // }
  //   else {
  //     // The backend returned an unsuccessful response code.
  //     // The response body may contain clues as to what went wrong,
  //     console.error(
  //       `Backend returned code ${error.status}, ` +
  //       `body was: ${error.error}`);
  //     return throwError(error.error);
  //   }
  //   // return an ErrorObservable with a user-facing error message
  //   return throwError(
  //     'Something bad happened; please try again later.');
  // }


  getErrorCode(err) {
    if (err && err.error) {
      if (err.error.code) {
        const errorCode = err.error.code;
        return errorCode;
      }
    }
  }

  updateLocalStorage(reload) {
    this.localDataStoreService.updateData(null);
    // this.localDataStoreService.updateSelectedOrgDetails(null);
    this.cookieStorage.clearCookie('token');
    // this.localDataStoreService.updateOrgChildrens(null);
    // this.localDataStoreService.updateOrganizations(null);
    this.clearStorage(reload);
  }

  async logoutOkta() {
    this.updateLocalStorage(true);
    await this.oauthService.logOut();
  }

  clearStorage(reload) {
    this.deleteToken();
    // update next value in userNameSubject to null
    const cookieConsentValue = this.localStorageService.get('cookies_consent_accepted');
    const locale = this.cookieStorage.getCookie('locale');
    this.localStorageService.clearAll();
    // preserving value
    if (cookieConsentValue) {
      this.localStorageService.set('cookies_consent_accepted', cookieConsentValue);
    }
    // preserve user preferred language
    if (locale) {
      this.cookieStorage.setCookie('locale', locale);
    }
    if (reload) {
      // this.router.navigate(['']);
      // bug fix by moving to login page on logout
      // this.router.navigate(['login']);
      this.window.location.href = environment['DASHBOARD_HOST_URL'];
    }
  }
}
