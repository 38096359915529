export class locales {
  dictionary: any = {
    // English
    'en-AU': 'en-US', // English - Australia
    'en-BZ': 'en-US', // English - Belize
    'en-CA': 'en-US', // English - Canada
    'en-CB': 'en-US', // English - Caribbean
    'en-US': 'en-US', // English - United States
    'en-GB': 'en-US', // English - Great Britain
    'en-IN': 'en-US', // English - India
    'en-IE': 'en-US', // English - Ireland
    'en-JM': 'en-US', // English - Jamaica
    'en-NZ': 'en-US', // English - New Zealand
    'en-PH': 'en-US', // English - Phillippines
    'en-ZA': 'en-US', // English - Southern Africa
    'en-TT': 'en-US', // English - Trinidad
    'in-ID': 'en-US', // English - Indonesia
    'en-MT': 'en-US', // English - Malta
    'si-LK': 'en-US', // English - Sri Lanka
    'bg-BG': 'en-US', // English - Bulgaria
    'en-EE': 'en-US', // English - Estonia
    'en-LV': 'en-US', // English - Latvia
    'en-BY': 'en-US', // English - Belarus
    'en-UA': 'en-US', // English - Ukraine
    'en-HR': 'en-US', // English - Croatia
    'en-SC': 'en-US', // English - Seychelles
    'en-BS': 'en-US', // English - Bahamas

    // DUTCH
    'nl-NL': 'nl-NL', // Dutch - Netherlands
    'nl-BE': 'nl-NL', // Dutch - Belgium

    // FRENCH
    'fr-BE': 'fr-FR', // French - Belgium
    'fr-FR': 'fr-FR', // French - France
    'fr-LU': 'fr-FR', // French - Luxembourg
    'fr-MA': 'fr-FR', // French - Morocco
    'fr-CH': 'fr-FR', // French - Switzerland
    'fr-SC': 'fr-FR', // English - Seychelles

    // GERMAN
    'de-AT': 'de-DE', // German - Austria
    'de-DE': 'de-DE', // German - Germany
    'de-LI': 'de-DE', // German - Liechtenstein
    'de-LU': 'de-DE', // German - Luxembourg
    'de-CH': 'de-DE', // German - Switzerland

    // POLISH
    'pl-PL': 'pl-PL',

    'zh-ZH': 'zh-ZH', // Chinese - China

    // Italian
    'it-IT': 'it-IT', // Italian - Italy
    'it-CH': 'it-IT', // Italian - Switzerland

    // Turkish
    'tr-TR': 'tr-TR', // Turkish - Turkey

    // Finnish
    'fi-FI': 'fi-FI', // Finnish - Finland

    // Dannish
    'da-DK': 'da-DK', // Dannish - Denmark
    'da-DA': 'da-DK', // Dannish - Denmark

    // Arabic (not using as of now)
    // 'ar-AE': 'ar-AR', // Arabic - UAE
    // 'ar-SA': 'ar-AR', // Arabic - Saudi Arabia
    // 'ar-BH': 'ar-AR', // Arabic - Bahrain

    // Portugal
    'pt-PT': 'pt-PT', // Portuguese - Portugal
    'pt-BR': 'pt-PT', // Portuguese - Brazil

    // Spanish
    'es-ES': 'es-ES', // Spanish - Spain
    'es-AR': 'es-ES', // Spanish - Argentina
    'es-CL': 'es-ES', // Spanish - Chile
    'es-MX': 'es-ES', // Spanish - Mexico
    'es-DO': 'es-ES', // Spanish - Dominican Republic

    // Swedish
    'sv-SE': 'sv-SE',  // Swedish - Sweden

    // Hungarian
    'hu-HU': 'hu-HU', // Hungarian - Hungary

    // Greek (not using as of now)
    'el-EL': 'el-EL', // Greek - Greece

    // Norwegian
    'no-NO': 'no-NO', // Norwegian - Norway

    // Russian
    'ru-RU': 'ru-RU', // Russian - Russia

    // Czech
    'cs-CZ': 'cs-CZ', // Czech - Czech Slovakia

    'sl-SL': 'sl-SL',  // Slovenian - Slovenian
    'sk-SK': 'sk-SK',  // Slovak - Slovak
    'ko-KO': 'ko-KO'  // Korean
  };
}
