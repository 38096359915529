import {
    ComponentFactoryResolver,
    Injectable,
    Inject
  } from '@angular/core';

@Injectable()
export class ComponentCreateService {
  public factoryResolver;
  public rootViewContainer;

  constructor(@Inject(ComponentFactoryResolver) factoryResolver) {
    this.factoryResolver = factoryResolver;
  }

  setRootViewContainerRef(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  addDynamicComponent(componentToCreate) {
    const factory = this.factoryResolver.resolveComponentFactory(componentToCreate);
    const component = factory.create(this.rootViewContainer.parentInjector);
    this.rootViewContainer.insert(component.hostView);
    return component;
  }
}
