import { Pipe, PipeTransform } from '@angular/core';

// pipe to get object keys - can be then used in ngFor directive
@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  transform(value): any {
    if (!value) {
      return value;
    }
    return Object.keys(value);
  }
}
