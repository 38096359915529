import { Injectable,  Inject } from '@angular/core';
import {
  HttpRequest, HttpHandler,
  HttpEvent, HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { LocalStorageService } from 'app/base/services/localStorage.service';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { CookieStorage } from 'app/base/services/cookie-storage.service';
import { environment } from 'environments/environment';
import { LocalDataStoreService } from './local-data-store.service';
import { ToasterMessageService } from './toaster.service';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  private loginUrl = `${environment['DASHBOARD_HOST_URL']}#/login`;
  private window: Window;

  constructor(
    private localStorageService: LocalStorageService,
    private _localDataStoreService: LocalDataStoreService,
    private _cookieStorage: CookieStorage,
    private _toaster: ToastrService,
    private toasterMessageService: ToasterMessageService,
    @Inject(DOCUMENT) private document: Document) {
      this.window = this.document.defaultView;
    }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this._cookieStorage.getCookie('token') || null;
    if (!token) {
      this.window.location.href = this.loginUrl;
    }
    if (token && !request.url.includes('maps.googleapis.com')) {
      request = request.clone({
        setHeaders: {
          Authorization: token
        }
      });
    }
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // code
      }
    }, (err: any) => {
      const apiErr = ['UnauthorizedAccessError', 'UnauthorizedSessionError'];
      const apiErrCodes = [500, 502, 503];
      if ((err.status === 403 || apiErrCodes.indexOf(err.status) > -1) && err.url.includes('logout')) {
        const cookieConsent = this.localStorageService.get('cookies_consent_accepted');
        localStorage.clear();
        if (cookieConsent) {
          this.localStorageService.set('cookies_consent_accepted', cookieConsent);
        }
        return false;
      }
      if (err.status === 403 && err.error && apiErr.indexOf(err.error.message) > -1) {
        const msg = this.toasterMessageService.getErrorMessage(8004);
        this._toaster.error(msg);
        this._localDataStoreService.updateData(null);
        this._cookieStorage.clearCookie('token');
        this.window.setTimeout(() => {
          this.window.location.href = this.loginUrl;
        }, 2000);
      } else if (apiErrCodes.indexOf(err.status) > -1) {
        const msg = this.toasterMessageService.getErrorMessage(8005);
        this._toaster.error(msg);
      }
    }));
  }
}
