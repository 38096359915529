/* eslint-disable angular/timeout-service */
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserRoleGuard } from './portal-admin/services/user-role-guard.service';
import { toasterState } from './portal-admin/models';
import { Subscription } from 'rxjs';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { CommonService } from 'app/base/services';
import { AppHeaderComponent } from './base/components/app-header/app-header.component';
import { DOCUMENT } from '@angular/common';
import { CookieStorage } from 'app/base/services/cookie-storage.service';
import { environment } from '../environments/environment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnDestroy, OnInit {
  @ViewChild(AppHeaderComponent) child: AppHeaderComponent;
  public loadingLazyModule = false;
  public loaderSubscription: Subscription;
  private subscription: Subscription;
  private window: Window;

  constructor(
    private userRoleGuard: UserRoleGuard,
    private router: Router,
    private commonService: CommonService,
    private cookieStorage: CookieStorage,
    @Inject('CommonObjects') commonObjects: any,
    @Inject(DOCUMENT) private doc: any,

    private oauthService: OAuthService) {
    this.window = this.doc.defaultView;

    this.subscription = this.userRoleGuard.toasterState.subscribe((state: toasterState) => { /**/ });

    router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.commonService.toggleLoader(true);
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.commonService.toggleLoader(false);
      }
    });


    this.oauthService.redirectUri = environment.PAC_HOST_URL;
    this.oauthService.scope = 'openid profile email address phone';
    this.oauthService.responseType = 'id_token token';
  
    this.oauthService.clientId = environment.OKTA_CLIENT_ID;
    this.oauthService.issuer = environment.OKTA_ISSUER;
    
    
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();

    const claims = this.oauthService.getIdentityClaims();
    // console.log(' givenName claims: ', claims);
    // console.log(' accessToken : ', this.oauthService.getAccessToken());
    // this.oauthService.initImplicitFlow();

    window.setTimeout(() => {
      if(this.oauthService.getAccessToken() != undefined && this.oauthService.getAccessToken() != null) {
        // console.log('settimeout set access token', this.oauthService.getAccessToken());
        // this.setToken(this.oauthService.getAccessToken());
        // location.reload();
      } else {
        // console.log('else part  window.setTimeout');
        
      }
    }, 1500);
  }

  ngOnInit() {
    this.loaderSubscription = this.commonService.appLoader.subscribe(res => {
      this.loadingLazyModule = res;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.loaderSubscription.unsubscribe();
  }

  componentAdded(componentReference) {
    componentReference.messageEvent && componentReference.messageEvent.subscribe(() => {
      this.child.showDeactivateModal();
    });
  }
}
