import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable ,  Subject } from 'rxjs';
import { UserService } from '../../base/services/user.service';
import { toasterState } from '../models';

@Injectable()
export class UserRoleGuard  {
  public toasterSubject = new Subject<toasterState>();
  public toasterState = this.toasterSubject.asObservable();

  constructor(
    private userService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    // const userRoleId = JSON.parse(this.userService.getCookie('user_data'))['user_role_id'];
    // const roleId = userRoleId ? userRoleId : null;
    // const roles = route.data["roles"] as Array<number>;
    // const isAccessible = (roles === null || (roles.indexOf(roleId) != -1));
    // if (!isAccessible) {
    //   this.toasterSubject.next(<toasterState>{ show: true, type: 'error', message: 'Unauthorised User, Access permission not granted' });
    // }
    // return isAccessible;
    return true;
  }
}
