import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { TopNavItemModel } from '../../models';
import { Router, NavigationEnd } from '@angular/router';
import { LocalDataStoreService, CookieStorage } from 'app/base/services';
import { locales } from '../../../../assets/pac_i18n/locale';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.scss']
})
export class TopNavBarComponent implements OnInit, OnDestroy {
  @Input() items: TopNavItemModel[];
  public adminTabActive = false;
  public userData;
  public isDMuser = false;
  public country_locale = new locales();
  public orgStructure = {
    Root: ['Company', 'Client', 'Customer'],
    Company: ['Client', 'Customer'],
    Client: ['Customer'],
    Customer: []
  };

  public activeNav = {
    hierarchy: false,
    Company: false,
    Client: false,
    Customer: false,
    user: false,
    sites: false
  };
  allowedOrgs = [];
  public userSubscription: Subscription;
  private window: Window;
  constructor(private _router: Router,
              private translate: TranslateService,
              private cookieStorage: CookieStorage,
              private localDataStoreService: LocalDataStoreService,
              @Inject(DOCUMENT) private document: Document) {
                this.window = this.document.defaultView;
    const locale = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    this.translate.use(this.country_locale.dictionary[locale]);
    this._router.events.subscribe(val => {
      this.adminTabActive = false;
      if (val instanceof NavigationEnd) {
        if (val.urlAfterRedirects.includes('landing')) {
          this.adminTabActive = true;
        }
        Object.keys(this.activeNav).forEach(elem => {
          if (val.urlAfterRedirects.includes('/' + elem)) {
            this.activeNav[elem] = true;
          } else {
            this.activeNav[elem] = false;
          }
        });

        if (val.urlAfterRedirects.includes('/sub-site')) {
          this.activeNav['sites'] = true;
        } else if (val.urlAfterRedirects.includes('/organization-threshold')) {
          this.activeNav['Company'] = true;
        }
      }
    });
  }

  ngOnInit() {
    this.userSubscription = this.localDataStoreService.currentUserState.subscribe(userData => {
      this.userData = userData || null;
      if (this.userData) { this.setData(userData); }
    });
  }

  setData(user_data) {
    if (user_data['organization'].org_name === 'Diversey') {
      this.allowedOrgs = this.orgStructure.Root;
    } else {
      const orgType = user_data['organization'].org_type;
      if (this.orgStructure && this.orgStructure[orgType]) {
        this.allowedOrgs = this.orgStructure[orgType];
      }
    }
    this.isAllowed('');
  }

  isAllowed(type) {
    if (this.userData) {
      const userData = this.userData ? this.userData : null;
      if (userData.user_role_id === 4) {
        this.isDMuser = true;
        return false;
      }
      if (type && userData.organization) {
        if (userData.organization.org_type) {
          const userOrg = userData.organization.org_type;
          return this.orgStructure[userOrg].indexOf(type) !== -1;
        }
      }
    }
    return false;
  }

  goToHome() {
    this.window.location.href = environment['DASHBOARD_HOST_URL'];
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
}
