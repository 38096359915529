import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class CommonService {
  public clientLogo: any;
  public _solutionSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public siteDataSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public orgDataSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public appLoader: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() { /** */ }

  emitSolutionName(solutionName) {
    this._solutionSubject.next(solutionName);
  }

  toggleLoader(show) {
    this.appLoader.next(show);
  }

  setSiteData(siteData) {
    this.siteDataSubject.next(siteData);
  }

  setOrgData(orgData) {
    this.orgDataSubject.next(orgData);
  }


  generateRandomPassword() {
    return this.generatePassword(this.getRandomArbitrary(15, 20))
  }

  getRandomArbitrary(min, max) {
    return Math.ceil(Math.random() * (max - min) + min);
  }

  generatePassword(length) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const classObj = this;
    if (!length || length == undefined) {
      length = 8;
    }
    const rules = [
      { chars: "abcdefghijklmnopqrstuvwxyz", min: 3 },  // As least 3 lowercase letters
      { chars: "ABCDEFGHIJKLMNOPQRSTUVWXYZ", min: 2 },  // At least 2 uppercase letters
      { chars: "0123456789", min: 2 },                  // At least 2 digits
      { chars: "~!@-#$_%*&?", min: 1 }      // At least 1 special char
    ];

    let allChars = "", allMin = 0;
    rules.forEach(function (rule) {
      allChars += rule.chars;
      allMin += rule.min;
    });
    if (length < allMin) {
      length = allMin;
    }
    rules.push({ chars: allChars, min: length - allMin });
    let pswd = "";
    rules.forEach(function (rule) {
      if (rule.min > 0) {
        pswd += classObj.shuffleString(rule.chars, rule.min);
      }
    });
    return this.shuffleString(pswd, length);
  }

  shuffleString(str, maxlength) {
    let shuffledString = str.split('').sort(function () { return 0.5 - Math.random() }).join('');
    if (maxlength > 0) {
      shuffledString = shuffledString.substr(0, maxlength);
    }
    return shuffledString;
  }

  
}
