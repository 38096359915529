
import { Directive, HostListener, Renderer2, ElementRef } from '@angular/core';
@Directive({
  selector: '[camelCase]'
})
export class CamelCaseDirective {

  constructor(
        private renderer: Renderer2,
        private el: ElementRef
    ) {}

  @HostListener('keyup') onKeyUp() {
    this.el.nativeElement.value = this.capitalizeString(this.el.nativeElement.value);
  }
  capitalizeString(inputString) {
    const res = inputString.substring(0, 1).toUpperCase() + inputString.substring(1).toLowerCase();
    return res.replace(/\s+/g, '');
  }
}
