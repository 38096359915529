import { Component, OnInit, OnDestroy, ViewChild, DoCheck, Inject } from '@angular/core';
import { UserService, ResponsiveService, CommonService, LocalDataStoreService, CookieStorage } from '../../services';
import { Subscription } from 'rxjs';
import { locales } from '../../../../assets/pac_i18n/locale';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { flags } from '../../../../assets/images/flags/flags';
import { DOCUMENT } from '@angular/common';
import { CookieKey } from '../../../config/config';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  host: {
    '(document:click)': 'hideDrop($event)'
  }
})

export class AppHeaderComponent implements OnDestroy, DoCheck, OnInit {
  name: any = null;
  user_role_id: any;
  date = new Date();
  responsiveSubscription: Subscription;
  userSubscription: Subscription;
  timeSubscription: Subscription;
  solutionSubscription: Subscription;
  showResponsive: any;
  public clientLogo: any;
  public solutionName;
  public flags = new flags();
  public isOktaLogin = false;

  // html lables
  public locale = 'en-US';
  public country_locale = new locales();
  public userData;
  public toggle = false;
  public flagIcon = 'en-US';
  public countryName = 'United States';

  // variable for showing user profile popup
  showUserProfile: boolean;
  showtab: boolean;
  showDeactivatetab: boolean;
  isAdminUser: boolean;
  private window: Window;

  public cookieConsentAccepted = true;

  constructor(private userService: UserService,
              private responsiveService: ResponsiveService,
              private commonService: CommonService,
              private translate: TranslateService,
              private localDataStoreService: LocalDataStoreService,
              private cookieStorage: CookieStorage,
              @Inject(DOCUMENT) private document: Document) {
                this.window = this.document.defaultView;
    this.locale = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    this.translate.use(this.country_locale.dictionary[this.locale]);
  }

  ngOnInit() {
    this.commonService._solutionSubject.subscribe(res => {
      this.solutionName = res;
    });
    this.responsiveSubscription = this.responsiveService.showResponsive.subscribe(responsive => {
      this.showResponsive = responsive;
    });

    this.userSubscription = this.localDataStoreService.currentUserState.subscribe(userData => {
      if (userData) {
        if (userData.locale) {
          this.flagIcon = userData.locale;
          this.countryName = this.flags.dictionary[userData.locale];
        }
        this.userData = userData;
        this.isOktaLogin = userData['is_okta_login'];
        this.name = userData['username'];
        this.user_role_id = userData['user_role_id'];

        this.cookieConsentAccepted = (userData['cookie_consent'] && userData['cookie_consent'] != null) ? true : false;

        if((userData['cookie_consent'] && userData['cookie_consent'] != null)) {
          this.cookieConsentAccepted = true;
          this.cookieStorage.setCookie(CookieKey.COOKIE_CONSENT, userData['cookie_consent']);
        } else {
          this.cookieStorage.clearCookie(CookieKey.COOKIE_CONSENT);
          this.cookieConsentAccepted = false;
        }
        
      }
    });
  }

  ngDoCheck() {
    if (typeof this.commonService.clientLogo !== 'undefined') {
      this.clientLogo = this.commonService.clientLogo;
    }
  }

  ngOnDestroy() {
    if (this.responsiveSubscription) {
      this.responsiveSubscription.unsubscribe();
    }
    if (this.timeSubscription) {
      this.timeSubscription.unsubscribe();
    }
    if (this.responsiveSubscription) {
      this.responsiveSubscription.unsubscribe();
    }
    if (this.solutionSubscription) {
      this.solutionSubscription.unsubscribe();
    }
    this.userSubscription.unsubscribe();
  }

  // logout() {
  //   this.userService.logout();
  //   this.cookieStorage.clearCookie('locale');
  //   this.cookieStorage.clearCookie(CookieKey.COOKIE_CONSENT);
  // }


  logout() {
    this.toggle = false;
    // this.userService.getUserDetails().subscribe((user)=> {
    //   console.log('logout function', user.is_okta_login, user.email);
    //     if(user && user.is_okta_login && user.email != undefined && user.email.includes('@diversey.com')) {
    //       console.log('logout okta', user);
    //       this.userService.logoutOkta(user.email);
    //     } else {
    //       console.log('logout', user);
    //       this.userService.logout(true,user.email);
    //       this.cookieStorage.clearCookie('locale');
    //       this.cookieStorage.clearCookie(CookieKey.COOKIE_CONSENT);
    //     }
    // });
    const user = this.userData;
    // console.log('logout function', user.is_okta_login, user.email);
    if(user && user.is_okta_login && user.email != undefined && user.email.includes('@diversey.com')) {
      // console.log('logout okta', user);
      // this.userService.logoutOkta(user.email);
      // okta-logout
      this.window.location.href = `${environment.DASHBOARD_HOST_URL}#/okta-logout`
    } else {
      // console.log('logout', user);
      this.userService.logout(true, user.email);
      this.cookieStorage.clearCookie('locale');
      this.cookieStorage.clearCookie(CookieKey.COOKIE_CONSENT);
    }
  }


  home() {
    this.window.location.href = environment['DASHBOARD_HOST_URL'];
  }

  toggleDropdown(e) {
    e.stopPropagation();
    this.toggle = !this.toggle;
  }

  hideDrop(e) {
    e.stopPropagation();
    this.toggle = false;
  }

  changePassword() {
    if(this.isOktaLogin) {
      // this.window.location.href = environment.OKTA_DOMAIN + '/enduser/settings';
      this.window.open(environment.OKTA_DOMAIN + '/enduser/settings');
    } else {
      this.toggle = false;
      this.showUserProfile = true;
      this.showtab = false;
      this.showDeactivatetab = false;
    } 
  }

  showDeactivateModal() {
    this.toggle = false;
    this.showUserProfile = true;
    this.showtab = false;
    this.showDeactivatetab = true;
  }

  // open user profile popup
  openUserProfile() {
    this.toggle = false;
    this.showUserProfile = true;
    this.showtab = true;
  }

  switchTabs(event) {
    if (event === 'profile') {
      this.showtab = true;
      this.showDeactivatetab = false;
    } else if (event === 'reset') {
      this.showtab = false;
      this.showDeactivatetab = false;
    } else if (event === 'deactivate') {
      this.showtab = false;
      this.showDeactivatetab = true;
    }
  }

  // close user profile popup
  closeUserProfile() {
    this.showUserProfile = false;
  }

  closeProfilePopUp(value: boolean) {
    this.toggle = value;
  }

  showHideDeactivateBtn() {
    this.isAdminUser = (this.user_role_id === 1 || this.user_role_id === 4) ? false : true;
    return this.isAdminUser;
  }


  hideConsentPopup() {
    this.cookieConsentAccepted = true;
  }

  modifyCookies() {
    // this.document.cookie = "OptanonAlertBoxClosed =;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    // this.document.cookie = "OptanonConsent =;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    // this.document.cookie = "cookie_consent =;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    // CookieKey.COOKIE_CONSENT
    this.cookieStorage.clearCookie(CookieKey.COOKIE_CONSENT);    
    this.cookieConsentAccepted = false;
    // this.window.location.reload();
  }


}
