import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppLoaderComponent, TopNavBarComponent, PopupComponent,
  HierarchyDropdownComponent, LanguageDropdownComponent,
  FeatureListComponent, CustomHierarchyDropdownComponent,
  ActionPopupComponent } from './components';
import { HierarchyDropdownService } from './components/hierarchy-dropdown/hierarchy-dropdown.service';
import { NgPipesModule } from 'ngx-pipes';
import { DatexPipe } from './pipes/date-pipe';
import { AccessibleData } from './pipes/filter-accessible-nodes-pipe';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TrimWhiteSpaceDirective } from '../portal-admin/directives/trimWhiteSpace';
import { FeatureListService } from './components/feature-list/feature-list.services';
import { ComponentCreateService } from './../shared/services/component-create.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/pac_i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgPipesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    AppLoaderComponent,
    TopNavBarComponent,
    PopupComponent,
    HierarchyDropdownComponent,
    CustomHierarchyDropdownComponent,
    TrimWhiteSpaceDirective,
    DatexPipe,
    AccessibleData,
    LanguageDropdownComponent,
    FeatureListComponent,
    ActionPopupComponent
  ],
  exports: [
    AppLoaderComponent,
    TopNavBarComponent,
    PopupComponent,
    HierarchyDropdownComponent,
    CustomHierarchyDropdownComponent,
    CommonModule,
    FormsModule,
    TrimWhiteSpaceDirective,
    DatexPipe,
    AccessibleData,
    LanguageDropdownComponent,
    FeatureListComponent,
    ActionPopupComponent,
    TranslateModule
  ],
  providers: [
    HierarchyDropdownService,
    FeatureListService,
    ComponentCreateService,
    { provide: "windowObject", useValue: window}
  ]
})

export class SharedModule { }
