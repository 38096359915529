import { Component, Input, EventEmitter, Output } from '@angular/core';
import { locales } from '../../../../assets/pac_i18n/locale';
import { CookieStorage } from 'app/base/services';

@Component({
  selector: 'action-popup',
  templateUrl: './action-popup.component.html',
  styleUrls: ['../../../../scss/_forms_new.scss', './action-popup.component.scss']
})
export class ActionPopupComponent {
  @Input() public showPopup: any;
  @Input() public actionPopupMessage: any;
  @Output() accept = new EventEmitter<boolean>();
  private locale;
  private country_locale = new locales();

  constructor(
    private cookieStorage: CookieStorage
  ) {
    const locale_value = this.cookieStorage.getCookie('locale') || 'en-US';
    if (locale_value && this.country_locale.dictionary[locale_value]) {
      this.locale = this.country_locale.dictionary[locale_value];
    }
  }
  onAction(flag) {
    this.accept.emit(flag);
  }
}
