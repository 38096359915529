import { Injectable } from '@angular/core';
import { UserService } from '../../base/services/user.service';

@Injectable()
export class UsersService {
  public solutionsAsset: Array<any> = [];

  public validationMessages = {
    company: {
      required: 'Company is required.'
    },
    hierarchy: {
      required: 'Hierarchy is required.'
    },
    solutions: {
      required: 'Solution is required.'
    }
  };

  public hierarchyConfig = {
    title: '',
    multiselect: false,
    select: 'site',
    emit: 'site'
  };

  constructor(private uService: UserService) {
    const uDataSolutions = JSON.parse(this.uService.getCookie('user_data'))['solutions'];
    this.solutionsAsset = uDataSolutions ? uDataSolutions.map(val => { return { name: val }; }) : [];
  }

  public getSolutionAsset(): Array<any> {
    return this.solutionsAsset;
  }

  public getValidationMessages() {
    return this.validationMessages;
  }

  public getHierarchyConfig(title = null) {
    const hierarchyConfig = this.hierarchyConfig;
    hierarchyConfig['title'] = title;

    return hierarchyConfig;
  }
}
