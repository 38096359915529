
import {throwError as observableThrowError,  Observable ,  of } from 'rxjs';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { map ,  catchError } from 'rxjs/operators';
import { HttpClientService } from 'app/base/services/http.client-module.service';

@Injectable()
export class GoogleService {
  private mapApi = environment.MAPS_API_URL;
  private mapTimeZoneApi = environment.MAPS_TIMEZONE_API_URL;
  private mapsTimeZoneApiKey = environment.MAPS_TIMEZONE_API_KEY;
  constructor(private httpClient: HttpClientService) { }
  getLatLng(address): Observable<any> {
    return this.httpClient.getDataWithNoAuth(`${this.mapApi}${address}`)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  getTimeZone(coordinates, timestamp): Observable<any> {
    const url = `${this.mapTimeZoneApi}${coordinates.lat},${coordinates.lng}&timestamp=${timestamp}&key=${this.mapsTimeZoneApiKey}`;
    return this.httpClient.getDataWithNoAuth(url)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  private handleError(error: Response) {
    return observableThrowError(error || 'Server Error');
  }

}
