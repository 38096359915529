<div class="main-container user-profile-container">
  <form class="user-profile-form form-group-container" novalidate #form="ngForm" name="resetPassForm" [formGroup]="resetPassForm"
    (ngSubmit)="onSubmitSetPassword(resetPassForm.value)" [ngClass]="{submitted: isSubmitted}">

    <label class="form-control-container">
      <div class="control-label">{{'current_password' | translate}}</div>
      <div class="control-input-container">
        <input class="control-input" type="password" id="currentPassword" name="currentPassword" placeholder="{{'current_password' | translate}}" formControlName="currentPassword"
          required>
        <div *ngIf="currentPassword.invalid || (currentPassword.dirty || currentPassword.touched)" class="control-error-container error-msgs">
          <div *ngIf="currentPassword.errors?.minlength"> {{'must_8_characters_message' | translate}}</div>
          <div *ngIf="currentPassword.errors?.pattern">{{'lower_upper_case' | translate}}</div>
          <div *ngIf="currentPassword.errors?.maxlength">{{'pass_max_length' | translate}}</div>
        </div>
      </div>
    </label>

    <div *ngIf="loader" class="site-loader-wrapper grey-bg-loader">
      <div class="loader"></div>
    </div>

    <label class="form-control-container">
      <div class="control-label">{{'new_password' | translate}}<span *ngIf="isOktaLogin" class="okta-password-note-symbol">*</span></div>
      <div class="control-input-container">
        <input class="control-input" type="password" id="newPassword" name="newPassword" placeholder="{{'new_password' | translate}}" formControlName="newPassword"
          required>
        <div class="password_suggestion_div"> <span> Suggestion: </span> <span> {{passwordSuggestValue}} </span>
          <span class="" (click)="copyPasswordToClipboard()"> <i class="fa fa-clipboard" aria-hidden="true" [title]="'copy'"></i> </span>
          <span class="refresh_icon" (click)="generateRandomPassword()"><i class="fa fa-refresh" [title]="'refresh'"></i></span>
        </div>
        <div *ngIf="newPassword.invalid || (newPassword.dirty || newPassword.touched)" class="control-error-container error-msgs">
          <div *ngIf="newPassword.errors?.minlength && !checkDiverseyOrPowerAdmin()">{{'must_8_characters_message' | translate}}</div>
          <div *ngIf="newPassword.errors?.minlength && checkDiverseyOrPowerAdmin()"> Must be at least 15 characters </div>
          <div *ngIf="newPassword.errors?.maxlength">{{'pass_max_length' | translate}}</div>
          <div *ngIf="newPassword.errors?.pattern">{{'lower_upper_case' | translate}}</div>
          <div *ngIf="newPassword.errors?.samePassword">{{'same_password' | translate}}</div>
        </div>
      </div>
    </label>

    <label class="form-control-container">
      <div class="control-label">{{'confirm_password' | translate}}<span *ngIf="isOktaLogin" class="okta-password-note-symbol">*</span></div>
      <div class="control-input-container">
        <input class="control-input" type="password" id="confirmPassword" name="confirmPass" placeholder="{{'confirm_password' | translate}}" formControlName="confirmPassword"
          required>
        <div *ngIf="confirmPassword.invalid || (confirmPassword.dirty || confirmPassword.touched)" class="control-error-container error-msgs">
          <div *ngIf="confirmPassword.errors?.MatchPassword">{{'match' | translate}}</div>
        </div>
      </div>
    </label>
    <div *ngIf="isOktaLogin" class="okta-password-note">
      <span *ngIf="isOktaLogin" class="okta-password-note-symbol">*</span>
      Modifying your password here will update your Diversey password
    </div>
    <div class="form-submit-container">
      <button [ngClass]="applyClass(resetPassForm.valid)" [disabled]="!resetPassForm.valid" type="submit">{{'reset_password' | translate}}</button>
    </div>
  </form>
</div>
