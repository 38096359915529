import { CountriesResolveGuardService } from './base/services/countries-resolve-guard.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
// import { AgmCoreModule } from '@agm/core';
import { HttpTokenInterceptor, CookieStorage, LocalDataStoreService } from 'app/base/services';
import { OAuthModule } from 'angular-oauth2-oidc';

import {
  HttpClientModule
} from '@angular/common/http';

import { NgPipesModule } from 'ngx-pipes';
import { AppComponent } from './app.component';

// Common modules
import { BaseModule } from './base/base.module';
import { SharedModule } from './shared/shared.module';
import { ToastrModule, ToastrService } from 'ngx-toastr';

// importing routing module and routing components used
import { RoutingModule, ROUTING_COMPONENTS } from './portal-admin/routing';
// import services
import {
  PortalAdminService,
  SitesService } from './portal-admin/services';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserService } from 'app/base/services/user.service';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserDataResolveGuardService } from 'app/base/services/user-data-resolve-guard.service';
import { OrgListResolveGuardService } from 'app/base/services/org-list-resolve-guard.service';
import { SolutionResolveGuardService } from 'app/base/services/solution-resolve-guard.service';
import { LocalesResolveGuardService } from 'app/base/services/locales-resolve-guard.service';

// returns a Object that can load Translations using Http and .json
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/pac_i18n/', '.json');
}

export function declarations() {
  return [
    ROUTING_COMPONENTS,
    AppComponent
  ];
}

export function modules() {
  return [
  //  HttpClientModule,
    BaseModule.forRoot(),
    SharedModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    TypeaheadModule.forRoot(),
    RoutingModule,
    NgPipesModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'XXXXXXXXXXXXXXXXXXXXXXXXX'
    // }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      timeOut: 10000
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    OAuthModule.forRoot(),
  ];
}

@NgModule({
  declarations: declarations(),
  imports: modules(),
  providers: [
    {
      provide: 'CommonObjects',
      useValue: {} // toasterInstance will be set from app.component
    },
    PortalAdminService,
    SitesService,
    UserService,
    ToastrService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },
    CookieStorage,
    LocalDataStoreService,
    UserDataResolveGuardService,
    OrgListResolveGuardService,
    SolutionResolveGuardService,
    LocalesResolveGuardService,
    CountriesResolveGuardService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
