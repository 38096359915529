import { Component, OnInit } from '@angular/core';
import * as moment from 'moment/moment';
import { locales } from '../../../../assets/pac_i18n/locale';
import { TranslateService } from '@ngx-translate/core';
import { CookieStorage } from 'app/base/services/cookie-storage.service';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {
  public year;
  public termsConditions = environment.TERMS_CONDITIONS;
  public country_locale = new locales();
  constructor(private cookieStorage: CookieStorage, private translate: TranslateService) {
    const locale = this.cookieStorage.getCookie('locale') || 'en-US';
    this.translate.use(this.country_locale.dictionary[locale]);
  }

  ngOnInit() {
    this.year = moment.utc().year();
  }

}
