export class HierarchyItem {
  public id: number;
  public name: string;
  public expand = false;
  public checked = false;
  public subordinates: HierarchyItem[];
  public sites: HierarchyItem[];

  constructor(hierarchy: any) {
    this.id = hierarchy.id;
    this.name = hierarchy.name;
    if (hierarchy.subordinates) {
      this.subordinates = hierarchy.subordinates.map(subordinate => {
        return new HierarchyItem(subordinate);
      });
    } else if (hierarchy.sites) {
      this.sites = hierarchy.sites.map(site => {
        return new HierarchyItem(site);
      });
    }
  }

  public toggle(event: Event, hierarchyArr: HierarchyItem[], item): void {
    event.stopPropagation();
    this.collapseOthers(hierarchyArr, item);
    this.expand = !this.expand;
  }

  // collapses others opened company dropdown
  collapseOthers(hierarchyArr: HierarchyItem[], item) {
    hierarchyArr.forEach(level => {
      if (level.id !== item.id) {
        if (level.subordinates) {
          this.collapseOthers(level.subordinates, item);
        }
        level.expand = false;
      }
    });
  }

  public check(event: Event, newState = !this.checked): void {
    event.stopPropagation();
    this.checked = newState;
    this.checkRecursive(newState);
  }

  private checkRecursive(newState: boolean): void {
    if (this.sites) {
      this.sites.forEach(site => {
        site.checked = newState;
      });
    } else if (this.subordinates) {
      this.subordinates.forEach(sub => {
        sub.checked = newState;
        sub.checkRecursive(newState);
      });
    }
  }
}
