import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  private window: Window;
  constructor(@Inject(DOCUMENT) private document: Document) { 
    this.window = this.document.defaultView;
   }

  ngOnInit() {
    // Scroll to top
    this.window.scrollTo(0, 0);
  }

}
