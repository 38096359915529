
import { Directive, HostListener, Renderer2, ElementRef } from '@angular/core';
@Directive({
  selector: '[lowerCase]'
})
export class LowerCaseDirective {

  constructor(
        private renderer: Renderer2,
        private el: ElementRef
    ) {}

  @HostListener('keyup') onKeyUp() {
    this.el.nativeElement.value = this.lowercase(this.el.nativeElement.value);
  }
  lowercase(inputString) {
    return inputString.toLowerCase().replace(/\s+/g, '');
  }
}
