import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'datePAC'
})
export class DatexPipe implements PipeTransform {

  transform(value: any, format = ''): string {
    const stillUTC = moment.utc(value);
    const localDateTime = moment(stillUTC).local();
    // If moment didn't understand the value, return it unformatted.
    if (!stillUTC.isValid()) {
      return value;
    }
   // Otherwise, return the date formatted as requested.
    return localDateTime.format(format);
  }
}
