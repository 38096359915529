<div class="action__popup overlay">
  <div class="action__popup__content">
    <p>{{ actionPopupMessage.actionMessage }}</p>
    <p>{{ actionPopupMessage.confirmation }}</p>
  </div>
  <div class="action__popup__buttons">
    <button class="btn btn-save" type="button" (click)="onAction(true)">{{ 'ok' | translate }}</button>
    <button class="btn" type="button" (click)="onAction(false)">{{ 'cancel' | translate }}</button>
  </div>
</div>
