<div class="main-container user-profile-container">
    <form class="user-profile-form form-group-container" novalidate #form="ngForm" name="deactivateForm" [formGroup]="deactivateForm"
      (ngSubmit)="onSubmitDeactivateAccount(deactivateForm.value)" [ngClass]="{submitted: isSubmitted}">
      <p style='margin: 0 0 10px 0; font-size: 15px; line-height: 1;display: block; margin-block-start: 1em; margin-block-end: 1em; margin-inline-start: 0px; margin-inline-end: 0px;'>{{'deactivate_account_popup' | translate}}</p>
       <!-- for normal user login -->
      <label *ngIf="!isOktaLogin" class="form-control-container">
        <div class="control-label">{{'password' | translate}}</div>
        <div class="control-input-container">
          <input class="control-input" type="password" id="password" name="password" placeholder="{{'password' | translate}}" formControlName="password"
            required>
          <div *ngIf="password.invalid || (password.dirty || password.touched)" class="control-error-container error-msgs">
            <div *ngIf="password.errors?.minlength">{{'must_8_characters_message' | translate}}</div>
            <div *ngIf="password.errors?.maxlength">{{'pass_max_length' | translate}}</div>
            <div *ngIf="password.errors?.pattern">{{'lower_upper_case' | translate}}</div>
          </div>
        </div>
      </label>
      <!-- for okta user login -->
      <label *ngIf="isOktaLogin" class="form-control-container">
        <div class="control-label">{{'email' | translate}}</div>
        <div class="control-input-container">
          <input class="control-input" type="text" id="email" name="email" placeholder="{{'enter_email' | translate}}" formControlName="email"
            required>
          <div *ngIf="email.invalid || (email.dirty || email.touched)" class="control-error-container error-msgs">
          </div>
        </div>
      </label>

      <div class="form-submit-container">
        <button *ngIf="!isOktaLogin" class="btn" [ngClass]="{'submit-btn-disabled': password.invalid}" type="submit">{{'deactivate_account' | translate}}</button>
        <button *ngIf="isOktaLogin" class="btn" [ngClass]="{'submit-btn-disabled': email.invalid}" type="submit">{{'deactivate_account' | translate}}</button>
        <!-- <button class="btn" [ngClass]="{'submit-btn-disabled': password.invalid}" type="submit">{{'deactivate_account' | translate}}</button> -->
      </div>
    </form>
  </div>
  