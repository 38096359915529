import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LocalDataStoreService {

  public currentUserState: BehaviorSubject<any> = new BehaviorSubject(null);
  public orgListSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public solutionListSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public localesListSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public countriesListSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public timezoneListSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  public userDetails = null;
  public orgList = null;
  public solutionList = null;
  public localesList = null;
  public countriesList = null;
  public tzList = null;
  constructor() { /** */ }

  updateData(data: any) {
    this.userDetails = data;
    this.currentUserState.next(data);
  }

  setOrganizations(data) {
    this.orgList = data;
    this.orgListSubject.next(data);
  }

  setSolutions(data) {
    this.solutionList = data;
    this.solutionListSubject.next(data);
  }

  setLocales(data) {
    this.localesList = data;
    this.localesListSubject.next(data);
  }

  setCountries(data) {
    this.countriesList = data;
    this.countriesListSubject.next(data);
  }

  setTimezones(data) {
    this.tzList = data;
    this.timezoneListSubject.next(data);
  }
}
