export class CustomHierarchyDropdownConfig {
  public title: string;
  public multiselect: boolean;
  public emit: string;
  /*only for single select*/
  public select: string;

  constructor() {
    this.title = 'Hierarchy & Permissions';
    this.multiselect = true;
    /*can cotain two values ==> node & site*/
    this.emit = 'node';
    /*can cotain two values ==> node & site*/
    this.select = 'node';
  }
}
