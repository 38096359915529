
import {throwError as observableThrowError,  Subject ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../base/services';
import { environment } from '../../../../environments/environment';
import { catchError ,  map } from 'rxjs/operators';

@Injectable()
export class HierarchyDropdownService {
  public selectedHierarchy = new Subject();
  private API_ENDPOINT = `${environment.NODE_URL}hierarchy`;

  constructor(private _httpClientService: HttpClientService ) {
  }

  public getImmediateOrgChild(nodeId) {
    const url = `${this.API_ENDPOINT}/immediate/child/${nodeId}`;
    return this._httpClientService.get(url)
    .pipe(
      map(res => res.data),
      catchError(error => this.handleError(error))
    );
  }

  private handleError(error) {
    return observableThrowError(error.error || 'Server Error');
  }
}
