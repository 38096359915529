
import {throwError as observableThrowError,  Subject ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../base/services';
import { environment } from '../../../../environments/environment';
import { catchError ,  map } from 'rxjs/operators';

@Injectable()
export class FeatureListService {
  private URLS = {
    api_endpoint: `${environment.NODE_URL}solution/feature`,
    user_features: `${environment.NODE_URL}user/?features=`,
    solutions: `${environment.NODE_URL}common/solutions`,
    user: `${environment.NODE_URL}user/`,
    users: `${environment.NODE_URL}users/`
  };

  constructor(private _httpClientService: HttpClientService ) {
  }

  public getFeatureList(solutionId) {
    const url = `${this.URLS.api_endpoint}/${solutionId}`;
    return this._httpClientService.get(url)
    .pipe(
      map(res => res.data),
      catchError(error => this.handleError(error))
    );
  }

  public getUsersFeatureList() {
    const url = `${this.URLS.user_features}`;
    return this._httpClientService.get(url)
    .pipe(
      map(res => res.data),
      catchError(error => this.handleError(error))
    );
  }

  public getAllFeatures() {
    const url = `${this.URLS.api_endpoint}/list/all`;
    return this._httpClientService.get(url)
    .pipe(
      map(res => res.data),
      catchError(error => this.handleError(error))
    );
  }

  getSolutions(): Observable<any> {
    return this._httpClientService.get(this.URLS.solutions)
      .pipe(
        catchError(error => this.handleError(error))
      );
  }

  fetchAllUserAssignedFeatures(userid): Observable<any> {
    return this._httpClientService.get(`${this.URLS.users}${userid}?features`)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  private handleError(error) {
    return observableThrowError(error.error || 'Server Error');
  }
}
