<div class="flag-holder" (click)="toggle($event);">
    <span>
      <span>{{getLangName()}}</span>
      <img *ngIf="getLangFlag()" src="../../../../assets/images/flags/{{getLangFlag()}}.svg" />
    </span>
  <i class="fa" [ngClass]="{'fa-caret-down': !toggleDiv, 'fa-caret-up': toggleDiv}"></i>
</div>
<div class="flag-list-container" *ngIf="toggleDiv">
  <ul>
    <li *ngFor="let lang of languages" (click)="onChange(lang)" [ngClass]="{'selected': lang.locale === selectedLanguage.locale}">
      <span>{{lang.name}}</span>
      <img src="../../../../assets/images/flags/{{lang.locale}}.svg" />
    </li>
  </ul>
</div>
