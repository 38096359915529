<app-custom-cookie-consent *ngIf="cookieConsentAccepted === false" (hideConsentPopup)="hideConsentPopup($event)"></app-custom-cookie-consent>

<div class="container main-container" [ngClass]="{responsive: showResponsive}">
    <div class="logo-img-container">
        <a class="logo-img-link" (click)="home()">
            <img src="assets/images/diversey-logo-new.png" class="logo-img" />
        </a>
        <span class="site-name">Internet of Clean</span>
    </div>
    <div *ngIf="name && (solutionName != 'portal-admin')" class="client-logo-holder">
        <!--<div *ngIf="!clientLogo"  class="no-logo">Client Logo</div>-->
        <img *ngIf="clientLogo" src="{{clientLogo}}" onerror="this.onerror=null;this.src='assets/images/nologo.png'" />
    </div>
    <div class="user-info-container flex-row" *ngIf="name">
        <div class="user-greeting flex-row" [ngClass]="{dropdown: !showResponsive}">
            {{'hello' | translate}}
            <span class="username" (click)="toggleDropdown($event)">{{name}}</span>
            <span [ngClass]="toggle ? 'arrowUp' : 'arrowDown'" (click)="toggleDropdown($event)"></span>
            <ul class="dropdown-menu" [style.display]="toggle ? 'block' : 'none'">
                <li class="dropdown-item" (click)="openUserProfile()">
                    <a class="item-link">
                        <span class="item-text">{{'profile' | translate}}</span>
                    </a>
                </li>
                <li class="dropdown-item" (click)="changePassword()">
                    <a class="item-link">
                        <span class="item-text">{{'change_password' | translate}}</span>
                    </a>
                </li>
                <li class="dropdown-item" *ngIf="showHideDeactivateBtn()" (click)="showDeactivateModal()">
                    <a class="item-link">
                        <span class="item-text">{{'deactivate_account' | translate}}</span>
                    </a>
                </li>
                <li class="dropdown-item" (click)="modifyCookies()">
                    <a class="item-link">
                        <span class="item-text">{{'modify_cookies' | translate}}</span>
                    </a>
                </li>
                <li class="dropdown-item" (click)="logout()">
                    <a class="item-link">
                        <span class="item-text">{{'logout' | translate}}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="flag-container">
            <img class="flag" src="assets/images/flags/{{flagIcon}}.svg" title="{{countryName}}" alt="{{countryName}}">
        </div>
    </div>
</div>

<!-- User Profile Popup-->
<app-popup *ngIf="showUserProfile" (isProfileTab)="switchTabs($event)" [isProfileTabActive]="showtab" [isDeactivateTabActive]="showDeactivatetab" [isAdminUser]="showHideDeactivateBtn()" (closeSelf)="closeUserProfile($event)" (click)="closeUserProfile()">
    <user-profile *ngIf="showtab" (notify)="closeUserProfile()"></user-profile>
    <app-reset-password *ngIf="!showtab && !showDeactivatetab" (notify)="closeUserProfile()"></app-reset-password>
    <deactivate-account *ngIf="!showtab && showDeactivatetab" (notify)="closeUserProfile()"></deactivate-account>
</app-popup>