import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'canAccessData' })

export class AccessibleData implements PipeTransform {
  transform(data, userOrgId, accessibleNodes): any {
    const isSubAdmin = Array.isArray(accessibleNodes) && (accessibleNodes !== null);
    if (data && isSubAdmin) {
      return data ? data.filter(d => {
        d['canAccess'] = (Number(d['org_id']) === userOrgId) || (Number(d['user_role_id']) === 1);
        return d;
      }) : [];
    } else {
      return data ? data.filter(d => {
        d['canAccess'] = Number(d['user_role_id']) === 1;
        return d;
      }) : [];
    }
  }
}
