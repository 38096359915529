<div class="select-dropdown multiple-solutions" [ngClass]="{'active': featureListActiveClass}" (click)="toggleActive($event);">
	<div class="label" *ngIf="!displaySelectedFeatures()">{{'select_feature' | translate }}</div>
	<div class="label ellipses-truncate" *ngIf="displaySelectedFeatures()">
		<span class="dropdown-heading">{{displaySelectedFeatures()}}</span>
	</div>
	<div *ngIf="features" class="select-dropdown-wrapper fixed-height">
		<div class="select-item" *ngFor="let item of features; let solutionIdx=index">
			<label for="checkbox_{{i}}">{{ item.solution_name }}</label>
			<ul>
				<li *ngFor="let value of item.features; let featureIdx=index">
					<span>{{value.name}}</span>
					<input type="checkbox" [checked]="value.checked"
					(click)="onFeatureChange(value.id, item.solution_id, item.solution_name, value.checked, solutionIdx, featureIdx, value.name); $event.stopPropagation()">
				</li>
			</ul>
		</div>
	</div>
</div>
