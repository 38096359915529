import { Subscription } from 'rxjs';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PortalAdminService } from 'app/portal-admin/services/portal-admin.service';
import { AccountOverview } from '../../models/account-overview';
import { CookieStorage, LocalDataStoreService, ToasterMessageService } from 'app/base/services';
import { locales } from '../../../../assets/pac_i18n/locale';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { isTileAccessible, isAdminUser, isSubAdminUser } from '../../../base/utils';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {
  landingPageDetails: AccountOverview = new AccountOverview({});
  userName = '';
  public solutionView = false;
  public solutions = [];
  public redirectUrl = {
    IntelliTrail: 'intelli-trail',
    IntelliTrak: 'intelli-trak',
    IntelliCare: 'intelli-care',
    IntelliDish: 'intelli-dish',
    'Food Safety Monitoring': 'food-safety-monitoring',
    'Temp Monitoring': 'temp-monitoring',
    IntelliLinen: 'intelli-linen'
  };
  public country_locale = new locales();

  public userData;
  public isDMuser = false;
  userRoleId = null;
  public userSubscription: Subscription;
  public finnishLocale;
  public isTileAccessible;
  private orgStructure = {
    Root: ['Company', 'Client', 'Customer'],
    Company: ['Client', 'Customer'],
    Client: ['Customer'],
    Customer: []
  };
  private window: Window;

  constructor(private router: Router,
              private portalService: PortalAdminService,
              private translate: TranslateService,
              private toasterService: ToastrService,
              private cookieStorage: CookieStorage,
              private localDataStoreService: LocalDataStoreService,
              private toasterMessageService: ToasterMessageService,
              @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
    const locale = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    if (locale === 'fi-FI') {
      this.finnishLocale = 'fi-FI';
    }
    this.translate.use(this.country_locale.dictionary[locale]);
  }

  ngOnInit() {
    this.setLocale();
    this.userSubscription = this.localDataStoreService.currentUserState.subscribe(userData => {
      if (userData) {this.setData(userData); }
    });
  }

  setData(data) {
    this.userData = data;
    this.solutions = data['solutions'] || [];
    this.isAllowed('');
    this.userName = data['username'];
    this.userRoleId = data['user_role_id'];
    this.getLandingPageData();
    const { accessible_nodes, organization } = this.userData;
    const haveAccessibleNodes = isSubAdminUser(accessible_nodes);
    this.isTileAccessible = isTileAccessible(organization.org_type, haveAccessibleNodes);
  }

  setLocale() {
    const locale = this.cookieStorage.getCookie('locale') || 'en-US';
    this.cookieStorage.setCookie('locale', locale);
  }

  isAllowed(type) {
    if (this.userData) {
      const org = this.userData.organization;
      if (this.userRoleId === 4) {
        this.isDMuser = true;
        return false;
      }
      if (type && org && org['org_type']) {
        const userOrg = org['org_type'];
        return this.orgStructure[userOrg].indexOf(type) !== -1;
      }
    }
    return false;
  }

  getLandingPageData() {
    this.portalService.getLandingPageData().subscribe(res => {
      if (res && res.data && res.success) {
        this.landingPageDetails = res.data;
      }
    }, error => {
      this.errorHandler(error.error.code);
    });
  }

  getData(val) {
    return val ? val : 0;
  }

  errorHandler(code) {
    const msg = this.toasterMessageService.getErrorMessage(code);
    this.toasterService.error(msg);
  }

  routeTo(path) {
    this.router.navigate([path]);
  }

  onSolutionSelect(solution) {
    if (this.redirectUrl[solution]) {
      this.window.location.href = `/${this.redirectUrl[solution]}`;
    }
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  showHideAddButton(tileType) {
    const allowed = this.userRoleId ? (isAdminUser(this.userRoleId) || this.userRoleId === 4) : false;
    return allowed && this.isTileAccessible[tileType];
  }

}
