export const constants = {
  ADMIN: 'Admin',
  ROLES: [{ name: 'Admin', id: 2 }, { name: 'User', id: 3 }],
  DEFAULT_LOCALE: 'en-US',
  DISH: {
    SOLUTION_NAME: 'IntelliDish',
    FEATURES: {
      global_admin: 'Diversey Global Admin',
      distributor: 'Distributor'
    }
  },
  LINEN: {
    SOLUTION_NAME: 'IntelliLinen',
    FEATURES: {
      global_admin: 'Diversey Global Admin',
      distributor: 'Distributor'
    }
  }
};
