import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, AbstractControl } from '@angular/forms';
import { UserService, LoaderService, CookieStorage, ToasterMessageService, LocalDataStoreService, CommonService } from '../../../base/services';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { locales } from '../../../../assets/pac_i18n/locale';
import { encryptPassword, isDiverseyAdmin, isPowerAdmin } from '../../utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../user-profile/user-profile.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  public forgotPassForm: UntypedFormGroup;
  public resetPassForm: UntypedFormGroup;
  public isSubmitted = false;
  loader = false;
  public country_locale = new locales();
  private locale;
  private userEmail: string;
  public isOktaLogin: boolean;
  private userSubscription: Subscription;

  public userData = null;
  public passwordSuggestValue = '';

  constructor(private _fB: UntypedFormBuilder,
              private _router: Router,
              private _userService: UserService,
              private toasterService: ToastrService,
              private loaderService: LoaderService,
              private translate: TranslateService,
              private cookieStorage: CookieStorage,
              private commonService: CommonService,
              private toasterMessageService: ToasterMessageService,
              private storeService: LocalDataStoreService,
              private clipboard: Clipboard
  ) {
    const loc = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    this.locale = this.country_locale.dictionary[loc];
    translate.use(this.locale);
  }

  ngOnInit() {
    this.loaderService.loaderState.subscribe(state =>  {
      this.loader = state;
    });
    this.generateRandomPassword();
    this.userSubscription = this.storeService.currentUserState.subscribe(userData => {
      if (userData) {
        this.userData = userData || null;
        this.userEmail = userData['email'];
      }
      if(userData && userData.is_okta_login && userData.email != undefined && userData.email.includes('@diversey.com')) {
        this.isOktaLogin = userData.is_okta_login;
      } else {
        this.isOktaLogin = false;
      }
    });

    this.resetPassForm = this._fB.group({
      currentPassword: ['', [Validators.required,
        Validators.minLength(8),
        Validators.pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/g)]],
      newPassword: ['', [Validators.required,
        Validators.minLength(8), Validators.pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/g)]],
      confirmPassword: ['', [Validators.required]]
    }, { validator: [ this.passwordMatchValidator ] });
    this.setPasswordMinLimit();
  }

  applyClass(isValid: boolean): string {
    if (isValid) {
      return 'login-btn login-btn-enable';
    }
    return 'login-btn login-btn-disabled';
  }

  setPasswordMinLimit() {
    if (this.resetPassForm && this.resetPassForm !== undefined && this.resetPassForm.controls['newPassword'] !== undefined) {
      if (this.checkDiverseyOrPowerAdmin()) {
        if (this.resetPassForm && this.resetPassForm.controls['newPassword']) {
          this.resetPassForm.controls['newPassword'].addValidators(Validators.minLength(15));
        }
      }
    }
  }

  generateRandomPassword() {
    const passwordtest = this.commonService.generateRandomPassword();
    this.passwordSuggestValue = passwordtest;
  }

  onSubmitSetPassword(passData) {
    this.loaderService.show();
    delete passData['confirmPassword'];
    if (!this.isOktaLogin) {
      passData['currentPassword'] = encryptPassword(passData['currentPassword'], this.userEmail);
      passData['newPassword'] = encryptPassword(passData['newPassword'], this.userEmail);
      this._userService.resetPassword(passData).subscribe(res => {
        this.isSubmitted = true;
        const msg = this.toasterMessageService.getSuccessMessage(6001);
        this.toasterService.success(msg);
        this.loaderService.hide();
        this.notify.emit();
      }, err => {
        this.isSubmitted = false;
        this.loaderService.hide();
        const msg = this.toasterMessageService.getErrorMessage(err.error.code);
        this.toasterService.error(msg);
      });
    } else {
      passData['currentPassword'] = encryptPassword(passData['currentPassword'], this.userEmail);
      passData['newPassword'] = encryptPassword(passData['newPassword'], this.userEmail);
      this._userService.resetOktaPassword(passData).subscribe(res => {
        // console.log(' onSubmitSetPassword resetOktaPassword: success: ', res);
        this.isSubmitted = true;
        const msg = this.toasterMessageService.getSuccessMessage(6001);
        this.toasterService.success(msg);
        this.loaderService.hide();
        this.notify.emit();
      }, err => {
        // console.log('onSubmitSetPassword resetOktaPassword: error: ', err);
        this.isSubmitted = false;
        this.loaderService.hide();
        // const msg = this.toasterMessageService.getErrorMessage(err.error.code);
        // this.toasterService.error(msg);
        this.toasterService.error(err.message, err.error);
      });
    }
    
  }

  copyPasswordToClipboard() {
    this.clipboard.copy(this.passwordSuggestValue);
    this.toasterService.info('Password copied to the clipboard!');
  }

  get newPassword() {
    return this.resetPassForm.get('newPassword') as UntypedFormControl;
  }

  get confirmPassword() {
    return this.resetPassForm.get('confirmPassword') as UntypedFormControl;
  }

  get currentPassword() {
    return this.resetPassForm.get('currentPassword') as UntypedFormControl;
  }

  passwordMatchValidator(AC: AbstractControl) {
    const pwd = AC.get('newPassword').value;
    const confirmPwd = AC.get('confirmPassword').value;

    if (pwd !== confirmPwd) {
      AC.get('confirmPassword').setErrors({MatchPassword: true});
    } else {
      return null;
    }
  }

  /** same password allowed */
  // pwdNotChangedValidator(AC: AbstractControl) {
  //   const currPwd = AC.get('currentPassword').value;
  //   const pwd = AC.get('newPassword').value;
  //   if (currPwd && (currPwd === pwd)) {
  //     AC.get('newPassword').setErrors({samePassword: true});
  //   } else {
  //     return null;
  //   }
  // }


  checkDiverseyOrPowerAdmin() {
    const { accessible_nodes, org_id, user_role_id } = this.userData;
    return (isPowerAdmin(this.userData.user_role_id) || isDiverseyAdmin(user_role_id, accessible_nodes, org_id));
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}
