export class AccountOverview {
  public companies: number;
  public sites: number;
  public users: number;
  public assets: number;
  public preset: number;
  public organizations: number;
  constructor(data) {
    this.companies = data.companies || 0;
    this.sites = data.sites || 0;
    this.users = data.users || 0;
    this.assets = data.assets || 0;
    this.preset = data.assets || 0;
    this.organizations = data.organizations || 0;
  }
}
