import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { CustomHierarchyDropdownConfig } from '../../models';
import { locales } from '../../../../assets/pac_i18n/locale';
import { CookieStorage } from 'app/base/services/cookie-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { sortData } from 'app/base/utils';
import { ToasterMessageService } from 'app/base/services/toaster.service';
import { uniqBy } from 'lodash';

@Component({
  selector: 'custom-hierarchy-dropdown',
  templateUrl: './custom-hierarchy-dropdown.component.html',
  styleUrls: ['./custom-hierarchy-dropdown.component.scss', '../../../../scss/_forms_new.scss'],
  host: {
    '(document:click)': 'closeCustomDropdown()'
  }
})
export class CustomHierarchyDropdownComponent implements OnInit, OnChanges {
  @Input('list') hierarchiesList?: Array<any>;
  @Input('config') config?: CustomHierarchyDropdownConfig = new CustomHierarchyDropdownConfig();
  @Output() onSelectionChange = new EventEmitter<any>();
  // gives full details of node(s) selected
  @Output() onChange = new EventEmitter<Array<any>>();
  @Input('nodesAllowed') nodesAllowed?: Array<any>;
  @Output() opened = new EventEmitter<boolean>();
  @Input('closeSelf') closeSelf?: string;
@Input('preSelectArr') preSelectArr: Array<number>;
  @Input('disabled') disabled: boolean;
  @Input('showTitle') showTitle = true;

  public isSingleSelectWithoutSite = false;
  public singleSelectNode = null;
  multiSelectNodes = [];
  removedNodes = [];
  activeTopLevelNode: any;
  showCustomDropdown = false;
  breadcrumbs = [];

  @Input('optionDisabled') optionDisabled ?= false;

  public country_locale = new locales();
  private orgTypes = {
    Root: ['Company', 'Client'],
    Company: ['Client'],
    Client: ['Customer', 'Site'],
    Customer: ['Site']
  };
  private nodeInAction;
  private totalNodes = 0;
  private checkedNodes = [];

  constructor(
    private translate: TranslateService,
    private toasterMessageService: ToasterMessageService,
    private cookieStorage: CookieStorage) {
    const locale = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    this.translate.use(this.country_locale.dictionary[locale]);
  }

  ngOnInit() { /** */ }

  isFullSelected(node) {
    const condition = Object.prototype.hasOwnProperty.call(node,'full_selected') ? node.full_selected : true;
    return (condition && (node.checked && node.full_selected));
  }

  allEntitySelected(entity) {
    let condition = true;
    if (Array.isArray(entity)) {
      condition = entity.every(i => this.isFullSelected(i));
    }
    return condition;
  }

  someEntitySelected(entity) {
    let condition = false;
    if (entity && Array.isArray(entity)) {
      condition = entity.some(i => i.checked);
    }
    return condition;
  }

  isPartiallySelected(node) {
    return node.checked && !node.full_selected && node.type !== 'Site';
  }

  public allChildrenSelected(node) {
    if (node.type === 'Root') {
      const { Client, Company } = node;
      return this.allEntitySelected(Client) && this.allEntitySelected(Company);
    } else if (node.type === 'Company') {
      const { Client } = node;
      return this.allEntitySelected(Client);
    } else if (node.type === 'Client') {
      const { Customer, Site } = node;
      return this.allEntitySelected(Customer) && this.allEntitySelected(Site);
    } else if (node.type === 'Customer') {
      const { Site } = node;
      return this.allEntitySelected(Site);
    }
  }

  updateParentSelection(node, value) {
    const parent = node ? node.parentRef : null;
    if (parent) {
      if (value) {
        // when checked any child node update their parent to checked
        parent.checked = value;
        this.updateParentSelection(parent, value);
      } else {
        if (parent.type === 'Root') {
          const { Company, Client } = parent;
          if (!this.someEntitySelected(Company) && !this.someEntitySelected(Client)) {
            parent.checked = value;
            this.updateParentSelection(parent, value);
          }
        } else if (parent.type === 'Company') {
          const { Client } = parent;
          if (!this.someEntitySelected(Client)) {
            parent.checked = value;
            this.updateParentSelection(parent, value);
          }
        } else if (parent.type === 'Client') {
          const { Site, Customer } = parent;
          if (!this.someEntitySelected(Customer) && !this.someEntitySelected(Site)) {
            parent.checked = value;
            this.updateParentSelection(parent, value);
          }
        } else if (parent.type === 'Customer') {
          const { Site } = parent;
          if (!this.someEntitySelected(Site)) {
            parent.checked = value;
            this.updateParentSelection(parent, value);
          }
        }
      }
    }
  }

  updateChildrenSelection(node, value) {
    const orgTypes = this.orgTypes[node.type] ? this.orgTypes[node.type] : [];
    orgTypes.forEach(org => {
      const nodeValues = node[org] || null;
      if (nodeValues) {
        this.updateNextChild(nodeValues, value);
      }
    });
  }

  updateNextChild(children, value) {
    if (children) {
      const len = children.length;
      const iLen = len; // not sure why @vishal
      for (let i = 0; i < iLen; ++i) {
        // if (children[i].is_active && !children[i].isDisabled) {
          children[i].checked = value;
          value ? children[i].full_selected = true : delete children[i].full_selected;
          this.updateChildrenSelection(children[i], value);
        // }
      }
    }
  }

  // recursively partially-select (set orange color) node's parent
  setParentPartialSelected(node) {
    if (node.parentRef) {
      node.parentRef.full_selected ? delete node.parentRef.full_selected : null;
      this.setParentPartialSelected(node.parentRef);
    }
  }

  removeFullSelected(node) {
    node.full_selected ? delete node.full_selected : null;
  }

  setSelected(value, parent, node) {
    if (value) {
      this.nodeInAction === node.id ? node.full_selected = true : null;
      if (parent) {
        if (this.allChildrenSelected(parent)) {
          parent.full_selected = true;
          parent.checked = true;
          this.setSelected(value, parent.parentRef, parent);
        } else {
          this.removeFullSelected(parent);
        }
      }
    } else {
      this.removeFullSelected(node);
      this.setParentPartialSelected(node);
    }
  }

  public updateMultipleSelection($event, node, parent) {
    let topLevelAccess = false;
    node.parentRef = parent;
    const value = $event.target.checked;
    node.checked = value;
    this.nodeInAction = node.id;
    this.setSelected(value, parent, node);
    this.updateParentSelection(node, value);
    this.updateChildrenSelection(node, value);
    topLevelAccess = node.id === this.hierarchiesList[0].id && value;
    this.updateMultiSelectNodes(topLevelAccess);

    let disabledNodes = JSON.parse(localStorage.getItem('disabledNodes'));
    disabledNodes = uniqBy(disabledNodes, 'id');

    let parentIds = [];
    disabledNodes.forEach(node => {
      if (node.parent_id) {
        parentIds.push(Number(node.parent_id));
      }
    });

    parentIds = parentIds.filter((id, i) => {
      return parentIds.indexOf(id) === i;
    });

    const data = this.multiSelectNodes.map(item => item.id);
    const removedNodes = this.removedNodes.map(item => item.id);
    parentIds.forEach(pId => {
      if (data.indexOf(pId) === -1) {
        if (removedNodes.indexOf(pId) !== -1) {
          removedNodes.splice(removedNodes.indexOf(pId), 1);
        }
      }
    });

    const emitDetails = this.multiSelectNodes.map(item => {
      return {
        id: item.id,
        name: item.name,
        breadcrumb: item.breadcrumbs,
        parentId: item.parent_id,
        is_active: item.is_active,
        isDisabled: item.isDisabled
      };
    });
    // if user selects all nodes manually one by one this condition will handle it
    // and emit []
    // const temp = this.isPartiallySelected(this.hierarchiesList[0]);
    const emitData = { addedNodes: data, removedNodes: removedNodes };  // (!temp || data[0] === 'allChecked') ? ['allChecked'] : data;
    this.onSelectionChange.emit(emitData);
    this.onChange.emit(emitDetails);
    if (node.checked && (Number(this.hierarchiesList[0].org_id) === Number(node.org_id))) {
      localStorage.setItem('rootClicked', 'true');
    } else {
      localStorage.removeItem('rootClicked');
    }
  }

  updateMultiSelectNodes(topLevelAccess) {
    this.multiSelectNodes = [];
    this.removedNodes = [];
    if (this.hierarchiesList) {
      if (this.config.emit === 'node') {
        this.hierarchiesList.forEach(node => {
          this.getCheckedNodes(node, topLevelAccess);
        });
      } else if (this.config.emit === 'site') {
        // this.hierarchiesList.forEach(node => {
        //   this.getCheckedSites(node);
        // });
      }
    }
  }

  nodeClicked(node, parent) {
    if (!node.is_active || node.isDisabled) {
      return false;
    }
    node.parentRef = parent;
    if (node.parent_id === 1 && (node.type === 'Company' || node.type === 'Client')) {
      if (this.activeTopLevelNode && this.activeTopLevelNode !== node) {
        this.activeTopLevelNode.expanded = false;
      }
      this.activeTopLevelNode = node;
    } else if (node.type === 'Site' || (this.isSingleSelectWithoutSite && node.path_length === this.activeTopLevelNode.hierarchySchema.levelsCount)) {
      return false;
    }
    node.expanded = !node.expanded;
    return true;
  }

  public toggleCustomDropdown($event): void {
    this.showCustomDropdown = !this.showCustomDropdown;
    this.opened.emit(this.showCustomDropdown);
    $event.stopPropagation();
  }

  closeCustomDropdown() {
    this.showCustomDropdown = false;
  }

  setNodeParent(node) {
    this.totalNodes++;
    const orgTypes = this.orgTypes[node.type] ? this.orgTypes[node.type] : [];
    orgTypes.forEach(org => {
      const nodeValues = node[org] || null;
      if (nodeValues) {
        nodeValues.forEach(i => {
          i.parentRef = this.buildParentRefData(node);
          this.setNodeParent(i);
          if (this.nodesAllowed && this.nodesAllowed.indexOf(i.id) > -1) {
            this.setNodeChecked(i);
          }
        });
      }
    });
  }

  setNodeChecked(node) {
    node.checked = true;
    node.type !== 'Site' ? this.checkedNodes.push(node) : null;
    node.type === 'Site' ? node.full_selected = true : null;
  }

  buildParentRefData(node) {
    if (node) {
      return {
        id: node.id,
        checked: node.checked,
        full_selected: node.full_selected,
        type: node.type,
        parent_id: node.parent_id,
        is_active: node.is_active,
        isDisabled: node.isDisabled
      };
    }
  }

  showSelectedOnInit() {
    this.checkedNodes.forEach(node => {
      if (this.allChildrenSelected(node)) {
        node.full_selected = true;
      }
    });
  }

  ngOnChanges(change) {
    /* hierarchies list change */
    if (change.hierarchiesList && change.hierarchiesList.currentValue) {
      this.checkedNodes = [];
      const preSelect = Array.isArray(this.nodesAllowed) && this.nodesAllowed.length;
      if (this.hierarchiesList.length) {
        const firstElement = this.hierarchiesList[0];
        sortData(this.hierarchiesList[0]);
        if (firstElement) {
          if ((this.nodesAllowed && this.nodesAllowed.indexOf(firstElement.id) > -1)) {
            firstElement.checked = true;
          }
          if (preSelect) {
            this.checkedNodes.push(firstElement);
          }
          this.setNodeParent(firstElement);
        }
      }

      preSelect ? this.showSelectedOnInit() : null;
      if (this.preSelectArr && this.hierarchiesList) {
        if (Array.isArray(this.preSelectArr) && this.preSelectArr.length) {
          this.selectFullHierarchy(this.preSelectArr, this.hierarchiesList);
        }
      }
    }

    if (change.closeSelf && change.closeSelf.currentValue) {
      if (change.closeSelf.currentValue === 'close') {
        this.showCustomDropdown = false;
      }
    }
  }

  selectFullHierarchy(preSelectArr, list) {
    const event = { target: { checked: true } };
    if (preSelectArr.length === 1 && preSelectArr[0] && list[0]) {
      list[0].checked = true;
      this.updateMultipleSelection(event, list[0], null);
    }
  }

  showMessage(type) {
    if (type === 4515) {
      return this.toasterMessageService.getErrorMessage(parseInt(type, 10));
    }
  }

  private getCheckedNodes(node, topLevelAccess) {
    if (node.checked) {
      /*
        if (this.hierarchiesList[0].id === node.id && topLevelAccess) {
          // full access under that organization
          this.multiSelectNodes.push({ id: 'allChecked'});
          return false;
        } else {
        */
      this.multiSelectNodes.push(node);
      // }
    } else {
      this.removedNodes.push(node);
    }

    const orgTypes = this.orgTypes[node.type] ? this.orgTypes[node.type] : [];
    orgTypes.forEach(org => {
      const children = node[org] || null;
      if (children) {
        children.forEach(item => this.getCheckedNodes(item, topLevelAccess));
      }
    });
  }
}
