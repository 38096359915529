<form [formGroup]="HierarchyDropdownForm">
  <div class="container">
    <div class="view__section">
      <div class="view__section--column view__section--body" *ngIf="showdropdown.Company">
        <div class="view__section--body-value-wrap">
          <label class="view__section--body-label">{{'select_company' | translate}}</label>
          <div class="view__section--body-value">
            <div class="select-element" [ngClass]="{'disabled': viaHierarchy}">
              <div class="select-dropdown {{activeClass.Company}} {{getFormArray('Company').touched && getFormArray('Company').errors ? 'ng-invalid ng-touched' : ''}}"
                formArrayName="Company" multiple (click)="toggleActiveClass($event,'Company');">
                <div class="label label-placeholder" *ngIf="!displayValue('Company')">{{'select_company' | translate}} *</div>
                <div class="label" *ngIf="displayValue('Company')"> {{displayValue('Company')}} </div>
                <div class="select-dropdown-wrapper fixed-height">
                  <div class="select-label">
                    <i class="fa fa-search"></i>
                    <input type="search" placeholder="{{'search' | translate}}" (click)="searchClick($event)"
                      [(ngModel)]="companySearch" [ngModelOptions]="{standalone: true}">
                  </div>
                  <div class="select-item" *ngFor="let company of getFormArray('Company').controls | filterBy: ['controls.name.value']: companySearch;let i=index"
                    [formGroupName]="i" (click)="onValueChange(company, 'Company', $event);closeDrop($event)"
                    [class.selected-item]="selectedValue.Company == company.value.id">
                    <label>{{company.value.name}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="view__section--column view__section--body" *ngIf="showdropdown.Client">
        <div class="view__section--body-value-wrap">
          <label class="view__section--body-label">{{'select_client' | translate}}</label>
          <div class="view__section--body-value">
            <div class="select-element" [ngClass]="{'disabled': viaHierarchy}">
              <div class="select-dropdown {{activeClass.Client}} {{getFormArray('Client').touched && getFormArray('Client').errors ? 'ng-invalid ng-touched' : ''}}"
                formArrayName="Client" multiple (click)="toggleActiveClass($event,'Client')">
                <div class="label label-placeholder" *ngIf="!displayValue('Client')">{{'select_client' | translate}} *</div>
                <div class="label" *ngIf="displayValue('Client')"> {{displayValue('Client')}} </div>
                <div class="select-dropdown-wrapper fixed-height">
                  <div class="select-label">
                    <i class="fa fa-search"></i>
                    <input type="search" placeholder="{{'search' | translate}}" (click)="searchClick($event)"
                      [(ngModel)]="clientSearch" [ngModelOptions]="{standalone: true}">
                  </div>
                  <div class="select-item" *ngFor="let client of getFormArray('Client').controls | filterBy: ['controls.name.value']: clientSearch;let i=index"
                    [formGroupName]="i" (click)="onValueChange(client, 'Client', $event); closeDrop($event)"
                    [class.selected-item]="selectedValue.Client == client.value.id">
                    <label>{{client.value.name}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="view__section--column view__section--body" *ngIf="showdropdown.Customer">

        <div class="view__section--body-value-wrap">
          <label class="view__section--body-label">{{'select_customer' | translate}}</label>
          <div class="view__section--body-value">
            <div class="select-element" [ngClass]="{'disabled': viaHierarchy}">
              <div class="select-dropdown {{activeClass.Customer}} {{getFormArray('Customer').touched && getFormArray('Customer').errors ? 'ng-invalid ng-touched' : ''}}"
                formArrayName="Customer" multiple (click)="toggleActiveClass($event,'Customer')">
                <div class="label label-placeholder" *ngIf="!displayValue('Customer')">{{'select_customer' | translate}}</div>
                <div class="label" *ngIf="displayValue('Customer')"> {{displayValue('Customer')}} </div>
                <div class="select-dropdown-wrapper fixed-height">
                  <div class="select-label">
                    <i class="fa fa-search"></i>
                    <input type="search" placeholder="{{'search' | translate}}" (click)="searchClick($event)"
                      [(ngModel)]="customerSearch" [ngModelOptions]="{standalone: true}">
                  </div>
                  <div class="select-item" *ngFor="let customer of getFormArray('Customer').controls | filterBy: ['controls.name.value']: customerSearch;let i=index"
                    [formGroupName]="i" (click)="onValueChange(customer, 'Customer', $event); closeDrop($event)"
                    [class.selected-item]="selectedValue.Customer == customer.value.id">
                    <label>{{customer.value.name}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
