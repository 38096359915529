<div id="popup" class="main-container popup" (click)="$event.stopPropagation()">
  <ul class="tabs flex-row">
    <li class="tabs__item" [ngClass]="{active: isProfileTabActive}" (click)="showContentOf('profile', $event)">
      <span class="tabs__item--text">{{'profile' | translate}}</span>
    </li>
    <li *ngIf="!isOktaLogin" class="tabs__item" [ngClass]="{active: !isProfileTabActive && !isDeactivateTabActive}" (click)="showContentOf('password', $event)">
      <span class="tabs__item--text">{{'reset_password' | translate}}</span>
    </li>
    <li class="tabs__item" [ngClass]="{active: !isProfileTabActive && isDeactivateTabActive}" *ngIf="isAdminUser" (click)="showContentOf('deactivate', $event)">
      <span class="tabs__item--text">{{'deactivate_account' | translate}}</span>
    </li>
  </ul>
  <div class="popup-body-container">
    <ng-content></ng-content>
    <span class="popup-collapse" (click)="collapse()">
      <i class="fa fa-compress" aria-hidden="true"></i>
    </span>
  </div>
</div>
