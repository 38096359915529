export class messages {
  'click_deactivate' = {
    'en-US': 'Click to deactivate',
    'nl-NL': 'Klik om te deactiveren',
    'fr-FR': 'Cliquez pour désactiver',
    'pl-PL': 'Kliknij, aby dezaktywować',
    'zh-ZH': '单击以停用',
    'de-DE': 'Zum Deaktivieren klicken',
    'it-IT': 'Clicca per disattivare',
    'tr-TR': 'Devre dışı bırakmak için tılayın',
    'fi-FI': 'Klikkaa deaktivoidaksesi',
    'da-DK': 'Tryk for at deaktivere',
    'ar-AR': 'انقر لإلغاء التنشيط',
    'pt-PT': 'Clicar para desactivar',
    'es-ES': 'Pinche para desactivar',
    'sv-SE': 'Klicka för att deaktivera',
    'sv-FI': 'Klicka för att inaktivera',
    'hu-HU': 'Kattintson a deaktiváláshoz',
    'el-EL': 'Κάντε κλικ για απενεργοποίηση',
    'no-NO': 'klikk for å deaktivere',
    'ru-RU': 'Нажмите, чтобы отключить',
    'cs-CZ': 'klikněte pro deaktivaci',
    'sl-SL': 'Kliknite za deaktiviranje',
    'sk-SK': 'Kliknutím deaktivujete',
    'ko-KO': '비활성화하려면 클릭하십시오.'
  };
  'click_activate' = {
    'en-US': 'Click to activate',
    'nl-NL': 'Klik om te activeren',
    'fr-FR': 'Cliquez pour activer',
    'pl-PL': 'Kliknij, aby aktywować',
    'zh-ZH': '点击激活',
    'de-DE': 'Klicken Sie, um zu aktivieren',
    'it-IT': 'Clicca per attivare',
    'tr-TR': 'Aktif etmek için TIKLAYIN',
    'fi-FI': 'Klikkaa aktivoidaksesi',
    'da-DK': 'Tryk for at aktivere',
    'ar-AR': 'انقر للتنشيط',
    'pt-PT': 'Clicar para activar',
    'es-ES': 'Pinche para activar',
    'sv-SE': 'Klicka för att aktivera',
    'sv-FI': 'Klicka för att aktivera',
    'hu-HU': 'kattintson az aktiváláshoz',
    'el-EL': 'Κάντε κλικ για να ενεργοποιήσετε',
    'no-NO': 'klikk for å aktivere',
    'ru-RU': 'Нажмите, чтобы активировать',
    'cs-CZ': 'Klikněte pro aktivaci',
    'sl-SL': 'Kliknite, če želite aktivirati',
    'sk-SK': 'Kliknutím aktivujete',
    'ko-KO': '활성화하려면 클릭하십시오.'
  };
  'edit' = {
    'en-US': 'Edit',
    'nl-NL': 'Bewerk',
    'fr-FR': 'Editer',
    'pl-PL': 'edytować',
    'zh-ZH': '编辑',
    'de-DE': 'bearbeiten',
    'it-IT': 'modificare',
    'tr-TR': 'Düzenle',
    'fi-FI': 'Muokkaa',
    'da-DK': 'Redigere',
    'ar-AR': 'تصحيح',
    'pt-PT': 'Editar',
    'es-ES': 'Editar',
    'sv-SE': 'Redigera',
    'sv-FI': 'redigera',
    'hu-HU': 'szerkesztése',
    'el-EL': 'Επεξεργασία',
    'no-NO': 'redigere',
    'ru-RU': 'редактировать',
    'cs-CZ': 'Upravit',
    'sl-SL': 'Uredi',
    'sk-SK': 'Editovať',
    'ko-KO': '편집하다'
  };
  'approve_request' = {
    'en-US': 'Approve Solution Request',
    'nl-NL': 'Approve Solution Request',
    'fr-FR': 'Approve Solution Request',
    'pl-PL': 'Approve Solution Request',
    'zh-ZH': 'Approve Solution Request',
    'de-DE': 'Approve Solution Request',
    'it-IT': 'Approve Solution Request',
    'tr-TR': 'Approve Solution Request',
    'fi-FI': 'Approve Solution Request',
    'da-DK': 'Approve Solution Request',
    'ar-AR': 'Approve Solution Request',
    'pt-PT': 'Approve Solution Request',
    'es-ES': 'Approve Solution Request',
    'sv-SE': 'Approve Solution Request',
    'sv-FI': 'Approve Solution Request',
    'hu-HU': 'Approve Solution Request',
    'el-EL': 'Approve Solution Request',
    'no-NO': 'Approve Solution Request',
    'ru-RU': 'Approve Solution Request',
    'cs-CZ': 'Approve Solution Request',
    'sl-SL': 'Approve Solution Request',
    'sk-SK': 'Approve Solution Request',
    'ko-KO': 'Approve Solution Request'
  };
  'reject_request' = {
    'en-US': 'Reject Solution Request',
    'nl-NL': 'Reject Solution Request',
    'fr-FR': 'Reject Solution Request',
    'pl-PL': 'Reject Solution Request',
    'zh-ZH': 'Reject Solution Request',
    'de-DE': 'Reject Solution Request',
    'it-IT': 'Reject Solution Request',
    'tr-TR': 'Reject Solution Request',
    'fi-FI': 'Reject Solution Request',
    'da-DK': 'Reject Solution Request',
    'ar-AR': 'Reject Solution Request',
    'pt-PT': 'Reject Solution Request',
    'es-ES': 'Reject Solution Request',
    'sv-SE': 'Reject Solution Request',
    'sv-FI': 'Reject Solution Request',
    'hu-HU': 'Reject Solution Request',
    'el-EL': 'Reject Solution Request',
    'no-NO': 'Reject Solution Request',
    'ru-RU': 'Reject Solution Request',
    'cs-CZ': 'Reject Solution Request',
    'sl-SL': 'Reject Solution Request',
    'sk-SK': 'Reject Solution Request',
    'ko-KO': 'Reject Solution Request'
  };

  'reject_request_action_popop' = {
    'en-US': 'This action will reject the solution access request',
    'nl-NL': 'This action will reject the solution access request',
    'fr-FR': 'This action will reject the solution access request',
    'pl-PL': 'This action will reject the solution access request',
    'zh-ZH': 'This action will reject the solution access request',
    'de-DE': 'This action will reject the solution access request',
    'it-IT': 'This action will reject the solution access request',
    'tr-TR': 'This action will reject the solution access request',
    'fi-FI': 'This action will reject the solution access request',
    'da-DK': 'This action will reject the solution access request',
    'ar-AR': 'This action will reject the solution access request',
    'pt-PT': 'This action will reject the solution access request',
    'es-ES': 'This action will reject the solution access request',
    'sv-SE': 'This action will reject the solution access request',
    'sv-FI': 'This action will reject the solution access request',
    'hu-HU': 'This action will reject the solution access request',
    'el-EL': 'This action will reject the solution access request',
    'no-NO': 'This action will reject the solution access request',
    'ru-RU': 'This action will reject the solution access request',
    'cs-CZ': 'This action will reject the solution access request',
    'sl-SL': 'This action will reject the solution access request',
    'sk-SK': 'This action will reject the solution access request',
    'ko-KO': 'This action will reject the solution access request'
  };
  'view' = {
    'en-US': 'View',
    'nl-NL': 'uitzicht',
    'fr-FR': 'Visualiser',
    'pl-PL': 'widok',
    'zh-ZH': '点击激活',
    'de-DE': 'Aussicht',
    'it-IT': 'vista',
    'tr-TR': 'görünüm',
    'fi-FI': 'Näytä',
    'da-DK': 'Oversigt',
    'ar-AR': 'رأي',
    'pt-PT': 'Ver',
    'es-ES': 'VER',
    'sv-SE': 'SE',
    'sv-FI': 'se',
    'hu-HU': 'KILÁTÁS',
    'el-EL': 'Δείτε',
    'no-NO': 'utsikt',
    'ru-RU': 'Посмотреть',
    'cs-CZ': 'Pohled',
    'sl-SL': 'pogled',
    'sk-SK': 'Pozrieť',
    'ko-KO': '전망'
  };
  'are_you_sure' = {
    'en-US': 'Are you sure ?',
    'nl-NL': 'Weet je het zeker ?',
    'fr-FR': 'Êtes-vous sûr ?',
    'pl-PL': 'Jesteś pewny ?',
    'zh-ZH': '你确定吗 ？',
    'de-DE': 'Bist du sicher ?',
    'it-IT': 'Sei sicuro ?',
    'tr-TR': 'Emin misiniz ?',
    'fi-FI': 'Oletko varma?',
    'da-DK': 'Er du sikker ?',
    'ar-AR': 'هل أنت واثق ؟',
    'pt-PT': 'Tem a certeza?',
    'es-ES': '¿Está seguro?',
    'sv-SE': 'Är du säker?',
    'sv-FI': 'Är du säker ?',
    'hu-HU': 'Biztos',
    'el-EL': 'Είσαι σίγουρος ?',
    'no-NO': 'Er du sikker ?',
    'ru-RU': 'Уверены ли вы ?',
    'cs-CZ': 'Jsi si jistá ?',
    'sl-SL': 'Ali si prepričan ?',
    'sk-SK': 'Ste si istí?',
    'ko-KO': '확실해 ?'
  };
  'action' = {
    'en-US': 'This action will',
    'nl-NL': 'Deze actie zal',
    'fr-FR': 'Cette action va',
    'pl-PL': 'Ta akcja będzie',
    'zh-ZH': '这个动作会',
    'de-DE': 'Diese Aktion wird',
    'it-IT': 'Questa azione lo farà',
    'tr-TR': 'Bu eylem',
    'fi-FI': 'Tämä toimennpide',
    'da-DK': 'Denne handling vil',
    'ar-AR': 'هذا العمل سوف',
    'pt-PT': 'Esta acção vai',
    'es-ES': 'Esta acción será',
    'sv-SE': 'Denna åtgärd kommer',
    'sv-FI': 'Denna åtgärd kommer att',
    'hu-HU': 'Ez az akció lesz',
    'el-EL': 'Η δράση αυτή θα',
    'no-NO': 'Denne handlingen vil',
    'ru-RU': 'Это действие будет',
    'cs-CZ': 'Tato akce bude',
    'sl-SL': 'To dejanje bo',
    'sk-SK': 'Táto akcia bude',
    'ko-KO': '이 작업은'
  };
  'delete' = {
    'en-US': 'delete',
    'nl-NL': 'verwijderen',
    'fr-FR': 'effacer',
    'pl-PL': 'kasować',
    'zh-ZH': '删除',
    'de-DE': 'löschen',
    'it-IT': 'Elimina',
    'tr-TR': 'sil',
    'fi-FI': 'poista',
    'da-DK': 'slet',
    'ar-AR': 'حذف',
    'pt-PT': 'apagar',
    'es-ES': 'borrar',
    'sv-SE': 'radera',
    'sv-FI': 'radera',
    'hu-HU': 'törlés',
    'el-EL': 'Διαγράψετε',
    'no-NO': 'slette',
    'ru-RU': 'удалять',
    'cs-CZ': 'vymazat',
    'sl-SL': 'izbrisati',
    'sk-SK': 'Zmazať',
    'ko-KO': '지우다'
  };
  'activate' = {
    'en-US': 'activate',
    'nl-NL': 'activeren',
    'fr-FR': 'Activer',
    'pl-PL': 'Aktywuj',
    'zh-ZH': '启用',
    'de-DE': 'aktivieren Sie',
    'it-IT': 'attivare',
    'tr-TR': 'etkinleştirme',
    'fi-FI': 'aktivoi',
    'da-DK': 'aktivere',
    'ar-AR': 'تفعيل',
    'pt-PT': 'Activar',
    'es-ES': 'activar\n',
    'sv-SE': 'aktivera',
    'sv-FI': 'Aktivera',
    'hu-HU': 'aktiválva',
    'el-EL': 'Ενεργοποιήσετε',
    'no-NO': 'aktivere',
    'ru-RU': 'активировать',
    'cs-CZ': 'aktivovat',
    'sk-SK': 'aktivovať',
    'sl-SL': 'aktivirajte',
    'ko-KO': '활성화하다'
  };
  'de_activate' = {
    'en-US': 'deactivate',
    'nl-NL': 'deactiveren',
    'fr-FR': 'désactiver',
    'pl-PL': 'dezaktywować',
    'zh-ZH': '关闭',
    'de-DE': 'deaktivieren',
    'it-IT': 'disattivare',
    'tr-TR': 'devre dışı bırakıldı',
    'fi-FI': 'Deaktivoi',
    'da-DK': 'deaktivere',
    'ar-AR': 'عطل',
    'pt-PT': 'desactivar',
    'es-ES': 'desactivar\n',
    'sv-SE': 'deaktivera',
    'sv-FI': 'avaktivera',
    'hu-HU': 'kikapcsolva',
    'el-EL': 'Απενεργοποιήσετε',
    'no-NO': 'deaktivere',
    'ru-RU': 'дезактивировать',
    'cs-CZ': 'deaktivovat',
    'sk-SK': 'deaktivovať',
    'sl-SL': 'deaktivirati',
    'ko-KO': '비활성화하다'
  };
  'Company' = {
    'en-US': 'Company',
    'nl-NL': 'Bedrijf',
    'fr-FR': 'Entreprise',
    'pl-PL': 'Firma',
    'zh-ZH': '公司',
    'de-DE': 'Unternehmen',
    'it-IT': 'Azienda',
    'tr-TR': 'şirket',
    'fi-FI': 'Yritys',
    'da-DK': 'Virksomhed',
    'ar-AR': 'شركة',
    'pt-PT': 'Empresa',
    'es-ES': 'Empresa',
    'sv-SE': 'Företag',
    'sv-FI': 'Företag',
    'hu-HU': 'Cég',
    'el-EL': 'Εταιρεία',
    'no-NO': 'Selskap',
    'ru-RU': 'Компания',
    'cs-CZ': 'Společnost',
    'sk-SK': 'Spoločnosť',
    'sl-SL': 'Podjetje',
    'ko-KO': '회사'
  };
  'Client' = {
    'en-US': 'Client',
    'nl-NL': 'Cliënt',
    'fr-FR': 'Client',
    'pl-PL': 'klient',
    'zh-ZH': '客户',
    'de-DE': 'Kunde',
    'it-IT': 'Cliente',
    'tr-TR': 'müşteri',
    'fi-FI': 'Yritysasiakas',
    'da-DK': 'Klient',
    'ar-AR': 'عميل',
    'pt-PT': 'Cliente',
    'es-ES': 'Cliente',
    'sv-SE': 'Klient',
    'sv-FI': 'Klient',
    'hu-HU': 'Ügyfél',
    'el-EL': 'Πελάτη',
    'no-NO': 'Klient',
    'ru-RU': 'клиент',
    'cs-CZ': 'Klienta',
    'sk-SK': 'Klient',
    'sl-SL': 'Odjemalec',
    'ko-KO': '고객'
  };
  'Customer' = {
    'en-US': 'Customer',
    'nl-NL': 'Klant',
    'fr-FR': 'Client',
    'pl-PL': 'Klient',
    'zh-ZH': '顾客',
    'de-DE': 'Endkunden',
    'it-IT': 'Cliente',
    'tr-TR': 'Müşteri',
    'fi-FI': 'Asiakas',
    'da-DK': 'Kunde',
    'ar-AR': 'زبون',
    'pt-PT': 'Sub-cliente',
    'es-ES': 'Cliente',
    'sv-SE': 'Kund',
    'sv-FI': 'Kund',
    'hu-HU': 'Vevő',
    'el-EL': 'Πελάτη',
    'no-NO': 'Kunde',
    'ru-RU': 'покупатель',
    'cs-CZ': 'Zákazník',
    'sk-SK': 'Zákazník',
    'sl-SL': 'Stranka',
    'ko-KO': '고객'
  };
  'please_activate' = {
    'en-US': 'Please activate',
    'nl-NL': 'Activeer alstublieft',
    'fr-FR': 'S\'il vous plaît activer',
    'pl-PL': 'Proszę aktywować',
    'zh-ZH': '请激活',
    'de-DE': 'Bitte aktivieren Sie',
    'it-IT': 'Si prega di attivare',
    'tr-TR': 'Lütfen etkinleştir',
    'fi-FI': 'Aktivoi',
    'da-DK': 'Aktiver venligst',
    'ar-AR': 'يرجى تفعيل',
    'pt-PT': 'Por favor activar',
    'es-ES': 'Por favor active',
    'sv-SE': 'Vänligen aktivera',
    'sv-FI': 'Vänligen aktivera',
    'hu-HU': 'Kérjük, aktiválja',
    'el-EL': 'Ενεργοποιήστε',
    'no-NO': 'Aktiver vennligst',
    'ru-RU': 'Пожалуйста, активируйте',
    'cs-CZ': 'Aktivujte',
    'sk-SK': 'Aktivujte ho',
    'sl-SL': 'Aktivirajte',
    'ko-KO': '활성화하십시오.'
  };
  'perform_action' = {
    'en-US': 'to perform this action',
    'nl-NL': 'om deze actie uit te voeren',
    'fr-FR': 'effectuer cette action',
    'pl-PL': 'wykonać tę akcję',
    'zh-ZH': '执行此操作',
    'de-DE': 'um diese Aktion auszuführen',
    'it-IT': 'per eseguire questa azione',
    'tr-TR': 'bu eylemi gerçekleştirmek için',
    'fi-FI': 'suorittaaksesi toiminnon',
    'da-DK': 'for at udføre denne handling',
    'ar-AR': 'لأداء هذا الإجراء',
    'pt-PT': 'para executar esta ação',
    'es-ES': 'para realizar esta acción',
    'sv-SE': 'att utföra denna åtgärd',
    'sv-FI': 'att utföra denna åtgärd',
    'hu-HU': 'A művelet végrehajtásához',
    'el-EL': 'για να εκτελέσετε αυτήν την ενέργεια',
    'no-NO': 'å utføre denne handlingen',
    'ru-RU': 'выполнить это действие',
    'cs-CZ': 'k provedení této akce',
    'sk-SK': 'vykonať túto akciu',
    'sl-SL': 'da izvedete to dejanje',
    'ko-KO': '이 동작을 수행하기 위해'
  };
  'no_records_found' = {
    'en-US': 'No records found.',
    'nl-NL': 'Geen verslagen gevonden.',
    'fr-FR': 'Aucun enregistrement trouvé.',
    'pl-PL': 'Nic nie znaleziono.',
    'zh-ZH': '没有找到记录。',
    'de-DE': 'Keine Aufzeichnungen gefunden.',
    'it-IT': 'Nessun record trovato.',
    'tr-TR': 'Kayıt bulunamadı.',
    'fi-FI': 'Tietoja ei löytynyt.',
    'da-DK': 'Ingen data fundet.',
    'ar-AR': 'لا توجد سجلات.',
    'pt-PT': 'Não foram encontrados registros.',
    'es-ES': 'No se han encontrado resultados',
    'sv-SE': 'Ingen data hittades.',
    'sv-FI': 'Inga uppgifter funna.',
    'hu-HU': 'Nincs találat.',
    'el-EL': 'Δεν βρέθηκαν αρχεία.',
    'no-NO': 'Ingen opptak funnet.',
    'ru-RU': 'Записей не найдено.',
    'cs-CZ': 'Nenalezeny žádné záznamy.',
    'sk-SK': 'Nenašli sa žiadne záznamy.',
    'sl-SL': 'Ni najdenih zapisov.',
    'ko-KO': '기록이 없습니다.'
  };
  'Site' = {
    'en-US': 'Site',
    'nl-NL': 'plaats',
    'fr-FR': 'Site',
    'pl-PL': 'Teren',
    'zh-ZH': '现场',
    'de-DE': 'Objekt',
    'it-IT': 'Posto',
    'tr-TR': 'yer',
    'fi-FI': 'Kohde',
    'da-DK': 'Lokation',
    'ar-AR': 'موقع',
    'pt-PT': 'Site',
    'es-ES': 'Centro',
    'sv-SE': 'Objekt',
    'sv-FI': 'Webbplats',
    'hu-HU': 'oldal',
    'el-EL': 'Τοποθεσία',
    'no-NO': 'Nettstedet',
    'ru-RU': 'сайт',
    'cs-CZ': 'Stránky',
    'sk-SK': 'Objekt',
    'sl-SL': 'Spletna stran',
    'ko-KO': '대지'
  };
  'no_data_found' = {
    'en-US': 'No data found.',
    'nl-NL': 'Geen data gevonden.',
    'fr-FR': 'Aucune donnée trouvée.',
    'pl-PL': 'Nie znaleziono danych.',
    'zh-ZH': '没有找到数据。',
    'de-DE': 'Keine Daten gefunden.',
    'it-IT': 'Nessun dato trovato.',
    'tr-TR': 'Veri bulunamadı.',
    'fi-FI': 'Dataa ei löytynyt',
    'da-DK': 'Ingen data fundet',
    'ar-AR': 'لاتوجد بيانات.',
    'pt-PT': 'Nenhum dado encontrado',
    'es-ES': 'Datos no encontrados',
    'sv-SE': 'Ingen information hittad',
    'sv-FI': 'Ingen information hittad.',
    'hu-HU': 'Nem található adat',
    'el-EL': 'Δεν βρέθηκαν δεδομένα',
    'no-NO': 'Ingen data funnet.',
    'ru-RU': 'Данные не найдены.',
    'cs-CZ': 'Nenalezena žádná data.',
    'sk-SK': 'Nenašli sa žiadne záznamy.',
    'sl-SL': 'Ni podatkov.',
    'ko-KO': '데이터가 없습니다.'
  };
  'info_msg' = {
    'en-US': 'No fields have been changed, there is nothing new to save',
    'nl-NL': 'Er zijn geen velden gewijzigd, er is niets nieuws om op te slaan',
    'fr-FR': 'Aucun champ n\'a été modifié, il n\'y a rien de nouveau à enregistrer',
    'pl-PL': 'Żadne pola nie zostały zmienione, nie ma nic nowego do zapisania',
    'zh-ZH': '没有任何字段被更改，没有什么新东西可以保存',
    'de-DE': 'Keine Felder wurden geändert, es gibt nichts Neues zu speichern',
    'it-IT': 'Nessun campo è stato modificato, non c\'è nulla di nuovo da salvare',
    'tr-TR': 'Hiçbir alan değiştirilmedi, kaydedilecek yeni bir şey yok',
    'fi-FI': 'Mitään tietoja ei ole muutettu, ei tallennettavaa',
    'da-DK': 'Ingen felter er blevet ændret. Der er intet nyt at gemme.',
    'ar-AR': 'لم يتم تغيير أي حقول ، ولا يوجد شيء جديد يمكن توفيره',
    'pt-PT': 'Nenhum campo foi alterado, não existe nada para guardar',
    'es-ES': 'No hay campos guardados, no hay nada nuevo para guardar',
    'sv-SE': 'Ingen data har ändrats, det finns ingenting nytt att spara',
    'sv-FI': 'Inga fält har ändrats, det finns inget nytt att spara',
    'hu-HU': 'Nem történt mezőváltoztatás, nincs semmi változás ami menthető',
    'el-EL': 'Δεν έχουν αλλάξει πεδία, δεν υπάρχει τίποτα νέο για να αποθηκεύσετε',
    'no-NO': 'Ingen felter har blitt endret, det er ikke noe nytt å lagre',
    'ru-RU': 'Никакие поля не были изменены, нет ничего нового для сохранения',
    'cs-CZ': 'Žádné pole nebyly změněny, není nic nového na uložení',
    'sk-SK': 'Žiadne polia neboli zmenené, nie je nič nové na uloženie',
    'sl-SL': 'Nobena polja niso bila spremenjena, ni nič novega za shranjevanje',
    'ko-KO': '필드가 변경되지 않았습니다. 저장하는 데 새로운 것은 없습니다.'
  };
  'info' = {
    'en-US': 'Info',
    'nl-NL': 'info',
    'fr-FR': 'Info',
    'pl-PL': 'Informacje',
    'zh-ZH': '信息',
    'de-DE': 'Info',
    'it-IT': 'Informazioni',
    'tr-TR': 'Bilgi',
    'fi-FI': 'Tiedot',
    'da-DK': 'Info',
    'ar-AR': 'معلومات',
    'pt-PT': 'Informação',
    'es-ES': 'Información',
    'sv-SE': 'Info',
    'sv-FI': 'Info',
    'hu-HU': 'Info',
    'el-EL': 'Πληροφορίες',
    'no-NO': 'info',
    'ru-RU': 'Информация',
    'cs-CZ': 'Informace',
    'sk-SK': 'info',
    'sl-SL': 'Info',
    'ko-KO': '정보'
  };
  'img_format' = {
    'en-US': 'Please upload file with format jpeg/jpg or png!',
    'nl-NL': 'Upload een bestand met het formaat jpeg / jpg of png!',
    'fr-FR': 'S\'il vous plaît télécharger le fichier avec le format jpeg / jpg ou png!',
    'de-DE': 'Bitte lade die Datei mit dem Format jpeg / jpg oder png hoch!',
    'pl-PL': 'Prześlij plik w formacie jpeg / jpg lub png!',
    'zh-HK': '请上传格式为jpeg / jpg或png的文件！',
    'zh-ZH': '請上傳格式為jpeg / jpg或png的文件！',
    'it-IT': 'Si prega di caricare file con formato jpeg / jpg o png!',
    'tr-TR': 'Lütfen jpeg / jpg veya png formatında dosya yükleyin!',
    'fi-FI': 'Lataa tiedosto muodossa jpeg/jpg tai png!',
    'da-DK': 'Upload venligst fil med jpeg/jpg eller png format!',
    'ar-AR': 'يرجى تحميل الملف بتنسيق jpeg / jpg أو png!',
    'pt-PT': 'Por favor carregue um ficheiro com o formato jpeg/jpg ou png!',
    'es-ES': '¡Por favor cargue el archivo en formato jpeg/jpg o png!',
    'sv-SE': 'Vänligen ladda upp filen i jpeg/jpg eller png format!',
    'sv-FI': 'Var god ladda upp filen med formatet jpeg / jpg eller png!',
    'hu-HU': 'Kérem, a fájlokat jpeg/jpg vagy png formátumban töltse fel!',
    'el-EL': 'Παρακαλούμε να ανεβάσετε το αρχείο με μορφή jpeg / jpg ή png!',
    'no-NO': 'Vennligst last opp fil med format jpeg / jpg eller png!',
    'ru-RU': 'Загрузите файл в формате jpeg / jpg или png!',
    'cs-CZ': 'Nahrajte soubor s formátem jpeg / jpg nebo png!',
    'sk-SK': 'prosím, nahrajte súbor s formátom jpeg / jpg alebo png!',
    'sl-SL': 'Naložite datoteko s formatom jpeg / jpg ali png!',
    'ko-KO': 'jpeg / jpg 또는 png 형식의 파일을 업로드하십시오!'
  };
  'img_size' = {
    'en-US': 'Please upload file with max 2MB size!',
    'nl-NL': 'Upload een bestand met een maximale grootte van 2 MB!',
    'fr-FR': 'S\'il vous plaît télécharger le fichier avec une taille maximale de 2 Mo!',
    'de-DE': 'Bitte lade die Datei mit maximal 2MB Größe hoch!',
    'pl-PL': 'Prześlij plik o rozmiarze maksymalnie 2 MB!',
    'zh-HK': '请上传最大2MB大小的文件！',
    'zh-ZH': '請上傳最大2MB大小的文件！',
    'it-IT': 'Si prega di caricare il file con una dimensione massima di 2 MB!',
    'tr-TR': 'Lütfen en fazla 2MB boyutunda bir dosya yükleyin!',
    'fi-FI': 'Ladattavan tiedostan tulee olla alle 2MB!',
    'da-DK': 'Upload venligst filer på max 2MB!',
    'ar-AR': 'يرجى تحميل الملف بحجم 2 ميغابايت كحد أقصى!',
    'pt-PT': 'Por favor carregue um ficheiro com o máximo de 2MB!',
    'es-ES': '¡Por favor suba un archivo de máx. 2MB de tamaño!',
    'sv-SE': 'Vänligen ladda upp filen med max 2MB!',
    'sv-FI': 'Var god ladda upp filen med max 2 MB storlek!',
    'hu-HU': 'Kérjük, töltsön fel maximum 2 MB méretű fájlt!',
    'el-EL': 'Παρακαλώ ανεβάστε το αρχείο με μέγιστο μέγεθος 2MB!',
    'no-NO': 'Vennligst last opp fil med maks 2 MB størrelse!',
    'ru-RU': 'Загрузите файл с максимальным размером 2 МБ!',
    'cs-CZ': 'Nahrajte soubor s maximální velikostí 2 MB!',
    'sk-SK': 'Nahrajte súbor s veľkosťou maximálne 2 MB!',
    'sl-SL': 'Naložite datoteko z največ 2 MB velikostjo!',
    'ko-KO': '최대 2MB 크기의 파일을 업로드하십시오!'
  };
  'img_dimensions' = {
    'en-US': 'Please upload file with dimensions : ',
    'nl-NL': 'Upload een bestand met dimensies: ',
    'fr-FR': 'S\'il vous plaît télécharger le fichier avec les dimensions',
    'de-DE': 'Bitte lade die Datei mit folgenden Dimensionen hoch: ',
    'pl-PL': 'Prześlij plik z wymiarami: ',
    'zh-HK': '请上传尺寸为：',
    'zh-ZH': '請上傳尺寸為：',
    'it-IT': 'Si prega di caricare il file con le dimensioni: ',
    'tr-TR': 'Lütfen boyutları olan bir dosya yükleyin',
    'fi-FI': 'Lataa oikean kokoinen tiedosto',
    'da-DK': 'Upload venligst filen med dimensioner',
    'ar-AR': 'يرجى تحميل الملف بأبعاد:',
    'pt-PT': 'Por favor carregue um ficheiro com as dimensões',
    'es-ES': 'Por favor cargue el archivo con las dimensiones adecuadas',
    'sv-SE': 'Vänligen ladda upp filen med mått',
    'sv-FI': 'Var god ladda upp filen med mått: ',
    'hu-HU': 'Kérem, a fájlt méretekkel együtt töltse fel.',
    'el-EL': 'Παρακαλούμε ανεβάστε το αρχείο με διαστάσεις: ',
    'no-NO': 'Vennligst last opp fil med dimensjoner: ',
    'ru-RU': 'Загрузите файл с размерами: ',
    'cs-CZ': 'Nahrajte soubor s rozměry: ',
    'sk-SK': 'Prosím, nahrajte súbor s rozmermi: ',
    'sl-SL': 'Naložite datoteko z dimenzijami: ',
    'ko-KO': '크기가있는 파일을 업로드하십시오.'
  };
  'Building' = {
    'en-US': 'Building',
    'nl-NL': 'Gebouw',
    'fr-FR': 'Bâtiment',
    'pl-PL': 'Budynek',
    'zh-ZH': '建造',
    'de-DE': 'Gebäude',
    'it-IT': 'Costruzione',
    'tr-TR': 'bina',
    'fi-FI': 'Rakennus',
    'da-DK': 'Bygning',
    'ar-AR': 'بناء',
    'pt-PT': 'Edifício',
    'es-ES': 'Edificio\n',
    'sv-SE': 'Byggnad',
    'sv-FI': 'Byggnad',
    'hu-HU': 'Épület',
    'el-EL': 'Κτίριο',
    'no-NO': 'Bygning',
    'ru-RU': 'Строительство',
    'cs-CZ': 'Budova',
    'sk-SK': 'Budova',
    'sl-SL': 'Stavba',
    'ko-KO': '건물'
  };
  'Floor' = {
    'en-US': 'Floor',
    'nl-NL': 'verdieping',
    'fr-FR': 'sol',
    'pl-PL': 'podłoga',
    'zh-ZH': '地板',
    'de-DE': 'Fussboden',
    'it-IT': 'pavimento',
    'tr-TR': 'zemin',
    'fi-FI': 'Kerros',
    'da-DK': 'Etage',
    'ar-AR': 'أرضية',
    'pt-PT': 'Piso',
    'es-ES': 'Suelo',
    'sv-SE': 'Våningsplan',
    'sv-FI': 'golv',
    'hu-HU': 'Emelet',
    'el-EL': 'Πάτωμα',
    'no-NO': 'gulv',
    'ru-RU': 'этаж',
    'cs-CZ': 'podlaha',
    'sk-SK': 'Podlažie',
    'sl-SL': 'Nadstropje',
    'ko-KO': '바닥'
  };
  'Campus' = {
    'en-US': 'Campus',
    'nl-NL': 'campus',
    'fr-FR': 'Campus',
    'pl-PL': 'kampus',
    'zh-ZH': '校园',
    'de-DE': 'Campus',
    'it-IT': 'Città universitaria',
    'tr-TR': 'kampus',
    'fi-FI': 'Kampus',
    'da-DK': 'Område',
    'ar-AR': 'الحرم الجامعي',
    'pt-PT': 'Campus',
    'es-ES': 'Campus',
    'sv-SE': 'Campus',
    'sv-FI': 'Campus',
    'hu-HU': 'Egyetem',
    'el-EL': 'Πανεπιστημιούπολη',
    'no-NO': 'campus',
    'ru-RU': 'кампус',
    'cs-CZ': 'Campus',
    'sk-SK': 'Kampus',
    'sl-SL': 'Campus',
    'ko-KO': '교정'
  };
  'select' = {
    'en-US': 'Select',
    'nl-NL': 'kiezen',
    'fr-FR': 'Sélectionner',
    'pl-PL': 'Wybierz',
    'zh-ZH': '选择',
    'de-DE': 'Wählen',
    'it-IT': 'Selezionare',
    'tr-TR': 'seçmek',
    'fi-FI': 'Valitse',
    'da-DK': 'Vælg',
    'ar-AR': 'تحديد',
    'pt-PT': 'Seleccionar',
    'es-ES': 'Seleccionar',
    'sv-SE': 'Välj',
    'sv-FI': 'Välj',
    'hu-HU': 'választ',
    'el-EL': 'Επιλέξτε',
    'no-NO': 'Å velge',
    'ru-RU': 'Выбрать',
    'cs-CZ': 'Vybrat',
    'sk-SK': 'Vybrať',
    'sl-SL': 'Izberite',
    'ko-KO': '고르다'
  };
  'companyNodeError' = {
    'en-US': 'Please activate Company/node first  to add custom levels',
    'nl-NL': 'Activeer eerst Bedrijf / knooppunt om aangepaste niveaus toe te voegen',
    'fr-FR': 'Veuillez d abord activer Société / noeud pour ajouter des niveaux personnalisés',
    'de-DE': 'Bitte aktivieren Sie zuerst Firma / Knoten, um benutzerdefinierte Ebenen hinzuzufügen',
    'pl-PL': 'Najpierw aktywuj Firmę / węzeł, aby dodać niestandardowe poziomy',
    'zh-HK': '请先激活公司/节点以添加自定义级别',
    'zh-ZH': '請先激活公司/節點以添加自定義級別',
    'tr-TR': 'Özel seviyeler eklemek için lütfen önce Şirket / düğümü etkinleştirin' ,
    'es-ES': 'Por favor, active primero la empresa para poder añadir niveles de cliente',
    'pt-PT': 'Por favor active Empresa/Nó antes de adicionar níveis personalizados.',
    'sv-SE': 'Vänligen välj Företag först innan du kan lägga till kundnivå',
    'da-DK': 'Aktiver venligst virksomhed/node først for at tilføje kundeniveau',
    'fi-FI': 'Aktivoi yritys ensin ennen lisäämistä',
    'no-NO': 'Vennligst aktiver Company / node først for å legge til tilpassede nivåer',
    'hu-HU': 'Az egyéni szintek hozzáadásához először aktiválja a Vállalat / csomópontot',
    'el-EL': 'Ενεργοποιήστε πρώτα την εταιρεία/τον κόμβο για να προσθέσετε προσαρμοσμένα επίπεδα',
    'cs-CZ': 'Aktivujte společnost / uzel nejprve pro přidání vlastních úrovní',
    'ru-RU': 'Сначала активируйте компанию / узел, чтобы добавить пользовательские уровни',
    'sk-SK': 'Ak chcete pridať vlastné úrovne, aktivujte najprv spoločnosť / uzol',
    'sl-SL': 'Najprej aktivirajte podjetje / vozlišče, da dodate ravni po meri',
    'ko-KO': '맞춤 레벨을 추가하려면 먼저 회사 / 노드를 활성화하십시오.'
  };
  'success' = {
    'en-US': 'Added successfully!',
    'nl-NL': 'Succesvol toegevoegd!',
    'fr-FR': 'Ok',
    'de-DE': 'Erfolgreich hinzugefügt!',
    'pl-PL': 'Dodano pomyślnie!',
    'zh-HK': '新增成功！',
    'zh-ZH': '新增成功！',
    'tr-TR': 'Başarıyla eklendi!',
    'es-ES': 'Éxito',
    'pt-PT': 'Sucesso',
    'sv-SE': 'Framgång',
    'da-DK': 'Succes',
    'fi-FI': 'Onnistui',
    'no-NO': 'Lagt til!',
    'hu-HU': 'Siker',
    'el-EL': 'Επιτυχία',
    'cs-CZ': 'Přidáno úspěšně!',
    'ru-RU': 'Добавлено успешно!',
    'sk-SK': 'Úspech',
    'sl-SL': 'Dodano uspešno!',
    'ko-KO': '성공적으로 추가되었습니다!'
  };
  'create_success' = {
    'en-US': 'Created successfully!',
    'nl-NL': 'Met succes gemaakt!',
    'fr-FR': 'Créé avec succès!',
    'de-DE': 'Erfolgreich erstellt!',
    'pl-PL': 'Utworzono pomyślnie!',
    'zh-HK': '创建成功！',
    'zh-ZH': '創建成功！',
    'tr-TR': 'Başarıyla oluşturuldu!',
    'es-ES': '¡Creado con éxito!',
    'pt-PT': 'Criado com sucesso!',
    'sv-SE': 'Skapades!',
    'da-DK': 'Oprettelse gennemført!',
    'fi-FI': 'Luotu onnistuunesti!',
    'no-NO': 'Opprettet med hell!',
    'hu-HU': 'Sikeresen létrehozva',
    'el-EL': 'Δημιουργήθηκε με επιτυχία!',
    'cs-CZ': 'Vytvořeno úspěšně!',
    'ru-RU': 'Создано успешно!',
    'sk-SK': 'Vytvorené úspešne!',
    'sl-SL': 'Ustvarjeno je uspešno!',
    'ko-KO': '성공적으로 생성되었습니다!'
  };
  'delete_success' = {
    'en-US': 'Deleted successfully!',
    'nl-NL': 'Met succes verwijderd!',
    'fr-FR': 'Supprimé avec succès!',
    'de-DE': 'Erfolgreich gelöscht!',
    'pl-PL': 'Skutecznie usunięte!',
    'zh-HK': '删除成功！',
    'zh-ZH': '創建成功！',
    'tr-TR': 'Başarıyla silindi!',
    'es-ES': '¡Borrado con éxito!',
    'pt-PT': 'Apagado com sucesso!',
    'sv-SE': 'Raderades!',
    'da-DK': 'Sletning gennemført!',
    'fi-FI': 'Poistettu onnistuneesti!',
    'no-NO': 'Slettet med hell!',
    'hu-HU': 'A törlés sikeres!',
    'el-EL': 'Διαγράφηκε με επιτυχία!',
    'cs-CZ': 'Bylo úspěšně odstraněno!',
    'ru-RU': 'Удалено успешно!',
    'sk-SK': 'Úspešne odstránené!',
    'sl-SL': 'Uspešno izbrisano!',
    'ko-KO': '성공적으로 삭제되었습니다.'
  };
  'adding_failed' = {
    'en-US': 'Adding Failed!',
    'nl-NL': 'Toevoegen mislukt!',
    'fr-FR': 'Ajout échoué!',
    'de-DE': 'Hinzufügen fehlgeschlagen!',
    'pl-PL': 'Dodawanie nie powiodło się!',
    'zh-HK': '添加失败！',
    'zh-ZH': '添加失敗！',
    'tr-TR': 'Ekleme Başarısız!',
    'es-ES': '¡Adición fallida!',
    'pt-PT': 'Erro na adição!',
    'sv-SE': 'Tillägg mislyckades!',
    'da-DK': 'Tilføjelse Fejlede!',
    'fi-FI': 'Lisääminen epäonnistui!',
    'no-NO': 'Legg til mislyktes!',
    'hu-HU': 'Nem sikerült hozzáadni!',
    'el-EL': 'Η προσθήκη απέτυχε!',
    'cs-CZ': 'Přidání se nezdařilo!',
    'ru-RU': 'Добавление сбой!',
    'sk-SK': 'Pridanie zlyhalo!',
    'sl-SL': 'Dodajanje ni uspelo!',
    'ko-KO': '추가 실패!'
  };
  'addition_failed' = {
    'en-US': 'Addition Failed!',
    'nl-NL': 'Toevoeging mislukt!',
    'fr-FR': 'L\'ajout a échoué!',
    'de-DE': 'Zusatz fehlgeschlagen!',
    'pl-PL': 'Dodatek nieudany!',
    'zh-HK': '加成失败！',
    'zh-ZH': '加成失敗！',
    'tr-TR': 'Ekleme Başarısız!',
    'es-ES': 'Adición fallida',
    'pt-PT': 'Erro na adição!',
    'sv-SE': 'Tillägg misslyckades!',
    'da-DK': 'Tilføjelse fejlede!',
    'fi-FI': 'Lisääminen epäonnistui!',
    'no-NO': 'Tillegg mislyktes!',
    'hu-HU': 'A hozzáadás sikertelen!',
    'el-EL': 'Η προσθήκη απέτυχε!',
    'cs-CZ': 'Doplnění se nezdařilo!',
    'ru-RU': 'Ошибка сложения!',
    'sk-SK': 'Pridanie zlyhalo!',
    'sl-SL': 'Dodajanje ni uspelo!',
    'ko-KO': '추가 실패!'
  };
  'update_success' = {
    'en-US': 'Updated successfully!',
    'nl-NL': 'Succesvol geupdatet!',
    'fr-FR': 'Mis à jour avec succés!',
    'de-DE': 'Erfolgreich geupdated!',
    'pl-PL': 'Aktualizacja zakończona sukcesem!',
    'zh-HK': '更新成功！',
    'zh-ZH': '更新成功！',
    'tr-TR': 'Başarıyla güncellendi!',
    'es-ES': 'Actualizado con éxito',
    'pt-PT': 'Actualização bem sucedida!',
    'sv-SE': 'Uppdatering lyckades!',
    'da-DK': 'Opdatering gennemført!',
    'fi-FI': 'Päivitetty onnistuneesti!',
    'no-NO': 'Oppdatert med hell!',
    'hu-HU': 'Feltöltés sikeres',
    'el-EL': 'Ενημερώθηκε με επιτυχία!',
    'cs-CZ': 'Úspěšně aktualizováno!',
    'ru-RU': 'Успешно Обновлено!',
    'sk-SK': 'Aktualizovanie úspešne!',
    'sl-SL': 'Posodobljeno uspešno!',
    'ko-KO': '성공적으로 업데이트되었습니다!'
  };
  'update_failed' = {
    'en-US': 'Update Failed!',
    'nl-NL': 'Bijgewerkte mislukt!',
    'fr-FR': 'Mise à jour a échoué!',
    'de-DE': 'Update fehlgeschlagen!',
    'pl-PL': 'Aktualizacja nie powiodła się!',
    'zh-HK': '更新失败！',
    'zh-ZH': '更新失敗！',
    'tr-TR': 'Güncelleme Başarısız!',
    'es-ES': '¡Actualización fallida!',
    'pt-PT': 'Erro na actualização!',
    'sv-SE': 'Uppdatering misslyckades!',
    'da-DK': 'Opdatering fejlede!',
    'fi-FI': 'Päivitys epäonnistui!',
    'no-NO': 'Oppdatering mislyktes!',
    'hu-HU': 'Frissítés sikertelen!',
    'el-EL': 'Η ενημέρωση απέτυχε!',
    'cs-CZ': 'Aktualizace selhala!',
    'ru-RU': 'Не удалось обновить!',
    'sk-SK': 'Aktualizacia neuspešná!',
    'sl-SL': 'Posodobitev ni uspela!',
    'ko-KO': '업데이트가 실패!'
  };
  'remove_success' = {
    'en-US': 'Removed successfully!',
    'nl-NL': 'Met succes verwijderd!',
    'fr-FR': 'Retiré avec succès!',
    'de-DE': 'Erfolgreich entfernt!',
    'pl-PL': 'Usunięto pomyślnie!',
    'zh-HK': '删除成功！',
    'zh-ZH': '刪除成功！',
    'tr-TR': 'Başarıyla kaldırıldı!',
    'es-ES': '¡Eliminado con éxito!',
    'pt-PT': 'Removido com sucesso!',
    'sv-SE': 'Borttagen!',
    'da-DK': 'Gennemført sletningen!',
    'fi-FI': 'Poistettu onnistuneesti!',
    'no-NO': 'Fjernet med hell!',
    'hu-HU': 'Az eltávolítás sikeres!',
    'el-EL': 'Καταργήθηκε με επιτυχία!',
    'cs-CZ': 'Bylo úspěšně odstraněno!',
    'ru-RU': 'Удалено успешно!',
    'sk-SK': 'Úspešne odstránené!',
    'sl-SL': 'Uspešno odstranjeno!',
    'ko-KO': '성공적으로 제거되었습니다.'
  };
  'remove_failed' = {
    'en-US': 'Removed Failed!',
    'nl-NL': 'Verwijder Mislukt!',
    'fr-FR': 'Supprimer a échoué!',
    'de-DE': 'Entfernen fehlgeschlagen!',
    'pl-PL': 'Usuń nieudane!',
    'zh-HK': '移除失败！',
    'zh-ZH': '移除失敗！',
    'tr-TR': 'Kaldırıma Başarısız! ',
    'es-ES': '¡Borrado fallido!',
    'pt-PT': 'Erro na remoção!',
    'sv-SE': 'Misslyckades med att ta bort!',
    'da-DK': 'Sletning fejlede!',
    'fi-FI': 'Poistaminen epäonnistui!',
    'no-NO': 'Fjernet mislyktes!',
    'hu-HU': 'Eltávolítás sikertelen!',
    'el-EL': 'Η κατάργηση απέτυχε!',
    'cs-CZ': 'Odstraněno se nezdařilo!',
    'ru-RU': 'Удалено сбой!',
    'sk-SK': 'Odstránenie zlyhalo!',
    'sl-SL': 'Odstranjeno ni uspelo!',
    'ko-KO': '제거 실패!'
  };
  'deactivate_success' = {
    'en-US': 'deactivated successfully',
    'nl-NL': 'gedeactiveerd met succes',
    'fr-FR': 'désactivé avec succès',
    'de-DE': 'erfolgreich deaktiviert',
    'pl-PL': 'dezaktywowany pomyślnie',
    'zh-HK': '停用成功',
    'zh-ZH': '停用成功',
    'tr-TR': 'başarıyla devre dışı bırakıldı',
    'es-ES': 'desactivado con éxito',
    'pt-PT': 'desactivado com sucesso',
    'sv-SE': 'deaktivering lyckades',
    'da-DK': 'deaktivering gennemført',
    'fi-FI': 'Deaktivoitu onnistuneesti',
    'no-NO': 'deaktivert med hell',
    'hu-HU': 'deaktiválás sikeres',
    'el-EL': 'απενεργοποιήθηκε με επιτυχία',
    'cs-CZ': 'deaktivováno úspěšně',
    'ru-RU': 'успешно деактивирован',
    'sk-SK': 'úspešne deaktivované',
    'sl-SL': 'uspešno deaktivirano',
    'ko-KO': '성공적으로 비활성화되었습니다'
  };
  'activate_success' = {
    'en-US': 'activated successfully',
    'nl-NL': 'succesvol geactiveerd',
    'fr-FR': 'activé avec succès',
    'de-DE': 'erfolgreich aktiviert',
    'pl-PL': 'aktywowane pomyślnie',
    'zh-HK': '激活成功',
    'zh-ZH': '激活成功',
    'tr-TR': 'başarıyla etkinleştirildi',
    'es-ES': 'activado satisfactoriamente',
    'pt-PT': 'activado com sucesso',
    'sv-SE': 'Aktivering lyckades',
    'da-DK': 'aktivering gennemført',
    'fi-FI': 'aktivoitu onnistuneesti',
    'no-NO': 'aktivert vellykket',
    'hu-HU': 'Aktiválás sikeres',
    'el-EL': 'ενεργοποιείται με επιτυχία',
    'cs-CZ': 'úspěšně aktivováno',
    'ru-RU': 'успешно активирован',
    'sk-SK': 'úspešne aktivované',
    'sl-SL': 'aktivirano uspešno',
    'ko-KO': '성공적으로 활성화되었습니다'
  };
  'activation_failed' = {
    'en-US': 'Activation failed!',
    'nl-NL': 'Activatie mislukt!',
    'fr-FR': 'Activation echouée!',
    'de-DE': 'Aktivierung fehlgeschlagen!',
    'pl-PL': 'Aktywacja nie powiodła się!',
    'zh-HK': '激活失败！',
    'zh-ZH': '激活失敗！',
    'tr-TR': 'Etkinleştirme başarısız!',
    'es-ES': 'Activación fallida',
    'pt-PT': 'Erro na activação!',
    'sv-SE': 'Aktivering misslyckades!',
    'da-DK': 'Aktivering fejlede!',
    'fi-FI': 'Aktivointi epäonnistui!',
    'no-NO': 'Aktivering mislyktes!',
    'hu-HU': 'Az aktiválás sikertelen!',
    'el-EL': 'Η ενεργοποίηση απέτυχε!',
    'cs-CZ': 'Aktivace se nezdařila!',
    'ru-RU': 'Активация не удалось!',
    'sk-SK': 'Aktivácia zlyhala!',
    'sl-SL': 'Aktiviranje ni uspelo!',
    'ko-KO': '활성화에 실패했습니다!'
  };
  'empty_from' = {
    'en-US': 'Please fill out all the details!',
    'nl-NL': 'Vul alstublieft alle details in!',
    'fr-FR': 'S\'il vous plaît remplir tous les détails!',
    'de-DE': 'Bitte füllen Sie alle Details aus!',
    'pl-PL': 'Proszę wypełnić wszystkie szczegóły!',
    'zh-HK': '请填写所有细节！',
    'zh-ZH': '請填寫所有細節！',
    'tr-TR': 'Lütfen tüm detayları doldurun!',
    'es-ES': 'Por favor, rellene todos los detalles',
    'pt-PT': 'Por favor preencha todos os campos!',
    'sv-SE': 'Vänligen fyll i alla detaljer!',
    'da-DK': 'Udfyld venligst alle detaljer!',
    'fi-FI': 'Täytä kaikki tiedot!',
    'no-NO': 'Vennligst fyll ut alle detaljer!',
    'hu-HU': 'Kérjük, töltse ki az összes részletet!',
    'el-EL': 'Παρακαλούμε συμπληρώστε όλες τις λεπτομέρειες!',
    'cs-CZ': 'Vyplňte prosím všechny podrobnosti!',
    'ru-RU': 'Пожалуйста, заполните все детали!',
    'sk-SK': 'Vyplňte prosím všetky údaje!',
    'sl-SL': 'Prosimo, izpolnite vse podrobnosti!',
    'ko-KO': '모든 세부 사항을 기입하십시오!'
  };
  'company_fetch_failed' = {
    'en-US': 'Failed fetching company details !',
    'nl-NL': 'Het ophalen van bedrijfsgegevens is mislukt!',
    'fr-FR': 'Impossible d\'extraire les détails de la société!',
    'de-DE': 'Fehler beim Abrufen von Firmendetails!',
    'pl-PL': 'Nie udało się pobrać danych firmy!',
    'zh-HK': '获取公司详细信息失败！',
    'zh-ZH': '獲取公司詳細信息失敗！',
    'tr-TR': 'Şirket ayrıntıları getirilemedi!',
    'es-ES': '¡Error al obtener los detalles de la empresa!',
    'pt-PT': 'Erro na busca dos detalhes da empresa!',
    'sv-SE': 'Hämtning av företagsdetaljer misslyckades!',
    'da-DK': 'Hentningen af virksomhedsdata fejlede !',
    'fi-FI': 'Yrityksen tietojen haku epäonnistui!',
    'no-NO': 'Kunne ikke hente bedriftens detaljer!',
    'hu-HU': 'Nem sikerült letölteni a cég adatait!',
    'el-EL': 'Απέτυχε η λήψη των λεπτομερειών της εταιρείας !',
    'cs-CZ': 'Nepodařilo se získat informace o společnosti!',
    'ru-RU': 'Не удалось получить данные о компании!',
    'sk-SK': 'Nepodarilo sa načítať podrobnosti spoločnosti!',
    'sl-SL': 'Ni uspelo pridobiti podrobnosti o podjetju!',
    'ko-KO': '회사 정보를 가져 오지 못했습니다!'
  };
  'non_editable_company' = {
    'en-US': 'Cannot edit company!',
    'nl-NL': 'Kan bedrijf niet bewerken!',
    'fr-FR': 'Impossible de modifier la société!',
    'de-DE': 'Kann Firma nicht bearbeiten!',
    'pl-PL': 'Nie można edytować firmy!',
    'zh-HK': '无法编辑公司！',
    'zh-ZH': '無法編輯公司！',
    'tr-TR': 'Şirket düzenlenemiyor!',
    'es-ES': '¡La empresa no puede ser editada!',
    'pt-PT': 'Não é possível editar a empresa!',
    'sv-SE': 'Kan inte editera företag!',
    'da-DK': 'Kan ikke ændre virksomhed!',
    'fi-FI': 'Yritystä ei voi muokata!',
    'no-NO': 'Kan ikke redigere selskap!',
    'hu-HU': 'A cég nem szerkeszthető!',
    'el-EL': 'Δεν είναι δυνατή η επεξεργασία της εταιρείας!',
    'cs-CZ': 'Společnost nelze editovat!',
    'ru-RU': 'Невозможно изменить компанию!',
    'sk-SK': 'Spoločnosť nie je možné upraviť!',
    'sl-SL': 'Ni mogoče urediti podjetja!',
    'ko-KO': '회사를 수정할 수 없습니다!'
  };
  'already_exists_company' = {
    'en-US': 'Company number already exists. Please try with different company number!',
    'nl-NL': 'Bedrijfsnummer bestaat al Probeer het met een ander bedrijfsnummer!',
    'fr-FR': 'Le numéro de société existe déjà.S\'il vous plaît essayez avec le numéro de l\'entreprise différente!',
    'de-DE': 'Die Firmennummer existiert bereits. Bitte versuchen Sie es mit einer anderen Firmennummer!',
    'pl-PL': 'Numer firmy już istnieje. Spróbuj z innym numerem firmy!',
    'zh-HK': '公司编号已经存在。请尝试不同的公司编号！',
    'zh-ZH': '公司編號已經存在。請嘗試不同的公司編號！',
    'tr-TR': 'Şirket numarası zaten var.Lütfen farklı şirket numarası ile deneyin!',
    'es-ES': 'El existe una compañía con el mismo número. Por favor, inténtelo con un número diferente!',
    'pt-PT': 'O nº de empresa já existe. Por favor tente com um nº de empresa diferente!',
    'sv-SE': 'Företagsnummer existera redan. Försök med ett annat nummer!',
    'da-DK': 'Virksomhedsnummer eksistere allerede. Prøv med et andet nummer!',
    'fi-FI': 'Yrityksen numero on jo olemassa, käytä eri numeroa!',
    'no-NO': 'Firmanummer eksisterer allerede.Vennligst prøv med annet firmanummer!',
    'hu-HU': 'A cégszám már létezik. Kérjük, próbálja meg más cégszámmal!',
    'el-EL': 'Ο αριθμός εταιρείας υπάρχει ήδη. Παρακαλώ δοκιμάστε με διαφορετικό αριθμό εταιρείας!',
    'cs-CZ': 'Číslo společnosti již existuje.Zkuste prosím jiné číslo společnosti!',
    'ru-RU': 'Номер компании уже существует.Пожалуйста, попробуйте с другим номером компании!',
    'sk-SK': 'Číslo spoločnosti už existuje. Skúste použiť iné číslo spoločnosti!',
    'sl-SL': 'Številka podjetja že obstaja.Poskusite z različno številko podjetja!',
    'ko-KO': '회사 번호가 이미 있습니다.다른 회사 번호로 시도하십시오!'
  };
  'logo_succes' = {
    'en-US': 'Company Logo uploaded succesfully!',
    'nl-NL': 'Bedrijfslogo succesvol geüpload!',
    'fr-FR': 'Logo de l\'entreprise importé avec succès!',
    'de-DE': 'Firmenlogo erfolgreich hochgeladen!',
    'pl-PL': 'Logo firmy przesłane pomyślnie!',
    'zh-HK': '公司标志 上传成功！',
    'zh-ZH': '公司標誌 上傳成功！',
    'tr-TR': 'Şirket Logosu başarıyla yüklendi!',
    'es-ES': '¡Logo de empresa cargado con éxito!',
    'pt-PT': 'Logotipo da empresa carregado com sucesso!',
    'sv-SE': 'Företagsloggo uppladdad!',
    'da-DK': 'Upload af virksomhedslogo gennemført!',
    'fi-FI': 'Yrityksen logo ladattu onnistuneesti!',
    'no-NO': 'Bedriftslogo lastet opp!',
    'hu-HU': 'A vállalat logójának feltöltése sikeres!\n',
    'el-EL': 'Λογότυπο της εταιρείας φορτώθηκε με επιτυχία!',
    'cs-CZ': 'Logo společnosti bylo nahráno úspěšně!',
    'ru-RU': 'Логотип компании загружен успешно!',
    'sk-SK': 'Logo spoločnosti bolo úspešne nahrané!',
    'sl-SL': 'Logo podjetja je uspešno naložen!',
    'ko-KO': '회사 로고가 성공적으로 업로드되었습니다!'
  };
  'logo_failed' = {
    'en-US': 'Company logo upload failed!',
    'nl-NL': 'Uploaden van bedrijfslogo mislukt!',
    'fr-FR': 'Le téléchargement du logo de l\'entreprise a échoué',
    'de-DE': 'Upload des Firmenlogos fehlgeschlagen!',
    'pl-PL': 'Przesyłanie logo firmy nie powiodło się!',
    'zh-HK': '公司标志上传失败！',
    'zh-ZH': '公司標誌上傳失敗！',
    'tr-TR': 'Şirket logosu yüklenemedi!',
    'es-ES': '¡El logo de la compañía falló!',
    'pt-PT': 'Erro no carregamento de logotipo de empresa!',
    'sv-SE': 'Företagsloggo uppladdning misslyckades!',
    'da-DK': 'Upload af virksomhedslogo fejlede!',
    'fi-FI': 'Yrityksen logon lataus epäonnistui!',
    'no-NO': 'Bedriftsloggopplastingen mislyktes!',
    'hu-HU': 'A cég logója feltöltése sikertelen!',
    'el-EL': 'Το λογότυπο της εταιρείας ανεβάστε απέτυχε!',
    'cs-CZ': 'Nahrát logo společnosti se nezdařilo!',
    'ru-RU': 'Ошибка загрузки логотипа компании!',
    'sk-SK': 'Nahrávanie loga spoločnosti zlyhalo!',
    'sl-SL': 'Nalaganje logotipa podjetja ni uspelo!',
    'ko-KO': '회사 로고를 업로드하지 못했습니다!'
  };
  'site_update_success' = {
    'en-US': 'Site Updated Successfully!',
    'nl-NL': 'Site is succesvol bijgewerkt!',
    'fr-FR': 'Site mis à jour avec succès!',
    'de-DE': 'Website wurde erfolgreich aktualisiert!',
    'pl-PL': 'Strona zaktualizowana pomyślnie!',
    'zh-HK': '网站更新成功！',
    'zh-ZH': '網站更新成功！',
    'tr-TR': 'Site başarıyla güncellendi!',
    'es-ES': '¡Centro actualizado satisfactoriamente!',
    'pt-PT': 'Site actualizado com sucesso!',
    'sv-SE': 'Objekt uppdaterades!',
    'da-DK': 'Opdatering af lokation gennemført!',
    'fi-FI': 'Kohde päivitetty onnistuneesti!',
    'no-NO': 'Nettsted Oppdatert Vellykket!',
    'hu-HU': 'Oldal frissítése sikeres!',
    'el-EL': 'Η τοποθεσία ενημερώθηκε με επιτυχία!',
    'cs-CZ': 'Stránky byly úspěšně aktualizovány!',
    'ru-RU': 'Сайт обновлен успешно!',
    'sk-SK': 'Objekt bol úspešne aktualizovaný!',
    'sl-SL': 'Site Posodobljeno Uspešno!',
    'ko-KO': '사이트가 성공적으로 업데이트되었습니다!'
  };
  'internal_server_error' = {
    'en-US': 'Internal server error occured. Please try again!',
    'nl-NL': 'Er is een interne serverfout opgetreden. Probeer het opnieuw!',
    'fr-FR': 'Une erreur interne du serveur s\'est produite. Veuillez réessayer!',
    'de-DE': 'Interner Serverfehler ist aufgetreten. Bitte versuche es erneut!',
    'pl-PL': 'Wystąpił wewnętrzny błąd serwera. Proszę spróbuj ponownie!',
    'zh-HK': '发生内部服务器错误。请再试一次！',
    'zh-ZH': '發生內部服務器錯誤。請再試一次！',
    'tr-TR': 'Dahili sunucu hatası oluştu.Lütfen tekrar deneyin!',
    'es-ES': 'Se ha producido un error interno en el servidor. ¡Por favor inténtelo de nuevo!',
    'pt-PT': 'Ocorreu um erro no servidor interno. Por favor tente novamente!',
    'sv-SE': 'Intern server fel. Vänligen prova igen!',
    'da-DK': 'Der opstod en serverfejl. Prøv venligst igen!',
    'fi-FI': 'Serverivirhe. Yritä uudestaan!',
    'no-NO': 'Intern serverfeil oppstod.Vær så snill, prøv på nytt!',
    'hu-HU': 'Belső szerver hiba. Kérem, próbálja újra!',
    'el-EL': 'Παρουσιάστηκε εσωτερικό σφάλμα διακομιστή. Προσπαθήστε ξανά!',
    'cs-CZ': 'Došlo k interní chybě serveru.Prosím zkuste to znovu!',
    'ru-RU': 'Произошла ошибка внутреннего сервера.Пожалуйста, попробуйте еще раз!',
    'sk-SK': 'Vyskytla sa interná chyba servera. Prosím, skúste znova!',
    'sl-SL': 'Prišlo je do notranje napake strežnika.Prosim poskusite ponovno!',
    'ko-KO': '내부 서버 오류가 발생했습니다.다시 시도하십시오!'
  };
  'site_update_failed' = {
    'en-US': 'Site Update Failed!',
    'nl-NL': 'Site-update is mislukt!',
    'fr-FR': 'La mise à jour du site a échoué!',
    'de-DE': 'Site-Update fehlgeschlagen!',
    'pl-PL': 'Aktualizacja witryny nie powiodła się!',
    'zh-HK': '网站更新失败！',
    'zh-ZH': '網站更新失敗！',
    'tr-TR': 'Site güncellenemedi!',
    'es-ES': '¡Actualización de centro fallida!',
    'pt-PT': 'Erro na actualização de sites!',
    'sv-SE': 'Objekt uppdatering misslyckades!',
    'da-DK': 'Opdateringen af lokationen fejlede!',
    'fi-FI': 'Kohteen päivitys epäonnistui!',
    'no-NO': 'Nettstedoppdatering mislyktes!',
    'hu-HU': 'Oldal frissítés sikertelen!',
    'el-EL': 'Η ενημέρωση τοποθεσίας απέτυχε!',
    'cs-CZ': 'Aktualizace webu se nezdařila!',
    'ru-RU': 'Ошибка сайта!',
    'sk-SK': 'Aktualizácia objektu zlyhala!',
    'sl-SL': 'Posodobitev spletnega mesta ni uspela!',
    'ko-KO': '사이트 업데이트 실패!'
  };
  'site_create_success' = {
    'en-US': 'Site Created Successfully!',
    'nl-NL': 'Site succesvol gemaakt!',
    'fr-FR': 'Site créé avec succès!',
    'de-DE': 'Site erfolgreich erstellt!',
    'pl-PL': 'Strona utworzona pomyślnie!',
    'zh-HK': '网站成功创建！',
    'zh-ZH': '網站成功創建！',
    'tr-TR': 'Site başarıyla silindi!',
    'es-ES': '¡Centro creado con éxito!',
    'pt-PT': 'Site criado com sucesso!',
    'sv-SE': 'Objekt skapades!',
    'da-DK': 'Oprettelse af lokation gennemført!',
    'fi-FI': 'Kohde luotu onnistuneesti!',
    'no-NO': 'Nettstedet ble opprettet suksessivt!',
    'hu-HU': 'Hely létrehozás sikeres',
    'el-EL': 'Η τοποθεσία δημιουργήθηκε με επιτυχία!',
    'cs-CZ': 'Stránky byly vytvořeny úspěšně!',
    'ru-RU': 'Сайт создан успешно!',
    'sk-SK': 'Objekt vytvorený úspešne!',
    'sl-SL': 'Ustvarjeno spletno mesto uspešno',
    'ko-KO': '사이트가 성공적으로 생성되었습니다!'
  };
  'site_create_failed' = {
    'en-US': 'Site Create Failed!',
    'nl-NL': 'Site maken is mislukt!',
    'fr-FR': 'La création du site a échoué!',
    'de-DE': 'Site Create fehlgeschlagen!',
    'pl-PL': 'Tworzenie witryny nie powiodło się!',
    'zh-HK': '网站创建失败！',
    'zh-ZH': '網站創建失敗！',
    'tr-TR': 'Site başarıyla oluşturuldu!',
    'es-ES': '¡Creación del centro fallida!',
    'pt-PT': 'Erro na criação do site!',
    'sv-SE': 'Objekt skapande misslyckades!',
    'da-DK': 'Oprettelse af lokation fejlede!',
    'fi-FI': 'Kohteen luonti epäonnistui!',
    'no-NO': 'Nettstedskapet mislyktes!',
    'hu-HU': 'Oldal létrehozása sikertelen!',
    'el-EL': 'Η δημιουργία τοποθεσίας απέτυχε!',
    'cs-CZ': 'Stránky se nezdařily!',
    'ru-RU': 'Ошибка создания сайта!',
    'sk-SK': 'Vytvorenie objektu zlyhalo!',
    'sl-SL': 'Ustvari spletno mesto ni uspelo!',
    'ko-KO': '사이트 만들기 실패!'
  };
  'requied_field' = {
    'en-US': 'Please fill out all the mandatory details!',
    'nl-NL': 'Vul alstublieft alle verplichte gegevens in!',
    'fr-FR': 'S\'il vous plaît remplir tous les détails obligatoires!',
    'de-DE': 'Bitte füllen Sie alle Pflichtangaben aus!',
    'pl-PL': 'Proszę wypełnić wszystkie obowiązkowe dane!',
    'zh-HK': '请填写所有强制性细节！',
    'zh-ZH': '請填寫所有必填信息！',
    'tr-TR': 'Lütfen tüm zorunlu bilgileri doldurun!',
    'es-ES': '¡Por favor, rellene los campos obligatorios!',
    'pt-PT': 'Por favor preencha todos os campos obrigatórios!',
    'sv-SE': 'Vänligen fyll i alla obligatoriska uppgifter!',
    'da-DK': 'Udfyld venligst alle de obligatoriske felter!',
    'fi-FI': 'Täytä pakolliset kentät!',
    'no-NO': 'Vennligst fyll ut alle obligatoriske detaljer!',
    'hu-HU': 'Kérem, töltse ki az összes kötelező mezőt!',
    'el-EL': 'Παρακαλούμε συμπληρώστε όλες τις υποχρεωτικές λεπτομέρειες!',
    'cs-CZ': 'Vyplňte prosím všechny povinné údaje!',
    'ru-RU': 'Пожалуйста, заполните все обязательные данные!',
    'sk-SK': 'Vyplňte všetky povinné údaje!',
    'sl-SL': 'Prosimo, izpolnite vse obvezne podatke!',
    'ko-KO': '모든 필수 세부 사항을 작성하십시오!'
  };
  'action_failed' = {
    'en-US': 'Action failed!',
    'nl-NL': 'Actie: mislukt!',
    'fr-FR': 'Action: échoué!',
    'de-DE': 'Aktion: fehlgeschlagen!',
    'pl-PL': 'Akcja: nieudana!',
    'zh-HK': '行动失败！',
    'zh-ZH': '行動失敗！',
    'tr-TR': 'Eylem başarısız!',
    'es-ES': 'Acción fallida',
    'pt-PT': 'Erro na acção!',
    'sv-SE': 'Åtgård misslyckades!',
    'da-DK': 'Handling fejlede!',
    'fi-FI': 'Toimenpide epäonnistui!',
    'no-NO': 'Handlingen mislyktes!',
    'hu-HU': 'A művelet sikertelen!',
    'el-EL': 'Η ενέργεια απέτυχε!',
    'cs-CZ': 'Akce se nezdařila!',
    'ru-RU': 'Действие не выполнено!',
    'sk-SK': 'Akcia zlyhala!',
    'sl-SL': 'Dejanje ni uspelo!',
    'ko-KO': '작업이 실패했습니다!'
  };
  'site_delete_success' = {
    'en-US': 'Site deleted successfully!',
    'nl-NL': 'Site is succesvol verwijderd!',
    'fr-FR': 'Site supprimé avec succès!',
    'de-DE': 'Die Site wurde erfolgreich gelöscht!',
    'pl-PL': 'Witryna usunięta pomyślnie!',
    'zh-HK': '网站已成功删除！',
    'zh-ZH': '網站已成功刪除！',
    'tr-TR': 'Site adı',
    'es-ES': '¡Centro eliminado con éxito!',
    'pt-PT': 'Site apagado com sucesso!',
    'sv-SE': 'Objekt borttagen!',
    'da-DK': 'Sletning af lokation gennemført!',
    'fi-FI': 'Kohde poistettu onnistuneesti!',
    'no-NO': 'Nettstedet slettet med hell!',
    'hu-HU': 'Oldal törlése sikeres!',
    'el-EL': 'Η τοποθεσία διαγράφηκε με επιτυχία!',
    'cs-CZ': 'Stránky byly úspěšně smazány!',
    'ru-RU': 'Удаленный сайт удален!',
    'sk-SK': 'Objekt bol úspešne odstránený!',
    'sl-SL': 'Spletno mesto je uspešno izbrisano!',
    'ko-KO': '사이트가 성공적으로 삭제되었습니다.'
  };
  'deactive_uneditable' = {
    'en-US': 'Cannot edit a deactivated',
    'nl-NL': 'Kan gedeactiveerd niet bewerken',
    'fr-FR': 'Impossible d\'éditer un désactivé',
    'de-DE': 'Eine deaktivierte kann nicht bearbeitet werden',
    'pl-PL': 'Nie można edytować dezaktywowanego',
    'zh-HK': '无法编辑停用的',
    'zh-ZH': '無法編輯停用的',
    'tr-TR': 'Devre dışı bırakılan bir öğe düzenlenemez',
    'es-ES': 'No se puede editar un desactivado',
    'pt-PT': 'Se desactivado não é possível editar um',
    'sv-SE': 'Kan inte ändra en deaktiverad',
    'da-DK': 'Du kan ikke ændre en deaktiveret',
    'fi-FI': 'Deaktivoitua ei voi muokata',
    'no-NO': 'Kan ikke redigere en deaktivert',
    'hu-HU': 'Nem tudja szerkeszteni kikapcsolt állapotban.',
    'el-EL': 'Η τοποθεσία διαγράφηκε με επιτυχία!',
    'cs-CZ': 'Účet nelze deaktivovat',
    'ru-RU': 'Невозможно отредактировать деактивированный',
    'sk-SK': 'Deaktivované nie je možné upraviť',
    'sl-SL': 'Ni mogoče urediti deaktiviranega',
    'ko-KO': '비활성화 된 광고는 수정할 수 없습니다.'
  };
  'user_create_failed' = {
    'en-US': 'User Creation Failed!',
    'nl-NL': 'Gebruikerscreatie mislukt!',
    'fr-FR': 'La création de l\'utilisateur a échoué!',
    'de-DE': 'Benutzererstellung fehlgeschlagen',
    'pl-PL': 'Tworzenie użytkownika nie powiodło się',
    'zh-HK': '用户创建失败',
    'zh-ZH': '用戶創建失敗',
    'tr-TR': 'Kullanıcı Oluşturma Başarısız!',
    'es-ES': '¡Creación de usuario fallida!',
    'pt-PT': 'Erro na criação do utilizador!',
    'sv-SE': 'Skapande av användare misslyckades!',
    'da-DK': 'Oprettelse af bruger fejlede!',
    'fi-FI': 'Käyttäjän luonti epäonnistui!',
    'no-NO': 'Brukeroppretting mislyktes!',
    'hu-HU': 'Felhasználó létrehozása sikertelen!',
    'el-EL': 'Η δημιουργία χρήστη απέτυχε!',
    'cs-CZ': 'Vytvoření uživatele se nezdařilo!',
    'ru-RU': 'Не удалось создать пользователя!',
    'sk-SK': 'Vytvorenie používateľa zlyhalo!',
    'sl-SL': 'Ustvarjanje uporabnika ni uspelo!',
    'ko-KO': '사용자 생성 실패!'
  };
  'user_create_failed_drupal' = {
    'en-US': 'User Creation Failed from Drupla layer!',
    'nl-NL': 'User Creation is mislukt van Drupal-laag!',
    'fr-FR': 'La création de l\'utilisateur a échoué depuis la couche Drupal!',
    'de-DE': 'User Creation fehlgeschlagen von Drupal-Ebene!',
    'pl-PL': 'Tworzenie użytkownika nie powiodło się z warstwy Drupala!',
    'zh-HK': '用户创建从Drupla层失败！',
    'zh-ZH': '用戶創建從Drupla層失敗！',
    'tr-TR': 'Kullanıcı Oluşturma Drupla katmanından başarısız oldu!',
    'es-ES': '¡Creación de usuario fallida desde Drupal layer!',
    'pt-PT': 'Erro na criação de utilizador a partir da camada Drupal!',
    'sv-SE': 'Användarskapelsen misslyckades från Drupla-lagret!',
    'da-DK': 'Oprettelse af bruger fejlede fra Drupla niveau!',
    'fi-FI': 'Käyttäjän luonti ei onnistunut!',
    'no-NO': 'Brukeroppretting Mislyktes fra Drupla-lag!',
    'hu-HU': 'Felhasználó létrehozása sikertelen a ',
    'el-EL': 'Η δημιουργία χρήστη απέτυχε από το επίπεδο Drupla!',
    'cs-CZ': 'Vytvoření uživatele se nezdařilo z vrstvy Drupla!',
    'ru-RU': 'Сбой пользователя с уровня Drupla!',
    'sk-SK': 'Vytvorenie používateľa zlyhalo z Drupla úrovne!',
    'sl-SL': 'Ustvarjanje uporabnika ni uspelo iz sloja Drupla!',
    'ko-KO': 'Drupla 레이어에서 사용자 생성 실패!'
  };
  'user_update_failed_drupal' = {
    'en-US': 'User Updation Failed from drupal layer!',
    'nl-NL': 'Gebruikersupdate mislukt van drupal laag!',
    'fr-FR': 'Mise à jour de l\'utilisateur Échec de la couche drupal!',
    'de-DE': 'Benutzeraktualisierung fehlgeschlagen von Drupal-Ebene!',
    'pl-PL': 'Aktualizacja użytkownika nie powiodła się z warstwy drupal!',
    'zh-HK': '用户更新失败从Drupal层！',
    'zh-ZH': '用戶更新失敗從Drupal層！',
    'tr-TR': 'Kullanıcı Güncellemesi drupal katmandan başarısız oldu! ',
    'es-ES': '¡Actualización de usuario fallida de la capa Drupal!',
    'pt-PT': 'Atualização do usuário falhou na camada drupal!',
    'sv-SE': 'Använder uppdatering misslyckades från Dupral-nivå!',
    'da-DK': 'Brugeropdatering Mislykkedes fra drupal lag!',
    'fi-FI': 'Käyttäjän päivittäminen epäonnistui!',
    'no-NO': 'Brukeroppdatering Mislyktes fra drupal lag!',
    'hu-HU': 'A felhasználó frissítés nem sikerült a \'drupal rétegből.',
    'el-EL': 'Η αναπάντεχη χρήστη απέτυχε από το στρώμα drupal!',
    'cs-CZ': 'Aktualizace uživatelů selhala z drupal vrstvy!',
    'ru-RU': 'Обновление пользователя Сбой с уровня drupal!',
    'sk-SK': 'Aktualizácia používateľa zlyhala z drupal vrstvy!',
    'sl-SL': 'Posodobitev uporabnika ni uspela iz sloja drupal!',
    'ko-KO': 'Drupal 레이어에서 사용자 업데이트 실패!'
  };
  'user_update_success' = {
    'en-US': 'User Updated Successfully!',
    'nl-NL': 'Gebruiker succesvol bijgewerkt!',
    'fr-FR': 'Utilisateur mis à jour avec succès!',
    'de-DE': 'Benutzer wurde erfolgreich aktualisiert!',
    'pl-PL': 'Użytkownik zaktualizowany z powodzeniem!',
    'zh-HK': '用户更新成功！',
    'zh-ZH': '用户更新成功！',
    'tr-TR': 'Kullanıcı başarıyla güncellendi!',
    'es-ES': '¡Usuario actualizado con éxito!',
    'pt-PT': 'Utilizador actualizado com sucesso!',
    'sv-SE': 'Användaren uppdaterades!',
    'da-DK': 'Opdatering af bruger er gennemført',
    'fi-FI': 'Käyttäjä päivitetty onnistuneesti!',
    'no-NO': 'Bruker oppdatert vellykket!',
    'hu-HU': 'Felhasználó frissítése sikeres!',
    'el-EL': 'Ο χρήστης ενημερώθηκε με επιτυχία!',
    'cs-CZ': 'Uživatel byl úspěšně aktualizován!',
    'ru-RU': 'Пользователь успешно обновлен!',
    'sk-SK': 'Používateľ bol úspešne aktualizovaný!',
    'sl-SL': 'Uporabnik je bil uspešno posodobljen!',
    'ko-KO': '사용자가 성공적으로 업데이트되었습니다!'
  };
  'user_update_failed' = {
    'en-US': 'User Updation Failed!',
    'nl-NL': 'Gebruikersupdate mislukt!',
    'fr-FR': 'La mise à jour de l\'utilisateur a échoué!',
    'de-DE': 'Benutzeraktualisierung fehlgeschlagen!',
    'pl-PL': 'Aktualizacja użytkownika nie powiodła się!',
    'zh-HK': '用户更新失败！',
    'zh-ZH': '用戶更新失敗！',
    'tr-TR': 'Kullanıcı Güncellemesi Başarısız!',
    'es-ES': '¡Actualización de usuario fallida!',
    'pt-PT': 'Erro na actualização do utilizador!',
    'sv-SE': 'Använder uppdatering misslyckades!',
    'da-DK': 'Opdatering af bruger fejlede!',
    'fi-FI': 'Käyttäjän päivittäminen epäonnistui!',
    'no-NO': 'Brukeroppdatering mislyktes!',
    'hu-HU': 'A felhasználó frissítése sikertelen!',
    'el-EL': 'Η δημιουργία ενέργειας χρήστη απέτυχε!',
    'cs-CZ': 'Aktualizace uživatele se nezdařila!',
    'ru-RU': 'Не удалось выполнить обновление пользователя!',
    'sk-SK': 'Aktualizácia používateľa zlyhala!',
    'sl-SL': 'Posodobitev uporabnika ni uspela!',
    'ko-KO': '사용자 업데이트 실패!'
  };
  'user_create_success' = {
    'en-US': 'User Created Successfully!',
    'nl-NL': 'Gebruiker succesvol gemaakt!',
    'fr-FR': 'Utilisateur créé avec succès!',
    'de-DE': 'Benutzer erfolgreich erstellt!',
    'pl-PL': 'Użytkownik został utworzony pomyślnie!',
    'zh-HK': '用户创建成功！',
    'zh-ZH': '用戶創建成功！',
    'tr-TR': 'Kullanıcı Başarıyla Oluşturuldu!',
    'es-ES': '¡Usuario creado con éxito!',
    'pt-PT': 'Utilizador criado com sucesso!',
    'sv-SE': 'Användaren skapades!',
    'da-DK': 'Oprettelse af bruger gennemført!',
    'fi-FI': 'Käyttäjä luotu onnistuneesti!',
    'no-NO': 'Bruker opprettet suksessivt!',
    'hu-HU': 'Felhasználó létrehozása sikeres!',
    'el-EL': 'Ο χρήστης δημιουργήθηκε με επιτυχία!',
    'cs-CZ': 'Uživatel byl úspěšně vytvořen!',
    'ru-RU': 'Пользователь создан успешно!',
    'sk-SK': 'Používateľ bol úspešne vytvorený!',
    'sl-SL': 'Uspešno ustvarjen uporabnik!',
    'ko-KO': '사용자가 성공적으로 생성되었습니다!'
  };
  'node_selection' = {
    'en-US': 'Please select atleast one child node from',
    'nl-NL': 'Selecteer ten minste één onderliggende knoop van',
    'fr-FR': 'Veuillez sélectionner au moins un noeud enfant de',
    'de-DE': 'Bitte wählen Sie mindestens einen Kindknoten aus',
    'pl-PL': 'Wybierz co najmniej jeden węzeł podrzędny z',
    'zh-HK': '请从中选择至少一个子节点',
    'zh-ZH': '請從中選擇至少一個子節點',
    'tr-TR': 'Lütfen en az bir alt düğüm seçin',
    'es-ES': 'Por favor seleccione al menos un nodo de niños para',
    'pt-PT': 'Por favor seleccione pelo menos um nó filho a partir de',
    'sv-SE': 'Vänligen välj minst 1 undernivå från',
    'da-DK': 'Vælg minimum en undernode fra',
    'fi-FI': 'Valitse vähintään yksi ',
    'no-NO': 'Vennligst velg minst ett barn knutepunkt fra',
    'hu-HU': 'Kérem válasszon egy \'gyerek csomópontot\' innen',
    'el-EL': 'Επιλέξτε τουλάχιστον έναν θυγατρικό κόμβο από',
    'cs-CZ': 'Zvolte prosím alespoň jeden podřízený uzel',
    'ru-RU': 'Выберите по крайней мере один дочерний узел из',
    'sk-SK': 'Vyberte aspoň jeden podradený uzol',
    'sl-SL': 'Izberite vsaj eno vozlišče otroka',
    'ko-KO': '최소한 하나 이상의 하위 노드를 선택하십시오.'
  };
  'step3' = {
    'en-US': 'in Step 3',
    'nl-NL': 'in stap 3',
    'fr-FR': 'à l\'étape 3',
    'de-DE': 'in Schritt 3',
    'pl-PL': 'w kroku 3',
    'zh-HK': '在步骤3中',
    'zh-ZH': '在步驟3中',
    'tr-TR': '3. Aşamada',
    'es-ES': 'en paso 3',
    'pt-PT': 'no passo 3',
    'sv-SE': 'i steg 3',
    'da-DK': 'i trin 3',
    'fi-FI': 'Vaihe 3:ssa',
    'no-NO': 'i trinn 3',
    'hu-HU': '3. lépésben',
    'el-EL': 'στο Βήμα 3',
    'cs-CZ': 'v kroku 3',
    'ru-RU': 'на этапе 3',
    'sk-SK': 'v kroku 3',
    'sl-SL': 'v 3. koraku',
    'ko-KO': '3 단계에서'
  };
  'reset_password' = {
    'en-US': 'Reset password email sent!',
    'nl-NL': 'Reset wachtwoord e-mail verzonden!',
    'fr-FR': 'Réinitialiser le mot de passe',
    'de-DE': 'Passwort zurücksetzen E-Mail gesendet!',
    'pl-PL': 'Zresetuj hasło e-mail wysłane!',
    'zh-HK': '重置密码邮件发送！',
    'zh-ZH': '重置密碼郵件發送！',
    'tr-TR': 'Şifre e-postasını sıfırlayın!',
    'es-ES': 'Restablecer la contraseña',
    'pt-PT': 'Redefinir senha',
    'sv-SE': 'Återställ lösenord',
    'da-DK': 'Nulstil password',
    'fi-FI': 'Nollaa salasana',
    'no-NO': 'Tilbakestill passord e-post sendt!',
    'hu-HU': 'Jelszó visszaállítása',
    'el-EL': 'Επαναφορά κωδικού πρόσβασης',
    'cs-CZ': 'Reset hesla e-mail odeslán!',
    'ru-RU': 'Сброс пароля электронной почты отправлен!',
    'sk-SK': 'Reset hesla',
    'sl-SL': 'Poslano e-poštno sporočilo za resetiranje!',
    'ko-KO': '보낸 암호 이메일 재설정!'
  };
  'user_deactivate_success' = {
    'en-US': 'User deactivated successfully!',
    'nl-NL': 'Gebruiker gedeactiveerd succesvol!',
    'fr-FR': 'L\'utilisateur a été désactivé avec succès!',
    'de-DE': 'Benutzer wurde erfolgreich deaktiviert!',
    'pl-PL': 'Użytkownik został deaktywowany pomyślnie!',
    'zh-HK': '用户已成功停用！',
    'zh-ZH': '用戶已成功停用！',
    'tr-TR': 'Kullanıcı başarıyla devre dışı bırakıldı!',
    'es-ES': '¡Usuario desactivado con éxito!',
    'pt-PT': 'Utilizador desactivado com sucesso!',
    'sv-SE': 'Användaren avaktiverade!',
    'da-DK': 'Aktivering af bruger gennemført!',
    'fi-FI': 'Käyttäjä deaktivoitu onnistuneesti!',
    'no-NO': 'Bruker deaktivert med hell!',
    'hu-HU': 'Felhasználó kikapcsolása sikeres!',
    'el-EL': 'Ο χρήστης απενεργοποιήθηκε με επιτυχία!',
    'cs-CZ': 'Uživatel byl úspěšně deaktivován!',
    'ru-RU': 'Пользователь успешно деактивирован!',
    'sk-SK': 'Používateľ bol úspešne deaktivovaný!',
    'sl-SL': 'Uporabnik je bil deaktiviran uspešno!',
    'ko-KO': '사용자가 성공적으로 비활성화되었습니다.'
  };
  'user_activate_success' = {
    'en-US': 'User activated successfully!',
    'nl-NL': 'Gebruiker succesvol geactiveerd!',
    'fr-FR': 'Utilisateur activé avec succès!',
    'de-DE': 'Benutzer wurde erfolgreich aktiviert!',
    'pl-PL': 'Użytkownik aktywowany pomyślnie!',
    'zh-HK': '用户激活成功！',
    'zh-ZH': '用戶激活成功！',
    'tr-TR': 'Kullanıcı başarıyla etkinleştirildi!',
    'es-ES': '¡Usuario creado con éxito!',
    'pt-PT': 'Utilizador activado com sucesso!',
    'sv-SE': 'Använder aktiverat!',
    'da-DK': 'Aktivering af bruger gennemført!',
    'fi-FI': 'Käyttäjä aktivoitu onnistuneesti!',
    'no-NO': 'Brukeren ble aktivert!',
    'hu-HU': 'Felhasználó aktiválás sikeres!',
    'el-EL': 'Ο χρήστης ενεργοποιήθηκε με επιτυχία!',
    'cs-CZ': 'Uživatel byl úspěšně aktivován!',
    'ru-RU': 'Пользователь успешно активирован!',
    'sk-SK': 'Používateľ bol úspešne aktivovaný!',
    'sl-SL': 'Uporabnik se je aktiviral uspešno!',
    'ko-KO': '사용자가 성공적으로 활성화되었습니다!'
  };
  'user_delete' = {
    'en-US': 'User deleted successfully!',
    'nl-NL': 'Gebruiker is succesvol verwijderd!',
    'fr-FR': 'Utilisateur supprimé avec succès!',
    'de-DE': 'Benutzer erfolgreich gelöscht!',
    'pl-PL': 'Użytkownik został usunięty!',
    'zh-HK': '用户删除成功！',
    'zh-ZH': '用戶刪除成功！',
    'tr-TR': 'Kullanıcı başarıyla silindi!',
    'es-ES': '¡Usuario borrado con éxito!',
    'pt-PT': 'Utilizador apagado com sucesso!',
    'sv-SE': 'Användare raderad!',
    'da-DK': 'Sletning af bruger gennemført!',
    'fi-FI': 'Käyttäjä poistetu onnistuneesti!',
    'no-NO': 'Bruker slettet med hell!',
    'hu-HU': 'Felhasználó törtlése sikeres!',
    'el-EL': 'Ο χρήστης διαγράφηκε με επιτυχία!',
    'cs-CZ': 'Uživatel byl úspěšně smazán!',
    'ru-RU': 'Пользователь удален успешно!',
    'sk-SK': 'Používateľ bol úspešne odstránený!',
    'sl-SL': 'Uporabnik je bil uspešno izbrisan!',
    'ko-KO': '사용자가 성공적으로 삭제되었습니다.'
  };
  'delete_error' = {
    'en-US': 'Cannot delete, Custom levels are associated.',
    'nl-NL': 'Kan niet verwijderen, aangepaste niveaus zijn gekoppeld.',
    'fr-FR': 'Impossible de supprimer, les niveaux personnalisés sont associés.',
    'de-DE': 'Kann nicht gelöscht werden, Benutzerdefinierte Ebenen sind verknüpft.',
    'pl-PL': 'Nie można usunąć, poziomy niestandardowe są powiązane.',
    'zh-HK': '无法删除，自定义级别关联。',
    'zh-ZH': '無法刪除，自定義級別關聯。',
    'tr-TR': 'Silinemiyor, Özel düzeyler bağlantılı.',
    'es-ES': 'No es posible borrar, hay niveles de cliente asociados',
    'pt-PT': 'Não é possível apagar, existem níveis personalizados atribuídos.',
    'da-DK': 'Kan ikke slette. Kundeniveau er forbundet.',
    'sv-SE': 'Kan inte radera, anpassade nivåer finns associerade.',
    'fi-FI': 'Ei voi poistaa',
    'it-IT': 'Impossibile eliminare, i livelli personalizzati sono associati.',
    'hu-HU': 'Nem törölhető, az egyéni szintek társítva vannak.',
    'el-EL': 'Δεν είναι δυνατή η διαγραφή, συσχετίζονται τα προσαρμοσμένα επίπεδα.',
    'no-NO': 'Kan ikke slette, Tilpassede nivåer er tilknyttet.',
    'ru-RU': 'Не удается удалить, пользовательские уровни связаны.',
    'cs-CZ': 'Nelze smazat, jsou přiřazeny vlastní úrovně.',
    'sk-SK': 'Nedá sa odstrániť, sú priradené vlastné úrovne.',
    'sl-SL': 'Ne morem izbrisati, povezane ravni po meri.',
    'ko-KO': '삭제할 수 없습니다. 사용자 지정 수준이 연결됩니다.'
  };
  'delete_confirm' = {
    'en-US': 'Are you sure you want to delete ',
    'nl-NL': 'Weet je zeker dat je wilt verwijderen',
    'fr-FR': 'Etes-vous sûr que vous voulez supprimer',
    'de-DE': 'Sind Sie sicher, dass Sie löschen möchten',
    'pl-PL': 'Czy na pewno chcesz usunąć',
    'zh-HK': '你确定你要删除',
    'zh-ZH': '你確定你要刪除',
    'tr-TR': 'Silmek istediğinizden emin misiniz?',
    'es-ES': '¿Está seguro de que quiere borrar?',
    'pt-PT': 'Tem a certeza de que pretende apagar?',
    'da-DK': 'Er du sikker på du vil slette',
    'sv-SE': 'Är du säker på att du vill radera?',
    'fi-FI': 'Oletko varma, etä haluat poistaa',
    'it-IT': 'Sei sicuro di voler eliminare',
    'hu-HU': 'Biztos benne, hogy törölni szeretné?',
    'el-EL': 'Είστε βέβαιοι ότι θέλετε να διαγράψετε',
    'no-NO': 'Er du sikker på at du vil slette',
    'ru-RU': 'Вы уверены, что хотите удалить',
    'cs-CZ': 'Jste si jistí, že to chcete smazat',
    'sl-SL': 'Ali ste prepričani, da želite izbrisati',
    'sk-SK': 'Naozaj chcete odstrániť',
    'ko-KO': '삭제 하시겠습니까'
  };
  'deactivate_error' = {
    'en-US': 'Cannot deactivate, Custom levels are associated.',
    'nl-NL': 'Kan niet deactiveren, aangepaste niveaus zijn gekoppeld',
    'fr-FR': 'Impossible de désactiver, les niveaux personnalisés sont associés.',
    'de-DE': 'Kann nicht deaktiviert werden, Benutzerdefinierte Ebenen sind zugeordnet',
    'pl-PL': 'Nie można dezaktywować, poziomy niestandardowe są powiązane',
    'zh-HK': '无法停用，自定义级别关联',
    'zh-ZH': '無法停用，自定義級別關聯',
    'tr-TR': 'Devre dışı bırakılamıyor, özel düzeyler bağlantılı.',
    'es-ES': 'No puede ser dasactivado, hay niveles de cliente asociados',
    'pt-PT': 'Não é possível desactivar, existem níveis personalizados atribuídos.',
    'da-DK': 'Kan ikke deaktivere. Kundeniveau er forbundet.',
    'sv-SE': 'Kan inte deaktiveras, anpassade nivåer finns associerade.',
    'fi-FI': 'Ei voi poistaa käytöstä, mukautetut tasot käytössä.',
    'it-IT': 'Impossibile disattivare, i livelli personalizzati sono associati.',
    'hu-HU': 'Nem lehet kikapcsolni, az egyéni szintek társítva vannak',
    'el-EL': 'Δεν είναι δυνατή η απενεργοποίηση, συσχετίζονται τα προσαρμοσμένα επίπεδα.',
    'no-NO': 'Kan ikke deaktivere, Tilpassede nivåer er tilknyttet.',
    'ru-RU': 'Невозможно деактивировать, пользовательские уровни связаны.',
    'cs-CZ': 'Nelze deaktivovat, vlastní úrovně jsou přiřazeny.',
    'sl-SL': 'Ne morem deaktivirati, ravni Custom so povezane.',
    'sk-SK': 'Nedá sa deaktivovať, sú priradené vlastné úrovne.',
    'ko-KO': '비활성화 할 수 없으며 사용자 지정 수준이 연결됩니다.'
  };
  'deactivate_confirm' = {
    'en-US': 'Are you sure you want to deactivate . ',
    'nl-NL': 'Weet je zeker dat je wilt deactiveren.',
    'fr-FR': 'Êtes-vous sûr de vouloir désactiver?',
    'de-DE': 'Möchtest du das wirklich deaktivieren?',
    'pl-PL': 'Czy na pewno chcesz dezaktywować?',
    'zh-HK': '你确定要停用吗？',
    'zh-ZH': '你確定要停用嗎？',
    'tr-TR': 'Devre dışı bırakmak istediğinizden emin misiniz?',
    'es-ES': '¿Está seguro de que quiere desactivar?',
    'pt-PT': 'Tem a certeza de que pretende desactivar?',
    'da-DK': 'Er du sikker på du vil deaktivere?',
    'sv-SE': 'Är du säker på att du vill deaktivera.',
    'fi-FI': 'Oletko varmma, että haluat deaktivoida.',
    'it-IT': 'Sei sicuro di voler disattivare.',
    'hu-HU': 'Biztosan szeretné kikapcsolni.',
    'el-EL': 'Είστε βέβαιοι ότι θέλετε να απενεργοποιήσετε το .',
    'no-NO': 'Er du sikker på at du vil deaktivere.',
    'ru-RU': 'Вы действительно хотите деактивировать.',
    'cs-CZ': 'Opravdu chcete deaktivovat.',
    'sl-SL': 'Ali ste prepričani, da želite deaktivirati.',
    'sk-SK': 'Naozaj chcete deaktivovať?',
    'ko-KO': '비활성화 하시겠습니까?'
  };
  'deactivate_room_type_error' = {
    'en-US': 'Cannot be deactivated as this Room type is associated with one of the room !',
    'nl-NL': 'Kan niet worden gedeactiveerd omdat dit kamertype geassocieerd is met een van de kamer!',
    'fr-FR': 'Ne peut pas être désactivé car ce type de pièce est associé à l\'une des pièces!',
    'de-DE': 'Kann nicht deaktiviert werden, da dieser Zimmertyp einem der Zimmer zugeordnet ist!',
    'pl-PL': 'Nie można go wyłączyć, ponieważ ten typ pokoju jest powiązany z jednym z pokoi!',
    'zh-HK': '由于此房间类型与房间之一相关联，因此无法取消激活！',
    'zh-ZH': '由於此房間類型與房間之一相關聯，因此無法取消激活！',
    'tr-TR': 'Bu Oda tipi odalardan biriyle ilişkili olduğundan devre dışı bırakılamaz!',
    'es-ES': '¡No se puede desactivar porque este tipo de habitación está asociado con una de las habitaciones!',
    'pt-PT': 'Não é possível desactivar porque este tipo de quarto está atribuído a um dos quartos.',
    'da-DK': 'Kan ikke deaktiveres, fordi Rumtypen er forbundet med et af rummene !',
    'sv-SE': 'Kan inte deaktiveras då rumstyp är kopplad till ett av rummen!',
    'fi-FI': 'Ei voi deaktivoida, koska huonetyyppi on käytössä!',
    'it-IT': 'Non può essere disattivato in quanto questo tipo di Stanza è associato a una stanza!',
    'hu-HU': 'Nem deaktiválható, mivel ez a szobatípus a szoba egyikéhez kapcsolódik!',
    'el-EL': 'Δεν μπορεί να απενεργοποιηθεί καθώς αυτός ο τύπος δωματίου σχετίζεται με ένα από τα δωμάτια!',
    'no-NO': 'Kan ikke deaktiveres da denne romtypen er knyttet til et av rommet!',
    'ru-RU': 'Нельзя деактивировать, так как этот тип номера связан с одной из комнат!',
    'cs-CZ': 'Nemůže být deaktivován, protože tento typ pokoje je spojen s jednou z místností!',
    'sl-SL': 'Ni mogoče deaktivirati, ker je ta vrsta sobe povezana z eno od sob!',
    'sk-SK': 'Nedá sa deaktivovať, tento typ miestnosti je spojený s jednou z miestností!',
    'ko-KO': '이 객실 유형이 객실 중 하나와 연결되어있어 비활성화 할 수 없습니다!'
  };
  'delete_room_type_error' = {
    'en-US': 'Cannot be deleted as this Room type is associated with one of the room !',
    'nl-NL': 'Kan niet worden verwijderd omdat dit kamertype is gekoppeld aan een van de kamers!',
    'fr-FR': 'Ne peut pas être supprimé car ce type de pièce est associé à l\'une des pièces!',
    'de-DE': 'Kann nicht gelöscht werden, da dieser Zimmertyp einem der Räume zugeordnet ist!',
    'pl-PL': 'Nie można usunąć, ponieważ ten typ pokoju jest powiązany z jednym z pokoi!',
    'zh-HK': '由于此房型与房间之一相关联，因此无法删除！',
    'zh-ZH': '由於此房型與房間之一相關聯，因此無法刪除！',
    'tr-TR': 'Bu Oda tipi odalardan biriyle ilişkili olduğundan silinemez!',
    'es-ES': '¡No se puede eliminar porque este tipo de habitación está asociado con una de las habitaciones!',
    'pt-PT': 'Não é possível apagar porque este tipo de quarto está atribuído a um dos quartos.',
    'da-DK': 'Kan ikke slettes, fordi Rumtypen er forbundet med et af rummene !',
    'sv-SE': 'Kan inte radera då rumstyp är associerad med ett av rum!',
    'fi-FI': 'Ei voi poistaa koska Huonetyyppi on käytössä!',
    'it-IT': 'Non può essere cancellato in quanto questo tipo di Stanza è associato a una stanza!',
    'hu-HU': 'Nem lehet törölni, mivel ez a Szobatípus a szoba egyikéhez kapcsolódik!',
    'el-EL': 'Δεν μπορεί να διαγραφεί καθώς αυτός ο τύπος δωματίου σχετίζεται με ένα από τα δωμάτια!',
    'no-NO': 'Kan ikke slettes fordi denne romtypen er knyttet til et av rommet!',
    'ru-RU': 'Невозможно удалить, поскольку этот тип номера связан с одной из комнат!',
    'cs-CZ': 'Nelze smazat, protože tento typ pokoje je spojen s některým z pokojů!',
    'sl-SL': 'Ni mogoče izbrisati, ker je ta vrsta sobe povezana z eno od sob!',
    'sk-SK': 'Nedá sa vymazať, tento typ miestnosti je priradený k jednej z miestností!',
    'ko-KO': '이 객실 유형은 방 하나와 연결되어 있으므로 삭제할 수 없습니다!'
  };
  'empty_name' = {
    'en-US': 'name cannot be empty!',
    'nl-NL': 'naam mag niet leeg zijn!',
    'fr-FR': 'nom ne peut pas être vide!',
    'de-DE': 'Der Name darf nicht leer sein!',
    'pl-PL': 'nazwa nie może być pusta!',
    'zh-HK': '名字不能为空！',
    'zh-ZH': '名字不能為空！',
    'tr-TR': 'isim boş olamaz!',
    'es-ES': 'el campo nombre no puede quedar vacío',
    'pt-PT': 'O nome tem de ser preenchido!',
    'da-DK': 'navn må ikke være tomt!',
    'sv-SE': 'namn kan inte vara tom!',
    'fi-FI': 'Nimi ei voi olla tyhjä',
    'it-IT': 'il nome non può essere vuoto!',
    'hu-HU': 'a név nem lehet üres!',
    'el-EL': 'το όνομα δεν μπορεί να είναι κενό!',
    'no-NO': 'Navnet kan ikke være tomt!',
    'ru-RU': 'имя не может быть пустым!',
    'cs-CZ': 'jméno nemůže být prázdné!',
    'sl-SL': 'ime ne sme biti prazno!',
    'sk-SK': 'meno nemôže byť prázdne!',
    'ko-KO': '이름은 비워 둘 수 없습니다!'
  };
  'activate_confirm' = {
    'en-US': 'Are you sure you want to activate ',
    'nl-NL': 'Weet je zeker dat je wilt activeren?',
    'fr-FR': 'Êtes-vous sûr de vouloir activer',
    'de-DE': 'Möchten Sie wirklich aktivieren?',
    'pl-PL': 'Czy na pewno chcesz aktywować?',
    'zh-HK': '你确定要激活吗？',
    'zh-ZH': '你確定要激活嗎？',
    'tr-TR': 'Etkinleştirmek istediğinizden emin misiniz?'  ,
    'es-ES': '¿Está seguro de que quiere activar?',
    'pt-PT': 'Tem a certeza de que pretende activar?',
    'da-DK': 'Er du sikker på du vil aktivere',
    'sv-SE': 'Är du säker på att du vill aktivera',
    'fi-FI': 'Haluatko varmasti aktivoida',
    'it-IT': 'Sei sicuro di voler attivare',
    'hu-HU': 'Biztosan aktiválja',
    'el-EL': 'Είστε βέβαιοι ότι θέλετε να ενεργοποιήσετε',
    'no-NO': 'Er du sikker på at du vil aktivere',
    'ru-RU': 'Вы действительно хотите активировать',
    'cs-CZ': 'Opravdu chcete aktivovat',
    'sl-SL': 'Ali ste prepričani, da želite aktivirati',
    'sk-SK': 'Naozaj chcete aktivovať',
    'ko-KO': '활성화 하시겠습니까?'
  };
  'max_length_245_characters' = {
    'en-US': 'Must be less than 245 characters long',
    'nl-NL': 'Moet minder dan 245 tekens lang zijn',
    'fr-FR': 'Doit contenir moins de 245 caractères',
    'de-DE': 'Muss weniger als 245 Zeichen lang sein',
    'pl-PL': 'Musi mieć mniej niż 245 znaków',
    'it-IT': 'Deve essere lungo meno di 245 caratteri',
    'tr-TR': '245 karakterden kısa olmalıdır.',
    'fi-FI': 'Ei saa olla yli 245 merkkiä',
    'da-DK': 'Skal være mindre end 245 tegn.',
    'pt-PT': 'Tem de ter menos de 245 caracteres',
    'es-ES': 'Must be less than 245 characters long.',
    'sv-SE': 'Måste vara mindre än 245 tecken lång.',
    'hu-HU': 'Kevesebb mint 245 karakter legyen.',
    'el-EL': 'Πρέπει να έχει μήκος μικρότερο από 245 χαρακτήρες',
    'no-NO': 'Må være mindre enn 245 tegn lang',
    'ru-RU': 'Должно быть менее 245 символов',
    'cs-CZ': 'Musí mít méně než 245 znaků',
    'ko-KO': '길이는 245 자 미만이어야합니다.',
    'sl-SL': 'Mora biti dolga manj kot 245 znakov',
    'sk-SK': 'Musí byť kratšie ako 245 znakov',
    'zh-ZH': '长度必须小于245个字符'
  };
  'max_length_45_characters' = {
    'en-US': 'Must be less than 45 characters long',
    'nl-NL': 'Moet minder dan 45 tekens lang zijn',
    'fr-FR': 'Doit contenir moins de 45 caractères',
    'de-DE': 'Muss weniger als 45 Zeichen lang sein',
    'pl-PL': 'Musi mieć mniej niż 45 znaków',
    'it-IT': 'Deve contenere meno di 45 caratteri',
    'tr-TR': '45 karakterden kısa olmalıdır.',
    'fi-FI': 'Ei saa olla yli 45 merkkiä',
    'da-DK': 'Skal være mindre end 45 tegn.',
    'pt-PT': 'Tem de ter menos de 45 caracteres',
    'es-ES': 'Must be less than 45 characters long.',
    'sv-SE': 'Måste vara mindre än 45 tecken lång.',
    'hu-HU': 'Legalább 45 karakter hosszúnak kell lennie',
    'el-EL': 'Πρέπει να έχει μήκος μικρότερο από 45 χαρακτήρες',
    'no-NO': 'Må være mindre enn 45 tegn lang',
    'ru-RU': 'Должно быть менее 45 символов',
    'cs-CZ': 'Musí mít délku menší než 45 znaků',
    'sl-SL': 'Mora biti dolga manj kot 245 znakov',
    'sk-SK': 'Musí mať menej ako 45 znakov',
    'ko-KO': '길이는 45 자 미만이어야합니다.',
    'zh-ZH': '长度不得超过45个字符'
  };
  'max_length_20_characters' = {
    'en-US': 'Must be less than 20 characters long',
    'nl-NL': 'Moet minder dan 20 tekens lang zijn',
    'fr-FR': 'Doit contenir moins de 20 caractères',
    'de-DE': 'Muss weniger als 20 Zeichen lang sein',
    'pl-PL': 'Musi mieć mniej niż 20 znaków',
    'it-IT': 'Deve contenere meno di 20 caratteri',
    'tr-TR': '20 karakterden kısa olmalıdır.',
    'fi-FI': 'Ei saa olla yli 20 merkkiä',
    'da-DK': 'Skal være mindre end 20 tegn.',
    'pt-PT': 'Tem de ter menos de 20 caracteres',
    'es-ES': 'Debe ser menos de 20 caracteres',
    'sv-SE': 'Måste vara minst 20 tecken lång',
    'hu-HU': 'Legalább 20 karakter hosszúnak kell lennie',
    'el-EL': 'Πρέπει να έχει μήκος μικρότερο από 20 χαρακτήρες',
    'no-NO': 'Må være mindre enn 20 tegn lang',
    'ru-RU': 'Должно быть менее 20 символов',
    'cs-CZ': 'Musí mít délku menší než 20 znaků',
    'sl-SL': 'Mora biti dolga manj kot 20 znakov',
    'sk-SK': 'Musí byť kratšie ako 20 znakov',
    'ko-KO': '20 자 미만이어야합니다.',
    'zh-ZH': '长度不得超过20个字符'
  };
  'must_alphaNumeric' = {
    'en-US': 'Must be AlphaNumeric',
    'nl-NL': 'Moet alfanumeriek zijn',
    'fr-FR': 'Doit être AlphaNumeric',
    'de-DE': 'Muss AlphaNumeric sein',
    'pl-PL': 'Musi być AlphaNumeric',
    'it-IT': 'Deve essere AlphaNumeric',
    'tr-TR': 'AlphaNumeric olmalı',
    'fi-FI': 'Pitää käyttää kirjaimia ja numeroita',
    'da-DK': 'Skal være alfanumerisk',
    'pt-PT': 'Os caracteres têm de ser alfanuméricos',
    'es-ES': 'Deben ser caracteres alfanuméricos',
    'sv-SE': 'Måste vara alfanumerisk',
    'hu-HU': 'Ne használjon speciális karaktereket. Legyen alfa numerikus',
    'el-EL': 'Πρέπει να είναι αλφαριθμητικό',
    'no-NO': 'Må være AlphaNumeric',
    'ru-RU': 'Должен быть AlphaNumeric',
    'cs-CZ': 'Musí být AlphaNumeric',
    'sl-SL': 'Mora biti AlphaNumeric',
    'sk-SK': 'Musí byť alfanumerické',
    'ko-KO': 'AlphaNumeric이어야합니다.',
    'zh-ZH': '必须是字母数字'
  };
  'lat_value_error' = {
    'en-US': 'Should be between or equal to -90 and +90',
    'nl-NL': 'Moet tussen -en gelijk aan -90 en +90 zijn',
    'fr-FR': 'Doit être compris entre -90 et +90',
    'de-DE': 'Sollte zwischen -90 und +90 liegen',
    'pl-PL': 'Powinien wynosić od lub równy -90 i +90',
    'it-IT': 'Deve essere compreso tra o uguale a -90 e +90',
    'tr-TR': '-90 ile +90 arasında veya buna eşit olmalıdır',
    'fi-FI': 'Arvon pitää olla -90 ja +90 välillä',
    'da-DK': 'Skal være imellem eller lig med -90 til +90',
    'pt-PT': 'O valor deve ser igual a ou estar entre -90 e +90',
    'es-ES': 'Debe ser igual o entre -90 y +90',
    'sv-SE': 'Borde ligga mellan eller lika med -90 och +90',
    'hu-HU': '-90 és +90 között kell lennie',
    'el-EL': 'Πρέπει να είναι μεταξύ ή ίσο με -90 και +90',
    'no-NO': 'Bør være mellom eller lik -90 og +90',
    'ru-RU': 'Должно быть от или до -90 и +90',
    'cs-CZ': 'Mělo by být mezi -90 a +90',
    'sl-SL': 'Naj bo med -90 in +90',
    'sk-SK': 'Malo by byť medzi -90 a +90',
    'ko-KO': '-90에서 +90 사이 여야합니다',
    'zh-ZH': '应介于或等于-90和+90之间'
  };
  'long_value_error' = {
    'en-US': 'Should be between or equal to -180 and +180',
    'nl-NL': 'Moet tussen -en gelijk aan -180 en +180 zijn',
    'fr-FR': 'Doit être compris entre -180 et +180',
    'de-DE': 'Sollte zwischen -180 und +180 liegen',
    'pl-PL': 'Powinien wynosić od lub równy -180 i +180',
    'it-IT': 'Deve essere compreso tra o uguale a -180 e +180',
    'tr-TR': '-180 ile +180 arasında olmalıdır',
    'fi-FI': 'Arvon pitää olla välillä -180 ja +180',
    'da-DK': 'Skal være imellem eller lig med -180 til +180',
    'pt-PT': 'O valor deve ser igual a ou estar entre -180 e +180',
    'es-ES': 'Debe estar entre  o igual a -180 y +180',
    'sv-SE': 'Borde ligga mellan eller lika med -180 och +180',
    'hu-HU': '-180 és +180 között kell lennie',
    'el-EL': 'Πρέπει να είναι μεταξύ ή ίση με -180 και +180',
    'no-NO': 'Bør være mellom eller lik -180 og +180',
    'ru-RU': 'Должно быть от или до -180 и +180',
    'cs-CZ': 'Mělo by být mezi -180 a +180',
    'sl-SL': 'Naj bo med -180 in +180',
    'sk-SK': 'Malo by byť medzi -180 a +180',
    'ko-KO': '-180에서 +180 사이 여야합니다',
    'zh-ZH': '应介于或等于-180和+180之间'
  };
  'min_length_1_characters' = {
    'en-US': 'Must be at least 1 character1 long',
    'nl-NL': 'Moet minimaal 1 tekens lang zijn',
    'fr-FR': 'Doit contenir au moins 1 caractères',
    'de-DE': 'Muss mindestens 1 Zeichen lang sein',
    'pl-PL': 'Musi mieć co najmniej 1 znaki',
    'it-IT': 'Deve contenere almeno 1 caratteri',
    'tr-TR': 'En az 1 karakter uzunluğunda olmalı',
    'fi-FI': 'Pitää olla vähintää yksi merrki.',
    'da-DK': 'Skal være minimum 1 karakter.',
    'pt-PT': 'Tem de ter pelo menos 1 carácter',
    'es-ES': 'Debe contener al menos un caracter',
    'sv-SE': 'Måste var minst 1 tecken.',
    'hu-HU': 'Legalább egy karakter legyen',
    'el-EL': 'Πρέπει να έχει μήκος τουλάχιστον 1 χαρακτήρα1',
    'no-NO': 'Må være minst 1 tegn lang',
    'ru-RU': 'Должно быть не менее 1 символов',
    'cs-CZ': 'Musí mít délku alespoň dvou znaků',
    'sl-SL': 'Mora biti vsaj 1 znaka',
    'sk-SK': 'Musí mať najmenej 1 znak',
    'ko-KO': '최소 1 자 이상이어야합니다.',
    'zh-ZH': '必须至少1个字符'
  };
  'min_length_2_characters' = {
    'en-US': 'Must be at least 2 characters long',
    'nl-NL': 'Moet minimaal 2 tekens lang zijn',
    'fr-FR': 'Doit contenir au moins 2 caractères',
    'de-DE': 'Muss mindestens 2 Zeichen lang sein',
    'pl-PL': 'Musi mieć co najmniej 2 znaki',
    'it-IT': 'Deve contenere almeno 2 caratteri',
    'tr-TR': 'En az 2 karakter uzunluğunda olmalı',
    'fi-FI': 'Pitää olla vähintää 2 merkkiä',
    'da-DK': 'Skal være på mindst 2 tegn',
    'pt-PT': 'Tem de ter pelo menos 2 caracteres',
    'es-ES': 'Debe contener menos 2 caracteres',
    'sv-SE': 'Måste vara minst 2 tecken lång.',
    'hu-HU': 'Legalább két karakternek kell lennie',
    'el-EL': 'Πρέπει να έχει μήκος τουλάχιστον 2 χαρακτήρες',
    'no-NO': 'Må være minst 2 tegn lang',
    'ru-RU': 'Должно быть не менее 2 символов',
    'cs-CZ': 'Musí mít délku alespoň dvou znaků',
    'sl-SL': 'Mora biti vsaj 2 znaka',
    'sk-SK': 'Musí obsahovať najmenej 2 znaky',
    'ko-KO': '최소 2 자 이상이어야합니다.',
    'zh-ZH': '必须至少2个字符'
  };
  'min_length_10_characters' = {
    'en-US': 'Must be at least 10 characters long',
    'nl-NL': 'Moet minimaal 10 tekens lang zijn',
    'fr-FR': 'Doit comporter au moins 10 caractères',
    'de-DE': 'Muss mindestens 10 Zeichen lang sein',
    'pl-PL': 'Musi mieć co najmniej 10 znaki',
    'it-IT': 'Deve contenere almeno 10 caratteri',
    'tr-TR': 'En az 10 karakter uzunluğunda olmalı',
    'fi-FI': 'Pitää olla vähintään 10 merkkiä',
    'da-DK': 'Skal være på mindst 10 tegn',
    'pt-PT': 'Tem de ter pelo menos 10 caracteres',
    'es-ES': 'Debe ser al menos 10 caracteres',
    'sv-SE': 'Måste vara minst 10 tecken lång.',
    'hu-HU': 'Legalább 10 karakter legyen',
    'el-EL': 'Πρέπει να έχει μήκος τουλάχιστον 10 χαρακτήρες',
    'no-NO': 'Må være minst 10 tegn lang',
    'ru-RU': 'Должно быть не менее 10 символов',
    'cs-CZ': 'Musí mít délku alespoň 10 znaků',
    'sl-SL': 'Mora biti vsaj 10 znaka',
    'sk-SK': 'Musí obsahovať najmenej 10 znakov',
    'ko-KO': '10 자 이상이어야합니다.',
    'zh-ZH': '必须至少10个字符'
  };
  'max_length_25_characters' = {
    'en-US': 'Must be less than 25 characters long.',
    'nl-NL': 'Moet minder dan 25 tekens lang zijn',
    'fr-FR': 'Doit contenir moins de 25 caractères.',
    'de-DE': 'Muss weniger als 25 Zeichen lang sein',
    'pl-PL': 'Musi mieć mniej niż 25 znaków',
    'it-IT': 'Deve essere lungo meno di 25 caratteri.',
    'tr-TR': '25 karakterden kısa olmalıdır.',
    'fi-FI': 'Ei saa olla yli 25 merkkiä.',
    'da-DK': 'Skal være mindre end 25 tegn.',
    'pt-PT': 'Tem de ter menos de 25 caracteres',
    'es-ES': 'Debe ser menos de 25 caracteres',
    'sv-SE': 'Måste vara mindre än 25 tecken lång.',
    'hu-HU': 'Legalább 25 karakter hosszúnak kell lennie.',
    'el-EL': 'Πρέπει να έχει μήκος μικρότερο από 25 χαρακτήρες.',
    'no-NO': 'Må være mindre enn 25 tegn lang.',
    'ru-RU': 'Должно быть не более 25 символов.',
    'cs-CZ': 'Musí mít délku menší než 25 znaků.',
    'sl-SL': 'Mora biti dolga manj kot 25 znakov',
    'sk-SK': 'Musí byť kratšie ako 25 znakov.',
    'ko-KO': '25 자 미만이어야합니다.',
    'zh-ZH': '长度不得超过25个字符。'
  };
  'max_length_10_characters' = {
    'en-US': 'Must be less than 10 characters long.',
    'nl-NL': 'Moet minder dan 10 tekens lang zijn',
    'fr-FR': 'Doit contenir moins de 10 caractères.',
    'de-DE': 'Muss weniger als 10 Zeichen lang sein',
    'pl-PL': 'Musi mieć mniej niż 10 znaków',
    'it-IT': 'Deve essere lungo meno di 10 caratteri.',
    'tr-TR': '10 karakterden kısa olmalıdır.',
    'fi-FI': 'Ei saa olla yli 10 merkkiä',
    'da-DK': 'Skal være mindre end 10 tegn.',
    'pt-PT': 'Tem de ter menos de 10 caracteres',
    'es-ES': 'Debe contener menos 10 caracteres\n',
    'sv-SE': 'Måste vara minst 10 tecken lång.',
    'hu-HU': '10 karakternél legyen rövidebb',
    'el-EL': 'Πρέπει να έχει μήκος μικρότερο από 10 χαρακτήρες.',
    'no-NO': 'Må være mindre enn 10 tegn langt.',
    'ru-RU': 'Должно быть менее 10 символов.',
    'cs-CZ': 'Musí mít délku menší než 10 znaků.',
    'sl-SL': 'Mora biti dolga manj kot 10 znakov',
    'sk-SK': 'Musí byť kratšie ako 10 znakov.',
    'ko-KO': '10 자 미만이어야합니다.',
    'zh-ZH': '长度不得超过10个字符。'
  };
  'max_length_255_characters' = {
    'en-US': 'Must be less than 255 characters long.',
    'nl-NL': 'Moet minder dan 255 tekens lang zijn',
    'fr-FR': 'Doit contenir moins de 255 caractères.',
    'de-DE': 'Muss weniger als 255 Zeichen lang sein',
    'pl-PL': 'Musi mieć mniej niż 255 znaków',
    'it-IT': 'Deve contenere meno di 255 caratteri.',
    'tr-TR': '255 karakterden kısa olmalıdır.',
    'fi-FI': 'Ei saa olla yli 25 merkkiä.',
    'da-DK': 'Skal være mindre end 255 tegn.',
    'pt-PT': 'Tem de ter menos de 255 caracteres',
    'es-ES': 'Debe ser menos de 255 caracteres',
    'sv-SE': 'Måste vara mindre än 255 tecken lång.',
    'hu-HU': 'Legalább 255 karakter hosszúnak kell lennie.',
    'el-EL': 'Πρέπει να έχει μήκος μικρότερο από 255 χαρακτήρες.',
    'no-NO': 'Må være mindre enn 255 tegn lang.',
    'ru-RU': 'Длина не менее 255 символов.',
    'cs-CZ': 'Musí mít délku menší než 255 znaků.',
    'sl-SL': 'Mora biti dolga manj kot 255 znakov',
    'sk-SK': 'Musí byť kratšie ako 255 znakov.',
    'ko-KO': '255 자 미만이어야합니다.',
    'zh-ZH': '长度必须小于255个字符。'
  };
  'max_length_18_characters' = {
    'en-US': 'Must be less than 18 characters long.',
    'nl-NL': 'Moet minder dan 18 tekens lang zijn',
    'fr-FR': 'Doit contenir moins de 18 caractères.',
    'de-DE': 'Muss weniger als 18 Zeichen lang sein',
    'pl-PL': 'Musi mieć mniej niż 18 znaków',
    'it-IT': 'Deve essere lungo meno di 18 caratteri.',
    'tr-TR': '18 karakterden kısa olmalıdır.',
    'fi-FI': 'Ei saa olla yli 18 merkkiä.',
    'da-DK': 'Skal være mindre end 18 tegn.',
    'pt-PT': 'Tem de ter menos de 18 caracteres',
    'es-ES': 'Debe ser inferior a 18 caracteres.',
    'sv-SE': 'Måste vara mindre en 18 tecken lång.',
    'hu-HU': 'Legalább 18 karakter hosszúnak kell lennie.',
    'el-EL': 'Πρέπει να έχει μήκος μικρότερο από 18 χαρακτήρες.',
    'no-NO': 'Må være mindre enn 18 tegn lang.',
    'ru-RU': 'Должно быть менее 18 символов.',
    'cs-CZ': 'Musí mít délku menší než 18 znaků.',
    'sl-SL': 'Mora biti dolga manj kot 18 znakov',
    'sk-SK': 'Musí byť kratšie ako 18 znakov.',
    'ko-KO': '18 자 미만이어야합니,다.',
    'zh-ZH': '长度不得超过18个字符。'
  };
  'max_length_15_characters' = {
    'en-US': 'Must be less than 15 characters long.',
    'nl-NL': 'Moet minder dan 15 tekens lang zijn',
    'fr-FR': 'Doit contenir moins de 15 caractères.',
    'de-DE': 'Muss weniger als 15 Zeichen lang sein',
    'pl-PL': 'Musi mieć mniej niż 15 znaków',
    'it-IT': 'Deve essere lungo meno di 15 caratteri.',
    'tr-TR': '15 karakterden kısa olmalıdır.',
    'fi-FI': 'Ei saa käyttää yli 15 merkkiä.',
    'da-DK': 'Skal være mindt 15 karakterer langt.',
    'pt-PT': 'Tem de ter menos de 15 caracteres',
    'es-ES': 'Debe contener menos de 15 caracteres',
    'sv-SE': 'Måste vara minst 15 tecken.',
    'hu-HU': 'Maximum 15 karakter legyen',
    'el-EL': 'Πρέπει να έχει μήκος μικρότερο από 15 χαρακτήρες.',
    'no-NO': 'Må være mindre enn 15 tegn lang.',
    'ru-RU': 'Должно быть менее 15 символов.',
    'cs-CZ': 'Musí mít délku menší než 15 znaků.',
    'sl-SL': 'Mora biti dolga manj kot 15 znakov',
    'sk-SK': 'Musí byť kratšie ako 15 znakov.',
    'ko-KO': '15 자 미만이어야합니다.',
    'zh-ZH': '长度必须少于15个字符。'
  };
  'max_length_100_characters' = {
    'en-US': 'Must be less than 100 characters long.',
    'nl-NL': 'Moet minder dan 100 tekens lang zijn',
    'fr-FR': 'Doit contenir moins de 100 caractères.',
    'de-DE': 'Muss weniger als 100 Zeichen lang sein',
    'pl-PL': 'Musi mieć mniej niż 100 znaków',
    'it-IT': 'Deve essere lungo meno di 100 caratteri.',
    'tr-TR': '100 karakterden kısa olmalıdır.',
    'fi-FI': 'Täytää olla alle 100 merkkiä.',
    'da-DK': 'Skal være kortere end 100 tegn.',
    'pt-PT': 'Tem de ter menos de 100 caracteres',
    'es-ES': 'Debe tener menos de 100 caracteres.',
    'sv-SE': 'Måste vara mindre än 100 tecken lång.',
    'hu-HU': 'Kevesebb mint 100 karakter legyen.',
    'el-EL': 'Πρέπει να έχει μήκος μικρότερο από 100 χαρακτήρες.',
    'no-NO': 'Må være mindre enn 100 tegn lang.',
    'ru-RU': 'Должно быть менее 100 символов.',
    'cs-CZ': 'Musí mít délku menší než 100 znaků.',
    'sl-SL': 'Mora biti dolga manj kot 100 znakov',
    'sk-SK': 'Musí byť kratšie ako 100 znakov.',
    'ko-KO': '100 자 미만이어야합니다.',
    'zh-ZH': '长度必须少于100个字符。'
  };
  'max_length_50_characters' = {
    'en-US': 'Must be less than 50 characters long.',
    'nl-NL': 'Moet minder dan 50 tekens lang zijn',
    'fr-FR': 'Doit contenir moins de 50 caractères.',
    'de-DE': 'Muss weniger als 50 Zeichen lang sein',
    'pl-PL': 'Musi mieć mniej niż 50 znaków',
    'it-IT': 'Deve essere lungo meno di 50 caratteri.',
    'tr-TR': '50 karakterden kısa olmalıdır.',
    'fi-FI': 'Ei saa olla yli 50 merkkiä',
    'da-DK': 'Skal være mindre end 50 tegn.',
    'pt-PT': 'Tem de ter menos de 50 caracteres',
    'es-ES': 'Debe contener menos 50 caracteres',
    'sv-SE': 'Måste vara mindre en 50 tecken.',
    'hu-HU': '50 karakternél legyen rövidebb',
    'el-EL': 'Πρέπει να έχει μήκος μικρότερο από 50 χαρακτήρες.',
    'no-NO': 'Må være mindre enn 50 tegn lang.',
    'ru-RU': 'Должно быть менее 50 символов.',
    'cs-CZ': 'Musí mít délku menší než 50 znaků.',
    'sl-SL': 'Mora biti dolga manj kot 50 znakov',
    'sk-SK': 'Musí byť kratšie ako 50 znakov.',
    'ko-KO': '50 자 미만이어야합니다.',
    'zh-ZH': '长度必须少于50个字符。'
  };
  'max_length_32_characters' = {
    'en-US': 'Must be less than 32 characters long.',
    'nl-NL': 'Moet minder dan 32 tekens lang zijn',
    'fr-FR': 'Doit contenir moins de 32 caractères.',
    'de-DE': 'Muss weniger als 32 Zeichen lang sein',
    'pl-PL': 'Musi mieć mniej niż 32 znaków',
    'it-IT': 'Deve contenere meno di 32 caratteri.',
    'tr-TR': '32 karakterden kısa olmalıdır.',
    'fi-FI': 'Ei saa olla yli 255 merkkiä.',
    'da-DK': 'Skal være mindre end 32 tegn.',
    'pt-PT': 'Tem de ter menos de 32 caracteres',
    'es-ES': 'Debe ser menos de 32 caracteres',
    'sv-SE': 'Måste vara mindre än 32 tecken lång.',
    'hu-HU': 'Legalább 32 karakter hosszúnak kell lennie.',
    'el-EL': 'Πρέπει να έχει μήκος μικρότερο από 32 χαρακτήρες.',
    'no-NO': 'Må være mindre enn 32 tegn lang.',
    'ru-RU': 'Должно быть не более 32 символов.',
    'cs-CZ': 'Musí mít délku menší než 32 znaků.',
    'sl-SL': 'Mora biti dolga manj kot 32 znakov',
    'sk-SK': 'Musí byť kratšie ako 32 znakov.',
    'ko-KO': '32 자 미만이어야합니다.',
    'zh-ZH': '长度必须少于32个字符。'
  };
  'invalid_name' = {
    'en-US': 'Invalid Name',
    'nl-NL': 'Ongeldige naam',
    'fr-FR': 'Nom non valide',
    'de-DE': 'Ungültiger Name',
    'pl-PL': 'Błędna nazwa',
    'it-IT': 'Nome non valido',
    'tr-TR': 'Geçersiz isim',
    'fi-FI': 'Nimi ei kelpaa',
    'da-DK': 'Ugyldigt navn',
    'pt-PT': 'Nome inválido',
    'es-ES': 'Nombre inválido',
    'sv-SE': 'Ogiltigt namn',
    'hu-HU': 'Érvénytelen név',
    'el-EL': 'Το όνομα δεν είναι έγκυρο',
    'no-NO': 'Ugyldig navn',
    'ru-RU': 'Неверное имя',
    'cs-CZ': 'Neplatné jméno',
    'sl-SL': 'Neveljavno ime',
    'sk-SK': 'Neplatné meno',
    'ko-KO': '잘못된 이름',
    'zh-ZH': '名称无效'
  };
  'account_type_required' = {
    'en-US': 'Account type is required.',
    'nl-NL': 'Kontotyp ist erforderlich.',
    'fr-FR': 'Le type de compte est requis.',
    'de-DE': 'Kontotyp ist erforderlich.',
    'pl-PL': 'Rodzaj konta jest wymagany.',
    'it-IT': 'È richiesto un tipo di account',
    'tr-TR': 'Hesap türü gerekli.',
    'fi-FI': 'Tilityyppi vaaditaan',
    'da-DK': 'Kundetype er nødvendig.',
    'pt-PT': 'É necessário o tipo de conta.',
    'es-ES': 'Tipo de cuenta requerida',
    'sv-SE': 'Konto typ behövs.',
    'hu-HU': 'Account típus szükséges',
    'el-EL': 'Απαιτείται τύπος λογαριασμού.',
    'no-NO': 'Kontotype er påkrevd.',
    'ru-RU': 'Тип учетной записи не требуется.',
    'cs-CZ': 'Typ účtu je vyžadován.',
    'sl-SL': 'Vrsta računa je potrebna.',
    'sk-SK': 'Vyžaduje sa typ účtu.',
    'ko-KO': '계정 유형이 필요합니다.',
    'zh-ZH': '帐户类型是必需的。'
  };
  'hierarchies_one_level_required' = {
    'en-US': 'Atleast one level is required.',
    'nl-NL': 'Er is minimaal één niveau vereist.',
    'fr-FR': 'Au moins un niveau est requis.',
    'de-DE': 'Es ist mindestens eine Stufe erforderlich.',
    'pl-PL': 'Wymagany jest co najmniej jeden poziom.',
    'it-IT': 'È richiesto almeno un livello.',
    'tr-TR': 'En az bir seviye gerekli.',
    'fi-FI': 'Vaaditaan vähintään yksi taso.',
    'da-DK': 'Der er nødvendigt med minimum et niveau.',
    'pt-PT': 'É necessário pelo menos um nível.',
    'es-ES': 'Se requiere al menos un nivel',
    'sv-SE': 'Minst en nivå krävs.',
    'hu-HU': 'Legalább egy szintre van szüksége.',
    'el-EL': 'Τουλάχιστον ένα επίπεδο απαιτείται.',
    'no-NO': 'Atleast ett nivå er nødvendig.',
    'ru-RU': 'По крайней мере, один уровень необходим.',
    'cs-CZ': 'Je požadována nejméně jedna úroveň.',
    'sl-SL': 'Najnižja raven je potrebna.',
    'sk-SK': 'Vyžaduje sa aspoň jedna úroveň.',
    'ko-KO': '최소한 하나의 레벨이 필요합니다.',
    'zh-ZH': '至少需要一级。'
  };
  'level_should_not_empty' = {
    'en-US': 'Level name should not be empty.',
    'nl-NL': 'Niveau naam mag niet leeg zijn.',
    'fr-FR': 'Le nom du niveau ne doit pas être vide.',
    'de-DE': 'Der Ebenenname sollte nicht leer sein.',
    'pl-PL': 'Nazwa poziomu nie powinna być pusta.',
    'it-IT': 'Il nome del livello non dovrebbe essere vuoto.',
    'tr-TR': 'Seviye adi boş bırakılmamalıdır.',
    'fi-FI': 'Tason nimi ei voi olla tyhjä.',
    'da-DK': 'Niveaunavn må ikke være tomt.',
    'pt-PT': 'O campo nome do nível tem de ser preenchido.',
    'es-ES': 'El campo nombre de nivel no puede quedar vacío',
    'sv-SE': 'Levelnamn kan inte vara tom.',
    'hu-HU': 'A szint neve nem lehet üres',
    'el-EL': 'Το όνομα επιπέδου δεν πρέπει να είναι κενό.',
    'no-NO': 'Nivånavn skal ikke være tomt.',
    'ru-RU': 'Имя уровня не должно быть пустым.',
    'cs-CZ': 'Název úrovně by neměl být prázdný.',
    'sl-SL': 'Ime stopnje ne sme biti prazno.',
    'sk-SK': 'Názov úrovne by nemal byť prázdny.',
    'ko-KO': '레벨 이름은 비워 둘 수 없습니다.',
    'zh-ZH': '级别名称不应为空。'
  };
  'invalid_city_name' = {
    'en-US': 'Invalid City Name',
    'nl-NL': 'Ongeldige naam van de stad',
    'fr-FR': 'Nom de ville invalide',
    'de-DE': 'Ungültiger Stadtname',
    'pl-PL': 'Nieprawidłowa nazwa miasta',
    'it-IT': 'Nome città non valido',
    'tr-TR': 'Geçersiz şehir ismi',
    'fi-FI': 'Kaupungin nimi ei kelpaa',
    'da-DK': 'Ugyldig Bynavn',
    'pt-PT': 'Nome de cidade inválido.',
    'es-ES': 'Nombre de ciudad no válido',
    'sv-SE': 'Ogiltig namn på ort',
    'hu-HU': 'Érvénytelen városnév',
    'el-EL': 'Το όνομα πόλης δεν είναι έγκυρο',
    'no-NO': 'Ugyldig bynavn',
    'ru-RU': 'Недопустимое название города',
    'cs-CZ': 'Neplatné jméno města',
    'sl-SL': 'Neveljavno ime mesta',
    'sk-SK': 'Neplatný názov mesta',
    'ko-KO': '도시 이름이 잘못되었습니다.',
    'zh-ZH': '城市名称无效'
  };
  'invalid_site_name' = {
    'en-US': 'Invalid Site Name',
    'nl-NL': 'Ongeldige sitenaam',
    'fr-FR': 'Nom de site invalide',
    'de-DE': 'Ungültiger Website-Name',
    'pl-PL': 'Nieprawidłowa nazwa witryny',
    'it-IT': 'Nome sito non valido',
    'tr-TR': 'Geçersiz Site Adı',
    'fi-FI': 'Kohteen nimi ei kelpaa',
    'da-DK': 'Ugyldig Lokationsnavn',
    'pt-PT': 'Nome de site inválido.',
    'es-ES': 'Nombre del centro no válido',
    'sv-SE': 'Ogiltig objektnamn',
    'hu-HU': 'Érvénytelen helyiségnév',
    'el-EL': 'Το όνομα τοποθεσίας δεν είναι έγκυρο',
    'no-NO': 'Ugyldig nettstednavn',
    'ru-RU': 'Недопустимое имя сайта',
    'cs-CZ': 'Neplatný název webu',
    'sl-SL': 'Neveljavno ime spletnega mesta',
    'sk-SK': 'Neplatný názov objektu',
    'ko-KO': '잘못된 사이트 이름',
    'zh-ZH': '无效的站点名称'
  };
  'invalid_zip_code' = {
    'en-US': 'Invalid Zip Code',
    'nl-NL': 'Ongeldige postcode',
    'fr-FR': 'Code postal invalide',
    'de-DE': 'Ungültige Postleitzahl',
    'pl-PL': 'Niepoprawny kod pocztowy',
    'it-IT': 'Codice postale errato',
    'tr-TR': 'Geçersiz zip kodu',
    'fi-FI': 'Postinumero ei kelpaa',
    'da-DK': 'Ugyldigt postnummer',
    'pt-PT': 'Código postal inválido',
    'es-ES': 'Código zip no válido',
    'sv-SE': 'Ogiltig postnummer',
    'hu-HU': 'Érvénytelen irányítószám',
    'el-EL': 'Μη έγκυρος ταχυδρομικός κώδικας',
    'no-NO': 'Ugyldig postnummer',
    'ru-RU': 'Неверный почтовый индекс',
    'cs-CZ': 'Neplatný PSČ',
    'sl-SL': 'Neveljavna poštna številka',
    'sk-SK': 'Nesprávne PSČ',
    'ko-KO': '잘못된 우편 번호',
    'zh-ZH': '无效的邮政编码'
  };
  'invalid_state_name' = {
    'en-US': 'Invalid State Name',
    'nl-NL': 'Ongeldige naam van de staat',
    'fr-FR': 'Nom d\'état invalide',
    'de-DE': 'Ungültiger Statusname',
    'pl-PL': 'Nieprawidłowa nazwa stanu',
    'it-IT': 'Nome stato non valido',
    'tr-TR': 'Geçersiz Durum Adı',
    'fi-FI': 'Osavaltion nimi ei kelpaa',
    'da-DK': 'Ugyldigt landsdelsnavn',
    'pt-PT': 'Nome de estado inválido.',
    'es-ES': 'Nombre de estado no válido',
    'sv-SE': 'Ogiltig lännamn',
    'hu-HU': 'Érvénytelen állapotnév',
    'el-EL': 'Το όνομα κατάστασης δεν είναι έγκυρο',
    'no-NO': 'Ugyldig statens navn',
    'ru-RU': 'Недопустимое имя штата',
    'cs-CZ': 'Neplatné jméno státu',
    'sl-SL': 'Neveljavno ime države',
    'sk-SK': 'Neplatný názov štátu',
    'ko-KO': '상태 이름이 잘못되었습니다.',
    'zh-ZH': '状态名称无效'
  };
  'required_field' = {
    'en-US': 'Required field',
    'nl-NL': 'verplicht veld',
    'fr-FR': 'Champ obligatoire',
    'de-DE': 'Pflichtfeld',
    'pl-PL': 'Pole wymagane',
    'it-IT': 'campo obbligatorio',
    'tr-TR': 'gerekli alan',
    'fi-FI': 'Vaadittu kenttä',
    'da-DK': 'Påkrævet område',
    'pt-PT': 'Campo obrigatório',
    'es-ES': 'Campo requerido',
    'sv-SE': 'Obligatoriskt fält',
    'hu-HU': 'Kötelező mező',
    'el-EL': 'Απαιτούμενο πεδίο',
    'no-NO': 'Obligatorisk felt',
    'ru-RU': 'Обязательное поле',
    'cs-CZ': 'Povinné pole',
    'sl-SL': 'Zahtevano polje',
    'sk-SK': 'Požadované pole',
    'ko-KO': '필수 입력란',
    'zh-ZH': '必填项目'
  };
  'invalid_organization_no' = {
    'en-US': 'Invalid organization no',
    'nl-NL': 'Ongeldig organisatie-nummer',
    'fr-FR': 'Organisation non valide non',
    'de-DE': 'Ungültige Organisationsnummer',
    'pl-PL': 'Nieprawidłowy numer organizacji',
    'it-IT': 'Organizzazione non valida n',
    'tr-TR': 'Geçersiz kuruluş numarası',
    'fi-FI': 'Organisaation numero ei kelpaa',
    'da-DK': 'Ugyldigt organisationsnr.',
    'pt-PT': 'Nº de organização inválido',
    'es-ES': 'Número de organización inválido',
    'sv-SE': 'Ogiltig organisationsnummer',
    'hu-HU': 'Érvénytelen szervezet',
    'el-EL': 'Μη έγκυρος οργανισμός όχι',
    'no-NO': 'Ugyldig organisasjonsnr',
    'ru-RU': 'Недопустимая организация no',
    'cs-CZ': 'Neplatná organizace č',
    'sl-SL': 'Neveljavna organizacija št',
    'sk-SK': 'Neplatná organizácia č.',
    'ko-KO': '조직이 잘못되었습니다.',
    'zh-ZH': '组织号无效'
  };
  'invalid_organization_name' = {
    'en-US': 'Invalid organization name',
    'nl-NL': 'Ongeldige organisatienaam',
    'fr-FR': 'Nom d\'organisation invalide',
    'de-DE': 'Ungültiger Organisationsname',
    'pl-PL': 'Nieprawidłowa nazwa organizacji',
    'it-IT': 'Nome organizzazione non valido',
    'tr-TR': 'Geçersiz kuruluş adı',
    'fi-FI': 'Organisaation nimi ei kelpaa',
    'da-DK': 'Ugyldig organisationsnavn',
    'pt-PT': 'Nome de organização inválido',
    'es-ES': 'Nombre de empresa no válido',
    'sv-SE': 'Ogiltig organisationsnamn',
    'hu-HU': 'Érvénytelen szervezet',
    'el-EL': 'Το όνομα οργανισμού δεν είναι έγκυρο',
    'no-NO': 'Ugyldig organisasjonsnavn',
    'ru-RU': 'Недопустимое название организации',
    'cs-CZ': 'Neplatný název organizace',
    'sl-SL': 'Neveljavno ime organizacije',
    'sk-SK': 'Neplatný názov organizácie',
    'ko-KO': '조직 이름이 잘못되었습니다.',
    'zh-ZH': '组织名称无效'
  };
  'invalid_address' = {
    'en-US': 'Invalid address',
    'nl-NL': 'Ongeldig adres',
    'fr-FR': 'Adresse non valide',
    'de-DE': 'Ungültige Adresse',
    'pl-PL': 'Błędny adres',
    'it-IT': 'Indirizzo non valido',
    'tr-TR': 'Geçersiz adres',
    'fi-FI': 'Osoite ei kelpaa',
    'da-DK': 'Ugyldig adresse',
    'pt-PT': 'Endereço inválido',
    'es-ES': 'Dirección inválida',
    'sv-SE': 'Ogiltig adress',
    'hu-HU': 'Helytelen cím',
    'el-EL': 'Μη έγκυρη διεύθυνση',
    'no-NO': 'Ugyldig adresse',
    'ru-RU': 'Недействительный адрес',
    'cs-CZ': 'Neplatná adresa',
    'sl-SL': 'Neveljaven naslov',
    'sk-SK': 'Nesprávna adresa',
    'ko-KO': '잘못된 주소',
    'zh-ZH': '无效地址'
  };
  'valid_mobile_no' = {
    'en-US': 'Must be valid mobile no',
    'nl-NL': 'Moet geldig mobiel nummer zijn',
    'fr-FR': 'Doit être valide mobile no',
    'de-DE': 'Muss gültige Handynummer sein',
    'pl-PL': 'Musi być poprawny nr telefonu komórkowego',
    'it-IT': 'Deve essere valido cellulare no',
    'tr-TR': 'Geçerli bir telefon numarası olmalı',
    'fi-FI': 'Puhelinnumero ei kelpaa',
    'da-DK': 'Skal være et gyldigt mobilnr.',
    'pt-PT': 'Tem de ser um nº de telemóvel válido',
    'es-ES': 'Debe ser un número móvil válido',
    'sv-SE': 'Måste vara giltig mobilnummer',
    'hu-HU': 'Érvényes mobilszám szükséges',
    'el-EL': 'Πρέπει να είναι έγκυρο κινητό όχι',
    'no-NO': 'Må være gyldig mobilnr',
    'ru-RU': 'Должно быть действительным мобильным нет',
    'cs-CZ': 'Musí být platný mobilní č',
    'sl-SL': 'Mora biti veljaven mobilni št',
    'sk-SK': 'Musí byť platné mobilné číslo',
    'ko-KO': '유효한 모바일 번호 여야합니다.',
    'zh-ZH': '必须是有效的手机号码'
  };
  'invalid_lat_lng' = {
    'en-US': 'Invalid value.',
    'nl-NL': 'ongeldige waarde',
    'fr-FR': 'valeur invalide',
    'de-DE': 'Ungültiger Wert',
    'pl-PL': 'niewłaściwa wartość',
    'it-IT': 'valore non valido',
    'tr-TR': 'geçersiz değer',
    'fi-FI': 'Arvo ei kelpaa.',
    'da-DK': 'Ugyldig værdi.',
    'pt-PT': 'Valor inválido',
    'es-ES': 'Valor no válido',
    'sv-SE': 'Ogiltig värde.',
    'sv-FI': 'ogiltigt värde',
    'hu-HU': 'Helytelen érték',
    'el-EL': 'Η τιμή δεν είναι έγκυρη.',
    'no-NO': 'ugyldig verdi',
    'ru-RU': 'Неверное значение',
    'cs-CZ': 'neplatná hodnota',
    'sl-SL': 'Neveljavna vrednost',
    'sk-SK': 'Nesprávna hodnota.',
    'ko-KO': '잘못된 값',
    'zh-ZH': '无效值'
  };
  'sub_site' = {
    'en-US': 'Sub Site',
    'nl-NL': 'Onder de site',
    'fr-FR': 'Sous le site',
    'de-DE': 'Unter der Site',
    'pl-PL': 'Pod witryną',
    'it-IT': 'Sotto il sito',
    'tr-TR': 'Sitenin Altında',
    'fi-FI': 'Alakohde',
    'da-DK': 'Underlokation',
    'pt-PT': 'Sub-site',
    'es-ES': 'Sub centro',
    'sv-SE': 'Under-objekt',
    'sv-FI': 'Under webbplatsen',
    'hu-HU': 'Sub site',
    'el-EL': 'Δευτερεύουσα τοποθεσία',
    'no-NO': 'Under nettstedet',
    'ru-RU': 'Под сайтом',
    'cs-CZ': 'Pod touto stránkou',
    'sl-SL': 'Pod spletno stranjo',
    'sk-SK': 'Pod-objekt',
    'ko-KO': 'Under the Site',
    'zh-ZH': '在网站下'
  };
  'add_new' = {
    'en-US': 'Add New',
    'nl-NL': 'Voeg nieuw toe',
    'fr-FR': 'Ajouter un nouveau',
    'de-DE': 'Neue hinzufügen',
    'pl-PL': 'Dodaj nowe',
    'it-IT': 'Aggiungere nuova',
    'tr-TR': 'Yeni ekle',
    'fi-FI': 'Lisää uusi',
    'da-DK': 'TILFØJ NY',
    'pt-PT': 'Adicionar novo',
    'es-ES': 'agregar nuevo',
    'sv-SE': 'Lägg till ny',
    'sv-FI': 'Lägg till ny',
    'hu-HU': 'ÚJ HOZZÁADÁSA',
    'el-EL': 'Προσθήκη νέου',
    'no-NO': 'Legg til ny',
    'ru-RU': 'Добавить новое',
    'cs-CZ': 'Přidat nový',
    'sl-SL': 'Dodaj novo',
    'sk-SK': 'Pridať nový',
    'ko-KO': '새로운 걸 더하다',
    'zh-ZH': '添新'
  };
  'cancel_invite' = {
    'en-US': 'cancel the invite for',
    'nl-NL': 'annuleer de uitnodiging voor',
    'fr-FR': 'annuler l\'invitation pour',
    'de-DE': 'stornieren Sie die Einladung für',
    'pl-PL': 'anuluj zaproszenie dla',
    'it-IT': 'annullare l\'invito per',
    'tr-TR': 'için davetiyeyi iptal et',
    'fi-FI': 'peruuta kutsu',
    'da-DK': 'slet invitationen for',
    'pt-PT': 'cancelar o convite para',
    'es-ES': 'Cancelar invitación para',
    'sv-SE': 'annullera inbjudan för',
    'sv-FI': 'Avbryt inbjudan för',
    'hu-HU': 'törölje a meghívást',
    'el-EL': 'ακύρωση της πρόσκλησης για',
    'no-NO': 'avbryt invitasjonen for',
    'ru-RU': 'отменить приглашение для',
    'cs-CZ': 'zrušit pozvání pro',
    'sl-SL': 'prekličite povabilo za',
    'sk-SK': 'zrušiť pozvanie pre používateľa',
    'ko-KO': '초대장 취소',
    'zh-ZH': '取消邀请'
  };
  'user' = {
    'en-US': 'user',
    'nl-NL': 'gebruiker',
    'fr-FR': 'Utilisateur',
    'de-DE': 'Nutzer',
    'pl-PL': 'użytkownik',
    'it-IT': 'utente',
    'tr-TR': 'kullanıcı',
    'fi-FI': 'Käyttäjä',
    'da-DK': 'Bruger',
    'pt-PT': 'Utilizador',
    'es-ES': 'Usuario',
    'sv-SE': 'Användare',
    'sv-FI': 'användare',
    'hu-HU': 'használó',
    'el-EL': 'Χρήστη',
    'no-NO': 'bruker',
    'ru-RU': 'пользователь',
    'cs-CZ': 'uživatel',
    'sl-SL': 'uporabnika',
    'sk-SK': 'Používateľ',
    'ko-KO': '사용자',
    'zh-ZH': '用户'
  };
  '8303' = {
    'en-US': 'Account deactivated successfully. Logging you out...',
    'nl-NL': 'Account gedeactiveerd. Log je uit...',
    'fr-FR': 'Compte désactivé avec succès. Vous sortir...',
    'de-DE': 'Konto erfolgreich deaktiviert. Sie ausloggen...',
    'pl-PL': 'Konto zostało pomyślnie dezaktywowane. Wylogowywanie się...',
    'zh-ZH': '帐户已成功停用。正在注销您...',
    'it-IT': 'Account disattivato correttamente. Disconnessione...',
    'tr-TR': 'Hesap başarıyla devre dışı bırakıldı. Çıkış...',
    'fi-FI': 'Tilin aktivointi onnistui. Kirjaudun ulos...',
    'da-DK': 'Kontoen blev deaktiveret. Logger dig ud...',
    'pt-PT': 'Conta desativada com sucesso. Registrando você para fora...',
    'es-ES': 'Cuenta desactivada correctamente. Cerrar sesión...',
    'sv-SE': 'Kontot har inaktiverats. Logga ut dig...',
    'hu-HU': 'A fiók inaktiválása sikeresen megtörtént. Kijelentkeztetlek...',
    'el-EL': 'Ο λογαριασμός απενεργοποιήθηκε με επιτυχία. Αποσύνδεση...',
    'no-NO': 'Kontoen ble deaktivert. Logger deg ut ...',
    'ru-RU': 'Учетная запись успешно деактивирована. Регистрация вас ...',
    'cs-CZ': 'Účet byl úspěšně deaktivován. Odhlašování vás...',
    'sl-SL': 'Račun je bil uspešno deaktiviran. Odjavljanje...',
    'sk-SK': 'Účet bol úspešne deaktivovaný. Odhlásiť sa...',
    'ko-KO': '계정이 성공적으로 비활성화되었습니다. 로깅 하기...'
  };
}
