import { HierarchyItem } from './hierarchy-item';

export class GlobalAccount {
  public id: number;
  public name: string;
  public checked = false;
  public expand = false;
  public hierarchies: HierarchyItem[] = [];

  constructor(account) {
    this.id = account.global_account.id;
    this.name = account.global_account.name;
    account.hierarchy.forEach(hierarchy => {
      this.hierarchies.push(new HierarchyItem(hierarchy));
    });
  }

  public check(event: Event, newState = !this.checked): void {
    event.stopPropagation();
    this.checked = newState;
    this.hierarchies.forEach(hierarchy => {
      hierarchy.check(event, newState);
    });
  }

  public toggle(event: Event): void {
    event.stopPropagation();
    this.expand = !this.expand;
  }
}
