export class lables {
  '1100' = {
    'en-US': 'Invalid Credentials !',
    'nl-NL': 'Ongeldige legitimeringen!',
    'fr-FR': 'Les informations d\'identification invalides !',
    'de-DE': 'Ungültige Anmeldeinformationen',
    'pl-PL': 'Nieprawidłowe dane logowania!',
    'zh-ZH': '无效证件 ！',
    'it-IT': 'Credenziali non valide !',
    'tr-TR': 'Geçersiz kimlik bilgileri !',
    'fi-FI': 'Valtuudet ei riitä!',
    'da-DK': 'Ugyldige legitimationsoplysninger !',
    'ar-AR': 'بيانات الاعتماد غير صالحة !',
    'pt-PT': 'Credenciais inválidas!',
    'es-ES': '¡Credenciales no válidas!',
    'sv-SE': 'Ogiltiga uppgifter!',
    'sv-FI': 'Ogiltiga uppgifter !',
    'hu-HU': 'Érvénytelen hitelesítő adatok!',
    'el-EL': 'Μη έγκυρα διαπιστευτήρια !',
    'no-NO': 'Ugyldige legitimasjon !',
    'ru-RU': 'Недопустимые учетные данные!',
    'cs-CZ': 'Neplatná pověření!',
    'sk-SK': 'Neplatné poverenia!',
    'sl-SL': 'Neveljavni akreditivi!',
    'ko-KO': '잘못된 자격 증명 !'
  };
  '1110' = {
    'en-US': 'Email is not registered !',
    'nl-NL': 'E-mail is niet geregistreerd!',
    'fr-FR': 'Email n\'est pas enregistré!',
    'de-DE': 'E-Mail ist nicht registriert!',
    'pl-PL': 'Email nie jest zarejestrowany!',
    'zh-ZH': '电子邮件未注册！',
    'it-IT': 'L\'email non è stata registrata!',
    'tr-TR': 'E-posta kayıtlı değil!',
    'fi-FI': 'Sähköposti ei ole rekisteröity!',
    'da-DK': 'Email er ikke registreret !',
    'ar-AR': 'البريد الإلكتروني غير مسجل!',
    'pt-PT': 'O email não está registrado!',
    'es-ES': '¡Email no registrado!',
    'sv-SE': 'E-postadress inte registrerad!',
    'sv-FI': 'E-post är inte registrerad!',
    'hu-HU': 'Az e-mail nincs regisztrálva!',
    'el-EL': 'Το email δεν έχει καταχωρηθεί!',
    'no-NO': 'E-post er ikke registrert!',
    'ru-RU': 'Электронная почта не зарегистрирована!',
    'cs-CZ': 'E-mail není registrován!',
    'sk-SK': 'Email nie je zaregistrovaný!',
    'sl-SL': 'E-pošta ni registrirana!',
    'ko-KO': '이메일이 등록되지 않았습니다!'
  };
  '1120' = {
    'en-US': 'Incorrect password !',
    'nl-NL': 'Incorrect wachtwoord !',
    'fr-FR': 'Mot de passe incorrect !',
    'de-DE': 'Falsches Passwort !',
    'pl-PL': 'Niepoprawne hasło !',
    'zh-ZH': '密码错误 ！',
    'it-IT': 'Password errata !',
    'tr-TR': 'Yanlış şifre!',
    'fi-FI': 'Salasana on väärin!',
    'da-DK': 'Password er ikke korrekt!',
    'ar-AR': 'كلمة سر خاطئة !',
    'pt-PT': 'Senha incorrecta!',
    'es-ES': '¡Contraseña incorrecta!\n',
    'sv-SE': 'Ogiltig lösenord!',
    'sv-FI': 'Fel lösenord !',
    'hu-HU': 'Téves jelszó!',
    'el-EL': 'Εσφαλμένος κωδικός πρόσβασης !',
    'no-NO': 'Feil passord !',
    'ru-RU': 'Неверный пароль !',
    'cs-CZ': 'Nesprávné heslo !',
    'sk-SK': 'Nesprávne heslo !',
    'sl-SL': 'Napačno geslo !',
    'ko-KO': '잘못된 비밀번호 !'
  };
  '1130' = {
    'en-US': 'User is inactive !',
    'nl-NL': 'Gebruiker is inactief!',
    'fr-FR': 'L\'utilisateur est inactif!',
    'de-DE': 'Benutzer ist inaktiv!',
    'pl-PL': 'Użytkownik jest nieaktywny!',
    'zh-ZH': '用户无效！',
    'it-IT': 'L\'utente è inattivo!',
    'tr-TR': 'Kullanıcı etkin değil!',
    'fi-FI': 'Käyttäjä ei ole aktiivinen!',
    'da-DK': 'Bruger er inaktiv !',
    'ar-AR': 'المستخدم غير نشط!',
    'pt-PT': 'O utilizador está inactivo!',
    'es-ES': '¡Usuario inactivo!',
    'sv-SE': 'Användare inaktiv!',
    'sv-FI': 'Användaren är inaktiv!',
    'hu-HU': 'Inaktív felhasználó',
    'el-EL': 'Ο χρήστης είναι ανενεργός !',
    'no-NO': 'Brukeren er inaktiv!',
    'ru-RU': 'Пользователь неактивен!',
    'cs-CZ': 'Uživatel je neaktivní!',
    'sk-SK': 'Používateľ je neaktívny!',
    'sl-SL': 'Uporabnik je neaktiven!',
    'ko-KO': '사용자가 비활성 상태입니다.'
  };
  '1140' = {
    'en-US': 'Please accept terms & conditions ! ',
    'nl-NL': 'Accepteer de algemene voorwaarden!',
    'fr-FR': 'Veuillez accepter les conditions générales',
    'de-DE': 'Bitte akzeptieren Sie die AGB!',
    'pl-PL': 'Proszę zaakceptować warunki i zasady!',
    'zh-ZH': '请接受条款和条件！',
    'it-IT': 'Si prega di accettare termini e condizioni!',
    'tr-TR': 'Lütfen şartlar ve koşulları kabul edin!',
    'fi-FI': 'Hyväksy Käyttöehdot!',
    'da-DK': 'Accepter venligst vilkår og betingelser !',
    'ar-AR': 'يرجى قبول الشروط والأحكام!',
    'pt-PT': 'Por favor aceite os termos e condições!',
    'es-ES': '¡Por favor acepte los téminos y condiciones!',
    'sv-SE': 'Vänligen acceptera villkoren!',
    'sv-FI': 'Vänligen acceptera villkoren!',
    'hu-HU': 'Kérem, fogadja el a feltételeket!',
    'el-EL': 'Παρακαλώ δεχτείτε τους όρους και τις προϋποθέσεις!',
    'no-NO': 'Vennligst godta vilkår og betingelser!',
    'ru-RU': 'Примите условия и условия!',
    'cs-CZ': 'Přijměte smluvní podmínky!',
    'sk-SK': 'Prijmite prosím zmluvné podmienky!',
    'sl-SL': 'Prosimo, sprejmite pogoje in pogoje!',
    'ko-KO': '이용 약관에 동의하십시오!'
  };
  '1200' = {
    'en-US': 'Token Validation Failed !',
    'nl-NL': 'Token-validatie mislukt!',
    'fr-FR': 'La validation du jeton a échoué!',
    'de-DE': 'Token-Validierung fehlgeschlagen!',
    'pl-PL': 'Sprawdzenie poprawności tokena nie powiodło się!',
    'zh-ZH': '令牌验证失败！',
    'it-IT': 'Convalida del token non riuscita!',
    'tr-TR': 'Jeton doğrulama başarısız!',
    'fi-FI': 'Valtuuden varmistamien epäonnistui!',
    'da-DK': 'Token validering fejlede !',
    'ar-AR': 'فشل التحقق من الرمز المميز!',
    'pt-PT': 'Erro na validação por Token!',
    'es-ES': '¡Validación de Token fallida!',
    'sv-SE': 'Token validering misslyckades!',
    'sv-FI': 'Token Validering misslyckades!',
    'hu-HU': 'Jel érvényesítési hiba!',
    'el-EL': 'Η επικύρωση διακριτικού απέτυχε !',
    'no-NO': 'Token validering mislyktes!',
    'ru-RU': 'Ошибка проверки токена!',
    'cs-CZ': 'Validace tokenu se nezdařila!',
    'sk-SK': 'Overenie tokenu zlyhalo!',
    'sl-SL': 'Validacija žetonov ni uspela!',
    'ko-KO': '토큰 유효성 검사가 실패했습니다!'
  };
  '1300' = {
    'en-US': 'Limited Access.',
    'nl-NL': 'Beperkte toegang.',
    'fr-FR': 'Accès limité.',
    'de-DE': 'Eingeschränkter Zugang.',
    'pl-PL': 'Ograniczony dostęp.',
    'zh-ZH': '限制访问。',
    'it-IT': 'Accesso limitato.',
    'tr-TR': 'Sınırlı erişim.',
    'fi-FI': 'Rajoitettu pääsy.',
    'da-DK': 'Begrænset adgang.',
    'ar-AR': 'وصول محدود.',
    'pt-PT': 'Acesso limitado.',
    'es-ES': 'Acceso limitado',
    'sv-SE': 'Begränsad tillgång.',
    'sv-FI': 'Begränsad åtkomst.',
    'hu-HU': 'Korlátozott hozzáférés.',
    'el-EL': 'Περιορισμένη πρόσβαση.',
    'no-NO': 'Begrenset tilgang.',
    'ru-RU': 'Ограниченный доступ.',
    'cs-CZ': 'Omezený přístup.',
    'sk-SK': 'Obmedzený prístup.',
    'sl-SL': 'Omejen dostop.',
    'ko-KO': '제한된 액세스.'
  };
  '1311' = {
    'en-US': 'Unable to access',
    'nl-NL': 'Toegang onmogelijk',
    'fr-FR': 'Incapable d\'accéder',
    'de-DE': 'Zugang nicht möglich',
    'pl-PL': 'Nie można uzyskać dostępu',
    'zh-ZH': '无法访问',
    'it-IT': 'Impossibile accedere',
    'tr-TR': 'Erişimyor',
    'fi-FI': 'Ei pääsyä',
    'da-DK': 'Adgang ikke mulig',
    'ar-AR': 'غير قادر على الدخول',
    'pt-PT': 'Não é possível aceder',
    'es-ES': 'No es posible acceder',
    'sv-SE': 'Komma inte åt',
    'sv-FI': 'Det gick inte att komma åt',
    'hu-HU': 'Képtelen hozzáférés',
    'el-EL': 'Δεν είναι δυνατή η πρόσβαση',
    'no-NO': 'Kan ikke få tilgang',
    'ru-RU': 'Не удалось получить доступ',
    'cs-CZ': 'Nelze získat přístup',
    'sk-SK': 'Nepodarilo sa získať prístup',
    'sl-SL': 'Ni mogoče dostopati',
    'ko-KO': '액세스 할 수 없습니다.'
  };
  '1312' = {
    'en-US': 'Data is not available in system',
    'nl-NL': 'Gegevens zijn niet beschikbaar in het systeem',
    'fr-FR': 'Les données ne sont pas disponibles dans le système',
    'de-DE': 'Daten sind im System nicht verfügbar',
    'pl-PL': 'Dane nie są dostępne w systemie',
    'zh-ZH': '系统中没有数据',
    'it-IT': 'I dati non sono disponibili nel sistema',
    'tr-TR': 'Veri sistemde mevcut değil',
    'fi-FI': 'Dataa ei ole saatavilla',
    'da-DK': 'Data er ikke tilgængelig i systemet',
    'ar-AR': 'البيانات غير متوفرة في النظام',
    'pt-PT': 'Os dados não estão disponíveis no sistema',
    'es-ES': 'Datos no disponibles en el sistema',
    'sv-SE': 'Ingen data finns tillgänglig i systemet',
    'sv-FI': 'Data är inte tillgängligt i systemet',
    'hu-HU': 'Az adatok nem érhetők el a rendszerben',
    'el-EL': 'Τα δεδομένα δεν είναι διαθέσιμα στο σύστημα',
    'no-NO': 'Data er ikke tilgjengelig i systemet',
    'ru-RU': 'Данные недоступны в системе',
    'cs-CZ': 'Data nejsou v systému k dispozici',
    'sk-SK': 'Údaje nie sú v systéme dostupné',
    'sl-SL': 'Podatki v sistemu niso na voljo',
    'ko-KO': '시스템에서 데이터를 사용할 수 없습니다.'
  };
  '1400' = {
    'en-US': 'Missing Auth Token',
    'nl-NL': 'Ontbrekende Auth-token',
    'fr-FR': 'Jeton d\'authentification manquant',
    'de-DE': 'Fehlendes Auth Token',
    'pl-PL': 'Brak tokena autoryzacji',
    'zh-ZH': '缺少验证令牌',
    'it-IT': 'Token di autenticazione mancante',
    'tr-TR': 'Eksik yetkilendirme jetonu',
    'fi-FI': 'Valtuus puuttuu',
    'da-DK': 'Mangler ægte token',
    'ar-AR': 'يفتقد Auth Token',
    'pt-PT': 'Auto Token não encontrado',
    'es-ES': 'Falta Auth Token',
    'sv-SE': 'Saknar autentiserings tecken',
    'sv-FI': 'Saknar Auth Token',
    'hu-HU': 'Hiányzó automatikus token',
    'el-EL': 'Λείπει το διακριτικό ελέγχου ταυτότητας',
    'no-NO': 'Mangler Auth Token',
    'ru-RU': 'Отсутствует Auth Token',
    'cs-CZ': 'Chybějící značka Auth',
    'sk-SK': 'Chýbajúci overovací token',
    'sl-SL': 'Manjka Auth Token',
    'ko-KO': '누락 된 인증 토큰'
  };
  '2020' = {
    'en-US': 'Parent organization is Inactive !',
    'nl-NL': 'Ouderorganisatie is niet actief!',
    'fr-FR': 'L\'organisation parente est inactive!',
    'de-DE': 'Elternorganisation ist inaktiv!',
    'pl-PL': 'Organizacja macierzysta jest nieaktywna!',
    'zh-ZH': '家长组织是非活动的！',
    'it-IT': 'L\'organizzazione principale è inattiva!',
    'tr-TR': 'Üst kuruluş etkin değil!',
    'fi-FI': 'Isäntäorganisaatio ei ole aktiivinen!',
    'da-DK': 'Overordnet organisation er inaktiv!',
    'ar-AR': 'المنظمة الأم غير نشطة!',
    'pt-PT': 'A organização mãe está inactiva!',
    'es-ES': '¡La organización matrizl está inactiva!',
    'sv-SE': 'Huvudorganisation är inaktiv!',
    'sv-FI': 'Föräldraorganisationen är inaktiv!',
    'hu-HU': 'Anyavállalat inaktív!',
    'el-EL': 'Ο γονικός οργανισμός είναι ανενεργός !',
    'no-NO': 'Foreldreorganisasjonen er Inaktiv!',
    'ru-RU': 'Родительская организация неактивна!',
    'cs-CZ': 'Rodičovská organizace je neaktivní!',
    'sk-SK': 'Materská organizácia je neaktívna!',
    'sl-SL': 'Matična organizacija je neaktivna!',
    'ko-KO': '부모 조직이 비활성입니다!'
  };
  '2030' = {
    'en-US': 'Organization with active child node can not be deactivated',
    'nl-NL': 'Organisatie met actief kindknooppunt kan niet worden gedeactiveerd',
    'fr-FR': 'L\'organisation avec un nœud enfant actif ne peut pas être désactivée',
    'de-DE': 'Organisation mit aktivem untergeordneten Knoten kann nicht deaktiviert werden',
    'pl-PL': 'Organizacja z aktywnym węzłem podrzędnym nie może być dezaktywowana',
    'zh-ZH': '无法停用具有活动子节点的组织',
    'it-IT': 'L\'organizzazione con nodo figlio attivo non può essere disattivata',
    'tr-TR': 'Etkin alt düğüme sahip kuruluş devre dışı bırakılamaz',
    'fi-FI': 'Organisaatioota, jossa on aktiivisia osia, ei voi deaktvioida',
    'da-DK': 'Organisation med aktiv undernode kan ikke deaktiveres',
    'ar-AR': 'منظمة مع عقدة الطفل النشط لا يمكن تعطيلها',
    'pt-PT': 'Uma organização com nós filho activos não pode ser desactivado',
    'es-ES': 'La organización que tenga actovado el nodo niños no puede desactivarlo',
    'sv-SE': 'Organisation med aktiv under-kategori kan inte deaktiveras ',
    'sv-FI': 'Organisation med aktiv barnkod kan inte avaktiveras',
    'hu-HU': 'Vállalatok \'aktív gyermekcsoportokkal(???)\' nem deaktiválhatóak',
    'el-EL': 'Δεν είναι δυνατή η απενεργοποίηση του οργανισμού με ενεργό θυγατρικό κόμβο',
    'no-NO': 'Organisasjon med aktiv barnekode kan ikke deaktiveres',
    'ru-RU': 'Организация с активным дочерним узлом не может быть деактивирована',
    'cs-CZ': 'Organizace s aktivním dětským uzlem nemůže být deaktivována',
    'sk-SK': 'Organizáciu s aktívnym priradeným uzlom nie je možné deaktivovať',
    'sl-SL': 'Organizacije z aktivnim vozliščem ni mogoče deaktivirati',
    'ko-KO': '활성 하위 노드가있는 조직은 비활성화 할 수 없습니다.'
  };
  '2031' = {
    'en-US': 'Building you are trying to deactivate has active floors, Please deactivate all floors to perform this action.',
    'nl-NL': 'Gebouw dat u probeert te deactiveren heeft actieve vloeren. Deactiveer alle verdiepingen om deze actie uit te voeren.',
    'fr-FR': 'Le bâtiment que vous essayez de désactiver a des étages actifs. \
    Veuillez désactiver tous les étages pour effectuer cette action.',
    'de-DE': 'Das Gebäude, das Sie deaktivieren möchten, hat aktive Etagen. \
    Bitte deaktivieren Sie alle Etagen, um diese Aktion auszuführen.',
    'pl-PL': 'Budynek, który próbujesz dezaktywować, ma aktywne piętra. Aby wykonać tę czynność, zdeaktywuj wszystkie piętra.',
    'zh-ZH': '您尝试停用的建筑物有活动地板，请停用所有楼层以执行此操作。',
    'it-IT': 'L\'edificio che stai cercando di disattivare ha piani attivi, \
    si prega di disattivare tutti i piani per eseguire questa azione.',
    'tr-TR': 'Devre dışı bırakmaya çalıştığınız binanın aktif katları var, \
    Lütfen bu işlemi gerçekleştirmek için tüm katları devre dışı bırakın.',
    'fi-FI': 'Rakennuksella, jota yrität poistaa, on aktiivisia kerroksia. Deaktivoi ensin kerrokset!',
    'da-DK': 'Den bygning du prøver at deaktivere har aktive etager. Deaktiver først alle etager for at udføre denne handling.',
    'ar-AR': 'المبنى الذي تحاول إلغاء تنشيطه به طوابق نشطة ، يرجى إلغاء تنشيط جميع الطوابق لتنفيذ هذا الإجراء.',
    'pt-PT': 'O edifício que pretende desactivar tem pisos activos. Por favor desactive todos os pisos antes de executar esta acção.',
    'es-ES': 'El edificio que está intentando desactivar tiene pisos activos. Desactive todos los pisos para realizar esta acción.',
    'sv-SE': 'Byggnaden du försöka deaktivera har aktiva våningsplan. \
    Vänligen deaktivera alla våningsplan för att fortsätta med denna funktion.',
    'sv-FI': 'Bygga du försöker avaktivera har aktiva golv. Avaktivera alla golv för att utföra denna åtgärd.',
    'hu-HU': 'Az épület amit kívánt deaktiválni aktív padlózattal rendelkezik. Kérem, \
    Kapcsolja ki a padlókat mielőtt folytatné a műveletet.',
    'el-EL': 'Κτίριο που προσπαθείτε να απενεργοποιήσετε έχει ενεργό δάπεδα, \
    Παρακαλούμε να απενεργοποιήσετε όλους τους ορόφους για να εκτελέσετε αυτή την ενέργεια.',
    'no-NO': 'Bygningen du forsøker å deaktivere har aktive gulv, Vennligst deaktiver alle gulv for å utføre denne handlingen.',
    'ru-RU': 'Здание, которое вы пытаетесь отключить, имеет активные этажи. Пожалуйста, отключите все этажи, чтобы выполнить это действие.',
    'cs-CZ': 'Budova, kterou se pokoušíte deaktivovat, má aktivní podlahy, deaktivujte všechny podlaží a proveďte tuto akci.',
    'sk-SK': 'Budova, ktorú sa pokúšate deaktivovať, má aktívne poschodia, ak chcete vykonať túto akciu, deaktivujte všetky poschodia.',
    'sl-SL': 'Stavba, ki jo poskušate izklopiti, ima aktivna tla, Prosimo, deaktivirajte vse nadstropja, da izvedete to dejanje.',
    'ko-KO': '활성 바닥을 비활성화하려는 건물은이 작업을 수행하기 위해 모든 층을 비활성화하십시오.'
  };
  '2032' = {
    'en-US': 'Campus you are trying to deactivate has active buildings, Please deactivate all buildings to perform this action.',
    'nl-NL': 'Campus die u probeert te deactiveren, heeft actieve gebouwen. Deactiveer alle gebouwen om deze actie uit te voeren.',
    'fr-FR': 'Le campus que vous essayez de désactiver possède des bâtiments actifs. \
    Veuillez désactiver tous les bâtiments pour effectuer cette action.',
    'de-DE': 'Campus, den Sie deaktivieren möchten, verfügt über aktive Gebäude. \
    Bitte deaktivieren Sie alle Gebäude, um diese Aktion auszuführen.',
    'pl-PL': 'Kampus, który próbujesz dezaktywować, ma aktywne budynki. Wyłącz wszystkie budynki, aby wykonać to działanie.',
    'zh-ZH': '您尝试停用的校园有活动建筑物，请停用所有建筑物以执行此操作。',
    'it-IT': 'Il campus che stai tentando di disattivare ha edifici attivi. Disattiva tutti gli edifici per eseguire questa azione.',
    'tr-TR': 'Devre dışı bırakmaya çalıştığınız kampüste aktif binalar var, \
    Lütfen bu eylemi gerçekleştirmek için tüm binaları devre dışı bırakın.',
    'fi-FI': 'Alueella, jota koitat deaktivoida, on aktiivisia rakennuksia, deaktivoi kaikki rakennukset suorittaaksesi toiminnon.',
    'da-DK': 'Området du prøver at deaktivere har aktive bygninger. Deaktiver først alle bygninger for at udføre denne handling.',
    'ar-AR': 'الحرم الجامعي الذي تحاول إلغاء تنشيطه يحتوي على مباني نشطة ، يُرجى إلغاء تنشيط جميع المباني لتنفيذ هذا الإجراء.',
    'pt-PT': 'O campus que pretende desactivar tem edifícios activos. Por favor desactive todos os edifícios antes de executar esta acção.',
    'es-ES': 'El campo que está intentando desactivar tiene edificios activos. Desactive todos los edificios para realizar esta acción.',
    'sv-SE': 'Campus du försöker avaktivera har aktiva byggnader. Avaktivera alla byggnader för att utföra kunna avaktivera campus.',
    'sv-FI': 'Campus du försöker avaktivera har aktiva byggnader, Avaktivera alla byggnader för att utföra denna åtgärd.',
    'hu-HU': 'Campus megpróbálta kikapcsolni az aktív épületeket. Kérem, az összes épületet kapcsolja ki a sikeres művelethez.',
    'el-EL': 'Πανεπιστημιούπολη που προσπαθείτε να απενεργοποιήσετε έχει ενεργά κτίρια, \
    Παρακαλούμε να απενεργοποιήσετε όλα τα κτίρια για να εκτελέσετε αυτήν την ενέργεια.',
    'no-NO': 'Campus du prøver å deaktivere, har aktive bygninger. Vennligst deaktiver alle bygninger for å utføre denne handlingen.',
    'ru-RU': 'Кампус, который вы пытаетесь отключить, имеет активные здания, \
    пожалуйста, отключите все здания, чтобы выполнить это действие.',
    'cs-CZ': 'Campus, který se pokoušíte deaktivovat, má aktivní budovy. Chcete-li tuto akci provést, deaktivujte všechny budovy.',
    'sk-SK': 'Areál, ktorý sa pokúšate deaktivovať, má aktívne budovy. Ak chcete vykonať túto akciu, deaktivujte všetky budovy.',
    'sl-SL': 'Campus, ki ga želite izklopiti, ima aktivne stavbe. Prosimo, deaktivirajte vse stavbe, da izvedete to dejanje.',
    'ko-KO': '비활성화하려는 캠퍼스에는 활성 건물이 있습니다.이 작업을 수행하려면 모든 건물을 비활성화하십시오.'
  };
  '2040' = {
    'en-US': 'Action Already Applied',
    'nl-NL': 'Actie al toegepast',
    'fr-FR': 'Action déjà appliquée',
    'de-DE': 'Aktion bereits angewendet',
    'pl-PL': 'Działanie już zastosowane',
    'zh-ZH': '行动已经应用',
    'it-IT': 'Azione già applicata',
    'tr-TR': 'İşlem zaten uygulandı',
    'fi-FI': 'Toiminto on jo toteutunut',
    'da-DK': 'Handling er allerede anvendt',
    'ar-AR': 'الإجراء المطبقة بالفعل',
    'pt-PT': 'Acção já realizada',
    'es-ES': 'Acción realizada ',
    'sv-SE': 'Åtgärd redan tillämpad',
    'sv-FI': 'Åtgärd redan tillämpad',
    'hu-HU': 'A művelet már alkalmazva volt.',
    'el-EL': 'Ενέργεια που έχει ήδη εφαρμοστεί',
    'no-NO': 'Handling allerede brukt',
    'ru-RU': 'Действие уже применено',
    'cs-CZ': 'Akce již aplikována',
    'sk-SK': 'Organizáciu s aktívnym priradeným uzlom nie je možné deaktivovať',
    'sl-SL': 'Dejanje je že uporabljeno',
    'ko-KO': '이미 적용된 작업'
  };
  '2050' = {
    'en-US': 'Inactive Entity!',
    'nl-NL': 'Inactieve entiteit!',
    'fr-FR': 'Entité inactive!',
    'de-DE': 'Inaktive Entität!',
    'pl-PL': 'Nieaktywny podmiot!',
    'zh-ZH': '不活跃的实体！',
    'it-IT': 'Entità inattiva!',
    'tr-TR': 'Aktif olmayan eleman!',
    'fi-FI': 'Kkokonaisuus ei ole aktiivinen!',
    'da-DK': 'Inaktiv enhed!',
    'ar-AR': 'الكيان غير النشط!',
    'pt-PT': 'Entidade inactiva!',
    'es-ES': '¡Entidad inactiva!',
    'sv-SE': 'Inaktiv enhet!',
    'sv-FI': 'Inaktiv Entitet!',
    'hu-HU': 'Inaktív entitás!',
    'el-EL': 'Ανενεργή οντότητα!',
    'no-NO': 'Inaktiv Entitet!',
    'ru-RU': 'Неактивная организация!',
    'cs-CZ': 'Neaktivní entita!',
    'sk-SK': 'Neaktívna entita!',
    'sl-SL': 'Neaktivni subjekt!',
    'ko-KO': '비활성 엔터티!'
  };
  '2110' = {
    'en-US': 'Email Already Exists!',
    'nl-NL': 'Email bestaat al!',
    'fr-FR': 'L\'email existe déjà!',
    'de-DE': 'E-Mail existiert bereits!',
    'pl-PL': 'Email już istnieje!',
    'zh-ZH': '电子邮件已经存在！',
    'it-IT': 'Email già esistente!',
    'tr-TR': 'Bu e-posta zaten var!',
    'fi-FI': 'Sähköposti on jo käytössä!',
    'da-DK': 'Email eksisterer allerede!',
    'ar-AR': 'البريد الالكتروني موجود بالفعل!',
    'pt-PT': 'O endereço de email já existe!',
    'es-ES': '¡Este correo ya existe!',
    'sv-SE': 'E-postadress finns redan!',
    'sv-FI': 'E-post finns redan!',
    'hu-HU': 'Már létező e-mail cím!',
    'el-EL': 'Το ηλεκτρονικό ταχυδρομείο υπάρχει ήδη!',
    'no-NO': 'Email finnes allerede!',
    'ru-RU': 'Адрес электронной почты уже существует!',
    'cs-CZ': 'Email již existuje!',
    'sk-SK': 'Email už existuje!',
    'sl-SL': 'Email že obstaja!',
    'ko-KO': '이메일이 이미 존재합니다!'
  };
  '2111' = {
    'en-US': 'Already sent invite!',
    'nl-NL': 'Reeds verzonden uitnodiging',
    'fr-FR': 'Invitation déjà envoyée',
    'de-DE': 'Bereits gesendet Einladung',
    'pl-PL': 'Już wysłałem zaproszenie',
    'zh-ZH': '已经发送邀请',
    'it-IT': 'Invito già inviato',
    'tr-TR': 'Davet çoktan gönderildi!',
    'fi-FI': 'Kutsu lähetetty jo!',
    'da-DK': 'Invitation er allerede afsendt!',
    'ar-AR': 'أرسلت بالفعل دعوة',
    'pt-PT': 'Convite já enviado!',
    'es-ES': '¡Invitación enviada!',
    'sv-SE': 'Inbjudan har redan skickats!',
    'sv-FI': 'Redan skickat inbjudan',
    'hu-HU': 'Már elküldött meghívást!',
    'el-EL': 'Ήδη έστειλε καλέσει!',
    'no-NO': 'Sendt allerede invitasjon!',
    'ru-RU': 'Уже отправлено приглашение!',
    'cs-CZ': 'Již poslán pozván!',
    'sk-SK': 'Pozvanie už odoslané!',
    'sl-SL': 'Že poslano povabilo!',
    'ko-KO': '이미 초대장을 보냈습니다.'
  };
  '2112' = {
    'en-US': 'The new password must differ from your previous password',
    'nl-NL': 'Het nieuwe wachtwoord moet verschillen van uw vorige wachtwoord',
    'fr-FR': 'Le nouveau mot de passe doit être différent de votre mot de passe précédent.',
    'de-DE': 'Das neue Passwort muss sich von Ihrem vorherigen Passwort unterscheiden',
    'pl-PL': 'Nowe hasło musi różnić się od poprzedniego',
    'zh-ZH': '新密码必须与以前的密码不同',
    'it-IT': 'La nuova password deve essere diversa dalla tua password precedente',
    'tr-TR': 'Yeni şifre eski şifrenizden farklı olmalı',
    'fi-FI': 'Uusi ja vanha salasana ei voi olla sama',
    'da-DK': 'Det nye password skal være forskellig fra det foregående.',
    'ar-AR': 'يجب أن تختلف كلمة المرور الجديدة عن كلمة المرور السابقة',
    'pt-PT': 'A nova senha tem de ser diferente da antiga',
    'es-ES': 'La nueva contraseña debe ser diferente a su contraseña anterior',
    'sv-SE': 'Det nya lösenord måste skilja sig från föregående lösenord.',
    'sv-FI': 'Det nya lösenordet måste skilja sig från ditt tidigare lösenord',
    'hu-HU': 'Az új jelszúnak különböznie kell a régi jelszótól.',
    'el-EL': 'Ο νέος κωδικός πρόσβασης πρέπει να διαφέρει από τον προηγούμενο κωδικό πρόσβασης',
    'no-NO': 'Det nye passordet må avvike fra ditt forrige passord',
    'ru-RU': 'Новый пароль должен отличаться от предыдущего пароля',
    'cs-CZ': 'Nové heslo se musí lišit od předchozího hesla',
    'sk-SK': 'The new password must differ from your previous password',
    'sl-SL': 'Novo geslo se mora razlikovati od prejšnjega gesla',
    'ko-KO': '새 암호는 이전 암호와 달라야합니다.'
  };
  '2113' = {
    'en-US': 'Invalid current password',
    'nl-NL': 'Ongeldig huidig ​​wachtwoord',
    'fr-FR': 'Mot de passe actuel invalide',
    'de-DE': 'Ungültiges aktuelles Passwort',
    'pl-PL': 'Nieprawidłowe bieżące hasło',
    'zh-ZH': '当前密码无效',
    'it-IT': 'Password attuale non valida',
    'tr-TR': 'Geçersiz şifre',
    'fi-FI': 'Salasana ei kelpaa',
    'da-DK': 'Nuværende password ugyldig',
    'ar-AR': 'كلمة مرور غير صحيحة',
    'pt-PT': 'Senha actual inválida',
    'es-ES': 'Contraseña actual invalida',
    'sv-SE': 'Ogiltig lösenord',
    'sv-FI': 'Ogiltigt nuvarande lösenord',
    'hu-HU': 'érvénytelen jelenlegi jelszó',
    'el-EL': 'Ο τρέχων κωδικός πρόσβασης δεν είναι έγκυρος',
    'no-NO': 'Ugyldig nåværende passord',
    'ru-RU': 'Недопустимый текущий пароль',
    'cs-CZ': 'Neplatné aktuální heslo',
    'sk-SK': 'Neplatné aktuálne heslo',
    'sl-SL': 'Neveljavno trenutno geslo',
    'ko-KO': '현재 비밀번호가 잘못되었습니다.'
  };
  '2210' = {
    'en-US': 'Organization with same name already exist under parent Organization, please register another organization',
    'nl-NL': 'Organisatie met dezelfde naam bestaat al onder ouderorganisatie, registreer een andere organisatie',
    'fr-FR': 'Une organisation avec le même nom existe déjà sous l\'organisation mère, veuillez enregistrer une autre organisation',
    'de-DE': 'Eine gleichnamige Organisation existiert bereits unter der\
     übergeordneten Organisation. Bitte registrieren Sie eine andere Organisation',
    'pl-PL': 'Organizacja o tej samej nazwie już istnieje w organizacji nadrzędnej, zarejestruj inną organizację',
    'zh-ZH': '父组织下已存在同名组织，请注册其他组织',
    'it-IT': 'Organizzazione con lo stesso nome esiste già in Organizzazione genitore, si prega di registrare un\'altra organizzazione',
    'tr-TR': 'Aynı adı taşıyan kuruluş üst Kuruluş altında zaten var, lütfen başka bir kuruluş kaydedin',
    'fi-FI': 'Isäntäorganisaation alla on jo samanniminen organisaatio, valitse toinen',
    'da-DK': 'Der eksistere allerede en organisation med samme navn. Opret en organisation med et andet navn.',
    'ar-AR': 'منظمة بنفس الاسم موجودة بالفعل تحت المنظمة الأم ، يرجى تسجيل منظمة أخرى',
    'pt-PT': 'Já existe uma organização com o mesmo nome associada à organização mãe. Por favor registre outra organização.',
    'es-ES': 'Ya existe una organización con el mismo nombre en la organización matriz, registre una nueva organización',
    'sv-SE': 'Organisation med samma namn finns redan under huvudorganisation. Vänligen registrerar en annan organisation.',
    'sv-FI': 'Organisation med samma namn finns redan under moderorganisationen, var god registrera en annan organisation',
    'hu-HU': 'Azonos nevű szervezet már létezik az anyavállalat alatt. Kérem, regisztráljon egy másik szervezetet.',
    'el-EL': 'Οργανισμός με το ίδιο όνομα υπάρχει ήδη στη γονική εταιρεία, πληκτρολογήστε έναν άλλο οργανισμό',
    'no-NO': 'Organisasjon med samme navn finnes allerede under foreldreorganisasjon, vennligst registrer en annen organisasjon',
    'ru-RU': 'Организация с тем же именем уже существует в рамках родительской организации, пожалуйста, зарегистрируйте другую организацию',
    'cs-CZ': 'Organizace se stejným jménem již existuje pod nadřazenou organizací, zaregistrujte prosím jinou organizaci',
    'sk-SK': 'Organizácia s rovnakým menom už existuje pod materskou organizáciou, zaregistrujte inú organizáciu',
    'sl-SL': 'Organizacija z istim imenom že obstaja pod nadrejeno organizacijo, prosimo, registrirajte drugo organizacijo',
    'ko-KO': '상위 조직 아래에 같은 이름의 조직이 이미 있습니다. 다른 조직을 등록하십시오.'
  };
  '2310' = {
    'en-US': 'Site Already Exists !',
    'nl-NL': 'Site bestaat al!',
    'fr-FR': 'Le site existe déjà!',
    'de-DE': 'Seite existiert bereits!',
    'pl-PL': 'Witryna już istnieje!',
    'zh-ZH': '网站已经存在！',
    'it-IT': 'Il sito esiste già!',
    'tr-TR': 'Site Zaten Var!',
    'fi-FI': 'Kohde on jo luotuna!',
    'da-DK': 'Lokation eksisterer allerede !',
    'ar-AR': 'الموقع موجود بالفعل!',
    'pt-PT': 'O site já existe!',
    'es-ES': '¡El centro ya existe!',
    'sv-SE': 'Objekt existerar redan!',
    'sv-FI': 'Webbplatsen finns redan!',
    'hu-HU': 'Az oldal már létezik!',
    'el-EL': 'Η τοποθεσία υπάρχει ήδη !',
    'no-NO': 'Nettstedet finnes allerede!',
    'ru-RU': 'Сайт уже существует!',
    'cs-CZ': 'Stránky již existují!',
    'sk-SK': 'Objekt už existuje!',
    'sl-SL': 'Spletna stran že obstaja!',
    'ko-KO': '사이트가 이미 있습니다!'
  };
  '2410' = {
    'en-US': 'Site with same name already exist under parent Organisation, please register another name',
    'nl-NL': 'Site met dezelfde naam bestaat al onder ouderorganisatie, registreer een andere naam',
    'fr-FR': 'Un site portant le même nom existe déjà sous l\'organisation mère. Veuillez enregistrer un autre nom.',
    'de-DE': 'Site mit demselben Namen existiert bereits unter der übergeordneten Organisation. Bitte registrieren Sie einen anderen Namen',
    'pl-PL': 'Strona o tej samej nazwie już istnieje w organizacji nadrzędnej, proszę zarejestrować inną nazwę',
    'zh-ZH': '父组织下已存在同名网站，请注册其他名称',
    'it-IT': 'Il sito con lo stesso nome esiste già in Organizzazione padre, per favore registra un altro nome',
    'tr-TR': 'Üst kuruluşta aynı ada sahip site var, lütfen başka bir ad kaydedin',
    'fi-FI': 'Samanniminen kohde on jo olemassa isäntäorganisaation alla, valitse toinen nimi',
    'da-DK': 'Der eksistere allerede en lokation med samme navn under hovedorganisationen. Opret en lokation med et andet navn.',
    'ar-AR': 'الموقع بنفس الاسم موجود بالفعل تحت المنظمة الأم ، يرجى تسجيل اسم آخر',
    'pt-PT': 'Já existe um site com o mesmo nome associado à organização mãe. Por favor registre outro nome.',
    'es-ES': 'Ya existe  un centro con el mismo nombre en la organización principal, por favor registre otro nombre',
    'sv-SE': 'Städobjekt med samma namn finns redan under huvudorganisationen, vänligen registrera ett annat namn',
    'sv-FI': 'Webbplats med samma namn finns redan under moderorganisationen, var god registrera ett annat namn',
    'hu-HU': 'Az azonos nevű hely már létezik a szülői szervezet alatt, kérjük, regisztráljon egy másik nevet',
    'el-EL': 'Ο ιστότοπος με το ίδιο όνομα υπάρχει ήδη στη μητρική εταιρεία, πληκτρολογήστε άλλο όνομα',
    'no-NO': 'Nettsted med samme navn finnes allerede under overordnet organisasjon, vennligst registrer et annet navn',
    'ru-RU': 'Сайт с таким же именем уже существует под родительской организацией, пожалуйста, зарегистрируйте другое имя',
    'cs-CZ': 'Stránky se stejným názvem již existují pod nadřazenou organizací, zaregistrujte prosím jiné jméno',
    'sk-SK': 'Objekt s rovnakým názvom už existuje pod materskou organizáciou, zaregistrujte prosím iný názov.',
    'sl-SL': 'Spletno mesto z istim imenom že obstaja pod nadrejeno organizacijo, prosimo, registrirajte drugo ime',
    'ko-KO': '상위 조직 아래에 같은 이름의 사이트가 이미 존재합니다. 다른 이름을 등록하십시오.'
  };
  '2510' = {
    'en-US': 'Solution already exists !',
    'nl-NL': 'Oplossing bestaat al!',
    'fr-FR': 'La solution existe déjà!',
    'de-DE': 'Lösung existiert bereits!',
    'pl-PL': 'Rozwiązanie już istnieje!',
    'zh-ZH': '解决方案已存在！',
    'it-IT': 'La soluzione esiste già!',
    'tr-TR': 'Solüsyon zaten var!',
    'fi-FI': 'Palvelu on jo olemassa!',
    'da-DK': 'Løsning eksisterer allerede !',
    'ar-AR': 'الحل موجود بالفعل!',
    'pt-PT': 'A solução já existe!',
    'es-ES': '¡Esta solución ya existe!',
    'sv-SE': 'Lösningen existera redan!',
    'sv-FI': 'Lösning finns redan!',
    'hu-HU': 'Megoldás már létezik!',
    'el-EL': 'Η λύση υπάρχει ήδη!',
    'no-NO': 'Løsning finnes allerede!',
    'ru-RU': 'Решение уже существует!',
    'cs-CZ': 'Řešení již existuje!',
    'sk-SK': 'Riešenie už existuje!',
    'sl-SL': 'Rešitev že obstaja!',
    'ko-KO': '해결책은 이미 존재합니다!'
  };
  '3400' = {
    'en-US': 'Validation Failed !',
    'nl-NL': 'Validatie mislukt !',
    'fr-FR': 'Validation échouée !',
    'de-DE': 'Überprüfung fehlgeschlagen !',
    'pl-PL': 'Sprawdzanie poprawności nie powiodło się!',
    'zh-ZH': '验证失败 ！',
    'it-IT': 'Validazione fallita !',
    'tr-TR': 'Doğrulama başarısız !',
    'fi-FI': 'Varmistus epäonnistui!',
    'da-DK': 'Validering fejlede !',
    'ar-AR': 'فشل التحقق من الصحة!',
    'pt-PT': 'Erro na validação!',
    'es-ES': '¡Validación fallida!',
    'sv-SE': 'Validering misslyckades!',
    'sv-FI': 'Validering misslyckades !',
    'hu-HU': 'Érvényesítési hiba!',
    'el-EL': 'Η επικύρωση απέτυχε !',
    'no-NO': 'Godkjenning mislyktes !',
    'ru-RU': 'Проверка не удалась !',
    'cs-CZ': 'Ověření selhalo!',
    'sk-SK': 'Overenie zlyhalo!',
    'sl-SL': 'Validacija ni uspela!',
    'ko-KO': '검증에 실패했습니다 !'
  };
  '3500' = {
    'en-US': 'Empty request !',
    'nl-NL': 'Leeg verzoek!',
    'fr-FR': 'Demande vide!',
    'de-DE': 'Leere Anfrage!',
    'pl-PL': 'Pusty wniosek!',
    'zh-ZH': '空要求！',
    'it-IT': 'Richiesta vuota!',
    'tr-TR': 'Boş istek!',
    'fi-FI': 'Tyhjä pyyntö!',
    'da-DK': 'Udefinerbar forespørgsel !',
    'ar-AR': 'طلب فارغ!',
    'pt-PT': 'Pedido vazio!',
    'es-ES': '¡Petición vacía!',
    'sv-SE': 'Tom förfrågan!',
    'sv-FI': 'Tom förfrågan!',
    'hu-HU': 'Üres kérés!',
    'el-EL': 'Κενή αίτηση !',
    'no-NO': 'Tom forespørsel!',
    'ru-RU': 'Пустой запрос!',
    'cs-CZ': 'Prázdný požadavek!',
    'sk-SK': 'Prázdna požiadavka!',
    'sl-SL': 'Prazna zahteva!',
    'ko-KO': '빈 요청!'
  };
  '3510' = {
    'en-US': 'Parent missing!',
    'nl-NL': 'Ouder ontbreekt!',
    'fr-FR': 'Parent manquant!',
    'de-DE': 'Eltern fehlen!',
    'pl-PL': 'Rodzic zaginiony!',
    'zh-ZH': '父母失踪了！',
    'it-IT': 'Manca il genitore!',
    'tr-TR': 'Üst öğe eksik!',
    'fi-FI': 'Puuttuu',
    'da-DK': 'Overordnet niveau mangler!',
    'ar-AR': 'الوالد المفقود!',
    'pt-PT': 'Não existe organização mãe!',
    'es-ES': '¡Matriz no encontrada!',
    'sv-SE': 'Huvudorganisation saknas!',
    'sv-FI': 'Föräldrar saknas!',
    'hu-HU': 'Anyavállalat hiányzik!',
    'el-EL': 'Ο γονιός αγνοείται!',
    'no-NO': 'Foreldre mangler!',
    'ru-RU': 'Родитель отсутствует!',
    'cs-CZ': 'Chybějící rodič!',
    'sk-SK': 'Chýba materská organizácia',
    'sl-SL': 'Manjkajoči starš!',
    'ko-KO': '부모가 실종되었습니다!'
  };
  '3600' = {
    'en-US': 'Invalid Content Type !',
    'nl-NL': 'Ongeldig inhoudstype',
    'fr-FR': 'Type de contenu non valide',
    'de-DE': 'Tipo de contenido no válido',
    'pl-PL': 'Nieprawidłowy typ zawartości',
    'zh-ZH': '内容类型无效',
    'it-IT': 'Tipo di contenuto non valido',
    'tr-TR': 'Geçersiz İçerik Türü',
    'fi-FI': 'Sisällön tyyppi ei kelpaa!',
    'da-DK': 'Ugyldig indholdstype !',
    'ar-AR': 'نوع محتوى غير صالح',
    'pt-PT': 'Tipo de conteúdo inválido!',
    'es-ES': '¡Tipo de contenido no válido!',
    'sv-SE': 'Ogiltig innehållstyp!',
    'sv-FI': 'Ogiltig innehållstyp',
    'hu-HU': 'Érvénytelen tartalmom!',
    'el-EL': 'Ο τύπος περιεχομένου !',
    'no-NO': 'Ugyldig innholdstype!',
    'ru-RU': 'Недопустимый тип содержимого!',
    'cs-CZ': 'Neplatný typ obsahu!',
    'sk-SK': 'Neplatný typ obsahu!',
    'sl-SL': 'Neveljavna vrsta vsebine!',
    'ko-KO': '잘못된 콘텐츠 유형!'
  };
  '3700' = {
    'en-US': 'Route Not Found',
    'nl-NL': 'Route niet gevonden',
    'fr-FR': 'Itinéraire non trouvé',
    'de-DE': 'Route nicht gefunden',
    'pl-PL': 'Nie znaleziono trasy',
    'zh-ZH': '未找到路线',
    'it-IT': 'Percorso non trovato',
    'tr-TR': 'Güzergah bulunmadı',
    'fi-FI': 'Reittiä ei löydetty',
    'da-DK': 'Rute ikke fundet',
    'ar-AR': 'الطريق غير موجود',
    'pt-PT': 'Rota não encontrada',
    'es-ES': 'Ruta no encontrada',
    'sv-SE': 'Rutt ej hittad!',
    'sv-FI': 'Rutt ej hittad',
    'hu-HU': 'Nem található útvonal',
    'el-EL': 'Η διαδρομή δεν βρέθηκε',
    'no-NO': 'Rute ikke funnet',
    'ru-RU': 'Маршрут не найден',
    'cs-CZ': 'Trasa nebyla nalezena',
    'sk-SK': 'Trasa nebola nájdená',
    'sl-SL': 'Pot ni bila najdena',
    'ko-KO': '경로를 찾을 수 없음'
  };
  '3800' = {
    'en-US': 'Already requested',
    'nl-NL': 'al aangevraagd',
    'fr-FR': 'Déjà demandé',
    'de-DE': 'Bereits angefragt',
    'pl-PL': 'Już zażądałem',
    'zh-ZH': '已经提出要求',
    'it-IT': 'Già richiesto',
    'tr-TR': 'Daha önceden istendi',
    'fi-FI': 'Pyydetty aiemmin',
    'da-DK': 'Allerede forespurgt',
    'ar-AR': 'تم الطلب بالفعل',
    'pt-PT': 'Já solicitado',
    'es-ES': 'Ya solicitado',
    'sv-SE': 'Redan begärd',
    'sv-FI': 'Redan begärd',
    'hu-HU': 'Már igényelt',
    'el-EL': 'Έχει ήδη ζητηθεί',
    'no-NO': 'Allerede forespurt',
    'ru-RU': 'Уже запрошено',
    'cs-CZ': 'Již jste požádali',
    'sk-SK': 'Už ste o to požiadali',
    'sl-SL': 'Že zahtevano',
    'ko-KO': '이미 요청 됨'
  };
  '3900' = {
    'en-US': 'Invalid Hierarchy',
    'nl-NL': 'Ongeldige hiërarchie',
    'fr-FR': 'Hiérarchie invalide',
    'de-DE': 'Ungültige Hierarchie',
    'pl-PL': 'Nieprawidłowa hierarchia',
    'zh-ZH': '无效的层次结构',
    'it-IT': 'Gerarchia non valida',
    'tr-TR': 'Geçersiz Hiyerarşi',
    'fi-FI': 'Hierarkia ei kelpaa',
    'da-DK': 'Ugyldig hieraki',
    'ar-AR': 'تسلسل هرمي غير صحيح',
    'pt-PT': 'Hierarquia inválida',
    'es-ES': 'Jerarquía no válida',
    'sv-SE': 'Ogiltig hierarki!',
    'sv-FI': 'ogiltig hierarki',
    'hu-HU': 'Érvénytelen Tartalom!',
    'el-EL': 'Μη έγκυρη ιεραρχία',
    'no-NO': 'Ugyldig hierarki',
    'ru-RU': 'Недопустимая иерархия',
    'cs-CZ': 'Neplatná hierarchie',
    'sk-SK': 'Neplatná hierarchia',
    'sl-SL': 'Neveljavna hierarhija',
    'ko-KO': '잘못된 계층 구조'
  };
  '3910' = {
    'en-US': 'Invalid sub-site hierarchy!',
    'nl-NL': 'Ongeldige sub-site hiërarchie!',
    'fr-FR': 'Hiérarchie de sous-site non valide!',
    'de-DE': 'Ungültige Unterstandorthierarchie!',
    'pl-PL': 'Nieprawidłowa hierarchia pod-witryny!',
    'zh-ZH': '无效的子站点层次结构！',
    'it-IT': 'Gerarchia di sotto-siti non valida!',
    'tr-TR': 'Geçersiz alt site hiyerarşisi!',
    'fi-FI': 'Alakohteen hierarkia ei onnistunut!',
    'da-DK': 'Ugyldig underlokation hieraki!',
    'ar-AR': 'تسلسل هرمي للموقع الفرعي غير صالح!',
    'pt-PT': 'Hierarquia de sub-site inválida!',
    'es-ES': 'Jerarquía de de sub centro inválida',
    'sv-SE': 'Ogiltig under-objekt hierarki!',
    'sv-FI': 'Ogiltig sub-site hierarki!',
    'hu-HU': 'Érvénytelen aloldal rangsor!',
    'el-EL': 'Η ιεραρχία δευτερεύουσας τοποθεσίας δεν είναι έγκυρη!',
    'no-NO': 'Ugyldig subsite-hierarki!',
    'ru-RU': 'Недопустимая иерархия подсайта!',
    'cs-CZ': 'Neplatná hierarchie dílčích stránek!',
    'sk-SK': 'Neplatná hierarchia pod-objektov!',
    'sl-SL': 'Neveljavna hierarhija podstrani!',
    'ko-KO': '잘못된 하위 사이트 계층 구조입니다!'
  };
  '4010' = {
    'en-US': 'Parent organization not found !',
    'nl-NL': 'Moederorganisatie niet gevonden!',
    'fr-FR': 'Organisation parente non trouvée!',
    'de-DE': 'Elternorganisation nicht gefunden!',
    'pl-PL': 'Nie znaleziono organizacji macierzystej!',
    'zh-ZH': '未找到家长组织！',
    'it-IT': 'Organizzazione principale non trovata!',
    'tr-TR': 'Üst kuruluş bulunamadı!',
    'fi-FI': 'Isäntäorganisaatiota ei löytynyt!',
    'da-DK': 'Overordnet organisation er ikke fundet !',
    'ar-AR': 'لم يتم العثور على المنظمة الأم!',
    'pt-PT': 'A organização mãe não foi encontrada!',
    'es-ES': '¡Organización matriz no encontrada!',
    'sv-SE': 'Huvudorganisation ej hittad!',
    'sv-FI': 'Föräldraorganisationen hittades inte!',
    'hu-HU': 'Anyavállalat nem található',
    'el-EL': 'Ο γονικός οργανισμός δεν βρέθηκε !',
    'no-NO': 'Foreldreorganisasjon ikke funnet!',
    'ru-RU': 'Родительская организация не найдена!',
    'cs-CZ': 'Nadřazená organizace nebyla nalezena!',
    'sk-SK': 'Materská organizácia nebola nájdená!',
    'sl-SL': 'Matična organizacija ni bila najdena!',
    'ko-KO': '학부모 단체를 찾을 수 없습니다!'
  };
  '4011' = {
    'en-US': 'Data not found !',
    'nl-NL': 'Data niet gevonden !',
    'fr-FR': 'Data niet gevonden !',
    'de-DE': 'Daten nicht gefunden !',
    'pl-PL': 'Dane nieodnalezione !',
    'zh-ZH': '数据未找到！',
    'it-IT': 'Dati non trovati !',
    'tr-TR': 'Veri bulunamadı !',
    'fi-FI': 'Dataa ei löydetty!',
    'da-DK': 'Data ikke fundet !',
    'ar-AR': 'لم يتم العثور على بيانات !',
    'pt-PT': 'Dados não encontrados!',
    'es-ES': '¡Datos no encontrados!',
    'sv-SE': 'Data ej hittad!',
    'sv-FI': 'Data hittades inte!',
    'hu-HU': 'Adat nem található!',
    'el-EL': 'Τα δεδομένα δεν βρέθηκαν !',
    'no-NO': 'Data ikke funnet!',
    'ru-RU': 'Данные не найдены!',
    'cs-CZ': 'Data nebyla nalezena!',
    'sk-SK': 'Údaje sa nenašli!',
    'sl-SL': 'Podatkov ni mogoče najti!',
    'ko-KO': '데이터를 찾을 수 없습니다!'
  };
  '4111' = {
    'en-US': 'Invitation data not found for emailId in invitation list',
    'nl-NL': 'Invitation data not found for emailId in invitation list',
    'fr-FR': 'Invitation data not found for emailId in invitation list',
    'de-DE': 'Invitation data not found for emailId in invitation list',
    'pl-PL': 'Invitation data not found for emailId in invitation list',
    'zh-ZH': 'Invitation data not found for emailId in invitation list',
    'it-IT': 'Invitation data not found for emailId in invitation list',
    'tr-TR': 'Davetiye listesinde e-postayla gönderilenler için davet verileri bulunamadı',
    'fi-FI': 'Kutsu tietoja ei löydy sähköpostitse kutsu luettelosta',
    'da-DK': 'Invitationsdata er ikke fundet for emailmodtagerne i invitationslisten',
    'ar-AR': 'Invitation data not found for emailId in invitation list',
    'pt-PT': 'Os dados para o convite deste destinatário não foram encontrados na lista de convites',
    'es-ES': 'Invitation data not found for emailId in invitation list',
    'sv-SE': 'Invitation data not found for emailId in invitation list',
    'sv-FI': 'Invitation data not found for emailId in invitation list',
    'hu-HU': 'Invitation data not found for emailId in invitation list',
    'el-EL': 'Τα δεδομένα πρόσκλησης δεν βρέθηκαν για αποστολή μέσω ηλεκτρονικού ταχυδρομείου στη λίστα προσκλήσεων',
    'no-NO': 'Invitation data not found for emailId in invitation list',
    'ru-RU': 'Invitation data not found for emailId in invitation list',
    'cs-CZ': 'Invitation data not found for emailId in invitation list',
    'sk-SK': 'Invitation data not found for emailId in invitation list',
    'sl-SL': 'Invitation data not found for emailId in invitation list',
    'ko-KO': 'Invitation data not found for emailId in invitation list'
  };
  '4110' = {
    'en-US': 'User not found !',
    'nl-NL': 'Gebruiker niet gevonden !',
    'fr-FR': 'Utilisateur non trouvé !',
    'de-DE': 'Benutzer nicht gefunden !',
    'pl-PL': 'Użytkownik nie znaleziony !',
    'zh-ZH': '找不到用户！',
    'it-IT': 'Utente non trovato !',
    'tr-TR': 'Kullanıcı bulunamadı !',
    'fi-FI': 'Käyttäjää ei löydetty!',
    'da-DK': 'Bruger ikke fundet !',
    'ar-AR': 'المستخدم ليس موجود !',
    'pt-PT': 'Utilizador não encontrado!',
    'es-ES': '¡Usuario  no encontrado!',
    'sv-SE': 'Användaren ej hittad!',
    'sv-FI': 'Användaren hittades inte !',
    'hu-HU': 'Felhasználó nem található!',
    'el-EL': 'Ο χρήστης δεν βρέθηκε !',
    'no-NO': 'Bruker ikke funnet !',
    'ru-RU': 'Пользователь не найден !',
    'cs-CZ': 'Uživatel nenalezen !',
    'sk-SK': 'Užívateľ nenájdený!',
    'sl-SL': 'Uporabnik ni najden !',
    'ko-KO': '사용자를 찾을 수 없습니다!'
  };
  '4210' = {
    'en-US': 'Organization not found !',
    'nl-NL': 'Organisatie niet gevonden!',
    'fr-FR': 'Organisation non trouvée!',
    'de-DE': 'Organisation nicht gefunden!',
    'pl-PL': 'Organizacja nie znaleziona!',
    'zh-ZH': '找不到组织！',
    'it-IT': 'Organizzazione non trovata!',
    'tr-TR': 'Kuruluş bulunamadı!',
    'fi-FI': 'Organisaatiota ei löytnyt!',
    'da-DK': 'Organisation er ikke fundet !',
    'ar-AR': 'منظمة غير موجود!',
    'pt-PT': 'A organização não foi encontrada!',
    'es-ES': '¡Organización no encontrada!',
    'sv-SE': 'Organisation ej hittad!',
    'sv-FI': 'Organisationen hittades inte!',
    'hu-HU': 'A szervezet nem található!',
    'el-EL': 'Ο οργανισμός δεν βρέθηκε!',
    'no-NO': 'Organisasjon ikke funnet!',
    'ru-RU': 'Организация не найдена!',
    'cs-CZ': 'Organizace nebyla nalezena!',
    'sk-SK': 'Organizácia sa nenašla!',
    'sl-SL': 'Organizacije ni mogoče najti!',
    'ko-KO': '조직을 찾을 수 없습니다!'
  };
  '4310' = {
    'en-US': 'Site not found !',
    'nl-NL': 'Site niet gevonden!',
    'fr-FR': 'Site non trouvé!',
    'de-DE': 'Seite nicht gefunden!',
    'pl-PL': 'Nie znaleziono strony!',
    'zh-ZH': '找不到网站！',
    'it-IT': 'Sito non trovato!',
    'tr-TR': 'Site bulunamadı!',
    'fi-FI': 'Kohdetta ei löytynyt!',
    'da-DK': 'Lokation ikke fundet !',
    'ar-AR': 'الموقع غير موجود!',
    'pt-PT': 'O site não foi encontrado!',
    'es-ES': 'Sitio no encontrado\n',
    'sv-SE': 'Objekt hittades inte!',
    'sv-FI': 'Webbplatsen hittades inte!',
    'hu-HU': 'Oldal nem található!\n',
    'el-EL': 'Η τοποθεσία δεν βρέθηκε!',
    'no-NO': 'Nettstedet ikke funnet!',
    'ru-RU': 'Сайт не найден!',
    'cs-CZ': 'Místo nebylo nalezeno!',
    'sk-SK': 'Objekt nenájdený!',
    'sl-SL': 'Spletna stran ni našla!',
    'ko-KO': '사이트를 찾을 수 없습니다!'
  };
  '4410' = {
    'en-US': 'SubSite not found !',
    'nl-NL': 'SubSite niet gevonden!',
    'fr-FR': 'Sous-site non trouvé!',
    'de-DE': 'SubSite nicht gefunden!',
    'pl-PL': 'SubSite nie znaleziony!',
    'zh-ZH': '找不到SubSite！',
    'it-IT': 'SubSite non trovato!',
    'tr-TR': 'Yan site bulunamadı!',
    'fi-FI': 'Alakohdetta ei löytynyt!',
    'da-DK': 'Underlokation ikke fundet !',
    'ar-AR': 'SubSite غير موجود!',
    'pt-PT': 'Sub-site não encontrado!',
    'es-ES': '¡Subcentro no encontrado!',
    'sv-SE': 'Under-objekt ej hittad!',
    'sv-FI': 'SubSite inte hittat!',
    'hu-HU': 'A SubSite nem található!',
    'el-EL': 'Η δευτερεύουσα τοποθεσία δεν βρέθηκε !',
    'no-NO': 'SubSite ikke funnet!',
    'ru-RU': 'SubSite не найден!',
    'cs-CZ': 'SubSite nebyl nalezen!',
    'sk-SK': 'Pod-objekt nenájdený!',
    'sl-SL': 'SubSite ni mogoče najti!',
    'ko-KO': '서브 사이트를 찾을 수 없습니다!'
  };
  '4510' = {
    'en-US': 'Solution not found',
    'nl-NL': 'Oplossing niet gevonden',
    'fr-FR': 'Solution non trouvée',
    'de-DE': 'Lösung nicht gefunden',
    'pl-PL': 'Nie znaleziono rozwiązania',
    'zh-ZH': '找不到解决方案',
    'it-IT': 'Soluzione non trovata',
    'tr-TR': 'Çözüm bulunamadı',
    'fi-FI': 'Palvelua ei löydy',
    'da-DK': 'Løsning ikke fundet',
    'ar-AR': 'لم يتم العثور على الحل',
    'pt-PT': 'Solução não encontrada',
    'es-ES': 'Solución no encontrada',
    'sv-SE': 'Lösning ej hittad',
    'sv-FI': 'Lösning ej hittad',
    'hu-HU': 'Megoldás nem található',
    'el-EL': 'Η λύση δεν βρέθηκε',
    'no-NO': 'Løsning ikke funnet',
    'ru-RU': 'Решение не найдено',
    'cs-CZ': 'Řešení nebylo nalezeno',
    'sk-SK': 'Riešenie sa nenašlo',
    'sl-SL': 'Rešitve ni bilo mogoče najti',
    'ko-KO': '해결책을 찾을 수 없음'
  };
  '4511' = {
    'en-US': 'Parent organization does not have solutions',
    'nl-NL': 'Ouderorganisatie heeft geen oplossingen',
    'fr-FR': 'L\'organisation mère n\'a pas de solutions',
    'de-DE': 'Elternorganisation hat keine Lösungen',
    'pl-PL': 'Organizacja macierzysta nie ma rozwiązań',
    'zh-ZH': '家长组织没有解决方案',
    'it-IT': 'L\'organizzazione principale non ha soluzioni',
    'tr-TR': 'Ana kuruluşun çözümleri yok',
    'fi-FI': 'Isäntäorganisaatiolla ei ole palveluita',
    'da-DK': 'Overordnet organisation har ingen løsninger',
    'ar-AR': 'لا يوجد لدى المنظمة الأم حلول',
    'pt-PT': 'A organização mãe não tem soluções',
    'es-ES': 'La empresa matriz no tiene soluciones.',
    'sv-SE': 'Huvudorganisation har inga lösningar',
    'sv-FI': 'Föräldraorganisationen har inga lösningar',
    'hu-HU': 'A anya vállalatnak nincs megoldása',
    'el-EL': 'Ο γονικός οργανισμός δεν έχει λύσεις',
    'no-NO': 'Foreldreorganisasjonen har ikke løsninger',
    'ru-RU': 'У родительской организации нет решений',
    'cs-CZ': 'Rodičovská organizace nemá řešení',
    'sk-SK': 'Materská organizácia nemá riešenia',
    'sl-SL': 'Matična organizacija nima rešitev',
    'ko-KO': '학부모 단체에는 해결책이 없습니다.'
  };
  '4512' = {
    'en-US': 'Solution not found for User!',
    'nl-NL': 'Oplossing niet gevonden voor Gebruiker!',
    'fr-FR': 'Solution non trouvée pour l\'utilisateur!',
    'de-DE': 'Lösung nicht für Benutzer gefunden!',
    'pl-PL': 'Nie znaleziono rozwiązania dla użytkownika!',
    'zh-ZH': '找不到用户的解决方案！',
    'it-IT': 'Soluzione non trovata per l\'utente!',
    'tr-TR': 'Kullanıcı için çözüm bulunamadı!',
    'fi-FI': 'Palvelua ei löytynyt käyttäjälle!',
    'da-DK': 'Løsning ikke fundet for bruger !',
    'ar-AR': 'لم يتم العثور على الحل للمستخدم!',
    'pt-PT': 'A solução para este utilizador não foi encontrada!',
    'es-ES': 'Solución no encontrada para este usuario',
    'sv-SE': 'Ingen lösning hittades för användaren!',
    'sv-FI': 'Lösning hittades inte för användaren!',
    'hu-HU': 'A felhasználó nem találja meg a megoldást!',
    'el-EL': 'Η λύση δεν βρέθηκε για το χρήστη!',
    'no-NO': 'Løsning ikke funnet for bruker!',
    'ru-RU': 'Решение не найдено для пользователя!',
    'cs-CZ': 'Řešení nebylo nalezeno u uživatele!',
    'sk-SK': 'Riešenie nebolo nájdené pre používateľa!',
    'sl-SL': 'Rešitev ni bila najdena za uporabnika!',
    'ko-KO': '사용자를위한 해결책이 없습니다!'
  };
  '4513' = {
    'en-US': 'Solution not present at Organization ! ',
    'nl-NL': 'Oplossing niet aanwezig bij Organisatie!',
    'fr-FR': 'Solution non présente à l\'organisation!',
    'de-DE': 'Lösung nicht vorhanden bei Organisation!',
    'pl-PL': 'Rozwiązanie nieobecne w organizacji!',
    'zh-ZH': '组织中没有解决方案！',
    'it-IT': 'Soluzione non presente nell\'organizzazione!',
    'tr-TR': 'Çözüm Kuruluşta mevcut değil!',
    'fi-FI': 'Palvelua ei ole organisaatiossa!',
    'da-DK': 'Løsning er ikke til stede i organisationen !',
    'ar-AR': 'الحل غير موجود في المنظمة!',
    'pt-PT': 'A solução não está presente nesta organização!',
    'es-ES': '¡Solución no presentada a la organización!',
    'sv-SE': 'Lösning finns ej hos organisation!',
    'sv-FI': 'Lösning inte närvarande hos organisationen!',
    'hu-HU': 'A megoldás nem jelenik meg a Szervezetben!',
    'el-EL': 'Η λύση δεν υπάρχει στον Οργανισμό!',
    'no-NO': 'Løsning ikke tilstede på organisasjonen!',
    'ru-RU': 'Решение отсутствует в Организации!',
    'cs-CZ': 'Řešení není v organizaci k dispozici!',
    'sk-SK': 'Riešenie v organizácii neexistuje!',
    'sl-SL': 'Rešitev ni prisotna pri Organizaciji!',
    'ko-KO': '조직에 솔루션이 없습니다!'
  };
  '4514' = {
    'en-US': 'Self solution assignment is not allowed !',
    'nl-NL': 'Zelfoplossingsopdracht is niet toegestaan!',
    'fr-FR': 'L\'affectation de solution personnelle n\'est pas autorisée!',
    'de-DE': 'Selbstlösungszuweisung ist nicht erlaubt!',
    'pl-PL': 'Przypisywanie własnego rozwiązania jest niedozwolone!',
    'zh-ZH': '不允许自行解决方案！',
    'it-IT': 'L\'assegnazione della soluzione autonoma non è consentita!',
    'tr-TR': 'Kendi kendine çözüm atamasına izin verilmiyor!',
    'fi-FI': 'Palvelun asettamien ei ole sallittua!',
    'da-DK': 'Egne tildelte løsninger er ikke tilladt !',
    'ar-AR': 'لا يسمح بتعيين الحل الذاتي!',
    'pt-PT': 'Não é permitida a auto atribuição de soluções!',
    'es-ES': '¡La asignación de auto solución no está permitida!',
    'sv-SE': 'Tilldelning av självlösning inte tillåten!',
    'sv-FI': 'Självlösningstilldelning är inte tillåtet!',
    'hu-HU': 'Ön magunk megadása nem megengedett!',
    'el-EL': 'Δεν επιτρέπεται η εκχώρηση αυτόματης λύσης !',
    'no-NO': 'Selvløsningstildeling er ikke tillatt!',
    'ru-RU': 'Самостоятельное решение не допускается!',
    'cs-CZ': 'Přiřazení vlastního řešení není povoleno!',
    'sk-SK': 'Priradenie vlastného riešenia nie je povolené!',
    'sl-SL': 'Dodelitev lastne rešitve ni dovoljena!',
    'ko-KO': '자체 솔루션 할당이 허용되지 않습니다!'
  };
  '4610' = {
    'en-US': 'Feature not found !',
    'nl-NL': 'Functie niet gevonden!',
    'fr-FR': 'Fonction non trouvée!',
    'de-DE': 'Feature nicht gefunden!',
    'pl-PL': 'Funkcja nie znaleziona!',
    'zh-ZH': '功能未找到！',
    'it-IT': 'Funzionalità non trovata!',
    'tr-TR': 'Özellik bulunamadı!',
    'fi-FI': 'Ominaisuutta ei löydetty!',
    'da-DK': 'Egenskab ikke fundet !',
    'ar-AR': 'الميزة غير موجودة!',
    'pt-PT': 'Característica não encontrada!',
    'es-ES': '¡Característica  no encontrado!',
    'sv-SE': 'Funktion ej hittat!',
    'sv-FI': 'Funktionen hittades inte!',
    'hu-HU': 'Funkciók nem találhatóak!',
    'el-EL': 'Η δυνατότητα δεν βρέθηκε !',
    'no-NO': 'Funksjonen ble ikke funnet!',
    'ru-RU': 'Функция не найдена!',
    'cs-CZ': 'Funkce nebyla nalezena!',
    'sk-SK': 'Funkcia sa nenašla!',
    'sl-SL': 'Funkcija ni bila najdena!',
    'ko-KO': '기능을 찾을 수 없습니다!'
  };
  '5000' = {
    'en-US': 'Server Error !',
    'nl-NL': 'Serverfout !',
    'fr-FR': 'Erreur du serveur !',
    'de-DE': 'Serverfehler !',
    'pl-PL': 'Błąd serwera !',
    'zh-ZH': '服务器错误 ！',
    'it-IT': 'Errore del server !',
    'tr-TR': 'Server hatası !',
    'fi-FI': 'Server Virhe!',
    'da-DK': 'Serverfejl !',
    'ar-AR': 'خطأ في الخادم !',
    'pt-PT': 'Erro no servidor!',
    'es-ES': '¡Error en el servidor!',
    'sv-SE': 'Serverfel!',
    'sv-FI': 'Serverfel !',
    'hu-HU': 'Szerver hiba',
    'el-EL': 'Σφάλμα διακομιστή !',
    'no-NO': 'Serverfeil !',
    'ru-RU': 'Ошибка сервера !',
    'cs-CZ': 'Chyba serveru !',
    'sk-SK': 'Chyba servera!',
    'sl-SL': 'Napaka strežnika !',
    'ko-KO': '서버 오류 !'
  };
  '5200' = {
    'en-US': 'Database Error',
    'nl-NL': 'Databank fout',
    'fr-FR': 'Erreur de la base de données',
    'de-DE': 'Datenbankfehler',
    'pl-PL': 'Błąd bazy danych',
    'zh-ZH': '数据库错误',
    'it-IT': 'Errore di Database',
    'tr-TR': 'Veri tabanı hatası',
    'fi-FI': 'Tietokantavirhe',
    'da-DK': 'Databasefejl',
    'ar-AR': 'خطأ في قاعدة البيانات',
    'pt-PT': 'Erro na base de dados',
    'es-ES': 'Error en la base de datos',
    'sv-SE': 'Database fel',
    'sv-FI': 'Databas fel',
    'hu-HU': 'Adatbázis hiba',
    'el-EL': 'Σφάλμα βάσης δεδομένων',
    'no-NO': 'Database feil',
    'ru-RU': 'Ошибка базы данных',
    'cs-CZ': 'Chyba databáze',
    'sk-SK': 'Chyba databázy!',
    'sl-SL': 'Napaka baze podatkov',
    'ko-KO': '데이터베이스 오류'
  };
  '6000' = {
    'en-US': 'Threshold for creation of entity is exceeded',
    'nl-NL': 'Drempelwaarde voor oprichting van entiteit wordt overschreden',
    'fr-FR': 'Le seuil de création d\'entité est dépassé',
    'de-DE': 'Der Schwellenwert für die Erstellung der Entität wurde überschritten',
    'pl-PL': 'Próg dla utworzenia jednostki został przekroczony',
    'zh-ZH': '超出了创建实体的阈值',
    'it-IT': 'La soglia per la creazione di entità viene superata',
    'tr-TR': 'Varlık oluşturuma eşiği aşıldı',
    'fi-FI': 'Kokonaisuuksien luonnin raja-arvo ylitetty',
    'da-DK': 'Tidsrammen for opsætning af en enhed er overskredet',
    'ar-AR': 'تم تجاوز الحد الأدنى لإنشاء الكيان',
    'pt-PT': 'Foi excedido o limite para a criação de entidades',
    'es-ES': ' Se ha excedido el umbral para la creación de la entidad',
    'sv-SE': 'Tröskeln för skapandet av en enhet överskrids',
    'sv-FI': 'Tröskeln för att skapa en enhet överskrids',
    'hu-HU': 'A kreálás elérte a küszöbértéket.',
    'el-EL': 'Υπέρβαση του κατωφλίου για τη δημιουργία οντότητας',
    'no-NO': 'Terskel for opprettelse av enhet overskrides',
    'ru-RU': 'Превышен порог для создания сущности',
    'cs-CZ': 'Prahová hodnota pro vytvoření entity je překročena',
    'sk-SK': 'Prahová hodnota pre vytvorenie entity je prekročená',
    'sl-SL': 'Prag za ustvarjanje subjekta je prekoračen',
    'ko-KO': '엔티티 생성 임계 값이 초과되었습니다.'
  };
  '6001' = {
    'en-US': 'Password reset successfully',
    'nl-NL': 'Wachtwoord resetten succesvol',
    'fr-FR': 'Mot de passe réinitialisé avec succès',
    'de-DE': 'Passwort erfolgreich zurückgesetzt',
    'pl-PL': 'Resetowanie hasła powiodło się',
    'zh-ZH': '密码重置成功',
    'it-IT': 'Password reimpostata correttamente',
    'tr-TR': 'Şifre başarıyla sıfırlandı',
    'fi-FI': 'Salasana nollattu onnistuneesti',
    'da-DK': 'Sletning af password gennemført',
    'ar-AR': 'إعادة ضبط كلمة المرور بنجاح',
    'pt-PT': 'Redefinição de senha bem sucedida',
    'es-ES': 'Contraseña eliminada con éxito',
    'sv-SE': 'Lösenord återställt',
    'sv-FI': 'Återställ lösenord med framgång',
    'hu-HU': 'Jelszó visszaállítás sikeres',
    'el-EL': 'Η επαναφορά του κωδικού πρόσβασης ολοκληρώθηκε με επιτυχία',
    'no-NO': 'Passord tilbakestilles vellykket',
    'ru-RU': 'Сброс пароля',
    'cs-CZ': 'Heslo bylo úspěšně obnoveno',
    'sk-SK': 'Heslo bolo úspešne obnovené',
    'sl-SL': 'Ponastavitev gesla uspešno',
    'ko-KO': '비밀번호 재설정 완료'
  };
  '6002' = {
    'en-US': 'Updated successfully!',
    'nl-NL': 'Succesvol geupdatet!',
    'fr-FR': 'Mis à jour avec succés!',
    'pl-PL': 'Aktualizacja zakończona sukcesem!',
    'zh-ZH': '更新成功！',
    'de-DE': 'Erfolgreich geupdated!',
    'it-IT': 'Aggiornato con successo!',
    'tr-TR': 'Başarıyla güncellendi!',
    'fi-FI': 'Päivitetty onnistuneest!',
    'da-DK': 'Opdatering gennemført!',
    'ar-AR': 'تم التحديث بنجاح!',
    'pt-PT': 'Actualização bem sucedida!',
    'es-ES': '¡Actualizado con éxito!',
    'sv-SE': 'Uppdatering lyckades!',
    'sv-FI': 'Uppdateringen lyckades!',
    'hu-HU': 'Frissítés sikeres!',
    'el-EL': 'Ενημερώθηκε με επιτυχία!',
    'no-NO': 'Oppdatert med hell!',
    'ru-RU': 'Успешно Обновлено!',
    'cs-CZ': 'Úspěšně aktualizováno!',
    'sk-SK': 'Aktualizované úspešne!',
    'sl-SL': 'Posodobljeno uspešno!',
    'ko-KO': '성공적으로 업데이트되었습니다!'
  };
  '6003' = {
    'en-US': 'Update Failed!',
    'nl-NL': 'Bijgewerkte mislukt!',
    'fr-FR': 'Mise à jour a échoué!',
    'pl-PL': 'Aktualizacja nie powiodła się!',
    'zh-ZH': '更新失敗！',
    'de-DE': 'Update fehlgeschlagen!',
    'it-IT': 'Aggiornamento non riuscito!',
    'tr-TR': 'Güncelleme başarısız!',
    'fi-FI': 'Päivitys epäonnistui!',
    'da-DK': 'Opdatering fejlede!',
    'ar-AR': 'فشل التحديث!',
    'pt-PT': 'Erro na actualização!',
    'es-ES': '¡Actualización fallida!',
    'sv-SE': 'Uppdatering misslyckades!',
    'sv-FI': 'Uppdateringen misslyckades!',
    'hu-HU': 'Frissítés sikertelen!',
    'el-EL': 'Η ενημέρωση απέτυχε!',
    'no-NO': 'Oppdatering mislyktes!',
    'ru-RU': 'Не удалось обновить!',
    'cs-CZ': 'Aktualizace selhala!',
    'sk-SK': 'Aktualizacia neuspešná!',
    'sl-SL': 'Posodobitev ni uspela!',
    'ko-KO': '업데이트가 실패!'
  };
  '6004' = {
    'en-US': 'Deleted successfully!',
    'nl-NL': 'Met succes verwijderd!',
    'fr-FR': 'Supprimé avec succès!',
    'pl-PL': 'Skutecznie usunięte!',
    'zh-ZH': '創建成功！',
    'de-DE': 'Erfolgreich gelöscht!',
    'it-IT': 'Cancellato con successo!',
    'tr-TR': 'Başarıyla silindi!',
    'fi-FI': 'Poistettu onnistuneesti!',
    'da-DK': 'Sletning gennemført!',
    'ar-AR': 'حذف بنجاح!',
    'pt-PT': 'Apagado com sucesso!',
    'es-ES': '¡Borrado con éxito!',
    'sv-SE': 'Raderades!',
    'sv-FI': 'Raderas framgångsrikt!',
    'hu-HU': 'Törlés sikeres!',
    'el-EL': 'Διαγράφηκε με επιτυχία!',
    'no-NO': 'Slettet med hell!',
    'ru-RU': 'Удалено успешно!',
    'cs-CZ': 'Bylo úspěšně odstraněno!',
    'sk-SK': 'Úspešne odstránené!',
    'sl-SL': 'Uspešno izbrisano!',
    'ko-KO': '성공적으로 삭제되었습니다.'
  };
  '6006' = {
    'en-US': 'Action failed!',
    'nl-NL': 'Actie: mislukt!',
    'fr-FR': 'Action échoué!',
    'pl-PL': 'Akcja: nieudana!',
    'zh-ZH': '行動失敗！',
    'de-DE': 'Aktion: fehlgeschlagen!',
    'it-IT': 'Azione fallita!',
    'tr-TR': 'Eylem başarısız!',
    'fi-FI': 'Toiminto epäonnistui!',
    'da-DK': 'Handling fejlede!',
    'ar-AR': 'العمل: فشل!',
    'pt-PT': 'Erro na acção!',
    'es-ES': '¡Acción fallida!',
    'sv-SE': 'Utförande misslyckades!',
    'sv-FI': 'Handling misslyckades!',
    'hu-HU': 'A művelet sikertelen!',
    'el-EL': 'Η ενέργεια απέτυχε!',
    'no-NO': 'Handlingen mislyktes!',
    'ru-RU': 'Действие не выполнено!',
    'cs-CZ': 'Akce se nezdařila!',
    'sk-SK': 'Akcia zlyhala!',
    'sl-SL': 'Dejanje ni uspelo!',
    'ko-KO': '작업이 실패했습니다!'
  };
  '6007' = {
    'en-US': 'Site Created Successfully!',
    'nl-NL': 'Site succesvol gemaakt!',
    'fr-FR': 'Site créé avec succès!',
    'pl-PL': 'Strona utworzona pomyślnie!',
    'zh-ZH': '网站创建成功！',
    'de-DE': 'Website erfolgreich erstellt!',
    'it-IT': 'Sito creato con successo!',
    'tr-TR': 'Site Başarıyla Oluşturuldu!',
    'fi-FI': 'Kohde luotu onnistuneesti!',
    'da-DK': 'Oprettelse af lokation gennemført!',
    'ar-AR': 'تم انشاء الموقع بنجاح!',
    'pt-PT': 'Site criado com sucesso!',
    'es-ES': '¡Centro creado con éxito!',
    'sv-SE': 'Objekt skapades!',
    'sv-FI': 'Webbplats skapad framgångsrikt!',
    'hu-HU': 'Sikeresen létrehozott hely!',
    'el-EL': 'Η τοποθεσία δημιουργήθηκε με επιτυχία!',
    'no-NO': 'Nettstedet ble opprettet suksessivt!',
    'ru-RU': 'Сайт создан успешно!',
    'cs-CZ': 'Stránky byly vytvořeny úspěšně!',
    'sk-SK': 'Objekt vytvorený úspešne!',
    'sl-SL': 'Ustvarjeno spletno mesto uspešno',
    'ko-KO': '사이트가 성공적으로 생성되었습니다!'
  };
  '6008' = {
    'en-US': 'Please select atleast one node from hierarchy and sites',
    'nl-NL': 'Selecteer ten minste één knooppunt uit de hiërarchie en sites',
    'fr-FR': 'Veuillez sélectionner au moins un nœud parmi la hiérarchie et les sites.',
    'pl-PL': 'Wybierz co najmniej jeden węzeł z hierarchii i witryn',
    'zh-ZH': '请从层次结构和站点中选择至少一个节点',
    'de-DE': 'Bitte wählen Sie mindestens einen Knoten aus der Hierarchie und den Sites aus',
    'it-IT': 'Seleziona almeno un nodo dalla gerarchia e dai siti',
    'tr-TR': 'Lütfen hiyerarşi ve sitelerden en az bir düğüm seçin',
    'fi-FI': 'Valitse vähintää yksi tietue hierarkiasta ja kohteista',
    'da-DK': 'Vælg minimum en node fra hieraki og lokation',
    'ar-AR': 'يرجى تحديد عقدة واحدة على الأقل من التسلسل الهرمي والمواقع',
    'pt-PT': 'Por favor seleccione pelo menos um nó a partir da hierarquia e sites',
    'es-ES': 'Por favor, elija al menos un nodo de la jerarquía y sitios',
    'sv-SE': 'Vänligen väl minst en kategori från hierarki och objekt',
    'sv-FI': 'Välj minst en nod från hierarkin och webbplatser',
    'hu-HU': 'Kérjük, válasszon legalább egy opciót a felsorolt oldalakból',
    'el-EL': 'Επιλέξτε atleast έναν κόμβο από την ιεραρχία και τις τοποθεσίες',
    'no-NO': 'Vennligst velg minst én node fra hierarki og nettsteder',
    'ru-RU': 'Выберите по крайней мере один узел из иерархии и сайтов',
    'cs-CZ': 'Vyberte prosím alespoň jeden uzel z hierarchie a stránek',
    'sk-SK': 'Vyberte aspoň jeden uzol z hierarchie a objektov',
    'sl-SL': 'Iz hierarhije in spletnih mest izberite vsaj eno vozlišče',
    'ko-KO': '계층 구조 및 사이트에서 적어도 하나의 노드를 선택하십시오.'
  };
  '8001' = {
    'en-US': 'Your Login session has expired! Please login again',
    'nl-NL': 'Uw login-sessie is verlopen! Log alsjeblieft nogmaals in',
    'fr-FR': 'Votre Login session a expiré! Veuillez vous reconnecter',
    'de-DE': 'Ihre Login-Sitzung ist abgelaufen! Bitte melden Sie sich erneut an',
    'pl-PL': 'Twoja sesja logowania wygasła! Proszę, zaloguj się ponownie',
    'zh-ZH': '您的登录会话已过期！请再次登录',
    'it-IT': 'La sessione di accesso è scaduta! Per favore esegui l\'accesso di nuovo',
    'tr-TR': 'Giriş oturumunuzun süresi doldu! Lütfen tekrar giriş yapın',
    'fi-FI': 'Istuntosi on vanhentunut! Kirjaudu uudelleen.',
    'da-DK': 'Din log in tid er udløbet! Log in igen',
    'ar-AR': 'انتهت صلاحية جلسة تسجيل الدخول الخاصة بك! الرجاد الدخول على الحساب من جديد',
    'pt-PT': 'A sessão expirou! Por favor efectuar novo login',
    'es-ES': '¡Su registro ha expirado! Por favor, regístrese de nuevo',
    'sv-SE': 'Din inloggning har löpt ut! Vänligen logga in igen',
    'sv-FI': 'Din inloggningssession har gått ut! Vänligen logga in igen',
    'hu-HU': 'A belépési munkamenete lejárt! Kérem, jelentkezzem be újra.',
    'el-EL': 'Η περίοδος σύνδεσης έχει λήξει! Παρακαλώ συνδεθείτε ξανά',
    'no-NO': 'Innloggingsøkten din er utløpt!Vennligst logg inn igjen',
    'ru-RU': 'Срок действия вашего входа окончен!Пожалуйста, войдите снова',
    'cs-CZ': 'Vaše přihlašovací relace vypršela!Přihlaste se znovu',
    'sk-SK': 'Platnosť Vášho prihlásenia vypršala! Prihláste sa znova',
    'sl-SL': 'Vaša prijava je potekla!Prosimo, prijavite se ponovno',
    'ko-KO': '로그인 세션이 만료되었습니다!다시 로그인하십시오.'
  };
  '8002' = {
    'en-US': 'Error !',
    'nl-NL': 'Fout !',
    'fr-FR': 'Erreur !',
    'de-DE': 'Error !',
    'pl-PL': 'Błąd!',
    'zh-ZH': '错误！',
    'it-IT': 'Errore!',
    'tr-TR': 'Hata !',
    'fi-FI': 'Virhe!',
    'da-DK': 'Fejl !',
    'ar-AR': 'خطأ!',
    'pt-PT': 'Erro!',
    'es-ES': '¡Error!',
    'sv-SE': 'Fel!',
    'sv-FI': 'Fel!',
    'hu-HU': 'Hiba!',
    'el-EL': 'Σφάλμα!',
    'no-NO': 'Feil!',
    'ru-RU': 'Ошибка !',
    'cs-CZ': 'Chyba!',
    'sk-SK': 'Chyba!',
    'sl-SL': 'Napaka!',
    'ko-KO': '오류!'
  };
  '8003' = {
    'en-US': 'An error occurred !',
    'nl-NL': 'Er is een fout opgetreden !',
    'fr-FR': 'Une erreur est survenue!',
    'de-DE': 'Ein Fehler ist aufgetreten !',
    'pl-PL': 'Wystąpił błąd !',
    'zh-ZH': '发生错误！',
    'it-IT': 'Si è verificato un errore !',
    'tr-TR': 'Bir hata oluştu !',
    'fi-FI': 'Virhe!',
    'da-DK': 'Der er opstået en fejl !',
    'ar-AR': 'حدث خطأ !',
    'pt-PT': 'Ocorreu um erro!',
    'es-ES': '¡Se ha producido un error!',
    'sv-SE': 'Ett fel uppstod!',
    'sv-FI': 'Ett fel uppstod!',
    'hu-HU': 'Hiba történt!',
    'el-EL': 'Παρουσιάστηκε σφάλμα !',
    'no-NO': 'En feil oppstod !',
    'ru-RU': 'Произошла ошибка !',
    'cs-CZ': 'Vyskytla se chyba !',
    'sk-SK': 'Nastala chyba!',
    'sl-SL': 'Pojavila se je napaka !',
    'ko-KO': '오류가 발생했습니다 !'
  };
  '8004' = {
    'en-US': 'User session expired redirect to login!',
    'nl-NL': 'Gebruikerssessie verlopen omleiding om in te loggen!',
    'fr-FR': 'Session utilisateur expirée rediriger pour vous Login!',
    'de-DE': 'Benutzersitzung abgelaufen Umleitung zum Login!',
    'pl-PL': 'Sesja użytkownika wygasła przekierowanie do logowania!',
    'zh-ZH': '用户会话已过期重定向到登录！',
    'it-IT': 'Sessione utente scaduta reindirizzare per accedere!',
    'tr-TR': 'Kullanıcı oturumunun süresi doldu, giriş için yeniden yönlendirin!',
    'fi-FI': 'Istunto vanhentunut, kirjaudu uudelleen!',
    'da-DK': 'Brugerens tid er udløbet. Gå til login!',
    'ar-AR': 'انتهت صلاحية جلسة المستخدم لإعادة تسجيل الدخول!',
    'pt-PT': 'A sessão expirou, direccionar para login!',
    'es-ES': '¡Sesión agotada, vuelva a registrarse!',
    'sv-SE': 'Användersession har gått ut och omdirigeras till inloggning!',
    'sv-FI': 'Användarsessionen utlöste omdirigering till inloggning!',
    'hu-HU': 'A felhasználói munkamenet lejárt átirányítása a bejelentkezéshez!',
    'el-EL': 'Η περίοδος λειτουργίας χρήστη έληξε ανακατεύθυνση για σύνδεση!',
    'no-NO': 'Brukerøkt utløpt omdirigering til innlogging!',
    'ru-RU': 'Пользовательский сеанс истек перенаправлен на логин!',
    'cs-CZ': 'Uživatelská relace skončila přesměrováním na přihlášení!',
    'sk-SK': 'Relácia používateľa vypršala presmerovanie na prihlásenie!',
    'sl-SL': 'Uporabniški seji je potekel preusmeritev, da se prijavite!',
    'ko-KO': '사용자 세션이 만료 된 로그인으로 리디렉션되었습니다.'
  };
  '8005' = {
    'en-US': 'Something went wrong! Please try again in sometime!',
    'nl-NL': 'Er is iets fout gegaan! Probeer het later nog een keer!',
    'fr-FR': 'Quelque chose a mal tourné! S\'il vous plaît essayez à nouveau dans un moment!',
    'de-DE': 'Etwas ist schief gelaufen! Bitte versuchen Sie es später erneut!',
    'pl-PL': 'Coś poszło nie tak! Spróbuj ponownie za jakiś czas!',
    'zh-ZH': '有些不对劲！请稍后再试一次！',
    'it-IT': 'Qualcosa è andato storto! Per favore riprova in qualche momento!',
    'tr-TR': 'Bir şeyler yanlış gitti! Lütfen bir ara tekrar deneyin!',
    'fi-FI': 'Jokin meni vikaan! Kokeile uudestaan hetken kuluttua!',
    'da-DK': 'Der opstod en fejl! Prøv venligst senere!',
    'ar-AR': 'هناك خطأ ما! يرجى المحاولة مرة أخرى في وقت ما!',
    'pt-PT': 'Erro detectado! Por favor tente novamente mais tarde!',
    'es-ES': '¡Algo fue mal! ¡Por favor, inténtelo más tardes!',
    'sv-SE': 'Någonting gick fel! Vänligen försök igen senare!',
    'sv-FI': 'Något gick fel! Var god försök igen någon gång!',
    'hu-HU': 'Valami elromlott! Kérjük, próbálja meg újra valamikor!',
    'el-EL': 'Κάτι πήγε στραβά! Δοκιμάστε ξανά κάποια στιγμή!',
    'no-NO': 'Noe gikk galt!Vennligst prøv igjen på en gang!',
    'ru-RU': 'Что-то пошло не так!Повторите попытку в какой-то момент!',
    'cs-CZ': 'Něco se pokazilo!Zkuste to prosím znovu někdy!',
    'sk-SK': 'Niečo sa pokazilo! Skúste to znova!',
    'sl-SL': 'Nekaj je šlo narobe!Poskusite znova v nekem trenutku!',
    'ko-KO': '문제가 발생했습니다.언젠가 다시 시도하십시오!'
  };
  '8006' = {
    'en-US': 'Image Upload Failed !',
    'nl-NL': 'Beeld uploaden mislukt!',
    'fr-FR': 'Échec du téléchargement de l\'image!',
    'de-DE': 'Bild hochladen fehlgeschlagen!',
    'pl-PL': 'Przesyłanie zdjęć nie powiodło się!',
    'zh-ZH': '图片上传失败！',
    'it-IT': 'Caricamento immagine fallito!',
    'tr-TR': 'Resim Yükleme Başarısız!',
    'fi-FI': 'Kuvan lataamien epäonnistui!',
    'da-DK': 'Upload af image fejlede !',
    'ar-AR': 'فشل تحميل الصورة!',
    'pt-PT': 'Erro no carregamento de imagem!',
    'es-ES': '¡Carga de imagen fallida!',
    'sv-SE': 'Bild uppladdning misslyckades!',
    'sv-FI': 'Bildöverföring misslyckades!',
    'hu-HU': 'A kép feltöltése sikertelen!',
    'el-EL': 'Η αποστολή εικόνας απέτυχε !',
    'no-NO': 'Bildeopplastning mislyktes!',
    'ru-RU': 'Ошибка загрузки изображений!',
    'cs-CZ': 'Nahrávání obrázku se nezdařilo!',
    'sk-SK': 'Nepodarilo sa načítať obrázok.',
    'sl-SL': 'Prenos slike ni uspel!',
    'ko-KO': '이미지 업로드 실패!'
  };
  '8007' = {
    'en-US': 'Updation failed!',
    'nl-NL': 'Updaten mislukt!',
    'fr-FR': 'La mise à jour a échoué!',
    'de-DE': 'Aktualisierung fehlgeschlagen!',
    'pl-PL': 'Aktualizacja nie powiodła się!',
    'zh-ZH': '更新失败！',
    'it-IT': 'Aggiornamento fallito!',
    'tr-TR': 'Güncelleme başarısız!',
    'fi-FI': 'Päivitys epäonnistui!',
    'da-DK': 'Opdatering fejlede!',
    'ar-AR': 'فشل التحديث!',
    'pt-PT': 'Erro na actualização!',
    'es-ES': '¡Actualización fallida!',
    'sv-SE': 'Uppdatering misslyckades!',
    'sv-FI': 'Uppdateringen misslyckades!',
    'hu-HU': 'Frissítés sikertelen!',
    'el-EL': 'Η αναθύμωση απέτυχε!',
    'no-NO': 'Oppdateringen mislyktes!',
    'ru-RU': 'Ошибка обновления!',
    'cs-CZ': 'Aktualizace se nezdařila!',
    'sk-SK': 'Aktualizácia zlyhala!',
    'sl-SL': 'Posodobitev ni uspela!',
    'ko-KO': '업데이트하지 못했습니다!'
  };
  '8008' = {
    'en-US': 'You have exceeded your daily request quota for this API.',
    'nl-NL': 'U heeft uw dagelijkse verzoekquota voor deze API overschreden.',
    'fr-FR': 'Vous avez dépassé votre quota de requêtes quotidiennes pour cette API.',
    'de-DE': 'Sie haben Ihr tägliches Anforderungskontingent für diese API überschritten.',
    'pl-PL': 'Przekroczyłeś dzienny limit żądań dla tego interfejsu API.',
    'zh-ZH': '您已超出此API的每日请求限额。',
    'it-IT': 'Hai superato la quota di richiesta giornaliera per questa API.',
    'tr-TR': 'Bu API için günlük istek kotanızı aştınız.',
    'fi-FI': 'Olet ylittänyt päivittäisten muutosten rajan.',
    'da-DK': 'Du har overskredet din daglige grænse for forespørgsler for denne API.',
    'ar-AR': 'لقد تجاوزت حصة الطلب اليومية لواجهة برمجة التطبيقات هذه.',
    'pt-PT': 'O número máximo de pedidos diários para este API foi excedido.',
    'es-ES': 'Ha excedido la cuota diaria para esta API',
    'sv-SE': 'Du har överskridit din dagliga kvot begäran för detta API.',
    'sv-FI': 'Du har överskridit din dagliga begärankvot för detta API.',
    'hu-HU': 'Meghaladta a napi lekérést az API-ban.',
    'el-EL': 'Έχετε υπερβεί το ημερήσιο όριο αίτησης για αυτό το API.',
    'no-NO': 'Du har overskredet din daglige forespørselkvote for denne APIen.',
    'ru-RU': 'Вы превысили свою ежедневную квоту запроса для этого API.',
    'cs-CZ': 'Překročili jste svou denní kvótu žádostí pro toto rozhraní API.',
    'sk-SK': 'Pre túto API ste prekročili svoju dennú kvótu požiadaviek.',
    'sl-SL': 'Presegli ste dnevno kvoto za ta API.',
    'ko-KO': '이 API에 대한 일일 요청 할당량을 초과했습니다.'
  };
  '8009' = {
    'en-US': 'Please fill all the required fields',
    'nl-NL': 'Vul alle verplichte velden in',
    'fr-FR': 'Veuillez remplir tous les champs obligatoires',
    'de-DE': 'Bitte füllen Sie alle erforderlichen Felder aus',
    'pl-PL': 'Proszę wypełnić wszystkie wymagane pola',
    'zh-ZH': '请填写所有必填字段',
    'it-IT': 'Si prega di compilare tutti i campi richiesti',
    'tr-TR': 'Lütfen gerekli tüm alanları doldurun',
    'fi-FI': 'Täytä kaikki pakolliset kentät',
    'da-DK': 'Udfyld venligst alle de obligatoriske felter!',
    'ar-AR': 'يرجى ملء جميع الحقول المطلوبة',
    'pt-PT': 'Por favor preencha todos os campos requeridos',
    'es-ES': 'Por favor complete los campos requeridos',
    'sv-SE': 'Vänligen fyll i alla obligatoriska fält',
    'sv-FI': 'Vänligen fyll i alla obligatoriska fält',
    'hu-HU': 'Kérjük, töltse ki az összes szükséges mezőt',
    'el-EL': 'Συμπληρώστε όλα τα απαιτούμενα πεδία',
    'no-NO': 'Vennligst fyll ut alle obligatoriske felt',
    'ru-RU': 'Пожалуйста, заполните все обязательные поля',
    'cs-CZ': 'Vyplňte prosím všechna povinná pole',
    'sk-SK': 'Vyplňte všetky povinné údaje!',
    'sl-SL': 'Prosimo, izpolnite vsa zahtevana polja',
    'ko-KO': '필수 입력란을 모두 작성하십시오.'
  };
  '8010' = {
    'en-US': 'Sub sites cannot be created for this site',
    'nl-NL': 'Subsites kunnen niet worden gemaakt voor deze site',
    'fr-FR': 'Les sous-sites ne peuvent pas être créés pour ce site',
    'de-DE': 'Unterwebsites können für diese Site nicht erstellt werden',
    'pl-PL': 'Nie można utworzyć podstron dla tej witryny',
    'zh-ZH': '无法为此站点创建子站点',
    'it-IT': 'I siti secondari non possono essere creati per questo sito',
    'tr-TR': 'Bu site için alt siteler oluşturulamıyor',
    'fi-FI': 'Kohteelle ei voi luoda alakohteita.',
    'da-DK': 'Underlokationer kan ikke oprettes for denne lokation',
    'ar-AR': 'لا يمكن إنشاء مواقع فرعية لهذا الموقع',
    'pt-PT': 'Neste site não podem ser criados sub-sites ',
    'es-ES': 'No se pueden crear sub centros para este centro',
    'sv-SE': ' Under-objekt kan inte skapas för detta objekt.',
    'sv-FI': 'Webbplatser kan inte skapas för den här webbplatsen',
    'hu-HU': 'Az aloldalak létrehozása nem hozhatóak létre ezen az oldalon.',
    'el-EL': 'Δεν είναι δυνατή η δημιουργία δευτερευθυνών τοποθεσιών για αυτήν την τοποθεσία',
    'no-NO': 'Nettsteder kan ikke opprettes for dette nettstedet',
    'ru-RU': 'Подсайты не могут быть созданы для этого сайта',
    'cs-CZ': 'Pro tyto stránky nelze vytvořit podstránky',
    'sk-SK': 'Pod-objekty nie je možné vytvoriť pre tento objekt',
    'sl-SL': 'Podstrani ni mogoče ustvariti za to spletno mesto',
    'ko-KO': '이 사이트에 대해 하위 사이트를 만들 수 없습니다.'
  };
  '8200' = {
    'en-US': 'Success',
    'nl-NL': 'Succes',
    'fr-FR': 'Succès',
    'de-DE': 'Erfolg',
    'pl-PL': 'Powodzenie',
    'zh-ZH': '成功',
    'it-IT': 'Successo',
    'tr-TR': 'başarı',
    'fi-FI': 'Onnistui',
    'da-DK': 'Gennemført',
    'ar-AR': 'نجاح',
    'pt-PT': 'Sucesso',
    'es-ES': 'Éxito',
    'sv-SE': 'Succe',
    'sv-FI': 'Framgång',
    'hu-HU': 'Sikeres',
    'el-EL': 'Επιτυχία',
    'no-NO': 'Suksess',
    'ru-RU': 'успех',
    'cs-CZ': 'Úspěch',
    'sk-SK': 'Úspech',
    'sl-SL': 'Uspeh',
    'ko-KO': '성공'
  };
  '8201' = {
    'en-US': 'Organization created successfully',
    'nl-NL': 'Organisatie succesvol gemaakt',
    'fr-FR': 'Organisation créée avec succès',
    'de-DE': 'Organisation erfolgreich erstellt',
    'pl-PL': 'Organizacja została pomyślnie utworzona',
    'zh-ZH': '组织创建成功',
    'it-IT': 'Organizzazione creata con successo',
    'tr-TR': 'Kuruluş başarıyla oluşturuldu',
    'fi-FI': 'Organisaatio luotu onnistuneesti',
    'da-DK': 'Oprettelsen af organisationen gennemført',
    'ar-AR': 'منظمة تم إنشاؤها بنجاح',
    'pt-PT': 'Organização criada com sucesso',
    'es-ES': 'Organización creada satisfactoriamente',
    'sv-SE': 'Organisation skapades',
    'sv-FI': 'Organisationen skapades framgångsrikt',
    'hu-HU': 'sikeresen létrehozott szervezet.',
    'el-EL': 'Ο οργανισμός δημιουργήθηκε με επιτυχία',
    'no-NO': 'Organisasjonen ble opprettet',
    'ru-RU': 'Успешно создана организация',
    'cs-CZ': 'Organizace byla úspěšně vytvořena',
    'sk-SK': 'Organizácia bola úspešne vytvorená',
    'sl-SL': 'Organizacija je uspešno ustvarila',
    'ko-KO': '조직이 성공적으로 생성되었습니다.'
  };
  '8202' = {
    'en-US': 'Image Uploaded successfully',
    'nl-NL': 'Afbeelding succesvol geüpload',
    'fr-FR': 'Image téléchargée avec succès',
    'de-DE': 'Bild wurde erfolgreich hochgeladen',
    'pl-PL': 'Obraz został pomyślnie przesłany',
    'zh-ZH': '图像已成功上传',
    'it-IT': 'Immagine caricata con successo',
    'tr-TR': 'Resim yüklemesi başarılı',
    'fi-FI': 'Kuva ladattiin onnistuneesti',
    'da-DK': 'Upload af image gennemført',
    'ar-AR': 'تم تحميل الصورة بنجاح',
    'pt-PT': 'Imagem carregada com sucesso!',
    'es-ES': 'Imagen cargada con éxito',
    'sv-SE': 'Bilduppladdning lyckades',
    'sv-FI': 'Bilden har laddats upp',
    'hu-HU': 'Kép feltöltése sikeres',
    'el-EL': 'Η αποστολή της εικόνας ολοκληρώθηκε με επιτυχία',
    'no-NO': 'Bilde lastet opp',
    'ru-RU': 'Изображение загружено успешно',
    'cs-CZ': 'Obrázek byl úspěšně vložen',
    'sk-SK': 'Obrázok bol úspešne nahraný',
    'sl-SL': 'Slika je bila uspešno prenesena',
    'ko-KO': '성공적으로 업로드 된 이미지'
  };
  '8203' = {
    'en-US': 'Solution assigned successfully',
    'nl-NL': 'Oplossing met succes toegewezen',
    'fr-FR': 'Solution attribuée avec succès',
    'de-DE': 'Lösung erfolgreich zugewiesen',
    'pl-PL': 'Rozwiązanie przypisane pomyślnie',
    'zh-ZH': '解决方案成功分配',
    'it-IT': 'Soluzione assegnata con successo',
    'tr-TR': 'Çözüm başarıyla atandı',
    'fi-FI': 'Palvelu asetettu onnistuneesti',
    'da-DK': 'Tildeling af løsning gennemført',
    'ar-AR': 'الحل المعين بنجاح',
    'pt-PT': 'Solução atribuída com sucesso!',
    'es-ES': 'Solución asignada con éxito',
    'sv-SE': 'Lyckad tilldelning av lösning',
    'sv-FI': 'Lösningen är framgångsrik',
    'hu-HU': 'A megoldás sikeresen megtörtént',
    'el-EL': 'Η λύση που εκχωρήθηκαν με επιτυχία',
    'no-NO': 'Løsning tilordnet',
    'ru-RU': 'Решение успешно назначено',
    'cs-CZ': 'Řešení bylo úspěšně přiřazeno',
    'sk-SK': 'Riešenie bolo úspešne priradené',
    'sl-SL': 'Rešitev dodeljena uspešno',
    'ko-KO': '솔루션이 성공적으로 할당되었습니다.'
  };
  '8204' = {
    'en-US': 'Organization created successfully without solution',
    'nl-NL': 'Organisatie succesvol gemaakt zonder oplossing',
    'fr-FR': 'Organisation créée avec succès sans solution',
    'de-DE': 'Organisation erfolgreich ohne Lösung erstellt',
    'pl-PL': 'Organizacja została pomyślnie utworzona bez rozwiązania',
    'zh-ZH': '组织创建成功没有解决方案',
    'it-IT': 'Organizzazione creata con successo senza soluzione',
    'tr-TR': 'Kuruluş çözüm olmadan başarıyla oluşturuldu',
    'fi-FI': 'Organisaatio luotu onnistuneesti ilman palveua',
    'da-DK': 'Oprettelsen af organisation uden løsning er gennemført',
    'ar-AR': 'منظمة تم إنشاؤها بنجاح دون حل',
    'pt-PT': 'Organização criada com sucesso, sem solução',
    'es-ES': 'Organización creada con éxito sin solución',
    'sv-SE': 'Organisation skapad utan lösning',
    'sv-FI': 'Organisationen skapades framgångsrikt utan lösning',
    'hu-HU': 'A szervezet sikeresen létrehozott megoldás nélkül',
    'el-EL': 'Ο οργανισμός δημιουργήθηκε με επιτυχία χωρίς λύση',
    'no-NO': 'Organisasjonen ble opprettet uten løsning',
    'ru-RU': 'Организация успешно создана без решения',
    'cs-CZ': 'Organizace byla úspěšně vytvořena bez řešení',
    'sk-SK': 'Organizácia bola úspešne vytvorená bez riešenia',
    'sl-SL': 'Organizacija je uspešno ustvarila brez rešitve',
    'ko-KO': '솔루션없이 성공적으로 생성 된 조직'
  };
  '8205' = {
    'en-US': 'Updated successfully!',
    'nl-NL': 'Succesvol geupdatet!',
    'fr-FR': 'Mis à jour avec succés!',
    'de-DE': 'Erfolgreich geupdated!',
    'pl-PL': 'Aktualizacja zakończona sukcesem!',
    'zh-ZH': '更新成功！',
    'it-IT': 'Aggiornato con successo!',
    'tr-TR': 'Başarıyla güncellendi!',
    'fi-FI': 'Päivitys onnistui!',
    'da-DK': 'Opdatering gennemført!',
    'ar-AR': 'تم التحديث بنجاح!',
    'pt-PT': 'Actualização bem sucedida!',
    'es-ES': '¡Actualizado con éxito!',
    'sv-SE': 'Uppdatering lyckades!',
    'sv-FI': 'Uppdateringen lyckades!',
    'hu-HU': 'Feltöltés sikeres',
    'el-EL': 'Ενημερώθηκε με επιτυχία!',
    'no-NO': 'Oppdatert med hell!',
    'ru-RU': 'Успешно Обновлено!',
    'cs-CZ': 'Úspěšně aktualizováno!',
    'sk-SK': 'Aktualizovanie úspešne!',
    'sl-SL': 'Posodobljeno uspešno!',
    'ko-KO': '성공적으로 업데이트되었습니다!'
  };
  '8206' = {
    'en-US': 'Site created successfully without solution',
    'nl-NL': 'Site is succesvol gemaakt zonder oplossing',
    'fr-FR': 'Site créé avec succès sans solution',
    'de-DE': 'Website wurde erfolgreich ohne Lösung erstellt',
    'pl-PL': 'Strona utworzona pomyślnie bez rozwiązania',
    'zh-ZH': '在没有解决方案的情',
    'it-IT': 'Sito creato con successo senza soluzione',
    'tr-TR': 'Site çözüm olmadan başarıyla oluşturuldu',
    'fi-FI': 'Kohde luotu onnistuneesti ilman palvelua',
    'da-DK': 'Oprettelsen af lokationen uden løsning er gennemført',
    'ar-AR': 'تم إنشاء الموقع بنجاح دون حل',
    'pt-PT': 'Site criado com sucesso, sem solução',
    'es-ES': ' Centro creado con éxito sin solución',
    'sv-SE': 'Objekt skapades utan lösning',
    'sv-FI': 'Webbplatsen skapades framgångsrikt utan lösning',
    'hu-HU': 'Az oldal létrehozása sikeres, megoldás nélkül',
    'el-EL': 'Η τοποθεσία δημιουργήθηκε με επιτυχία χωρίς λύση',
    'no-NO': 'Nettstedet ble opprettet uten løsning',
    'ru-RU': 'Сайт создан без решения',
    'cs-CZ': 'Stránky byly úspěšně vytvořeny bez řešení',
    'sk-SK': 'Objekt bol úspešne vytvorený bez riešenia',
    'sl-SL': 'Spletno mesto je bilo uspešno ustvarjeno brez rešitve',
    'ko-KO': '솔루션없이 성공적으로 생성 된 사이트'
  };
  '8207' = {
    'en-US': 'Site updated successfully',
    'nl-NL': 'Site is succesvol bijgewerkt',
    'fr-FR': 'Site mis à jour avec succès',
    'de-DE': 'Die Site wurde erfolgreich aktualisiert',
    'pl-PL': 'Strona zaktualizowana pomyślnie',
    'zh-ZH': '网站已成功更新',
    'it-IT': 'Sito aggiornato correttamente',
    'tr-TR': 'Site başarıyla güncellendi',
    'fi-FI': 'Kohde päivitetty onnistuneesti',
    'da-DK': 'Opdatering af lokation gennemført',
    'ar-AR': 'تم تحديث الموقع بنجاح',
    'pt-PT': 'Site actualizado com sucesso',
    'es-ES': '¡Centro actualizado con éxito!',
    'sv-SE': 'Objekt uppdaterades.',
    'sv-FI': 'Webbplatsen uppdateras framgångsrikt',
    'hu-HU': 'A hely sikeresen frissült',
    'el-EL': 'Η τοποθεσία ενημερώθηκε με επιτυχία',
    'no-NO': 'Nettstedet er oppdatert',
    'ru-RU': 'Обновлен сайт',
    'cs-CZ': 'Stránky byly úspěšně aktualizovány',
    'sk-SK': 'Objekt bol úspešne aktualizovaný',
    'sl-SL': 'Spletna stran je uspešno posodobljena',
    'ko-KO': '사이트가 성공적으로 업데이트되었습니다.'
  };
  '8208' = {
    'en-US': 'User updated successfully',
    'nl-NL': 'Gebruiker is succesvol bijgewerkt',
    'fr-FR': 'Utilisateur mis à jour avec succès',
    'de-DE': 'Benutzer wurde erfolgreich aktualisiert',
    'pl-PL': 'Użytkownik zaktualizowany pomyślnie',
    'zh-ZH': '用户已成功更新',
    'it-IT': 'Utente aggiornato correttamente',
    'tr-TR': 'Kullanıcı başarıyla güncellendi',
    'fi-FI': 'Käyttäjä päivitetty onnistuneesti',
    'da-DK': 'Opdatering af bruger er gennemført',
    'ar-AR': 'تم تحديث المستخدم بنجاح',
    'pt-PT': 'Utilizador actualizado com sucesso',
    'es-ES': 'Usuario actualizado con éxito',
    'sv-SE': 'Uppdatering av användare lyckades',
    'sv-FI': 'Användaren uppdaterades framgångsrikt',
    'hu-HU': 'Felhasználó frissítés sikeres',
    'el-EL': 'Ο χρήστης ενημερώθηκε με επιτυχία',
    'no-NO': 'Brukeren er oppdatert vellykket',
    'ru-RU': 'Пользователь обновлен успешно',
    'cs-CZ': 'Uživatel byl úspěšně aktualizován',
    'sk-SK': 'Používateľ bol úspešne aktualizovaný',
    'sl-SL': 'Uporabnik je bil uspešno posodobljen',
    'ko-KO': '사용자가 성공적으로 업데이트했습니다.'
  };
  '8209' = {
    'en-US': 'solution assigned to site',
    'nl-NL': 'oplossing toegewezen aan de site',
    'fr-FR': 'solution affectée au site',
    'de-DE': 'Lösung zugewiesen vor Ort',
    'pl-PL': 'rozwiązanie przypisane do strony',
    'zh-ZH': '解决方案分配到网站',
    'it-IT': 'soluzione assegnata al sito',
    'tr-TR': 'Çözüm siteye atandı',
    'fi-FI': 'palvelu asetettu onnistuneesti',
    'da-DK': 'løsning er tildelt lokation',
    'ar-AR': 'الحل المخصص للموقع',
    'pt-PT': 'solução atribuída ao site',
    'es-ES': 'Solución asignada al centro',
    'sv-SE': 'Lösning tilldelades till objekt',
    'sv-FI': 'lösning tilldelad till platsen',
    'hu-HU': 'A megoldás az oldalhoz rendelve',
    'el-EL': 'λύση που έχει αντιστοιχιστεί στην τοποθεσία',
    'no-NO': 'løsning tildelt sted',
    'ru-RU': 'решение, назначенное сайту',
    'cs-CZ': 'řešení přidělené webu',
    'sk-SK': 'riešenie priradené k objektu',
    'sl-SL': 'rešitev, dodeljena mestu',
    'ko-KO': '사이트에 할당 된 솔루션'
  };
  '8210' = {
    'en-US': 'Solution updated successfully',
    'nl-NL': 'Oplossing succesvol bijgewerkt',
    'fr-FR': 'Solution mise à jour avec succès',
    'de-DE': 'Lösung erfolgreich aktualisiert',
    'pl-PL': 'Rozwiązanie zaktualizowane pomyślnie',
    'zh-ZH': '解决方案更新成功',
    'it-IT': 'Soluzione aggiornata con successo',
    'tr-TR': 'Çözüm başarıyla güncellendi',
    'fi-FI': 'Palvelu päivitetty onnistuneesti',
    'da-DK': 'Opdatering af løsning gennemført',
    'ar-AR': 'تم تحديث الحل بنجاح',
    'pt-PT': 'Solução actualizada com sucesso',
    'es-ES': 'Solución actualizada con éxito',
    'sv-SE': 'Lösningen uppdaterades',
    'sv-FI': 'Lösningen uppdateras framgångsrikt',
    'hu-HU': 'A megoldás sikeresen frissült',
    'el-EL': 'Η λύση ενημερώθηκε με επιτυχία',
    'no-NO': 'Løsningen er oppdatert',
    'ru-RU': 'Решение успешно обновлено',
    'cs-CZ': 'Řešení bylo úspěšně aktualizováno',
    'sk-SK': 'Riešenie bolo úspešne aktualizované',
    'sl-SL': 'Rešitev je bila uspešno posodobljena',
    'ko-KO': '솔루션이 성공적으로 업데이트되었습니다.'
  };
  '8211' = {
    'en-US': 'Solution and features updated successfully',
    'nl-NL': 'Oplossing en functies zijn   bijgewerkt',
    'fr-FR': 'Solution et fonctionnalités mises à jour avec succès',
    'de-DE': 'Lösung und Funktionen wurden erfolgreich aktualisiert',
    'pl-PL': 'Rozwiązanie i funkcje zostały pomyślnie zaktualizowane',
    'zh-ZH': '解决方案和功能已成功更新',
    'it-IT': 'Soluzione e funzionalità aggiornate correttamente',
    'tr-TR': 'Çözüm ve özellikler başarıyla güncellendi',
    'fi-FI': 'Ratkaisu ja ominaisuudet päivitettiin onnistuneesti',
    'da-DK': 'Løsning og funktioner opdateret med succes',
    'ar-AR': 'الحل والميزات التي تم تحديثها بنجاح',
    'pt-PT': 'Solução e recursos atualizados com êxito',
    'es-ES': 'Solución y características actualizadas con éxito.',
    'sv-SE': 'Lösning och funktioner uppdateras framgångsrikt',
    'sv-FI': 'Lösning och funktioner uppdateras framgångsrikt',
    'hu-HU': 'A megoldás és a szolgáltatások sikeresen frissültek',
    'el-EL': 'Η λύση και οι δυνατότητες ενημερώθηκαν με επιτυχία',
    'no-NO': 'Løsning og funksjoner oppdatert vellykket',
    'ru-RU': 'Решение и функции успешно обновлены',
    'cs-CZ': 'Řešení a funkce byly úspěšně aktualizovány',
    'sk-SK': 'Rešitev in funkcije so uspešno posodobljene',
    'sl-SL': 'Rešitev in funkcije so uspešno posodobljene',
    'ko-KO': '솔루션 및 기능이 성공적으로 업데이트되었습니다.'
  };
  '8300' = {
    'en-US': 'No fields have been changed, there is nothing new to save',
    'nl-NL': 'Er zijn geen velden gewijzigd, er is niets nieuws om op te slaan',
    'fr-FR': 'Aucun champ n\'a été modifié, il n\'y a rien de nouveau à enregistrer',
    'de-DE': 'Keine Felder wurden geändert, es gibt nichts Neues zu speichern',
    'pl-PL': 'Żadne pola nie zostały zmienione, nie ma nic nowego do zapisania',
    'zh-ZH': '没有任何字段被更改，没有什么新东西可以保存',
    'it-IT': 'Nessun campo è stato modificato, non c\'è nulla di nuovo da salvare',
    'tr-TR': 'Alan değiştirilmedi, kaydedilecek yeni bir şey yok',
    'fi-FI': 'Mitään kenttää ei ole muutettu, ei tallennettavaa',
    'da-DK': 'Ikke felter er blevet ændret. Der er intet nyt at gemme',
    'ar-AR': 'لم يتم تغيير أي حقول ، ولا يوجد شيء جديد يمكن توفيره',
    'pt-PT': 'Nenhum campo foi alterado, não existe nada para guardar',
    'es-ES': 'Ningún campo ha sido cambiado, no hay nada nuevo para guardar',
    'sv-SE': 'Inga fält har ändrats, det finns ingenting nytt att spara',
    'sv-FI': 'Inga fält har ändrats, det finns inget nytt att spara',
    'hu-HU': 'Nincsenek mezők módosítva, nincs semmi új mentés',
    'el-EL': 'Δεν έχουν αλλάξει πεδία, δεν υπάρχει τίποτα νέο για να αποθηκεύσετε',
    'no-NO': 'Ingen felter har blitt endret, det er ikke noe nytt å lagre',
    'ru-RU': 'Никакие поля не были изменены, нет ничего нового для сохранения',
    'cs-CZ': 'Žádné pole nebyly změněny, není nic nového na uložení',
    'sk-SK': 'Žiadne polia neboli zmenené, nie je nič nové na uloženie',
    'sl-SL': 'Nobena polja niso bila spremenjena, ni nič novega za shranjevanje',
    'ko-KO': '필드가 변경되지 않았습니다. 저장하는 데 새로운 것은 없습니다.'
  };
  '8301' = {
    'en-US': 'created successfully',
    'nl-NL': 'succesvol gemaakt',
    'fr-FR': 'créé avec succès',
    'de-DE': 'erfolgreich erstellt',
    'pl-PL': 'utworzony pomyślnie',
    'zh-ZH': '创建成功',
    'it-IT': 'creato con successo',
    'tr-TR': 'başarıyla oluşturuldu',
    'fi-FI': 'luotu onnistuneesti',
    'da-DK': 'Oprettelse gennemført',
    'ar-AR': 'تم إنشاؤها بنجاح',
    'pt-PT': 'criado com sucesso',
    'es-ES': '¡Creado con éxito!',
    'sv-SE': 'Skapades',
    'sv-FI': 'skapades framgångsrikt',
    'hu-HU': 'sikeresen létrehozott',
    'el-EL': 'δημιουργήθηκε με επιτυχία',
    'no-NO': 'opprettet vellykket',
    'ru-RU': 'успешно создан',
    'cs-CZ': 'vytvořeno úspěšně',
    'sk-SK': 'úspešne vytvorené',
    'sl-SL': 'Ustvarjeno je uspešno',
    'ko-KO': '성공적으로 생성되었습니다.'
  };
  '9999' = {
    'en-US': 'Undefined Error',
    'nl-NL': 'Ongedefinieerde fout',
    'fr-FR': 'Erreur indéfinie',
    'de-DE': 'Nicht definierter Fehler',
    'pl-PL': 'Nieokreślony błąd',
    'zh-ZH': '未定义的错误',
    'it-IT': 'Errore non definito',
    'tr-TR': 'Tanımsız hata',
    'fi-FI': 'Määrittämätön virhe',
    'da-DK': 'Ukendt fejl',
    'ar-AR': 'خطأ غير محدد',
    'pt-PT': 'Erro  indefinido',
    'es-ES': 'Error no definido',
    'sv-SE': 'Odefinerad fel',
    'sv-FI': 'Odefinierat fel',
    'hu-HU': 'Meghatározatlan hiba',
    'el-EL': 'Απροσδιόριστο σφάλμα',
    'no-NO': 'Udefinert feil',
    'ru-RU': 'Неопределенная ошибка',
    'cs-CZ': 'Nedefinovaná chyba',
    'sk-SK': 'Nedefinovaná chyba',
    'sl-SL': 'Nedoločena napaka',
    'ko-KO': '정의되지 않은 오류'
  };
  '8302' = {
    'en-US': 'User invite sent successfully',
    'nl-NL': 'Gebruikersuitnodiging met succes verzonden',
    'fr-FR': 'Invitation utilisateur envoyée avec succès',
    'de-DE': 'Benutzereinladung erfolgreich gesendet',
    'pl-PL': 'Zaproszenie użytkownika wysłane pomyślnie',
    'zh-ZH': '用户邀请已成功发送',
    'it-IT': 'Invito utente inviato con successo',
    'tr-TR': 'Kullanıcı daveti başarıyla gönderildi',
    'fi-FI': 'Käyttäjän Kutsu lähetetty onnistuneesti',
    'da-DK': 'Bruger invitation blev sendt',
    'ar-AR': 'تم إرسال دعوة المستخدم بنجاح',
    'pt-PT': 'Convite do usuário enviado com sucesso',
    'es-ES': 'Invitación de usuario enviada correctamente',
    'sv-SE': 'Användarinbjudan skickad med framgång',
    'sv-FI': 'Användarinbjudan skickad med framgång',
    'hu-HU': 'A felhasználó meghívása sikeresen elküldött',
    'el-EL': 'Η πρόσκληση χρήστη στάλθηκε με επιτυχία',
    'no-NO': 'Brukerinvitasjon sendes vellykket',
    'ru-RU': 'Приглашение пользователя успешно отправлено',
    'cs-CZ': 'Pozvání uživatele bylo úspěšně odesláno',
    'sk-SK': 'Pozvánka používateľa bola úspešne odoslaná',
    'sl-SL': 'Povabilo uporabnika je uspešno poslano',
    'ko-KO': '사용자 초대가 성공적으로 전송되었습니다.'
  };
  '4515' = {
    'en-US': 'Solution not found for user or hierarchy!',
    'nl-NL': 'Oplossing niet gevonden voor gebruiker of hiërarchie!',
    'fr-FR': 'Solution introuvable pour l’utilisateur ou la hiérarchie !',
    'de-DE': 'Lösung für Benutzer oder Hierarchie nicht gefunden!',
    'pl-PL': 'Nie znaleziono rozwiązania dla użytkownika lub hierarchii!',
    'zh-ZH': '找不到用户或层次结构的解决方案！',
    'it-IT': 'Soluzione non trovata per l’utente o la gerarchia.',
    'tr-TR': 'Çözüm kullanıcı veya hiyerarşi için bulunamadı!',
    'fi-FI': 'Ratkaisua ei löydy käyttäjälle tai hierarkialle!',
    'da-DK': 'Løsningen blev ikke fundet for bruger eller hierarki!',
    'pt-PT': 'Solução não encontrada para usuário ou hierarquia!',
    'es-ES': '¡Solución no encontrada para el usuario o la jerarquía!',
    'sv-SE': 'Lösningen hittades inte för användare eller hierarki!',
    'hu-HU': 'A megoldás nem található a felhasználóhoz vagy a hierarchiához!',
    'el-EL': 'Η λύση δεν βρέθηκε για το χρήστη ή την ιεραρχία!',
    'no-NO': 'Løsning ikke funnet for bruker eller hierarki!',
    'ru-RU': 'Решение не найдено для пользователя или иерархии!',
    'cs-CZ': 'Řešení nebylo nalezeno pro uživatele nebo hierarchii!',
    'sk-SK': 'Riešenie sa nenašlo pre používateľa alebo hierarchiu!',
    'sl-SL': 'Rešitve ni bilo mogoče najti za uporabnika ali hierarhijo!',
    'ko-KO': '사용자 또는 계층 구조에 대한 솔루션을 찾을 수 없습니다!'
  };
}
