<div class="main-container user-profile-container">
  <form class="user-profile-form form-group-container" novalidate [formGroup]="userProfile" (ngSubmit)="onSubmit()"
    [ngClass]="{submitted: formSubmitted}">
    <label class="form-control-container">
      <div class="control-label">{{'email' | translate}}</div>
      <div class="control-input-container">
        <input class="control-input disabled" type="text" readonly formControlName="email">
      </div>
    </label>
    <div *ngIf="loader" class="site-loader-wrapper grey-bg-loader">
      <div class="loader"></div>
    </div>
    <label class="form-control-container">
      <div class="control-label">{{'username' | translate}}</div>
      <div class="control-input-container">
        <input class="control-input disabled" type="text" id="username" readonly formControlName="username">
      </div>
    </label>
    <label class="form-control-container language-dropdown">
      <div class="control-label">{{'language' | translate}}</div>
      <div class="control-input-container">
        <language-dropdown [reload]="false" (onSelect)="onSelect($event)"></language-dropdown>
      </div>
    </label>
    <div class="form-submit-container">
      <button class="btn" [ngClass]="{'disabled': disableSubmit}" type="submit">{{'save' | translate}}</button>
    </div>
  </form>
</div>
