import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { HierarchyDropdownService } from './hierarchy-dropdown.service';
import { CookieStorage, LocalDataStoreService } from 'app/base/services';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { locales } from '../../../../assets/pac_i18n/locale';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { isDiverseyAdmin } from '../../../base/utils';

@Component({
  selector: 'hierarchy-dropdown',
  templateUrl: './hierarchy-dropdown.component.html',
  styleUrls: ['./hierarchy-dropdown.component.scss', '../../../../scss/_forms_new.scss'],
  host: {
    '(document:click)': 'closeDrop($event)'
  }
})

/* IMP TO-DO: IMPROVE CODE (Phil) */

export class HierarchyDropdownComponent implements OnInit, OnChanges {
  // NOTE: For input based approach
  @Input('parentData') parentData: any;
  @Input('hierarchyClose') hierarchyClose: boolean;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Output() hierarchyOpen: EventEmitter<boolean> = new EventEmitter<any>();

  public selectedValue = { Company: '', Client: '', Customer: '' };
  public activeClass = { Company: false, Client: false, Customer: false };
  public HierarchyDropdownForm: UntypedFormGroup;
  public userData;
  public companyList = [];
  public clientList = [];
  public customerList = [];
  public selectedCompany;
  public selectedClient;
  public selectedCustomer;
  public formArrayList: string[] = ['Company', 'Client', 'Customer'];
  public companySearch;
  public clientSearch;
  public customerSearch;

  showdropdown = {
    Company: true,
    Client: true,
    Customer: true
  };

  viaHierarchy = false;
  public country_locale = new locales();
  private isDiverseyAdmin = false;
  constructor(
    private _hierarchyDropdownService: HierarchyDropdownService,
    private _formBuilder: UntypedFormBuilder,
    private _changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private cookieStorage: CookieStorage,
    private localDataStoreService: LocalDataStoreService,
    private _activatedRoute: ActivatedRoute) {
    const locale = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    this.translate.use(this.country_locale.dictionary[locale]);
  }

  ngOnInit() {
    if (this.localDataStoreService.userDetails) {
      this._activatedRoute.queryParams.subscribe(params => {
        if (params && params.previous) {
          if (params.previous === 'hierarchy') {
            this.viaHierarchy = true;
          }
        }
      });

      this.userData = this.localDataStoreService.userDetails;
      this.isDiverseyAdmin = this.userData ?
      isDiverseyAdmin(this.userData.user_role_id, this.userData.accessible_nodes, this.userData.org_id) : false;
      const orgType = this.userData.organization.org_type;
      this.buildForm();
      this.loadDropdownData(orgType);
    }
  }

  ngOnChanges(change) {
    const close = change.hierarchyClose;
    if (close && close.currentValue === true) {
      this.closeOtherDropdowns();
    }
  }

  get Company() {
    return this.HierarchyDropdownForm.get('Company');
  }

  get Client() {
    return this.HierarchyDropdownForm.get('Client');
  }

  get Customer() {
    return this.HierarchyDropdownForm.get('Customer');
  }

  buildForm() {
    this.HierarchyDropdownForm = this._formBuilder.group({
      Company: this._formBuilder.array([]),
      Client: this._formBuilder.array([]),
      Customer: this._formBuilder.array([])
    });
  }

  loadDropdownData(userOrgType) {
    let organization = this.userData.organization;
    if (this.parentData.hierarchyData) {
      organization = this.parentData.hierarchyData;
    }
    const nodeId = organization.node_id;
    if (userOrgType === 'Root') {
      this.rootAdminCheck(userOrgType, nodeId, organization);
    } else if (userOrgType === 'Company') {
      this.companyAdminCheck(userOrgType, nodeId, organization);
    } else if (userOrgType === 'Client') {
      this.clientAdminCheck(userOrgType, nodeId, organization);
    } else if (userOrgType === 'Customer') {
      this.customerAdminCheck(userOrgType, nodeId, organization);
    }
  }

  rootAdminCheck(userOrgType, nodeId, organization) {
    if (this.parentData.entity === 'company') {
      this.showdropdown = { Company: true, Client: false, Customer: false };
      this.companyList.push(this.generateDefaultValue('Company', organization));
      this.companyList.length ? this.setFormList(this.companyList, 'Company') : null;
      this.emitPreSelectedOrg(organization);
    } else if (this.parentData.entity === 'client') {
      this.showdropdown = { Company: true, Client: false, Customer: false };
      if (this.viaHierarchy || this.isDiverseyAdmin) {
        const data = this.generateDefaultValue(organization.org_type, organization);
        this.companyList.push(this.generateDefaultValue(organization.org_type, organization));
        this.companyList.length ? this.setFormList(this.companyList, 'Company') : null;
        this.emitPreSelectedOrg(organization);
        this.setSelectedOrg('Company', organization.node_id);
        this.getImmediateOrg(nodeId);
      } else {
        this.getImmediateOrg(nodeId);
      }
    } else if (this.parentData.entity === 'customer') {
      this.showdropdown = { Company: true, Client: true, Customer: false };
      if (this.parentData.hierarchyData) {
        const orgData = organization.mainParent;
        const orgType = orgData.type || orgData.org_type;
        if (orgData && (orgType === 'Company' || orgType === 'Root')) {
          this.companyList.push(this.generateDefaultValue(orgType, orgData));
        } else {
          this.companyList.push(this.generateDefaultValue(this.userData.organization.org_type, this.userData.organization));
        }
        this.companyList.length ? this.setFormList(this.companyList, 'Company') : null;
        this.clientList.push(this.generateDefaultValue(organization.org_type, organization));
        this.clientList.length ? this.setFormList(this.clientList, 'Client') : null;
        this.setSelectedOrg('Client', organization.node_id);
        this.emitPreSelectedOrg(organization);
      } else {
        this.getImmediateOrg(nodeId);
        this.companyList.push(this.generateDefaultValue(this.userData.organization.org_type, this.userData.organization));
        this.companyList.length ? this.setFormList(this.companyList, 'Company') : null;
        this.setSelectedOrg('Company', organization.node_id);
      }
    } else if (this.parentData.entity === 'site') {
      if (this.parentData.hierarchyData) {
        this.showdropdown = { Company: true, Client: true, Customer: true };
        if (organization.org_type === 'Client') {
          this.showdropdown.Customer = false;
          const orgData = organization.mainParent;
          const orgType = orgData.type || orgData.org_type;

          if (orgData && (orgType === 'Company' || orgType === 'Root') && this.viaHierarchy) {
            this.companyList.push(this.generateDefaultValue(orgType, orgData));
          } else {
            this.companyList.push(this.generateDefaultValue(this.userData.organization.org_type, this.userData.organization));
          }
          this.companyList.length ? this.setFormList(this.companyList, 'Company') : null;

          this.clientList.push(this.generateDefaultValue(organization.org_type, organization));
          this.clientList.length ? this.setFormList(this.clientList, 'Client') : null;
          this.emitPreSelectedOrg(organization);
        } else if (organization.org_type === 'Customer') {
          const companyData = organization.mainParent;
          const orgType = companyData.type || companyData.org_type;

          if (companyData && (orgType === 'Root' || orgType === 'Company')) {
            this.companyList.push(this.generateDefaultValue(companyData.type, companyData));
          } else {
            this.companyList.push(this.generateDefaultValue(this.userData.organization.org_type, this.userData.organization));
          }
          this.companyList.length ? this.setFormList(this.companyList, 'Company') : null;

          const clientData = organization.mainParent.Client ? organization.mainParent.Client[0] : organization.mainParent;
          this.clientList.push(this.generateDefaultValue(clientData.type, clientData));
          this.clientList.length ? this.setFormList(this.clientList, 'Client') : null;

          this.customerList.push(this.generateDefaultValue(organization.org_type, organization));
          this.customerList.length ? this.setFormList(this.customerList, 'Customer') : null;
          this.emitPreSelectedOrg(organization);
        }
      } else {
        this.companyList.push(this.generateDefaultValue(this.userData.organization.org_type, this.userData.organization));
        this.companyList.length ? this.setFormList(this.companyList, 'Company') : null;
        this.showdropdown = { Company: true, Client: true, Customer: true };
        this.getImmediateOrg(nodeId);
      }
    } else {
      this.showdropdown = { Company: false, Client: false, Customer: false };
    }
  }

  companyAdminCheck(userOrgType, nodeId, organization) {
    if (this.parentData.entity === 'company') {
      this.showdropdown = { Company: false, Client: false, Customer: false };
    } else if (this.parentData.entity === 'client') {
      this.showdropdown = { Company: true, Client: false, Customer: false };
      this.companyList.push(this.generateDefaultValue(organization.org_type, organization));
      this.companyList.length ? this.setFormList(this.companyList, 'Company') : null;
      this.emitPreSelectedOrg(organization);
    } else if (this.parentData.entity === 'customer') {
      this.showdropdown = { Company: false, Client: true, Customer: false };
      // this.companyList.push(this.generateDefaultValue(this.userData.organization.org_type, this.userData.organization));
      // this.companyList.length ? this.setFormList(this.companyList, 'Company') : null;
      if (this.parentData.hierarchyData) {
        this.showdropdown.Company = false;
        this.clientList.push(this.generateDefaultValue(organization.org_type, organization));
        this.clientList.length ? this.setFormList(this.clientList, 'Client') : null;
        this.emitPreSelectedOrg(organization);
      } else {
        this.getImmediateOrg(nodeId);
      }
    } else if (this.parentData.entity === 'site') {
      this.showdropdown = { Company: true, Client: true, Customer: true };
      this.companyList.push(this.generateDefaultValue(this.userData.organization.org_type, this.userData.organization));
      this.companyList.length ? this.setFormList(this.companyList, 'Company') : null;
      if (this.parentData.hierarchyData) {
        if (organization.org_type === 'Client') {
          this.showdropdown.Customer = false;
          this.clientList.push(this.generateDefaultValue(organization.org_type, organization));
          this.clientList.length ? this.setFormList(this.clientList, 'Client') : null;
          this.emitPreSelectedOrg(organization);
        } else if (organization.org_type === 'Customer') {
          this.showdropdown = { Company: false, Client: false, Customer: true };
          this.customerList.push(this.generateDefaultValue(organization.org_type, organization));
          this.customerList.length ? this.setFormList(this.customerList, 'Customer') : null;
          this.emitPreSelectedOrg(organization);
        }
      } else {
        this.getImmediateOrg(nodeId);
      }
    }
  }

  clientAdminCheck(userOrgType, nodeId, organization) {
    if (this.parentData.entity === 'company' || this.parentData.entity === 'client') {
      this.showdropdown = { Company: false, Client: false, Customer: false };
    } else if (this.parentData.entity === 'customer') {
      this.showdropdown = { Company: false, Client: true, Customer: false };
      this.clientList.push(this.generateDefaultValue(organization.org_type, organization));
      this.clientList.length ? this.setFormList(this.clientList, 'Client') : null;
      this.emitPreSelectedOrg(organization);
    } else if (this.parentData.entity === 'site') {
      this.showdropdown = { Company: false, Client: false, Customer: true };
      if (this.parentData.hierarchyData && this.parentData.hierarchyData.org_type === 'Client') {
        this.showdropdown.Customer = false;
        this.showdropdown.Client = true;
        this.clientList.push(this.generateDefaultValue(this.userData.organization.org_type, this.userData.organization));
        this.clientList.length ? this.setFormList(this.clientList, 'Client') : null;
      } else if (this.parentData.hierarchyData && this.parentData.hierarchyData.org_type === 'Customer') {
        this.customerList.push(this.generateDefaultValue(organization.org_type, organization));
        this.customerList.length ? this.setFormList(this.customerList, 'Customer') : null;
      } else {
        this.getImmediateOrg(nodeId);
      }
      this.emitPreSelectedOrg(organization);
    }
  }

  customerAdminCheck(userOrgType, nodeId, organization) {
    if (this.parentData.entity === 'company' || this.parentData.entity === 'client' || this.parentData.entity === 'customer') {
      this.showdropdown = { Company: false, Client: false, Customer: false };
    } else if (this.parentData.entity === 'site') {
      this.showdropdown = { Company: false, Client: false, Customer: true };
      this.customerList.push(this.generateDefaultValue(organization.org_type, organization));
      this.customerList.length ? this.setFormList(this.customerList, 'Customer') : null;
      this.emitPreSelectedOrg(organization);
    }
  }

  emitPreSelectedOrg(organization) {
    const id = organization.node_id || organization.id;
    const org_id = organization.id || organization.org_id;

    this.notify.emit({
      nodeId: Number(id),
      orgId: Number(org_id),
      orgType: organization.org_type || organization.type,
      isActive: organization.is_active === 1,
      orgName: organization.org_name,
      solutions: organization.solutions ? organization.solutions : []
    });
  }

  generateDefaultValue(orgType, organization) {
    const id = organization.node_id || organization.id;
    const org_id = organization.org_id || organization.id;
    this.setSelectedOrg(orgType, Number(id));
    return {
      id: Number(id),
      is_active: organization.is_active,
      name: organization.org_name || organization.name,
      org_id: Number(org_id),
      type: organization.org_type || organization.type,
      solutions: organization.solutions
    };
  }

  setSelectedOrg(orgType, nodeId) {
    orgType === 'Root' || orgType === 'Company' ? this.selectedValue.Company = nodeId : null;
    orgType === 'Client' ? this.selectedValue.Client = nodeId : null;
    orgType === 'Customer' ? this.selectedValue.Customer = nodeId : null;
  }

  setFormList(orgList, orgType) {
    const orgFG = orgList.map(org =>
      this._formBuilder.group({
        id: org.id,
        is_active: org.is_active,
        name: org.name,
        org_id: org.org_id,
        parent_id: org.parent_id,
        type: org.type,
        solutions: this._formBuilder.array(org.solutions ? org.solutions : [])
      })
    );
    const orgFA = this._formBuilder.array(orgFG);
    this.HierarchyDropdownForm.setControl(orgType, orgFA);
    this._changeDetectorRef.detectChanges();
  }

  getImmediateOrg(nodeId, orgId = null) {
    this._hierarchyDropdownService.getImmediateOrgChild(nodeId).subscribe(data => {
      data.forEach(item => {
        if (item.is_active) {
          // orgId !== 1 condition is required to avoid re-populating Company dropdown on Diversey selection
          if (item.type === 'Company' && orgId !== 1) {
            this.companyList.push(item);
          } else if (item.type === 'Client') {
            this.clientList.push(item);
          } else if (item.type === 'Customer') {
            this.customerList.push(item);
          }
        }
      });
      this.companyList.length ? this.setFormList(this.companyList, 'Company') : null;
      this.clientList.length ? this.setFormList(this.clientList, 'Client') : null;
      this.customerList.length ? this.setFormList(this.customerList, 'Customer') : null;
    });
  }

  getFormArray(step, name?) {
    if (name !== undefined) {
      return this.HierarchyDropdownForm.get(step).get(name) as UntypedFormArray;
    } else {
      return this.HierarchyDropdownForm.get(step) as UntypedFormArray;
    }
  }

  searchClick(event) {
    event.stopPropagation();
  }

  displayValue(controlName) {
    let selectedValueString;
    if (this.selectedValue.Company || this.selectedValue.Client || this.selectedValue.Customer) {
      const entityControl = this.getFormArray(controlName);
      entityControl.controls.filter((fg: UntypedFormGroup) => {
        fg.value.id === this.selectedValue[controlName] ? selectedValueString = fg.value.name : '';
      });
      return selectedValueString;
    }
    return null;
  }

  toggleActiveClass(e: Event, controlName) {
    const userOrgType = this.userData.organization.org_type;
    if ((controlName === 'Company' && userOrgType === 'Root') ||
      (controlName === 'Client' && (userOrgType === 'Root' || userOrgType === 'Company')) ||
      (controlName === 'Customer' && userOrgType !== 'Customer')) {
      const control = this.getFormArray(controlName);
      control.markAsTouched();
      this.closeOtherDropdowns();
      this.activeClass[controlName] === 'active' ? this.activeClass[controlName] = '' : this.activeClass[controlName] = 'active';
      e.stopPropagation();

      this.hierarchyOpen.emit(this.activeClass[controlName] === 'active');
    }
  }

  closeOtherDropdowns() {
    Object.keys(this.activeClass).forEach(el => {
      this.activeClass[el] = '';
    });
  }

  onValueChange(control, controlName, event) {
    event.stopPropagation();
    const entityControl = this.getFormArray(controlName);
    this.selectedValue[controlName] = control.value.id;
    entityControl.updateValueAndValidity();
    if (controlName === 'Company') {
      this.HierarchyDropdownForm.setControl('Client', this._formBuilder.array([]));
      this.HierarchyDropdownForm.setControl('Customer', this._formBuilder.array([]));
      this.selectedValue.Client = null;
      this.selectedValue.Customer = null;
      this.clientList = [];
      this.customerList = [];
      if (this.parentData.entity !== 'company') {
        this.getImmediateOrg(this.selectedValue[controlName], control.value.id);
      }
      this.companySearch = '';
    } else if (controlName === 'Client') {
      this.HierarchyDropdownForm.setControl('Customer', this._formBuilder.array([]));
      this.selectedValue.Customer = null;
      this.customerList = [];
      this.getImmediateOrg(this.selectedValue[controlName]);
      this.clientSearch = '';
    } else {
      this.customerSearch = '';
    }
    this.notify.emit({
      nodeId: control.value.id,
      orgId: control.value.org_id,
      orgType: control.value.type,
      isActive: control.value.is_active,
      orgName: control.value.name,
      solutions: control.value.solutions
    });
  }

  closeDrop(e: Event) {
    this.formArrayList.forEach(dropdown => {
      if (this.activeClass[dropdown] !== 'active') {
        e.stopPropagation();
      } else {
        this.activeClass[dropdown] = '';
      }
    });
  }

}
