import { Injectable } from '@angular/core';
import { lables } from '../../../assets/pac_i18n/error';
import { CookieStorage } from './cookie-storage.service';
import { messages } from '../../../assets/pac_i18n/messages';
import { locales } from '../../../assets/pac_i18n/locale';

@Injectable()
export class ToasterMessageService {
  private languages: any = new locales();
  private translateTo: any = new lables();
  private locale = 'en-US';
  private translateMsg: any = new messages();

  constructor(
    private cookieStorage: CookieStorage) {
    const value = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    this.locale = this.languages['dictionary'][value];
  }

  // TODO: checks if code is not their in our system then add fallbacks.
  getSuccessMessage(code) {
    return this.translateTo[code][this.locale];
  }

  getErrorMessage(code) {
    if (typeof(code) === 'number') {
      return this.translateTo[code][this.locale];
    } else {
      this.returnFallbackMessage();
    }
  }

  // fallback if invalid/code returned from api's are not in our system
  returnFallbackMessage() {
    return this.translateTo[8005][this.locale];
  }

  getMessage(key) {
    return this.translateMsg[key][this.locale];
  }

}
