import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CookieStorage } from '../../../base/services/cookie-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { locales } from '../../../../assets/pac_i18n/locale';
import { LocalDataStoreService } from 'app/base/services';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent {
  // event emitter to destroy self
  @Output() closeSelf: EventEmitter<any> = new EventEmitter();
  @Output() isProfileTab: EventEmitter<any> = new EventEmitter();
  @Input() isProfileTabActive = true;
  @Input() isDeactivateTabActive = false;
  @Input() isAdminUser = false;


  public isOktaLogin = false;
  
  public country_locale = new locales();

  constructor (private cookieStorage: CookieStorage, private translate: TranslateService,
    private localDataStoreService: LocalDataStoreService) {
    let locale = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    locale = this.country_locale.dictionary[locale];
    translate.use(locale);

    if(this.localDataStoreService.userDetails) {
      if (this.localDataStoreService.userDetails.is_okta_login) {
        this.isOktaLogin = this.localDataStoreService.userDetails.is_okta_login;
      } else {
        this.isOktaLogin = false;
      }
    }

  }

  collapse() {
    this.closeSelf.emit(null);
  }

  showContentOf(tab, event) {
    if (tab === 'profile') {
      this.isProfileTabActive = true;
      this.isDeactivateTabActive = false;
      this.isProfileTab.emit('profile');
    } else if (tab === 'password') {
      this.isProfileTabActive = false;
      this.isDeactivateTabActive = false;
      this.isProfileTab.emit('reset');
    } else if (tab === 'deactivate') {
      this.isProfileTabActive = false;
      this.isDeactivateTabActive = true;
      this.isProfileTab.emit('deactivate');
    }
  }
}
