
import {throwError as observableThrowError,  Subject ,  Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { LocalStorageService } from './localStorage.service';

import { HttpClientService } from 'app/base/services/http.client-module.service';
import { LocalDataStoreService } from 'app/base/services/local-data-store.service';
import { CookieStorage } from 'app/base/services/cookie-storage.service';
import { catchError ,  map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'environments/environment';


@Injectable()
export class UserService {
  userName = '';
  userNameSubject = new Subject();
  private loggedIn = false;
  private URLS = {
    countries: `${environment.NODE_URL}common/countries`,
    languages: `${environment.NODE_URL}common/languages`,
    solutions: `${environment.NODE_URL}common/solutions`,
    logout: `${environment.NODE_URL}auth/logout`,
    user: `${environment.NODE_URL}user/`,
    cities: `${environment.NODE_URL}common/cities/`,
    states: `${environment.NODE_URL}common/states/`,
    timezones: `${environment.NODE_URL}common/new-timezones`,
    deactivate: `${environment.NODE_URL}users/action`,
    deactivatewithemail: `${environment.NODE_URL}users/action-email`,
    clearcache: `${environment.NODE_URL}auth/clearcache`,
  };
  private window: Window;
  constructor(
    private localStorageService: LocalStorageService,
    private httpClientService: HttpClientService,
    private localDataStoreService: LocalDataStoreService,
    private cookieStorage: CookieStorage,
    @Inject(DOCUMENT) private document: Document,
    private oauthService: OAuthService ) {
    this.loggedIn = !!cookieStorage.getCookie('token');
    this.window = this.document.defaultView;
  }

  getCommonData(dataUrl) {
    if (this.URLS[dataUrl]) {
      return this.httpClientService.getDataWithNoAuth(`${this.URLS[dataUrl]}`)
        .pipe(map(data => data));
    }
  }

  logout(reload = true, email = null) {
    if(email != null) {
      this.clearcache(email).subscribe();
    }
    return this.httpClientService
      .post(this.URLS.logout, {})
      .pipe(map(res => res)
      ).subscribe(result => {
        // update next value in userNameSubject to null
        const cookie = this.localStorageService.get('cookies_consent_accepted');
        this.userNameSubject.next(null);
        this.localStorageService.clearAll();
        this.loggedIn = false;
        if (cookie) {
          this.localStorageService.set('cookies_consent_accepted', cookie);
        }
        this.window.location.href = `${environment['DASHBOARD_HOST_URL']}#/login`;
        this.cookieStorage.clearCookie('token');
      }, err => {
        const cookie = this.localStorageService.get('cookies_consent_accepted');
        this.userNameSubject.next(null);
        this.localStorageService.clearAll();
        this.loggedIn = false;
        if (cookie) {
          this.localStorageService.set('cookies_consent_accepted', cookie);
        }
        this.window.location.href = environment['DASHBOARD_HOST_URL'];
      });
  }


  async logoutOkta(email = null) {
    this.updateLocalStorage(false);
    if(email != null) {
      this.clearcache(email).subscribe();
    }
    await this.oauthService.logOut();
  }

 
  clearcache(email) {
    const body = {
      email : email
    };
    return this.httpClientService.post(this.URLS.clearcache, body)
      .pipe(map((res) => res));
  }

  updateLocalStorage(reload) {
    this.localDataStoreService.updateData(null);
    // this.localDataStoreService.updateSelectedOrgDetails(null);
    this.cookieStorage.clearCookie('token');
    // this.localDataStoreService.updateOrgChildrens(null);
    // this.localDataStoreService.updateOrganizations(null);
    this.clearStorage(reload);
  }


  clearStorage(reload) {
    this.httpClientService.deleteToken();
    // update next value in userNameSubject to null
    this.userNameSubject.next(null);
    const cookieConsentValue = this.localStorageService.get('cookies_consent_accepted');
    const locale = this.cookieStorage.getCookie('locale');
    this.localStorageService.clearAll();
    // preserving value
    if (cookieConsentValue) {
      this.localStorageService.set('cookies_consent_accepted', cookieConsentValue);
    }
    // preserve user preferred language
    if (locale) {
      this.cookieStorage.setCookie('locale', locale);
    }
    this.loggedIn = false;
    if (reload) {
      this.window.location.href = environment['DASHBOARD_HOST_URL'];

    }
   
  }

  getUserName() {
    return this.getLocalUserData('username');
  }

  updateCookieConsent(value): Observable<any> {
    return this.httpClientService.post(`${this.URLS.user}accept/cookie-consent`, {consent: value})
      .pipe(map((updatedUser) => updatedUser));
  }
  
  getUserGlobalAccountList() {
    return this.getLocalUserData('global_accounts');
  }

  isLoggedIn() {
    return this.loggedIn;
  }

  getCountries(): Observable<any> {
    return this.httpClientService.get(this.URLS.countries)
      .pipe(
        catchError(error => this.handleCommonError(error))
      );
  }

  getSolutions(): Observable<any> {
    return this.httpClientService.get(this.URLS.solutions)
      .pipe(
        catchError(error => this.handleCommonError(error))
      );
  }

  getLocales(): Observable<any> {
    return this.httpClientService.get(this.URLS.languages)
      .pipe(
        catchError(error => this.handleCommonError(error))
      );
  }

  handleCommonError(error) {
    return observableThrowError(error || 'Server Error');
  }

  getUserData() {
    return this.httpClientService.get(`${this.URLS.user}`)
      .pipe(
        catchError(error => this.handleCommonError(error))
      );
  }

  getLocalUserData(property) {
    return this.localDataStoreService.userDetails ? this.localDataStoreService.userDetails[property] : null;
  }

  // TODO: remove following methods once cookie implementation moved to cookie-service
  // and integration is done from their
  getCookie(property) {
    const name = property + '=';
    const decodedCookie = decodeURIComponent(this.document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  checkCookieAvailability(value) {
    const data = this.getCookie(value);
    if (data !== '') {
      return true;
    } else {
      return false;
    }
  }

  clearCookie(value) {
    const domainName = environment.COOKIE_DOMAIN_NAME;
    this.document.cookie = `${value}=;domain=${domainName};`;
  }

  setCookie(name, value) {
    const domainName = environment.COOKIE_DOMAIN_NAME;
    this.document.cookie = `${name}=${value};domain=${domainName};`;
  }

  updateProfile(user) {
    return this.httpClientService.put(`${this.URLS.user}`, user)
      .pipe(map(updatedUser => updatedUser),
        catchError(this.handleError));
  }

  getLocaleDetails(userData) {
    if (userData) {
      this.cookieStorage.setCookie('locale', userData.locale);
      return this.httpClientService.get(`${this.URLS.languages}/${userData.locale}`)
        .pipe(
          map(localeDetails => {
            userData.locale_details = localeDetails.data;
            this.userNameSubject.next(userData['username']);
            this.localDataStoreService.updateData(userData);
          }),
          catchError(this.handleError)
        );
    }
  }

  resetPassword(data): Observable<any> {
    return this.httpClientService.put(`${this.URLS.user}reset-password`, data)
      .pipe(map(updatedUser => updatedUser));
  }

  resetOktaPassword(data): Observable<any> {
    return this.httpClientService.put(`${this.URLS.user}reset-okta-password`, data)
      .pipe(map(updatedUser => updatedUser),
      catchError((error) => {
        // console.log('**** resetOktaPassword : catch error: ', error);
        
        return this.handleError(error);
      }));
  }
 
  // get list of cities based on the state selected
  getCities(stateId) {
    return this.httpClientService.getDataWithNoAuth(this.URLS.cities + stateId)
      .pipe(
        map(res => res),
        catchError(error => this.handleError(error))
      );
  }

  // get list of cities based on the state selected
  getStates(countryId) {
    return this.httpClientService.getDataWithNoAuth(this.URLS.states + countryId)
      .pipe(
        map(res => res),
        catchError(error => this.handleError(error))
      );
  }

  // get all-timezones
  getTimezones(): Observable<any> {
    return this.httpClientService.get(this.URLS.timezones)
      .pipe(
        catchError(error => this.handleCommonError(error))
      );
  }

  deactivateAccount(user) {
    return this.httpClientService.put(`${this.URLS.deactivate}/${user.userid}?hasPassword=true`,
    { is_active: false, password: user.password })
      .pipe(map(updatedUser => updatedUser),
        catchError(this.handleError));
  }


  deactivateAccountWithEmail(payload) {
    return this.httpClientService.put(`${this.URLS.deactivatewithemail}`, payload)
      .pipe(map((updatedUser) => updatedUser),
        catchError(this.handleError));
  }

  private handleError(error) {
    return observableThrowError(error.error || 'Server Error');
  }

}
