<div class="main-container">
  <div class="nav-container">
    <ul class="nav">
      <li class="nav-item" [ngClass]="{ active : false}" (click)="goToHome()">
        <span class="item-text">{{'home' | translate}}</span>
      </li>
      <li class="nav-item" routerLink="/" [ngClass]="{ active : adminTabActive }">
        <span class="item-text">{{'admin' | translate}}</span>
      </li>
      <li class="nav-item" routerLink="/hierarchy" *ngIf="!isDMuser" [ngClass]="{'active': activeNav.hierarchy}">
        <span class="item-text"> {{'hierarchy' | translate}}</span>
      </li>
      <li class="nav-item" routerLink="/organization/list/Company" *ngIf="isAllowed('Company')" [ngClass]="{'active': activeNav.Company}">
        <span class="item-text">{{'companies' | translate}}</span>
      </li>

      <li class="nav-item" routerLink="/organization/list/Client" *ngIf="isAllowed('Client')" [ngClass]="{'active': activeNav.Client}">
        <span class="item-text">{{'clients' | translate}}</span>
      </li>

      <li class="nav-item" routerLink="/organization/list/Customer" *ngIf="isAllowed('Customer')" [ngClass]="{'active': activeNav.Customer}">
        <span class="item-text">{{'customers' | translate}}</span>
      </li>

      <li class="nav-item" routerLink="/sites/list" [ngClass]="{'active': activeNav.sites}">
        <span class="item-text">{{'sites' | translate}}</span>
      </li>
      <li class="nav-item" routerLink="/user/list" [ngClass]="{'active': activeNav.user}">
        <span class="item-text">{{'users' | translate}}</span>
      </li>
      <li class="nav-item" *ngIf="isDMuser">
        <span class="item-text">T&amp;C</span>
      </li>
    </ul>
  </div>
</div>
