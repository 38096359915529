import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
  selector: '[trimWhiteSpace]'
})
export class TrimWhiteSpaceDirective {

  constructor(
    private el: ElementRef, private control: NgControl
  ) { }

  @HostListener('change') onchange() {
    this.el.nativeElement.value = this.trimWhiteSpace(this.el.nativeElement.value);
    if (this.control && this.control.control) {
      this.control.control.setValue(this.trimWhiteSpace(this.el.nativeElement.value));
    }
  }
  trimWhiteSpace(inputString) {
    return inputString.replace(/\s\s+/g, ' ').trim();
  }
}
