<div class="main-container">
    <div class="tiles-container upper">
      <div class="tile" *ngIf="!isDMuser">
        <div class="tile-body" (click)="routeTo('hierarchy')">
          <div class="tile-sticker arrow-box">
            <div class="sticker-body">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 96"><defs><style>.cls-1{fill:#fff;}</style></defs><title>Company</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Admin-V2"><g id="Portal-Admin-Console-V2-SA"><g id="Group-6"><path id="Company-icon" class="cls-1" d="M0,93.07l1.2-12c.1-1,.22-2.17,1-2.77a10.76,10.76,0,0,1,3.35-1.2c2.45-.68,3.62-1.3,6.09-2,.71,2.87,1.24,7.35,2.55,10l.7-5.81,1-.7,1,.7.7,5.81c1.32-2.65,1.84-7.13,2.55-10,2.47.7,3.64,1.33,6.09,2a10.27,10.27,0,0,1,3.39,1.23A4.14,4.14,0,0,1,30.62,81l1.2,12a2.74,2.74,0,0,1-2,2.93H2a2.74,2.74,0,0,1-2-2.93M24.3,44.55l1.45-14.13c.11-1.12.25-2.55,1.14-3.25s2.77-1.08,3.93-1.4c2.87-.8,3.83-1.53,6.73-2.36.84,3.37,2,9.47,3.5,12.58l.73-7.66L43,27.51l1.23.82L45,36c1.54-3.11,2.66-9.22,3.5-12.58,2.9.83,3.86,1.56,6.73,2.36,1.15.32,3.08.68,4,1.44s1,2.11,1.09,3.21L61.7,44.55a2.74,2.74,0,0,1-2,2.93L43,52.24,26.27,47.48a2.74,2.74,0,0,1-2-2.93M43,20.63C50.51,20.63,55.81,0,43,0s-7.51,20.63,0,20.63M70.08,73.17c6.14,0,10.48-16.88,0-16.88s-6.14,16.88,0,16.88m-54.16,0c6.14,0,10.48-16.88,0-16.88s-6.14,16.88,0,16.88m38.26,19.9,1.2-12c.1-1,.22-2.17,1-2.77a10.76,10.76,0,0,1,3.35-1.2c2.45-.68,3.62-1.3,6.09-2,.71,2.87,1.24,7.35,2.55,10l.7-5.81,1-.7,1,.7.7,5.81c1.32-2.65,1.84-7.13,2.55-10,2.47.7,3.64,1.33,6.09,2a10.27,10.27,0,0,1,3.39,1.23A4.14,4.14,0,0,1,84.79,81l1.2,12A2.74,2.74,0,0,1,84,96H56.15A2.74,2.74,0,0,1,54.18,93.07ZM41.07,57.62a1.93,1.93,0,1,1,3.86,0v8.9L52.55,71a2,2,0,0,1,.71,2.65,1.91,1.91,0,0,1-2.63.72L43,69.88l-7.63,4.45a1.91,1.91,0,0,1-2.63-.72A2,2,0,0,1,33.45,71l7.62-4.44Z"/></g></g></g></g></g></svg>
            </div>
            <span class="sticker-text">
              {{'hierarchy' | translate}}
            </span>
          </div>
        </div>
      </div>
      <div class="tile" *ngIf="isAllowed('Company')">
        <div class="tile-body" (click)="routeTo('organization/list/Company')">
          <div class="tile-sticker arrow-box">
            <div class="sticker-body">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96"><defs><style>.a{fill:#fff;}</style></defs><title>Companies</title><path class="a" d="M37,92V85h4v7H61V62.75L27,58.28V92Zm55,0h4v4H0V92H5V5H25V0h8V5H53V28.89l11-1.76V18H85v5.77l7-1.12Zm-4,0V27.35L42,34.71V56.22l23,3V92ZM23,92V53.72l15,2V31.29l11-1.76V9H9V92ZM83,24.09V20H66v6.81ZM13,13h4v8H13Zm0,11h4v8H13Zm7,0h4v8H20Zm0,11h4v8H20ZM41,13h4v8H41Zm-7,0h4v8H34Zm-7,0h4v8H27Zm0,11h4v8H27ZM80,35h4v8H80Zm0,11h4v8H80Zm-7,0h4v8H73Zm7,11h4v8H80ZM73,36h4v7H73Zm-7,1h4v6H66Zm-7,1h4v5H59ZM30,63l4,.5V71H30Zm0,11h4v8H30Zm14,0h4v8H44ZM37,64l4,.5V71H37Zm7,1,4,.5V71H44Zm7,1,4,.5V71H51Z"/></svg>
            </div>
            <span class="sticker-text">
              {{'companies' | translate}}
            </span>
          </div>
        </div>
      </div>
      <div class="tile" *ngIf="isAllowed('Client')">
        <div class="tile-body" (click)="routeTo('organization/list/Client')">
          <div class="tile-sticker arrow-box">
            <div class="sticker-body">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 90"><defs><style>.a{fill:#fff;}</style></defs><title>Clients</title><path class="a" d="M5,86V15H17V0H80V15H92V86h4v4H0V86Zm4,0H38V55H59V86H88V19H9ZM21,15H76V4H21ZM59,27V47H38V27Zm2,0H80V47H61Zm0,28H80V75H61ZM36,27V47H17V27Zm0,28V75H17V55Z"/></svg>
            </div>
            <span class="sticker-text">
              {{'clients' | translate}}
            </span>
          </div>
        </div>
      </div>
      <div class="tile" *ngIf="isAllowed('Customer')">
        <div class="tile-body" (click)="routeTo('organization/list/Customer')">
          <div class="tile-sticker arrow-box">
            <div class="sticker-body">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 85"><defs><style>.a{fill:#fff;}</style></defs><title>Customers</title><path class="a" d="M84,81V30.1L47.55,4.88,12,30.07V81H38V50H58V81ZM8,81V28L47.52,0,88,28V81h8v4H0V81ZM58,23v7H38V23l10-7Zm2,27H79V70H60ZM36,50V70H17V50ZM17,38H79v4H17Z"/></svg>
            </div>
            <span class="sticker-text">
                {{'customers' | translate}}
            </span>
          </div>
        </div>
      </div>
      <div class="tile">
        <div class="tile-body" (click)="routeTo('sites/list')">
          <div class="tile-sticker arrow-box">
            <div class="sticker-body">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96"><defs><style>.cls-1{fill:#fff;}</style></defs><title>Sites</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Admin-V2"><g id="Portal-Admin-Console-V2-SA"><g id="Group"><path id="Sites" class="cls-1" d="M30.75,71.11,4.5,82.59v8.28l26.25-6.56ZM38.8,85.5l3.36-13.18-8.41-1.8V84.3ZM91.5,67.71l-4.64,1.56a111.49,111.49,0,0,1,1,19.2L91.5,88ZM75.22,36.08A84.68,84.68,0,0,1,85,59.89q.24,1,.46,1.95l6-2V34Zm-63.3,35.1L8.77,59.87A3.72,3.72,0,0,1,9,57.34l9.46-23.82L4.5,37V74.43ZM62.25,91.08V76.63L49.5,73.9,46.1,87.23Zm3-14.52V91.3l15.09-1.89a106.07,106.07,0,0,0-.7-17.7Zm0-39.24V68.67l13.12-4.43q-.28-1.29-.59-2.57A76.9,76.9,0,0,0,67,37.11ZM1.83,33l15.12-3.78a36.91,36.91,0,0,0,3.83,7.07l2.88,4.26L16.31,59.08l2.52,9.08,11.92-5.22V51.09l3,4.44v7.33L62.25,69V37.2l-2.48-.59.19-.29a43.54,43.54,0,0,0,2.28-3.75l1.63.39,29.6-3.7a2.25,2.25,0,0,1,2.51,2,2.31,2.31,0,0,1,0,.27h0V90a2.25,2.25,0,0,1-2,2.24L64,96a2.25,2.25,0,0,1-.84-.06L32.26,88.56,2.79,95.93A2.25,2.25,0,0,1,0,93.75H0V35.25A2.25,2.25,0,0,1,1.83,33Zm54.4.77L41.43,55.73a1.38,1.38,0,0,1-2.11,0L24.51,33.81c-2.6-3.85-4.76-8.17-4.76-13.18a20.63,20.63,0,0,1,41.25,0C61,25.64,58.83,30,56.23,33.81ZM40.37,8.88A11.25,11.25,0,1,0,51.62,20.13,11.25,11.25,0,0,0,40.37,8.88Z"/></g></g></g></g></g></svg>
            </div>
            <span class="sticker-text">
                {{'sites' | translate}}
            </span>
          </div>
        </div>
      </div>
      <div class="tile">
        <div class="tile-body" (click)="routeTo('user/list')">
          <div class="tile-sticker arrow-box">
            <div class="sticker-body">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.7 96"><defs><style>.cls-1{fill:#fff;}</style></defs><title>Users</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Admin-V2"><g id="Portal-Admin-Console-V2-SA"><g id="Group-3"><path id="Users" class="cls-1" d="M43.31,77.81l.53-1.26C47.05,69.56,50,59,51.9,51.49h0l.17-.66v-.06l.17-.69c5.92,2.3,14.17,3,17.11,9,3,6.13,5.84,23,6.33,33.83a3,3,0,0,1-.94,2.2,2.88,2.88,0,0,1-2.15.92H3a2.88,2.88,0,0,1-2.15-.92A2.94,2.94,0,0,1,0,92.88C.49,82,3.24,65.17,6.27,59c2.95-6,11.2-6.68,17.11-9l.14.54C24.64,55,25.72,59,26.87,62.84c1.2,4,2.54,8,4,11.44.51,1.29,1,2.59,1.6,3.9l2.78-18.06L34.1,55.69l3.73-2.44,3.72,2.44-1.12,4.43,2.87,17.69ZM26.5,4.32a17,17,0,0,1,22.82.16C52,6.84,54.6,8,55.6,12.63c.72,3.35,0,7.48-.73,10.81v0c1-.25,1.91-.19,2.2.85A6.15,6.15,0,0,1,57,28.11c-.38,1.08-1.94,2.06-2.76,3a2.19,2.19,0,0,1-1.64.53C49.57,39.21,44.39,46,37.82,46S26.07,39.21,23.09,31.63a2.19,2.19,0,0,1-1.64-.53c-.82-.92-2.38-1.91-2.76-3a6.14,6.14,0,0,1-.12-3.78c.29-1,1.24-1.1,2.2-.85C20,20.14,19.31,16,20,12.63,21.05,7.9,23.71,6.78,26.5,4.32Z"/></g></g></g></g></g></svg>
            </div>
            <span class="sticker-text">
                {{'users' | translate}}
            </span>
          </div>
        </div>
      </div>
      <div class="tile" *ngIf="isDMuser">
        <div class="tile-body">
          <div class="tile-sticker arrow-box">
            <div class="sticker-body">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 76.47"><defs><style>.cls-1{fill:#fff;}</style></defs><title>Assets</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Admin-V2"><g id="Portal-Admin-Console-V2-SA"><g id="Group-8"><path id="Assets" class="cls-1" d="M48,6.37a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,48,6.37ZM16.89,13.49V57H79.48V41.51l.34.39a1.64,1.64,0,0,0,1.91.56V57.61a1.63,1.63,0,0,1-1.63,1.63H16.26a1.63,1.63,0,0,1-1.63-1.63V12.87a1.63,1.63,0,0,1,1.63-1.63h43.6q-.39.69-.72,1.42l-4.64.16a1.5,1.5,0,0,0-1.2.67ZM79.2,6.28,81.85,2,88,5.59,85.66,10a14.18,14.18,0,0,1,3.64,6.31l5,.17v7.12l-5,.17a14.18,14.18,0,0,1-3.64,6.31L88,34.51l-6.17,3.56L79.2,33.83a14.31,14.31,0,0,1-7.28,0l-2.65,4.24L63.1,34.51l2.35-4.42a14.18,14.18,0,0,1-3.64-6.31l-5-.17V16.49l5-.17A14.18,14.18,0,0,1,65.45,10L63.1,5.59,69.27,2l2.65,4.24A14.31,14.31,0,0,1,79.2,6.28ZM54.86,28.05l2.88-1L60,30.91l-2.34,2a10,10,0,0,1,0,4.7l2.34,2-2.25,3.9-2.88-1a9.92,9.92,0,0,1-4.07,2.35l-.54,3H45.75l-.54-3a9.93,9.93,0,0,1-4.07-2.35l-2.88,1L36,39.57l2.34-2a10,10,0,0,1,0-4.7l-2.34-2L38.25,27l2.89,1a9.93,9.93,0,0,1,4.07-2.35l.54-3h4.51l.54,3A9.92,9.92,0,0,1,54.86,28.05ZM48,29.7a5.54,5.54,0,1,0,5.54,5.54A5.54,5.54,0,0,0,48,29.7ZM69.73,14.22a8.25,8.25,0,1,0,11.66,0A8.25,8.25,0,0,0,69.73,14.22ZM48,76.47a398.53,398.53,0,0,1-42.59-2c-7.47-.81-6.95-12,.24-12.17l1.52,0a6.61,6.61,0,0,1-.38-2.09l0-52.44a6.54,6.54,0,0,1,6.16-6.36C23,.32,35.49,0,48,0c5.7,0,11.41.07,16.89.24l-5.54,3.2a1.5,1.5,0,0,0-.74,1.12c-3.5-.06-7.05-.09-10.61-.09-12.43,0-24.81.35-34.67,1.34A2.1,2.1,0,0,0,11.22,7.7V60.14a2.1,2.1,0,0,0,1.87,2.1H83a2,2,0,0,0,1.8-2.1l0-19.43,4.5-2.6,0,22a6.62,6.62,0,0,1-.37,2.09c2.77,0,5.1.18,6.51,3.16,1.68,3.55-.06,8.54-4.77,9a399.1,399.1,0,0,1-42.64,2ZM5.86,70a393,393,0,0,0,41.76,2,396.51,396.51,0,0,0,42.52-2c1.85-.2,1.76-3.24.24-3.24H5.62c-1.52,0-1.61,3,.24,3.24Zm37.89.14a1.12,1.12,0,0,1,0-2.25h8.49a1.12,1.12,0,0,1,0,2.25Z"/></g></g></g></g></g></svg>
            </div>
            <span class="sticker-text">
              {{'term_condition' | translate}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="tiles-container">
      <!-- hierarchy -->
      <div class="tile" *ngIf="!isDMuser">
        <div class="tile-body">
          <div class="tile-buttons">
            <span class="button view-button hierarchy-view-button" (click)="routeTo('hierarchy')">
              {{'view' | translate}}
            </span>
          </div>
        </div>
        <span class="tile-caption">
          <p>
            <span>&nbsp;</span>
          </p>
        </span>
      </div>

      <!-- company -->
      <div class="tile"  *ngIf="isAllowed('Company')">
        <div class="tile-body">
          <div class="tile-buttons">
            <span class="button view-button company-view-button" (click)="routeTo('organization/list/Company')">
              {{'view' | translate}}
            </span>
            <span class="button add-button company-add-new-button" (click)="routeTo('organization/add/Company')" *ngIf="showHideAddButton('Company')">
              {{'add_new' | translate}}
            </span>
          </div>
        </div>
        <span class="tile-caption">
          <p>{{getData(landingPageDetails.company)}}
              <span *ngIf=finnishLocale> {{'noOfCompanies' | translate}} </span>
              <span *ngIf=!finnishLocale> {{'companies' | translate}} </span>
          </p>
        </span>
      </div>

      <!-- client -->
      <div class="tile"  *ngIf="isAllowed('Client')">
        <div class="tile-body">
          <div class="tile-buttons">
            <span class="button view-button client-view-button" (click)="routeTo('organization/list/Client')">
              {{'view' | translate}}
            </span>
            <span class="button add-button client-add-new-button" (click)="routeTo('organization/add/Client')" *ngIf="showHideAddButton('Client')">
              {{'add_new' | translate}}
            </span>
          </div>
        </div>
        <span class="tile-caption">
          <p>{{getData(landingPageDetails.client)}}
            <span *ngIf=finnishLocale> {{'noOfClients' | translate}} </span>
            <span *ngIf=!finnishLocale> {{'clients' | translate}} </span>
          </p>
        </span>
      </div>

      <!-- customer -->
      <div class="tile"  *ngIf="isAllowed('Customer')">
        <div class="tile-body">
          <div class="tile-buttons">
            <span class="button view-button customer-view-button" (click)="routeTo('organization/list/Customer')">
              {{'view' | translate}}
            </span>
            <span class="button add-button customer-add-new-button" (click)="routeTo('organization/add/Customer')" *ngIf="showHideAddButton('Customer')">
              {{'add_new' | translate}}
            </span>
          </div>
        </div>
        <span class="tile-caption">
          <p>{{getData(landingPageDetails.customer)}}
            <span> {{'customer' | translate}} </span>
          </p>
        </span>
      </div>

      <!-- sites -->
      <div class="tile">
        <div class="tile-body">
          <div class="tile-buttons">
            <span class="button view-button sites-view-button" (click)="routeTo('sites/list')">
              {{'view' | translate}}
            </span>
            <span class="button add-button sites-add-new-button" (click)="routeTo('sites/add')" *ngIf="showHideAddButton('Site')">
              {{'add_new' | translate}}
            </span>
          </div>
        </div>
        <span class="tile-caption">
          <p>{{getData(landingPageDetails.sites)}}
            <span *ngIf=finnishLocale> {{'noOfSites' | translate}} </span>
            <span *ngIf=!finnishLocale> {{'sites' | translate}} </span>
          </p>
        </span>
      </div>

      <!-- users -->
      <div class="tile">
        <div class="tile-body">
          <div class="tile-buttons">
            <span class="button view-button users-view-button" (click)="routeTo('user/list')">
              {{'view' | translate}}
            </span>
            <span class="button add-button users-add-new-button" (click)="routeTo('user/add')" *ngIf="showHideAddButton('User')">
              {{'add_new' | translate}}
            </span>
          </div>
        </div>
        <span class="tile-caption">
          <p>{{getData(landingPageDetails.users)}}
            <span *ngIf=finnishLocale> {{'noOfUsers' | translate}} </span>
            <span *ngIf=!finnishLocale> {{'users' | translate}} </span>
          </p>
        </span>
      </div>
      
      <!-- T&C -->
      <div class="tile" *ngIf="isDMuser">
        <div class="tile-body">
          <div class="tile-buttons">
            <span class="button view-button terms-view-button">
              {{'view' | translate}}
            </span>
            <span class="button add-button terms-add-new-button">
              {{'add_new' | translate}}
            </span>
          </div>
        </div>
        <span class="tile-caption">
          <p><span>&nbsp;</span></p>
        </span>
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="solutionView" (click)="solutionView = false">
  </div>
