<div class="cookie-consent-container"
    *ngIf="showCookieConsentPopup && (showCookieConsentOption || showCustomizeConsentOption)">

    <div *ngIf="showCookieConsentOption" class="cookie-consent-option-popup">
        <div class="cookie-consent-description">
            <p>
                By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site
                navigation and analyse site usage. <br>
                <a target="_blank" href="{{diverseyCookiePolicy}}">Diversey Cookie Policy</a>
            </p>
        </div>
        <div class="cookie-consent-option-btns">
            <button class="btn btn-accept-all" (click)="onAcceptAll()">Accept All Cookies</button>
            <button class="btn btn-customize" (click)="onCLickCustomizeSettings()">Cookie Settings</button>
        </div>
    </div>

    <div *ngIf="showCustomizeConsentOption" class="cookie-consent-customize-popup">
        <div class="cookie-consent-custom-header">
            <h2>Cookie Settings</h2>
        </div>
        <div class="cookie-consent-custom-body">

            <p>
                When you visit any of our websites, it may store or retrieve information on your browser, mostly in the
                form of
                cookies. This information might be about you, your preferences or your device and is mostly used to make
                the site work as you expect it to. The information does not usually directly identify you, but it can
                give you a more personalized web experience. Because we respect your right to privacy, you can choose
                not to allow some types of cookies. Click on the different category headings to find out more and change
                our default settings. However, blocking some types of cookies may impact your experience of the site and
                the services we are able to offer.
            </p>

            <div class="accordion-content" *ngFor="let cookieConsent of cookieConsentList; let i = index">
                <div class="accordion-header" (click)="expandCookieConsent(i)">
                    <span class="arrow " [ngClass]="(cookieConsent.expand)? 'up' : 'down'"></span>
                    <span class="accordion-header-name">{{cookieConsent.name}}</span>

                    <label class="switch" [ngClass]="{'disabled': cookieConsent.disabled}">
                        <input type="checkbox" id="togBtn" [disabled]="cookieConsent.disabled"
                            [checked]="cookieConsent.selected" (change)="toggleSelect(i,$event)">
                        <span class="slider round" [ngClass]="{'disabled': cookieConsent.disabled}">
                            <span class="on"></span>
                            <span class="off"></span>
                        </span>
                    </label>

                </div>
                <div class="accordion-body" *ngIf="cookieConsent.expand">
                    <p>{{cookieConsent.description}}</p>
                    <table *ngIf="cookieConsent.cookieList.length > 0">
                        <tr>
                            <th>Name</th>
                            <th>Domain</th>
                            <th>Expiration</th>
                            <th>Description</th>
                        </tr>
                        <tr *ngFor="let cookieItem of cookieConsent.cookieList">
                            <td>{{cookieItem.name}}</td>
                            <td>{{cookieItem.domain}}</td>
                            <td>{{cookieItem.expiration}}</td>
                            <td>{{cookieItem.description}}</td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>
        <div class="cookie-consent-custom-footer">
            <button class="btn allow-all-btn" (click)="onAcceptAll()"> Allow All</button>
            <button class="btn allow-selection-btn" (click)="onAllowSelection()">Confirm My Choices</button>
        </div>
    </div>
</div>