
import {throwError as observableThrowError,  Observable ,  of } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClientService } from '../../base/services';
import { Solution } from '../models/solution';
import { environment } from '../../../environments/environment';
import { catchError ,  map } from 'rxjs/operators';

@Injectable()
export class SitesService {
  public hierarchyConfig = {
    title: 'Hierarchy & Sites*',
    multiselect: false,
    select: 'node'
  };
  public URLS = {
    deactive: `${environment.NODE_URL}power-admin/sites`,
    deleteDeactive: `${environment.NODE_URL}power-admin/site`
  };
  private NEW_API_ENDPOINT = environment.NODE_URL;
  private recievedSolutions = [];

  constructor( private _httpClientService: HttpClientService ) { }

  fetchSitesList(request) {
    const url = `${this.NEW_API_ENDPOINT}site/list`;
    return this._httpClientService.post(url, request)
      .pipe(
        map(res => res),
        catchError(error => this.handleError(error))
      );
  }

  getOrgData(orgId) {
    const url = `${this.NEW_API_ENDPOINT}organization/${orgId}`;
    return this._httpClientService.get(`${url}`)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  getSiteDetails(siteId) {
    const url = `${this.NEW_API_ENDPOINT}site`;
    const request = { search: {}, sort: {} };
    return this._httpClientService.get(`${url}/${siteId}`)
      .pipe(
        map(res => res),
        catchError(error => this.handleError(error))
      );
  }

  createSite(site): Observable<any> {
    const url = `${this.NEW_API_ENDPOINT}site`;
    return this._httpClientService.post(url, site)
      .pipe(
        map(res => res),
        catchError(error => this.handleError(error))
      );
  }

  updateSite(site, siteId): Observable<any> {
    const url = `${this.NEW_API_ENDPOINT}site/${siteId}`;
    return this._httpClientService.put(url, site)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  assignSolutions(siteId, solutions) {
    const url = `${this.NEW_API_ENDPOINT}site/solution-assign/${siteId}`;
    return this._httpClientService.put(url, { solutions: solutions })
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  activateDeactivateSite(siteId, flag) {
    const request = { is_active: flag };
    const url = `${this.NEW_API_ENDPOINT}site/action/${siteId}`;
    return this._httpClientService.put(url, request)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  disableSite(siteId) {
    const request = { is_disabled: true };
    const url = `${this.NEW_API_ENDPOINT}site/action/${siteId}`;
    return this._httpClientService.put(url, request)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  public getHierarchyConfig(title = null) {
    const hierarchyConfig = this.hierarchyConfig;
    hierarchyConfig['title'] = title;
    return hierarchyConfig;
  }

  createSubSite(subSiteType, subSiteData): Observable<any> {
    const url = `${this.NEW_API_ENDPOINT}site/${subSiteData.site_id}/${subSiteType}`;
    return this._httpClientService.post(url, subSiteData)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  // list all building/campus under given site-id
  getSubSiteList(siteId, subSiteType, parentSubSiteId = null): Observable<any> {
    let url;
    if (parentSubSiteId) {
      url = `${this.NEW_API_ENDPOINT}site/${siteId}/${subSiteType}s/${parentSubSiteId}`;
    } else {
      url = `${this.NEW_API_ENDPOINT}site/${siteId}/${subSiteType}s`;
    }
    return this._httpClientService.get(url)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  subSiteGenericService(site_id, subSiteType, formData, subSiteId = null): Observable<any> {
    let url;
    let serviceMethod;
    if (subSiteId) {
      url = `${this.NEW_API_ENDPOINT}site/${site_id}/${subSiteType}/${subSiteId}`;
      serviceMethod = this._httpClientService.put(url, formData);
    } else {
      url = `${this.NEW_API_ENDPOINT}site/${site_id}/${subSiteType}`;
      serviceMethod = this._httpClientService.post(url, formData);
    }
    return serviceMethod.pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  // countryCode like 'US', 'UK', 'IN', etc...
  fetchTimeZoneList(countryCode) {
    const url = `${this.NEW_API_ENDPOINT}common/timezones/country/${countryCode}`;
    return this._httpClientService.get(url)
      .pipe(
        map(res => res),
        catchError(error => this.handleError(error))
      );
  }

  activateDeactivateSubSite(siteId, subSiteType, subSiteId, flag) {
    const request = { is_active: flag };
    subSiteType = subSiteType.toLowerCase();
    const url = `${this.NEW_API_ENDPOINT}site/${siteId}/action/${subSiteType}/${subSiteId}`;
    return this._httpClientService.put(url, request)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  getDeactiveSites() {
    const url = `${this.URLS.deactive}`;
    return this._httpClientService.get(url)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  deleteDeactiveSites(id) {
    const url = `${this.URLS.deleteDeactive}/${id}`;
    return this._httpClientService.delete(url, null)
    .pipe(
      map(res => res),
      catchError(error => this.handleError(error))
    );
  }

  private handleError(error) {
    return observableThrowError(error.error || 'Server Error');
  }

  private transformData(data) {
    this.recievedSolutions = data.solutions;
    this.recievedSolutions = this.recievedSolutions.map(item => new Solution(item, true));
    data.solutions = this.recievedSolutions;
    return data;
  }

}
