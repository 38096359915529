export class HierarchyNode {
  public id: string;
  public name: string;
  public parentId: string;
  public breadcrumbs: string;
  public isDeleted: number;
  public pathLength: number;
  public type: string;
  public children?: HierarchyNode[];
  public sites?: HierarchyNode[];
  public checked = false;
  public expand = false;

  constructor(node) {
    this.id = node.id;
    this.name = node.name;
    this.breadcrumbs = node.breadcrumbs;
    this.type = node.type;
    this.parentId = node.parentId;
    if (node.children) {
      this.children = [];
      this.children = node.children.map(child => new HierarchyNode(child));
    }
    if (node.sites) {
      this.sites = [];
      this.sites = node.sites.map(child => new HierarchyNode(child));
    }
  }

  public check(event?: Event, data?: HierarchyNode[], config?: any, node?: HierarchyNode): void {
    this.checked = !this.checked;
    event.stopPropagation();
    if (config['multiselect']) {
      this.setAllChildren(this.checked);
    }
  }

  setAllChildren(condition: boolean, node?: HierarchyNode) {
    if (node) {
      node.checked = condition;
    }
    if (this.children) {
      this.children.forEach(children => {
        children.setAllChildren(condition, children);
      });
    }
    if (this.sites) {
      this.sites.forEach(site => {
        site.setAllChildren(condition, site);
      });
    }
  }

  collapseOthers(hierarchyArr: HierarchyNode[], item) {
    hierarchyArr.forEach(level => {
      if (level.id !== item.id) {
        if (level.children) {
          this.collapseOthers(level.children, item);
        }
        if (level.sites) {
          this.collapseOthers(level.sites, item);
        }
        level.expand = false;
      }
    });
  }

  public toggle(event: Event, hierarchyNodes: HierarchyNode[], item): void {
    event.stopPropagation();
    this.collapseOthers(hierarchyNodes, item);
    this.expand = !this.expand;
  }
}
