// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  SupportEmailId: 'digital.support@diversey.com',
  MAPS_API_URL: 'https://maps.googleapis.com/maps/api/geocode/json?address=',
  MAPS_TIMEZONE_API_URL: 'https://maps.googleapis.com/maps/api/timezone/json?location=',
  MAPS_TIMEZONE_API_KEY: 'AIzaSyAR2zqTfKGaUCkvELCYOiOAPWxJkUDFESg',
  NODE_URL: 'https://uat-pac.diverseyiot.com/api/v1/',
  COOKIE_DOMAIN_NAME: '.diverseyiot.com',
  DASHBOARD_HOST_URL: 'https://uat-dashboard.diverseyiot.com/',
  PAC_HOST_URL: 'https://uat-pac.diverseyiot.com/',
  COOKIE_PREFIX: 'preprod_',
  ENVIRONMENT: 'uat',
  ZIP_VALIDATION: false,
  BULK_UPLOAD: 'https://docs.google.com/spreadsheets/d/1oMUvM4IsofnhZfiTKJFfN6YLXEYairUtapksrMcIQuo/edit#gid=0',
  TERMS_CONDITIONS: 'https://www.diversey.com/ioc-terms-and-conditions',
  OKTA_DOMAIN: 'https://diversey.okta.com',
  OKTA_ISSUER: 'https://diversey.okta.com',
  OKTA_CLIENT_ID: '0oao8pnyovtAyMM7W357'
};
