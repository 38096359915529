import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit, Inject } from '@angular/core';
import { UserService } from 'app/base/services';
import { locales } from '../../../../assets/pac_i18n/locale';
import { CookieStorage } from 'app/base/services';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
  host: {
    '(document: click)': 'hideDrop($event)'
  }
})
export class LanguageDropdownComponent implements OnInit, AfterViewInit {
  @Input() public reload: boolean;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() value: string;
  public toggleDiv = false;
  public languages = [];
  public country_locale = new locales();
  // default selected language will be 'en-US'
  public selectedLanguage = {
    name: null,
    locale: null
  };
  public locale;
  private window: Window;

  constructor(
    private userService: UserService,
    private cookieStorage: CookieStorage,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
    const locale_value = this.cookieStorage.getCookie('locale') || 'en-US';
    if (locale_value && this.country_locale.dictionary[locale_value]) {
      this.locale = this.country_locale.dictionary[locale_value];
    }
  }

  ngOnInit() {
    this.fetchLanguages();
  }

  ngAfterViewInit() {
    if (this.reload === false) {
      const id = this.document.getElementById('popup');
      if (id) {
        id.addEventListener('click', this.hideDrop.bind(this));
      }
    }
  }

  fetchLanguages() {
    this.userService.getCommonData('languages').subscribe(res => {
      if (res && res.data) {
        this.languages = res.data;
        this.showSelectedLanguage();
      }
    });
  }

  showSelectedLanguage() {
    if (this.value) {
      if (this.languages && this.languages.length) {
        this.selectedLanguage = this.languages.find(lang => lang.locale === this.value);
      }
    } else {
      const cookieLocale = this.cookieStorage.getCookie('locale') || 'en-US';
      if (this.languages && this.languages.length) {
        this.selectedLanguage = this.languages.find(lang => lang.locale === cookieLocale);
      }
    }
  }

  toggle(e) {
    e.stopPropagation();
    this.toggleDiv = !this.toggleDiv;
  }

  hideDrop(e) {
    e.stopPropagation();
    this.toggleDiv = false;
  }

  onChange(language) {
    if (this.reload === false) {
      this.selectedLanguage = language;
      this.onSelect.emit(this.selectedLanguage);
      this.toggleDiv = false;
    } else if (language && language.locale) {
      this.cookieStorage.setCookie('locale', language.locale);
      this.selectedLanguage = language;
      this.window.setTimeout(() => this.document.location.reload(), 2000);
    }
  }

  getLangFlag() {
    return this.selectedLanguage.locale || null;
  }

  getLangName() {
    return this.selectedLanguage.name || 'Language';
  }

}
